var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "v-layout",
        {
          staticClass: "align-center entry_filters_left_justified",
          attrs: { row: "" }
        },
        [
          _c(
            "v-flex",
            {
              staticClass: "align-center",
              attrs: { lg7: "", md6: "", sm9: "", xs7: "" }
            },
            [_c("span", [_vm._v(_vm._s(_vm.heading))])]
          ),
          !_vm.all_open
            ? _c(
                "v-flex",
                {
                  staticClass: "align-start justify-start mr-1",
                  attrs: { lg1: "" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "white mr-4 ml-5",
                      attrs: { text: "", "x-small": "" },
                      on: {
                        click: function($event) {
                          _vm.show = !_vm.show
                        }
                      }
                    },
                    [
                      !_vm.show
                        ? _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v(" mdi-chevron-down ")
                          ])
                        : _vm._e(),
                      _vm.show
                        ? _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v(" mdi-chevron-up ")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          true
            ? _c(
                "v-flex",
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "", "max-width": "300px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c("div", _vm._g({}, on), [
                                  _c("span", {
                                    domProps: { innerHTML: _vm._s(_vm.result) }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "ml-1 green--text text--darken-2 fine-tune-font"
                                    },
                                    [
                                      _vm._v(
                                        "Rank " +
                                          _vm._s(
                                            _vm.$store.getters.getDsmAppState
                                              .dsm_filter_setttings.results
                                              .dsmresult[0]["Market_Rank"]
                                          ) +
                                          "/ "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "trade-blue yellow--text  rounded row-pointer pa-1",
                                          on: {
                                            click: _vm.get_total_opportunities
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$store.getters.getDsmAppState
                                                .dsm_filter_setttings.results
                                                .dsmresult[0][
                                                "Market_REO_Count"
                                              ]
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" opportunities (REO"),
                                      _c("sub", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$store.state.tradeDSM
                                                .dsm_filter_setttings.Filters
                                                .META.combinations[
                                                "reo_summary_values"
                                              ][0]["REO_col"]
                                            ) +
                                            "," +
                                            _vm._s(
                                              _vm.$store.state.tradeDSM
                                                .dsm_filter_setttings.Filters
                                                .META.combinations[
                                                "reo_summary_values"
                                              ][0]["REO_row"]
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _vm._v(") ")
                                    ]
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        57362944
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Click here to view these " +
                            _vm._s(
                              _vm.$store.getters.getDsmAppState
                                .dsm_filter_setttings.results.dsmresult[0][
                                "Market_REO_Count"
                              ]
                            ) +
                            " markets."
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.show
        ? _c(
            "v-layout",
            {
              staticClass: "justify-space-between mt-2 mb-2 mr-2",
              attrs: { row: "" }
            },
            [
              _c(
                "v-flex",
                {
                  staticClass: "blue-grey white--text pa-2",
                  attrs: { lg12: "", md7: "", sm11: "", xs11: "" }
                },
                [
                  _c("span", { staticClass: "pa-3 transition-ease-in-out" }, [
                    _vm._v(_vm._s(_vm.explanation) + " ")
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }