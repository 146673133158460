<template>
  <v-row class="no-gutters">
    <v-col sm="12">
      <v-card class="pa-2 green--text" elevation="0">
        <div v-if="get_meta_data_message !== ''" class="mb-2">
          <v-icon class="mr-1 orange--text">mdi-alert</v-icon>
          {{ get_meta_data_message() }}
        </div>

        <v-icon class="mr-1">mdi-earth</v-icon>
        <template v-if="$store.state.tradeData.report_selection === 'EMF_Overview'">
          <span
            v-if="
              $store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .report_type_to_generate === '1PMC'
            "
            >Filtered markets =
            {{
              $store.state.tradeDSM.dsm_filter_setttings.Filters.META.combinations
                .markets_count
            }}
          </span>
          <span
            v-else-if="
              $store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .report_type_to_generate === '1P1C' ||
                $store.state.tradeDSM.dsm_filter_setttings.Filters.META
                  .report_type_to_generate === 'MP1C'
            "
            class="font-weight-bold "
          >
            {{ selected_market() }}</span
          >
        </template>
        <template v-else>
          <span> All traded markets. (Excluding Re-Imports and Re-Exports)</span>
        </template>
      </v-card>
      <v-card class="pa-2 green--text" elevation="0">
        <v-icon class="mr-1">mdi-dolly</v-icon>
        <span
          class="font-weight-bold"
          v-if="
            $store.state.tradeDSM.dsm_filter_setttings.Filters.META
              .report_type_to_generate === '1PMC' ||
              $store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .report_type_to_generate === '1P1C'
          "
          >[{{ code_to()['HS6_Code'] }}] {{ code_to()['HS6_Description'] }}</span
        >
        <span
          v-else-if="
            $store.state.tradeDSM.dsm_filter_setttings.Filters.META
              .report_type_to_generate === 'MP1C'
          "
        >
          Filtered products ={{
            $store.state.tradeDSM.dsm_filter_setttings.Filters.META.combinations
              .products_count
          }}
        </span>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'tabbed-graph-top-view-text',
  methods: {
    selected_market() {
      if (
        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.markets
          .C_Code.length > 0
      ) {
        return `${this.codeToMarket(
          this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.markets
            .C_Code[0],
        )} (${
          this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.markets
            .C_Code[0]
        })`;
      } else {
        return '';
      }
    },
    get_meta_data_message() {
      if (this.$store.state.tradeData.report_selection !== 'EMF_Overview') {
        if (this.$store.state.tradeData.trade_data_cube['export_year']) {
          return this.$store.state.tradeData.trade_data_cube['export_year']['data_meta'][
            'data_message'
          ];
        } else {
          return 'Loading...';
        }
      } else
        return this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.Data_Models
          .Trade_History.data_message;
    },
  },
};
</script>
<style scoped>
@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

/* Pulse class and keyframe animation */
@-webkit-keyframes pulse {
  0% {
    width: 200px;
  }
  50% {
    width: 340px;
  }
  100% {
    width: 200px;
  }
}

@keyframes pulse {
  0% {
    width: 200px;
  }
  50% {
    width: 340px;
  }
  100% {
    width: 200px;
  }
}
</style>
