var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "white ma-0 pa-0 no-gutters" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "align-left text-left mb-0 pb-0 ",
              attrs: { sm: "6" }
            },
            [
              _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .report_type_to_generate === "1P1C"
                ? _c("span", { staticClass: "small mr-8 pr-12 pa-0 ma-0" }, [
                    _c("span", { staticClass: "home_dot" }),
                    _vm._v(
                      _vm._s(
                        "Home Market - " +
                          _vm.$store.state.app.selectedHomeCountry +
                          " [" +
                          _vm.$store.state.app.selectedModelName +
                          "]"
                      ) + " "
                    ),
                    _c("span", [
                      _c("span", { staticClass: "green_dot_1p1c" }),
                      _vm._v(
                        _vm._s(
                          "Target Market - " +
                            _vm.codeToMarket(
                              _vm.$store.state.tradeDSM.dsm_filter_setttings
                                .Filters.META.selections.markets.C_Code[0]
                            )
                        ) + " "
                      )
                    ]),
                    _c("span", { staticClass: "red_dot" }),
                    _vm._v(
                      "Major competing sources of imports into target market. Size indicates relative value imported from each competitor country. "
                    )
                  ])
                : _vm._e(),
              _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .report_type_to_generate === "1PMC" ||
              _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .report_type_to_generate === "MPMC" ||
              _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .report_type_to_generate === "MP1C"
                ? _c("span", { staticClass: "small mr-8 pr-12" }, [
                    _c("div", { staticClass: "ma-0 pa-0" }, [
                      _c("span", { staticClass: "home_dot" }),
                      _vm._v(
                        _vm._s(
                          "Home Market - " +
                            _vm.$store.state.app.selectedHomeCountry +
                            " [" +
                            _vm.$store.state.app.selectedModelName +
                            "]"
                        ) + " "
                      )
                    ]),
                    _c("div", [
                      _c("span", { staticClass: "green_dot_1pmc" }),
                      _vm._v(
                        "Markets importing selected product(s) from all markets. Size indicates 'untapped' potential from home market's perspective. "
                      )
                    ]),
                    _c("div", [
                      _c("span", { staticClass: "grey_dot_1pmc" }),
                      _vm._v("No results.")
                    ])
                  ])
                : _vm._e()
            ]
          ),
          _vm.show_map_ta_overlay
            ? _c(
                "v-col",
                { staticClass: "mb-0 pb-0", attrs: { sm: "3" } },
                [
                  _c("v-img", {
                    attrs: {
                      contain: "",
                      "content-class": "img-center",
                      "max-height": "100",
                      "max-width": "65",
                      src: _vm.loader_institution_image_url()
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("v-col", { attrs: { sm: "3" } }, [
            _c("span", { staticClass: "small_font" }, [
              _vm._v("Map not to scale")
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }