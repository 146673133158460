var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "pa-0 ma-0", attrs: { "ml-2": "", row: "" } },
    [
      _c(
        "v-container",
        [
          !(
            _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
              .combinations.products_count === 1 &&
            _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
              .combinations.markets_count === 1
          )
            ? [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c("v-col", [
                      _vm.entry.display_unit === "currency"
                        ? _c(
                            "select",
                            {
                              staticClass:
                                "blue white--text pa-1 pt-0 pb-0 rounded mr-1 caption mt-2",
                              staticStyle: { width: "75px" },
                              on: {
                                change: function($event) {
                                  return _vm.update_values($event)
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { disabled: "" },
                                  domProps: { value: _vm.multiplier_selected }
                                },
                                [_vm._v("Please Select")]
                              ),
                              _c("option", [_vm._v("Base")]),
                              _c("option", [_vm._v("K (000)")]),
                              _c("option", [_vm._v("Mn (Million)")]),
                              _c("option", [_vm._v("Bn (Billion)")])
                            ]
                          )
                        : _vm._e()
                    ]),
                    _c(
                      "v-col",
                      { attrs: { sm: "3" } },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "", color: "success" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "span",
                                        _vm._g({}, on),
                                        [
                                          _c("v-text-field", {
                                            staticClass: "caption",
                                            attrs: {
                                              type: _vm.number,
                                              outlined: "",
                                              "single-line": "",
                                              dense: "",
                                              "hide-details": true,
                                              "hide-spin-buttons": true,
                                              disabled:
                                                _vm.filters_updating_status ||
                                                (_vm.entry_min ===
                                                  _vm.entry_max &&
                                                  !_vm.filters_changed),
                                              "append-outer-icon":
                                                _vm.entry_min === _vm.entry_max
                                                  ? "mdi-code-equal"
                                                  : "mdi-code-greater-than-or-equal"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.update_state(
                                                  _vm.filter_name,
                                                  _vm.entry.id,
                                                  [
                                                    _vm.entry_min,
                                                    _vm.entry_max
                                                  ],
                                                  false
                                                )
                                              },
                                              input: function($event) {
                                                return _vm.update_state(
                                                  _vm.filter_name,
                                                  _vm.entry.id,
                                                  [
                                                    _vm.entry_min,
                                                    _vm.entry_max
                                                  ],
                                                  false
                                                )
                                              }
                                            },
                                            model: {
                                              value: _vm.entry_min,
                                              callback: function($$v) {
                                                _vm.entry_min = $$v
                                              },
                                              expression: "entry_min"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              748888309
                            )
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.entry_min_hover_f))])]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { sm: "3" } },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "", color: "success" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _vm.entry_max !== _vm.entry_min ||
                                      _vm.filters_changed
                                        ? _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                staticClass: "caption",
                                                attrs: {
                                                  type: _vm.number,
                                                  outlined: "",
                                                  "single-line": "",
                                                  "hide-details": true,
                                                  "hide-spin-buttons": true,
                                                  disabled:
                                                    _vm.filters_updating_status,
                                                  dense: "",
                                                  "prepend-icon":
                                                    "mdi-code-less-than-or-equal"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.update_state(
                                                      _vm.filter_name,
                                                      _vm.entry.id,
                                                      [
                                                        _vm.entry_min,
                                                        _vm.entry_max
                                                      ],
                                                      false
                                                    )
                                                  },
                                                  input: function($event) {
                                                    return _vm.update_state(
                                                      _vm.filter_name,
                                                      _vm.entry.id,
                                                      [
                                                        _vm.entry_min,
                                                        _vm.entry_max
                                                      ],
                                                      false
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: _vm.entry_max,
                                                  callback: function($$v) {
                                                    _vm.entry_max = $$v
                                                  },
                                                  expression: "entry_max"
                                                }
                                              },
                                              on
                                            )
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              4198617680
                            )
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.entry_max_hover_f))])]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "ml-2 pt-1", attrs: { sm: "3" } },
                      [
                        _c(
                          "div",
                          { staticClass: "d-inline-block" },
                          [
                            (_vm.entry_min !== _vm.entry.min / _vm.multiplier ||
                              _vm.entry_max !==
                                _vm.entry.max / _vm.multiplier) &&
                            !_vm.filters_updating_status &&
                            _vm.entry.last_selected === true
                              ? [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        top: "",
                                        "max-width": "100px",
                                        color: "success"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "yellow mt-1",
                                                      attrs: { "x-small": "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.update_state(
                                                            _vm.filter_name,
                                                            _vm.entry.id,
                                                            [
                                                              _vm.entry_min,
                                                              _vm.entry_max
                                                            ],
                                                            true
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v(" Update ")]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        781415606
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Click on [UPDATE] to refresh summary info with your latest filter changes. To extract updated results for current filter changes select [APPLY] at the top of this form."
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            : _c(
                "v-flex",
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "", color: "success" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c("div", _vm._g({ staticClass: "ml-7" }, on), [
                                _vm._v(" " + _vm._s(_vm.entry_max) + " ")
                              ])
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.p1c1_hover_text_f))])]
                  )
                ],
                1
              )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }