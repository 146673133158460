var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "v-tooltip",
        {
          attrs: {
            right: "",
            disabled: !this.$store.state.user.userServerInfo.app_config
              .help_dict[this.help_id]["show_help_text"]
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-icon",
                    _vm._g(
                      {
                        staticClass:
                          "grey lighten-2 rounded-circle pa-1 black--text",
                        attrs: { "x-small": "" },
                        on: {
                          click: function($event) {
                            return _vm.open_help(_vm.help_id)
                          }
                        }
                      },
                      on
                    ),
                    [_vm._v(" mdi-help ")]
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.show_link))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }