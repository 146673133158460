var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0 pl-0 white", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        {
          staticClass: "justify-space-around mt-2 pb-2 pr-1 pl-0",
          attrs: { row: "" }
        },
        [
          _c(
            "MapContainer",
            [
              _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .selections.markets.C_Code.length === 1 &&
              _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .selections.products.HS6_Code.length === 1
                ? _c("world-map")
                : _c("WorldMap_MPMC")
            ],
            1
          ),
          !_vm.$store.state.app.big_map
            ? _c(
                "v-flex",
                {
                  staticClass: "mt-2",
                  attrs: { lg7: "", md12: "", sm12: "", xl6: "" }
                },
                [
                  _c("top-summary-results", { staticClass: "mb-2" }),
                  _c("dsm-single-text-prod-country", {
                    staticClass: "ma-0 pa-0 align-center white"
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      !_vm.$store.state.tradeDSM.loading_indicator &&
      !_vm.$store.state.app.big_map &&
      !_vm.$store.state.tradeDSM.productsOpen &&
      !_vm.$store.state.tradeDSM.countriesOpen &&
      _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.combinations
        .combinations > 0
        ? [
            _c("tabbed_graph_view", {
              attrs: { caption: "Trade statistics and trends", help_id: "8" }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }