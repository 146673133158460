import { render, staticRenderFns } from "./snapshot-report-1p1c.vue?vue&type=template&id=058b719e&scoped=true&"
import script from "./snapshot-report-1p1c.vue?vue&type=script&lang=js&"
export * from "./snapshot-report-1p1c.vue?vue&type=script&lang=js&"
import style0 from "./snapshot-report-1p1c.vue?vue&type=style&index=0&id=058b719e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "058b719e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VLayout,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('058b719e')) {
      api.createRecord('058b719e', component.options)
    } else {
      api.reload('058b719e', component.options)
    }
    module.hot.accept("./snapshot-report-1p1c.vue?vue&type=template&id=058b719e&scoped=true&", function () {
      api.rerender('058b719e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/trade-dsm-application/result-components/snapshot-report-1p1c.vue"
export default component.exports