import { render, staticRenderFns } from "./tab-mp1c.vue?vue&type=template&id=95b0c036&"
import script from "./tab-mp1c.vue?vue&type=script&lang=js&"
export * from "./tab-mp1c.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VContainer,VFlex,VLayout,VSkeletonLoader,VTab,VTabItem,VTabs})


/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('95b0c036')) {
      api.createRecord('95b0c036', component.options)
    } else {
      api.reload('95b0c036', component.options)
    }
    module.hot.accept("./tab-mp1c.vue?vue&type=template&id=95b0c036&", function () {
      api.rerender('95b0c036', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/trade-dsm-application/results-pages/tabs/tab-mp1c.vue"
export default component.exports