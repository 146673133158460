var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return true
    ? _c(
        "v-container",
        [
          _vm.display_advanved_filters_applied.items.length > 0
            ? _c(
                "v-layout",
                { staticClass: "ml-0 mr-1", attrs: { row: "" } },
                [
                  _c(
                    "span",
                    { staticClass: "result_box_no_border mt-1 mr-2 pa-0" },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.$store.commit(
                                                "toggleProductsFilter",
                                                true
                                              )
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [_vm._v("mdi-dolly")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1179193414
                          )
                        },
                        [_c("span", [_vm._v("Click to open product filters")])]
                      )
                    ],
                    1
                  ),
                  _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                    .report_type_to_generate !== "1PMC"
                    ? _c(
                        "v-flex",
                        { staticClass: "mt-1 ml-4" },
                        [
                          _c(
                            "v-expansion-panels",
                            { staticClass: "ma-0 pa-0" },
                            [
                              _c(
                                "v-expansion-panel",
                                { staticClass: "elevation-0" },
                                [
                                  _c("v-expansion-panel-header", {
                                    staticClass: "ma-o pa-0",
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var open = ref.open
                                            return [
                                              open
                                                ? _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "justify-space-between align-center"
                                                    },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticClass: "ml-9",
                                                          attrs: { sm10: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ma-0 pa-0 ml-1"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$store
                                                                    .state
                                                                    .tradeDSM
                                                                    .dsm_filter_setttings
                                                                    .Filters
                                                                    .META
                                                                    .selections
                                                                    .products
                                                                    .HS6_Code
                                                                    .length > 0
                                                                    ? "Selected"
                                                                    : "Filtered"
                                                                ) + " products "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                "x-small": "",
                                                                text: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                  _vm.html_to_xls_download(
                                                                    "results_current_products"
                                                                  )
                                                                  open = true
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "green--text"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-microsoft-excel"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c("v-flex")
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "span",
                                                    { staticClass: "ml-2" },
                                                    [
                                                      _vm._v(
                                                        "Click to view " +
                                                          _vm._s(
                                                            _vm.$store.state
                                                              .tradeDSM
                                                              .dsm_filter_setttings
                                                              .Filters.META
                                                              .selections
                                                              .products.HS6_Code
                                                              .length > 0
                                                              ? "selected"
                                                              : "filtered"
                                                          ) +
                                                          " products (" +
                                                          _vm._s(
                                                            _vm.$store.getters
                                                              .selectionCounts
                                                              .results
                                                              .products_count
                                                          ) +
                                                          ")"
                                                      )
                                                    ]
                                                  )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      410790569
                                    )
                                  }),
                                  _c(
                                    "v-expansion-panel-content",
                                    { staticClass: "ma-0 pa-0" },
                                    [
                                      _c("v-data-table", {
                                        staticClass: "elevation-1 ma-0 pa-0",
                                        attrs: {
                                          id: "results_current_products",
                                          headers:
                                            _vm
                                              .display_advanved_filters_applied[
                                              "headers"
                                            ],
                                          items:
                                            _vm
                                              .display_advanved_filters_applied[
                                              "items"
                                            ],
                                          "items-per-page": 5,
                                          dense: "",
                                          "footer-props": {
                                            "items-per-page-options": _vm.calc_items_per_page(
                                              _vm
                                                .display_advanved_filters_applied[
                                                "items"
                                              ].length,
                                              4,
                                              _vm.$store.state.user
                                                .userServerInfo.app_config
                                                .max_products
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-flex",
                        { staticClass: "green--text mb-2 sm11 ml-2" },
                        [
                          _c(
                            "span",
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { left: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c("span", [
                                              _c(
                                                "a",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "align-center",
                                                      attrs: {
                                                        href:
                                                          _vm.itc_products_url,
                                                        target: "_blank"
                                                      }
                                                    },
                                                    "a",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-img", {
                                                    staticClass:
                                                      "d-inline-block mb-auto mt-auto",
                                                    attrs: {
                                                      contain: "",
                                                      "max-width": "40",
                                                      src: require("../../../assets/img/itc-logo.gif")
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                " [" +
                                                  _vm._s(_vm.single_product) +
                                                  "] " +
                                                  _vm._s(
                                                    _vm.hs6_codeToDescription(
                                                      _vm.single_product
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    591253972
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Click to view ITC tariff information"
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }