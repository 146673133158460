var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$store.state.tradeDSM.loading_indicator
    ? _c(
        "div",
        { staticClass: "white" },
        [
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c("v-flex", { staticClass: "xs12 sm12 lg12" }, [
                _c(
                  "div",
                  { staticClass: "body-2 font-weight-bold nav nav-tabs mt-2" },
                  [
                    _c("li", [
                      _c(
                        "a",
                        [
                          _vm._v(
                            "Home market export characteristics for product "
                          ),
                          _c("help-link", {
                            staticStyle: { float: "right" },
                            attrs: { help_id: "2" }
                          }),
                          !_vm.all_open_selection
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "white--text mr-0 pr-0",
                                  staticStyle: { float: "right" },
                                  attrs: { text: "", "x-small": "" },
                                  on: {
                                    click: function($event) {
                                      _vm.all_open_selection = true
                                    }
                                  }
                                },
                                [
                                  !_vm.all_open_selection
                                    ? _c("v-icon", [
                                        _vm._v(" mdi-chevron-triple-down")
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.all_open_selection
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "white--text mr-0 pr-0",
                                  staticStyle: { float: "right" },
                                  attrs: { text: "", "x-small": "" },
                                  on: {
                                    click: function($event) {
                                      _vm.all_open_selection = false
                                    }
                                  }
                                },
                                [
                                  _vm.all_open_selection
                                    ? _c("v-icon", [
                                        _vm._v(" mdi-chevron-triple-up")
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ]
                )
              ])
            ],
            1
          ),
          _c("div", { staticClass: "tab-content" }, [
            _c(
              "div",
              { staticClass: "tab-pane active white black--text pl-3" },
              [
                _c(
                  "v-container",
                  { attrs: { fluid: "" } },
                  [
                    _c("result_hover", {
                      attrs: {
                        all_open: _vm.all_open_selection,
                        explanation: _vm.get_dsm_result["REO_F4_1_Text"],
                        result:
                          _vm.get_yes_no("RCA1_0") +
                          " <span class='ml-2 green--text rta_rta_text'>" +
                          _vm.share_text() +
                          "</span>",
                        heading:
                          "Home market is comparatively large exporter of product? [F4.1]"
                      }
                    }),
                    _c("result_hover", {
                      attrs: {
                        all_open: _vm.all_open_selection,
                        explanation: _vm.get_dsm_result["REO_F4_2_Text"],
                        result:
                          _vm.get_yes_no("RTA_0") +
                          " <span class='ml-2 green--text rta_rta_text'>" +
                          _vm.rta_text() +
                          "</span>",
                        heading:
                          "Home market has product export production capacity? [F4.2]"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c("v-flex", { staticClass: "xs12 sm12 lg12" }, [
                _c(
                  "div",
                  { staticClass: "body-2 font-weight-bold nav nav-tabs mt-2" },
                  [
                    _c("li", [
                      _c(
                        "a",
                        {},
                        [
                          _vm._v("Opportunity characteristics "),
                          _c("help-link", {
                            staticStyle: { float: "right" },
                            attrs: { help_id: "3" }
                          }),
                          !_vm.all_open_export
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "white--text mr-0 pr-0",
                                  staticStyle: { float: "right" },
                                  attrs: { text: "", "x-small": "" },
                                  on: {
                                    click: function($event) {
                                      _vm.all_open_export = true
                                    }
                                  }
                                },
                                [
                                  !_vm.all_open_export
                                    ? _c("v-icon", [
                                        _vm._v(" mdi-chevron-triple-down")
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.all_open_export
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "white--text mr-0 pr-0",
                                  staticStyle: { float: "right" },
                                  attrs: { text: "", "x-small": "" },
                                  on: {
                                    click: function($event) {
                                      _vm.all_open_export = false
                                    }
                                  }
                                },
                                [
                                  _vm.all_open_export
                                    ? _c("v-icon", [
                                        _vm._v(" mdi-chevron-triple-up")
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ]
                )
              ])
            ],
            1
          ),
          _c("div", { staticClass: "tab-content" }, [
            _c(
              "div",
              { staticClass: "tab-pane active white black--text pl-3" },
              [
                _c(
                  "v-container",
                  {
                    staticClass: "justify-center align-center",
                    attrs: { fluid: "" }
                  },
                  [
                    _c("result_hover_opportunities", {
                      attrs: {
                        all_open: _vm.all_open_export,
                        explanation: _vm.get_dsm_result["Overall_Text"],
                        result: _vm.opportunity,
                        heading: "Is this an overall opportunity?"
                      }
                    }),
                    _c("result_hover", {
                      attrs: {
                        all_open: _vm.all_open_export,
                        explanation: _vm.get_dsm_result["REO_F1_Text"],
                        result: _vm.get_yes_no("F1_Select"),
                        heading:
                          "Market economy size, wealth, growth trends & risk?"
                      }
                    }),
                    _c("result_hover", {
                      attrs: {
                        all_open: _vm.all_open_export,
                        explanation: _vm.get_dsm_result["REO_F2_Text"],
                        result: _vm.get_yes_no("F2_Select"),
                        heading:
                          "Market import demand size and growth patterns?"
                      }
                    }),
                    _c("result_hover", {
                      attrs: {
                        all_open: _vm.all_open_export,
                        explanation: _vm.get_dsm_result["REO_F2_4_Text"],
                        result: _vm.get_yes_no("F2_Positive"),
                        heading:
                          "Market historical strong positive import demand growth?"
                      }
                    }),
                    _c("result_hover", {
                      attrs: {
                        all_open: _vm.all_open_export,
                        explanation: _vm.get_dsm_result["REO_F3_1_Text"],
                        result: _vm.get_yes_no("F31_Select"),
                        heading:
                          "Market trading with various alternative supplying countries"
                      }
                    }),
                    _c("result_hover", {
                      attrs: {
                        all_open: _vm.all_open_export,
                        explanation: _vm.get_dsm_result["REO_F3_2_Text"],
                        result: _vm.get_yes_no("F32_Select"),
                        heading:
                          "Market is relatively accessible in terms of tariffs & logistics?"
                      }
                    }),
                    _c("result_hover", {
                      attrs: {
                        all_open: _vm.all_open_export,
                        explanation: _vm.get_dsm_result["REO_HM_Rank_Text"],
                        result: _vm.rank + " out of " + _vm.total_exporters,
                        heading:
                          "Home market share of imports into target market [rank]:"
                      }
                    }),
                    _c("result_hover", {
                      attrs: {
                        all_open: _vm.all_open_export,
                        explanation: _vm.supply.text,
                        image: _vm.supply.src,
                        result: null,
                        heading: "Home market supply into target market"
                      }
                    }),
                    _c("result_hover", {
                      attrs: {
                        all_open: _vm.all_open_export,
                        explanation: _vm.demand.text,
                        image: _vm.demand.src,
                        result: null,
                        heading: "Target market demand characteristics"
                      }
                    }),
                    _c("result_hover", {
                      attrs: {
                        all_open: _vm.all_open_export,
                        explanation: _vm.get_dsm_result["REO_Tariff_Text"],
                        heading:
                          "Country x Product tariff for model:  " +
                          _vm.add_velorem_tariff.toFixed(2) +
                          "% [" +
                          _vm.add_velorem_tariff_year +
                          "]",
                        result: null
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    : _c("v-skeleton-loader", {
        staticClass: "mx-auto mt-5",
        attrs: { "max-width": "100%", type: "card" }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }