var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show_reo,
          expression: "show_reo"
        }
      ],
      key:
        _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.combinations
          .combinations,
      staticClass: "outline"
    },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "ma-0 pa-0 no-gutters" },
            [
              _c(
                "v-col",
                { staticClass: "ma-0 pa-0 no-gutters" },
                [
                  _c("span", { staticClass: "black--text ml-1" }, [
                    _vm._v(
                      " Map of Realistic Export Opportunities (REOs): " +
                        _vm._s(_vm.market_or_product()) +
                        " "
                    )
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticClass: "black--text float-right mr-12",
                      staticStyle: { float: "left" },
                      attrs: { "data-html2canvas-ignore": "" },
                      on: {
                        click: function($event) {
                          return _vm.element2clipboard("REO_FULL_TABLE")
                        }
                      }
                    },
                    [_vm._v("mdi-camera ")]
                  )
                ],
                1
              ),
              _vm.is_run_cube_valid
                ? _c(
                    "v-col",
                    {
                      staticClass: "mt-0 pt-0",
                      attrs: { sm: "12", md: "12", lg: "7", xl: "7" }
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "black--text ml-2" },
                        [
                          _vm._v(
                            " Click in a REO cell on the left for specific REO column information "
                          ),
                          _c("help-link", { attrs: { help_id: "57" } })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  attrs: {
                    sm: "12",
                    md: "12",
                    lg: _vm.is_run_cube_valid ? 5 : 12,
                    xl: _vm.is_run_cube_valid ? 5 : 12,
                    id: "REO_FULL_TABLE"
                  }
                },
                [
                  _c("table", [
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { attrs: { colspan: "2", rowspan: "3" } }, [
                          _vm._v(" No. "),
                          _c("br"),
                          _vm._v(" [%of total no.]"),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(_vm.multiplerString) +
                              " " +
                              _vm._s(_vm.currencyString) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(" Untapped potential value "),
                          _c("br"),
                          _vm._v(" [% of total value] ")
                        ]),
                        _c("td", { attrs: { colspan: "4" } }, [
                          _vm._v(
                            "Home market relative share of target market imports"
                          )
                        ]),
                        _c("td", { staticClass: "noborder-topright" })
                      ]),
                      _c("tr", [
                        _c("td", { attrs: { rowspan: "2" } }, [
                          _vm._v("Small (x1)")
                        ]),
                        _c("td", { attrs: { colspan: "2" } }, [
                          _vm._v("Intermediate")
                        ]),
                        _c("td", { attrs: { rowspan: "2" } }, [
                          _vm._v("Large (x4)")
                        ]),
                        _c("td", { attrs: { rowspan: "2" } }, [
                          _vm._v("Row Totals")
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("Small (x2)")]),
                        _c("td", [_vm._v("Large (x3)")])
                      ]),
                      _c(
                        "tr",
                        [
                          _c("td", { attrs: { rowspan: "5" } }, [
                            _vm._v("Product x Country market size and growth")
                          ]),
                          _c("td", [
                            _vm._v("Large "),
                            _c("br"),
                            _vm._v("(y1)")
                          ]),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large | Home market - Small"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["1_1"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large | Home market - Small",
                                  [1],
                                  [1]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large | Home market - Intermediate - Small"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["2_1"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large | Home market - Intermediate - Small",
                                  [1],
                                  [2]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large | Home market - Intermediate - Large"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["3_1"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large | Home market - Intermediate - Large",
                                  [1],
                                  [3]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large | Home market - Large"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["4_1"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large | Home market - Large",
                                  [1],
                                  [4]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large - Total"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["1_T"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large - Total",
                                  [1],
                                  []
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "tr",
                        [
                          _c("td", [
                            _vm._v("Growing (y2) (Short & Long term)")
                          ]),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Growing - Short & Long term | Home market - Small"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["1_2"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Growing - Short & Long term | Home market - Small",
                                  [2],
                                  [1]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Growing - Short & Long term | Home market - Intermediate - Small"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["2_2"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Growing - Short & Long term | Home market - Intermediate - Small",
                                  [2],
                                  [2]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Growing - Short & Long term | Home market - Intermediate - Large"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["3_2"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Growing - Short & Long term | Home market - Intermediate - Large",
                                  [2],
                                  [3]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Growing - Short & Long term | Home market - Large"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["4_2"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Growing - Short & Long term | Home market - Large",
                                  [2],
                                  [4]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Growing - Short & Long term - Total"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["2_T"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Growing - Short & Long term - Total",
                                  [2],
                                  []
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "tr",
                        [
                          _c("td", [
                            _vm._v("Large and growing (y3) (Short term)")
                          ]),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large and Growing - Short term | Home market - Small"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["1_3"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large and Growing - Short term | Home market - Small",
                                  [3],
                                  [1]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large and Growing - Short term | Home market - Intermediate - Small"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["2_3"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large and Growing - Short term | Home market - Intermediate - Small",
                                  [3],
                                  [2]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large and Growing - Short term | Home market - Intermediate - Large"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["3_3"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large and Growing - Short term | Home market - Intermediate - Large",
                                  [3],
                                  [3]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large and Growing - Short term |  Home market - Large"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["4_3"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large and Growing - Short term |  Home market - Large",
                                  [3],
                                  [4]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large and Growing - Short term - Total"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["3_T"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large and Growing - Short term - Total",
                                  [3],
                                  []
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "tr",
                        [
                          _c("td", [
                            _vm._v("Large and growing (y4) (Long term)")
                          ]),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large and Growing - Long term |  Home market - Small"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["1_4"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large and Growing - Long term |  Home market - Small",
                                  [4],
                                  [1]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large and Growing - Long term |  Home market - Intermediate - Small"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["2_4"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large and Growing - Long term |  Home market - Intermediate - Small",
                                  [4],
                                  [2]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large and Growing - Long term |  Home market - Intermediate - Large"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["3_4"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large and Growing - Long term |  Home market - Intermediate - Large",
                                  [4],
                                  [3]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large and Growing - Long term |  Home market - Large"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["4_4"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large and Growing - Long term |  Home market - Large",
                                  [4],
                                  [4]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large and Growing - Long term - Total"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["4_T"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large and Growing - Long term - Total",
                                  [4],
                                  []
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "tr",
                        [
                          _c("td", [
                            _vm._v("Large and growing (y5) (Short & Long term)")
                          ]),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large and Growing - Short and Long term |  Home market - Small"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["1_5"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large and Growing - Short and Long term |  Home market - Small",
                                  [5],
                                  [1]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large and Growing - Short and Long term |  Home market - Intermediate - Small"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["2_5"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large and Growing - Short and Long term |  Home market - Intermediate - Small",
                                  [5],
                                  [2]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large and Growing - Short and Long term |  Home market - Intermediate - Large"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["3_5"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large and Growing - Short and Long term |  Home market - Intermediate - Large",
                                  [5],
                                  [3]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large and Growing - Short and Long term |  Home market - Large"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["4_5"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large and Growing - Short and Long term |  Home market - Large",
                                  [5],
                                  [4]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Product x Country - Large and Growing - Short and Long term - Total"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["5_T"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Product x Country - Large and Growing - Short and Long term - Total",
                                  [5],
                                  []
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "tr",
                        [
                          _c(
                            "td",
                            { staticClass: "noborder-bottomleft" },
                            [
                              _c("v-checkbox", {
                                attrs: { label: "Show  all totals", dense: "" },
                                model: {
                                  value: _vm.show_REO_totals,
                                  callback: function($$v) {
                                    _vm.show_REO_totals = $$v
                                  },
                                  expression: "show_REO_totals"
                                }
                              })
                            ],
                            1
                          ),
                          _c("td", [_vm._v("REO Totals")]),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Home market - Small - Total"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["T_1"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Home market - Small - Total",
                                  [],
                                  [1]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Home market - Intermediate Small - Total"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["T_2"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Home market - Intermediate Small - Total",
                                  [],
                                  [2]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Home market - Intermediate Large - Total"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["T_3"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Home market - Intermediate Large - Total",
                                  [],
                                  [3]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked ===
                                "Home market - Large - Total"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["T_4"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "Home market - Large - Total",
                                  [],
                                  [4]
                                )
                              }
                            }
                          }),
                          _c("reo-td", {
                            class: {
                              "seleted-box":
                                _vm.last_cell_clicked === "All opportunities"
                            },
                            attrs: {
                              data: _vm.calc_reo_data(_vm.cube_name)["T_T"]
                            },
                            on: {
                              newReoParameters: function($event) {
                                return _vm.cellClicked(
                                  $event,
                                  "All opportunities",
                                  [],
                                  []
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm.show_REO_totals
                        ? _c(
                            "tr",
                            { staticClass: "yellow lighten-3" },
                            [
                              _c("td", { staticClass: "noborder-bottomleft" }),
                              _c("td", [_vm._v("Non REO Totals")]),
                              _c("reo-td", {
                                class: {
                                  "seleted-box":
                                    _vm.last_cell_clicked === "T1_0"
                                },
                                attrs: {
                                  hover: false,
                                  data: _vm.calc_reo_data(_vm.cube_name)["T1_0"]
                                },
                                on: {
                                  newReoParameters: function($event) {
                                    return _vm.total_non_reo_cells_clicked(
                                      [1],
                                      "T1_0"
                                    )
                                  }
                                }
                              }),
                              _c("reo-td", {
                                class: {
                                  "seleted-box":
                                    _vm.last_cell_clicked === "T2_0"
                                },
                                attrs: {
                                  hover: false,
                                  data: _vm.calc_reo_data(_vm.cube_name)["T2_0"]
                                },
                                on: {
                                  newReoParameters: function($event) {
                                    return _vm.total_non_reo_cells_clicked(
                                      [2],
                                      "T2_0"
                                    )
                                  }
                                }
                              }),
                              _c("reo-td", {
                                class: {
                                  "seleted-box":
                                    _vm.last_cell_clicked === "T3_0"
                                },
                                attrs: {
                                  hover: false,
                                  data: _vm.calc_reo_data(_vm.cube_name)["T3_0"]
                                },
                                on: {
                                  newReoParameters: function($event) {
                                    return _vm.total_non_reo_cells_clicked(
                                      [3],
                                      "T3_0"
                                    )
                                  }
                                }
                              }),
                              _c("reo-td", {
                                class: {
                                  "seleted-box":
                                    _vm.last_cell_clicked === "T4_0"
                                },
                                attrs: {
                                  hover: false,
                                  data: _vm.calc_reo_data(_vm.cube_name)["T4_0"]
                                },
                                on: {
                                  newReoParameters: function($event) {
                                    return _vm.total_non_reo_cells_clicked(
                                      [4],
                                      "T4_0"
                                    )
                                  }
                                }
                              }),
                              _c("reo-td", {
                                class: {
                                  "seleted-box":
                                    _vm.last_cell_clicked === "TT_0"
                                },
                                attrs: {
                                  hover: false,
                                  data: _vm.calc_reo_data(_vm.cube_name)["TT_0"]
                                },
                                on: {
                                  newReoParameters: function($event) {
                                    return _vm.total_non_reo_cells_clicked(
                                      [1, 2, 3, 4],
                                      "TT_0"
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.show_REO_totals
                        ? _c(
                            "tr",
                            { staticClass: "yellow lighten-3" },
                            [
                              _c("td", { staticClass: "noborder-bottomleft" }),
                              _c("td", [_vm._v("Grand Total")]),
                              _c("reo-td", {
                                class: {
                                  "seleted-box":
                                    _vm.last_cell_clicked === "TT1_TT"
                                },
                                attrs: {
                                  hover: false,
                                  data: _vm.calc_reo_data(_vm.cube_name)[
                                    "TT1_TT"
                                  ]
                                },
                                on: {
                                  newReoParameters: function($event) {
                                    return _vm.grand_total_cells_clicked(
                                      [1],
                                      "TT1_TT"
                                    )
                                  }
                                }
                              }),
                              _c("reo-td", {
                                class: {
                                  "seleted-box":
                                    _vm.last_cell_clicked === "TT2_TT"
                                },
                                attrs: {
                                  hover: false,
                                  data: _vm.calc_reo_data(_vm.cube_name)[
                                    "TT2_TT"
                                  ]
                                },
                                on: {
                                  newReoParameters: function($event) {
                                    return _vm.grand_total_cells_clicked(
                                      [2],
                                      "TT2_TT"
                                    )
                                  }
                                }
                              }),
                              _c("reo-td", {
                                class: {
                                  "seleted-box":
                                    _vm.last_cell_clicked === "TT3_TT"
                                },
                                attrs: {
                                  hover: false,
                                  data: _vm.calc_reo_data(_vm.cube_name)[
                                    "TT3_TT"
                                  ]
                                },
                                on: {
                                  newReoParameters: function($event) {
                                    return _vm.grand_total_cells_clicked(
                                      [3],
                                      "TT3_TT"
                                    )
                                  }
                                }
                              }),
                              _c("reo-td", {
                                class: {
                                  "seleted-box":
                                    _vm.last_cell_clicked === "TT4_TT"
                                },
                                attrs: {
                                  hover: false,
                                  data: _vm.calc_reo_data(_vm.cube_name)[
                                    "TT4_TT"
                                  ]
                                },
                                on: {
                                  newReoParameters: function($event) {
                                    return _vm.grand_total_cells_clicked(
                                      [4],
                                      "TT4_TT"
                                    )
                                  }
                                }
                              }),
                              _c("reo-td", {
                                class: {
                                  "seleted-box":
                                    _vm.last_cell_clicked === "TT_TT"
                                },
                                attrs: {
                                  hover: false,
                                  data: _vm.calc_reo_data(_vm.cube_name)[
                                    "TT_TT"
                                  ]
                                },
                                on: {
                                  newReoParameters: function($event) {
                                    return _vm.grand_total_cells_clicked(
                                      [1, 2, 3, 4],
                                      "TT_TT"
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                ]
              ),
              _vm.is_run_cube_valid
                ? _c(
                    "v-col",
                    {
                      staticClass: "mt-0 pt-0",
                      attrs: { sm: "12", md: "12", lg: "7", xl: "7" }
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "mx-auto outline-red rounded-0" },
                        [
                          _c(
                            "div",
                            { staticClass: "ml-1 pl-2 mr-2 text-h7 " },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c("v-col", { attrs: { sm: "12" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "font-weight-bold mt-2" },
                                      [
                                        _vm._v(
                                          " Product x Country market size and growth: "
                                        )
                                      ]
                                    ),
                                    _c("div", { staticClass: "ml-3" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.get_cell_name()[
                                              "product_market_selection_text"
                                            ]
                                          ) +
                                          " "
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "mt-1 font-weight-bold" },
                                      [
                                        _vm._v(
                                          " Home market relative share of target market imports: "
                                        )
                                      ]
                                    ),
                                    _c("div", { staticClass: "ml-3" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.get_cell_name()[
                                              "home_market_selection_text"
                                            ]
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]),
                                  _c("v-col", [
                                    _c(
                                      "div",
                                      { staticClass: "font-weight-bold mt-1" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.products_and_markets_count_string
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _vm.current_report_to_run === "MP1C"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "font-weight-bold mt-1"
                                          },
                                          [
                                            _vm._v(
                                              " Map of Realistic Export Opportunities with " +
                                                _vm._s(
                                                  _vm.codeToMarket(
                                                    this.$store.state.tradeDSM
                                                      .dsm_filter_setttings
                                                      .Filters.META.selections
                                                      .markets.C_Code[0]
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm.current_report_to_run === "1PMC"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "font-weight-bold mt-1"
                                          },
                                          [
                                            _vm._v(
                                              " Map of Realistic Export Opportunities for " +
                                                _vm._s(
                                                  this.$store.state.tradeDSM
                                                    .dsm_filter_setttings
                                                    .Filters.META.selections
                                                    .products.HS6_Code[0]
                                                ) +
                                                " - " +
                                                _vm._s(
                                                  _vm.hs6_codeToDescription(
                                                    this.$store.state.tradeDSM
                                                      .dsm_filter_setttings
                                                      .Filters.META.selections
                                                      .products.HS6_Code[0]
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                ],
                                1
                              ),
                              _vm.which_list.length === 0
                                ? _c("p", {
                                    staticClass: "ml-4 font-weight-bold"
                                  })
                                : [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c(
                                              "v-radio-group",
                                              {
                                                staticClass: "ml-2 ",
                                                attrs: { row: "" },
                                                model: {
                                                  value: _vm.showlist,
                                                  callback: function($$v) {
                                                    _vm.showlist = $$v
                                                  },
                                                  expression: "showlist"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      top: "",
                                                      color:
                                                        "trade-blue text-subtitle-1",
                                                      "max-width": "200px"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _vm.combos_list
                                                                .length > 0
                                                                ? _c(
                                                                    "v-radio",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Matrix",
                                                                          value:
                                                                            "market_product_summary"
                                                                        }
                                                                      },
                                                                      on
                                                                    )
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      975383678
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Click to view results as product and market combinations"
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      top: "",
                                                      color:
                                                        "trade-blue text-subtitle-1",
                                                      "max-width": "200px"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-radio",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "List",
                                                                      value:
                                                                        "table"
                                                                    }
                                                                  },
                                                                  on
                                                                )
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2262766331
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Click to view results by resuts as a list"
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      top: "",
                                                      color:
                                                        "trade-blue text-subtitle-1",
                                                      "max-width": "200px"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-radio",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Graph",
                                                                      value:
                                                                        "graph"
                                                                    }
                                                                  },
                                                                  on
                                                                )
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1078511367
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Click to view results as a bar graph"
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          [
                                            _vm.which_list.length > 0
                                              ? _c(
                                                  "v-card-actions",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "trade-blue white--text",
                                                        class: {
                                                          grey: _vm.hide_apply
                                                        },
                                                        attrs: {
                                                          outlined: "",
                                                          text: "",
                                                          disabled:
                                                            _vm.hide_apply
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.update_selection
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Apply this selection (" +
                                                            _vm._s(
                                                              this.which_list.filter(
                                                                function(v) {
                                                                  return v.selected
                                                                }
                                                              ).length
                                                            ) +
                                                            ") "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "trade-blue white--text mr-2",
                                                        attrs: {
                                                          outlined: "",
                                                          text: ""
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.clear_selection
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Clear this selection "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                              _c("p", { staticClass: "mr-4" }, [
                                !_vm.showlist === "table" &&
                                _vm.last_cell_clicked
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "float-right font-weight-bold"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.multiplerString) +
                                            " " +
                                            _vm._s(_vm.currencyString)
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ],
                            2
                          ),
                          _vm.showlist === "graph"
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "mr-2 outline ml-1 pt-1" },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "mr-4 black--text float-right mr-3",
                                                  staticStyle: {
                                                    float: "left"
                                                  },
                                                  attrs: {
                                                    "data-html2canvas-ignore":
                                                      ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.element2clipboard(
                                                        "REO_FULL_GRAPH"
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("mdi-camera ")]
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "overflow overflow-x-hidden",
                                              attrs: { id: "REO_FULL_GRAPH" }
                                            },
                                            [
                                              _vm.which_list.length > 0
                                                ? _c("vue-plotly", {
                                                    ref: "reoTableRef",
                                                    staticClass: "mr-3",
                                                    attrs: {
                                                      id: "plotid",
                                                      data: _vm.plotData(
                                                        _vm.cube_name
                                                      ),
                                                      layout: _vm.layout,
                                                      options: _vm.options
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            : _vm._e(),
                          _vm.showlist === "table" && _vm.which_list.length > 0
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "overflow-y-auto overflow-x-hidden  ml-2 mr-2 outline "
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      { staticClass: "ma-0 pa-0 caption" },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass:
                                              "ma-0 pa-0 align-center",
                                            attrs: { "no-gutters": "" }
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "1" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: { text: "" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.download_list()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "green--text mr-2"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-microsoft-excel"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "1" } },
                                              [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "trade-blue white--text",
                                                        class: {
                                                          green:
                                                            _vm.sort_reo_by
                                                              .last_clicked ===
                                                            "code"
                                                        },
                                                        attrs: {
                                                          outlined: "",
                                                          text: "",
                                                          "x-small": ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.sort_clicked(
                                                              "code"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.sort_reo_by[
                                                              "code"
                                                            ]
                                                              ? "↓"
                                                              : "↑"
                                                          ) + " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "8" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "trade-blue white--text  pa-1",
                                                    class: {
                                                      green:
                                                        _vm.sort_reo_by
                                                          .last_clicked ===
                                                        "description"
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      text: "",
                                                      "x-small": ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.sort_clicked(
                                                          "description"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.sort_reo_by[
                                                          "description"
                                                        ]
                                                          ? "↑"
                                                          : "↓"
                                                      ) + " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "trade-blue white--text float-right mr-3",
                                                    class: {
                                                      green:
                                                        _vm.sort_reo_by
                                                          .last_clicked ===
                                                        "reo_monetary_val"
                                                    },
                                                    attrs: {
                                                      outlined: "",
                                                      text: "",
                                                      "x-small": ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.sort_clicked(
                                                          "reo_monetary_val"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.sort_reo_by[
                                                          "reo_monetary_val"
                                                        ]
                                                          ? "↓"
                                                          : "↑"
                                                      ) + " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          {
                                            staticClass:
                                              "ma-0 pa-0 align-center",
                                            attrs: { "no-gutters": "" }
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { right: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "yellow trade-blue--text mt-2 mb-2",
                                                                    attrs: {
                                                                      "x-small":
                                                                        ""
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.select_all
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      " mdi-check "
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3016518437
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Select all")
                                                    ])
                                                  ]
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { right: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "yellow trade-blue--text mt-2 mb-2 ml-2",
                                                                    attrs: {
                                                                      "x-small":
                                                                        ""
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.unselect_all
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      " mdi-close "
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      776157808
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Unselect all")
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._l(_vm.which_list, function(
                                      applied_list,
                                      ix
                                    ) {
                                      return _c(
                                        "p",
                                        {
                                          key: applied_list.id,
                                          staticClass:
                                            "ma-0 pa-0 trade-blue-text caption ma-1 listItem"
                                        },
                                        [
                                          _c(
                                            "v-container",
                                            {
                                              staticClass: "ma-0 pa-0 caption"
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "ma-0 pa-0 align-center",
                                                  attrs: { "no-gutters": "" }
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "2" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.handle_item_click(
                                                                applied_list
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-checkbox", {
                                                            staticClass:
                                                              "shrink small mr-1 ma-0 pa-0 d-inline-block no-gutters",
                                                            attrs: {
                                                              "hide-details": ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "label",
                                                                  fn: function() {
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle: {
                                                                            "font-size":
                                                                              "13px",
                                                                            "margin-top":
                                                                              "3px"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              ix +
                                                                                1
                                                                            ) +
                                                                              "-" +
                                                                              _vm._s(
                                                                                applied_list.code
                                                                              )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  },
                                                                  proxy: true
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                            model: {
                                                              value:
                                                                applied_list.selected,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  applied_list,
                                                                  "selected",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "applied_list.selected"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "9" } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "overflow-hidden",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.highlight(
                                                                applied_list.description,
                                                                _vm.searchkey
                                                              )
                                                            )
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              applied_list.description
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "grey lighten-3",
                                                      attrs: { cols: "1" }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "float-right mr-3"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              applied_list.reo_val
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    })
                                  ],
                                  2
                                )
                              ]
                            : _vm._e(),
                          _vm.showlist === "market_product_summary" &&
                          _vm.combos_list.length > 0
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass: "ml-1 outline mr-1 pt-1 pa-1"
                                  },
                                  [
                                    _c("reo-market-product-matrix", {
                                      attrs: {
                                        combos_list: _vm.combos_list,
                                        market_count: _vm.mpmc_unique_markets()
                                          .length,
                                        product_count: _vm.mpmc_unique_products()
                                          .length,
                                        val_monetary: _vm.get_cell_name()[
                                          "val_monetary"
                                        ]
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }