var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "v-row",
          { attrs: { "no-gutters": "" } },
          [
            _c(
              "v-col",
              { attrs: { sm: "auto" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "pb-3",
                    attrs: { text: "" },
                    on: {
                      click: function($event) {
                        return _vm.html_to_xls_download(
                          "reo_matrix",
                          _vm.display_advanved_filters_applied
                        )
                      }
                    }
                  },
                  [
                    _c("v-icon", { staticClass: "green--text mr-2" }, [
                      _vm._v("mdi-microsoft-excel")
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c("v-col", { attrs: { sm: "auto" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.enable_zoom,
                    expression: "enable_zoom"
                  }
                ],
                attrs: {
                  type: "checkbox",
                  id: "enable_zoom",
                  name: "enable_zoom",
                  value: "enable_zoom"
                },
                domProps: {
                  checked: Array.isArray(_vm.enable_zoom)
                    ? _vm._i(_vm.enable_zoom, "enable_zoom") > -1
                    : _vm.enable_zoom
                },
                on: {
                  change: [
                    function($event) {
                      var $$a = _vm.enable_zoom,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "enable_zoom",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.enable_zoom = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.enable_zoom = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.enable_zoom = $$c
                      }
                    },
                    function($event) {
                      return _vm.zoom_func("reo_matrix", _vm.zoom)
                    }
                  ]
                }
              }),
              _c(
                "label",
                { staticClass: "mr-2", attrs: { for: "enable_zoom" } },
                [_vm._v(" Zoom " + _vm._s(_vm.enable_zoom ? " in " : " out "))]
              ),
              _c("br")
            ]),
            _c("v-col", { attrs: { sm: "2" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.enable_select,
                    expression: "enable_select"
                  }
                ],
                staticClass: "caption ml-1",
                attrs: {
                  type: "checkbox",
                  id: "enable_select",
                  name: "enable_select",
                  value: "enable_select"
                },
                domProps: {
                  checked: Array.isArray(_vm.enable_select)
                    ? _vm._i(_vm.enable_select, "enable_select") > -1
                    : _vm.enable_select
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.enable_select,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "enable_select",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.enable_select = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.enable_select = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.enable_select = $$c
                    }
                  }
                }
              }),
              _c("label", { attrs: { for: "enable_select" } }, [
                _vm._v(
                  " " +
                    _vm._s(!_vm.enable_select ? "Enable " : "Disable ") +
                    "selection"
                )
              ])
            ])
          ],
          1
        )
      ],
      1
    ),
    _c("div", { key: _vm.re_render, staticClass: "ma-2 overflow" }, [
      _c(
        "table",
        {
          key: _vm.re_render,
          staticClass: "caption ",
          attrs: { id: "reo_matrix" }
        },
        [
          _c("thead", [
            _c(
              "tr",
              [
                _c("th", { staticClass: "font-italic" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.multiplerStringLongName) +
                      "-" +
                      _vm._s(_vm.currencyString) +
                      " "
                  )
                ]),
                _vm._m(0),
                _vm._m(1),
                _vm._l(_vm.get_all_products, function(product) {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        key: product,
                        attrs: {
                          color: "trade-blue text-subtitle-1",
                          "max-width": "400px",
                          top: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "th",
                                    _vm._g(
                                      {
                                        staticClass: "rotate",
                                        class: { cell: _vm.enable_select },
                                        on: {
                                          click: function($event) {
                                            return _vm.handle_product_click(
                                              product
                                            )
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "ma-0 pa-0 stick",
                                          class: {
                                            "select-accent": _vm.enable_select,
                                            cell: _vm.enable_select
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handle_product_click(
                                                product
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(product) + " ")]
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(product) +
                              ":" +
                              _vm._s(
                                _vm.$store.state.tradeDSM.hs6_to_desc_hash_map[
                                  product
                                ]
                              )
                          )
                        ])
                      ]
                    )
                  ]
                })
              ],
              2
            )
          ]),
          _c(
            "tr",
            [
              _c("td", {
                staticClass: "full-border pa-1 stick font-weight-bold"
              }),
              _c("td", { staticClass: "text-center ma-1" }),
              _c("td", { staticClass: "text-center ma-1" }, [
                _vm._v("Product Description")
              ]),
              _vm._l(_vm.get_all_products, function(product) {
                return [
                  _c("td", { key: product, staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$store.state.tradeDSM.hs6_to_desc_hash_map[
                            product
                          ]
                        ) +
                        " "
                    )
                  ])
                ]
              })
            ],
            2
          ),
          _c(
            "tr",
            [
              _c("td", {
                staticClass: "full-border pa-1 stick font-weight-bold"
              }),
              _c("td", { staticClass: "text-center ma-1" }),
              _c("td", { staticClass: "text-center ma-1" }, [
                _vm._v("Count (" + _vm._s(_vm.product_count) + ")")
              ]),
              _vm._l(_vm.get_all_products, function(product) {
                return [
                  _c("td", { key: product, staticClass: "text-center" }, [
                    _vm._v(" " + _vm._s(_vm.count_products(product)) + " ")
                  ])
                ]
              })
            ],
            2
          ),
          _c(
            "tr",
            [
              _c("td", { staticClass: "pa-1 font-weight-bold stick" }, [
                _vm._v("Country")
              ]),
              _c("td", { staticClass: "text-center pa-1" }, [
                _vm._v("Count (" + _vm._s(_vm.market_count) + ")")
              ]),
              _c("td", { staticClass: "text-center" }, [
                _vm._v(" Value "),
                _c("br"),
                _vm._v(" (" + _vm._s(_vm.val_monetary) + ") ")
              ]),
              _vm._l(_vm.get_all_products, function(product) {
                return [
                  _c("td", { key: product, staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.vFmt(_vm.sum_products_value(product), 2)) +
                        " "
                    )
                  ])
                ]
              })
            ],
            2
          ),
          _vm._l(_vm.get_all_markets, function(market) {
            return [
              _c(
                "tr",
                { key: market, staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "td",
                    {
                      staticClass: "stick",
                      class: { cell: _vm.enable_select }
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "ma-1",
                          class: { "select-accent": _vm.enable_select },
                          on: {
                            click: function($event) {
                              return _vm.handle_country_click(market)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.codeToMarket(market)) + " ")]
                      )
                    ]
                  ),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(Object.keys(_vm.base_values[market]).length) +
                        " "
                    )
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.vFmt(_vm.sum_markets_value(market), 2)) +
                        " "
                    )
                  ]),
                  _vm._l(_vm.get_all_products, function(product) {
                    return [
                      Object.keys(_vm.base_values[market]).includes(product) &&
                      _vm.base_values[market][product] !== "NaN"
                        ? _c(
                            "td",
                            {
                              key: product,
                              class: _vm.cell_color(
                                _vm.base_values[market][product]
                              )
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "ma-0 pa-1 text-center",
                                  on: {
                                    click: function($event) {
                                      return _vm.handle_cell_click(
                                        product,
                                        market
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.vFmt(
                                          Math.round(
                                            _vm.base_values[market][product] *
                                              100,
                                            2
                                          ) / 100,
                                          2
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        : _c("td", {
                            key: product,
                            staticClass: "ma-0 pa-0 ",
                            class: { empty_hover: _vm.enable_select },
                            on: {
                              click: function($event) {
                                return _vm.handle_cell_click(product, market)
                              }
                            }
                          })
                    ]
                  })
                ],
                2
              )
            ]
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "rotate " }, [_c("div")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "rotate" }, [
      _c("div", [_vm._v("Products")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }