var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        {
          staticClass: "justify-space-around mt-2 pb-2 pr-2 pl-0 mb-6",
          attrs: { row: "" }
        },
        [
          _c("MapContainer", [_c("WorldMap_MPMC")], 1),
          _c(
            "v-flex",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.$store.state.app.big_map,
                  expression: "!$store.state.app.big_map"
                }
              ],
              staticClass: "mt-2",
              attrs: { lg7: "", md12: "", sm12: "", xl6: "" }
            },
            [
              _c("top-summary-results", { staticClass: "mb-2" }),
              _c(
                "div",
                {},
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c("v-flex", { staticClass: "xs12 sm12 lg12" }, [
                        _c(
                          "div",
                          {
                            staticClass: "body-2 font-weight-bold nav nav-tabs"
                          },
                          [
                            _c("li", {}, [
                              _c(
                                "a",
                                { staticClass: "disabled" },
                                [
                                  _vm._v(
                                    "Details (" +
                                      _vm._s(
                                        _vm.$store.getters.selectionCounts
                                          .results.markets_count
                                      ) +
                                      " target markets x " +
                                      _vm._s(
                                        _vm.$store.getters.selectionCounts
                                          .results.products_count
                                      ) +
                                      " products) "
                                  ),
                                  _c("help-link", {
                                    staticStyle: { float: "right" },
                                    attrs: { help_id: "15" }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "tab-content" }, [
                    _c(
                      "div",
                      { staticClass: "tab-pane active white" },
                      [
                        _c(
                          "v-container",
                          { attrs: { fluid: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { sm: "12" } },
                                  [
                                    _c("applied_filters_box", {
                                      staticClass: "mr-4 mb-0"
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              {
                                staticClass:
                                  "justify-space-between mt-0 ml-0 mr-1 black--text",
                                attrs: { row: "" }
                              },
                              [
                                _vm.$store.state.tradeDSM.dsm_filter_setttings
                                  .Filters.META.combinations.markets_count !==
                                  0 &&
                                _vm.$store.state.tradeDSM.dsm_filter_setttings
                                  .Filters.META.combinations.products_count !==
                                  0
                                  ? [
                                      _c(
                                        "v-flex",
                                        [_c("products_filtered_box")],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        [
                                          _c("countries_filtered_box", {
                                            staticClass: "mt-2"
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            ),
                            _vm.is_run_cube_valid
                              ? _c(
                                  "v-layout",
                                  {
                                    staticClass:
                                      "justify-space-between mt-0 ml-2 mr-1 black--text",
                                    attrs: { row: "" }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { sm12: "" } },
                                      [
                                        _vm.cube["items"].length > 0
                                          ? _c(
                                              "v-layout",
                                              { attrs: { row: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        staticClass:
                                                          "pt-2 mr-3 ml-4"
                                                      },
                                                      [
                                                        _c(
                                                          "v-tabs",
                                                          {
                                                            attrs: {
                                                              "active-class":
                                                                "trade-blue",
                                                              "background-color":
                                                                "tab_header_color",
                                                              "slider-color":
                                                                "green",
                                                              dark: "",
                                                              dense: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.country_tabs,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.country_tabs = $$v
                                                              },
                                                              expression:
                                                                "country_tabs"
                                                            }
                                                          },
                                                          [
                                                            _c("v-tab", [
                                                              _vm._v(
                                                                " Countries"
                                                              )
                                                            ]),
                                                            _c("v-tab", [
                                                              _vm._v(
                                                                " Sub-Region"
                                                              )
                                                            ]),
                                                            _c("v-tab", [
                                                              _vm._v(
                                                                " Main-Region"
                                                              )
                                                            ]),
                                                            _c(
                                                              "v-tab-item",
                                                              [
                                                                _c(
                                                                  "v-card",
                                                                  {
                                                                    attrs: {
                                                                      flat: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-layout",
                                                                      {
                                                                        staticClass:
                                                                          "pa-2",
                                                                        attrs: {
                                                                          row:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-flex",
                                                                          [
                                                                            _c(
                                                                              "reo_table_markets",
                                                                              {
                                                                                attrs: {
                                                                                  cube:
                                                                                    "cube",
                                                                                  table_id:
                                                                                    "country_cube"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-tab-item",
                                                              [
                                                                _c(
                                                                  "v-layout",
                                                                  {
                                                                    staticClass:
                                                                      "pa-2",
                                                                    attrs: {
                                                                      row: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-flex",
                                                                      [
                                                                        _c(
                                                                          "reo_table_markets",
                                                                          {
                                                                            attrs: {
                                                                              cube:
                                                                                "cube_C_Region",
                                                                              table_id:
                                                                                "C_Region_cube"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-tab-item",
                                                              [
                                                                _c(
                                                                  "v-card",
                                                                  {
                                                                    attrs: {
                                                                      flat: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-layout",
                                                                      {
                                                                        staticClass:
                                                                          "pa-2",
                                                                        attrs: {
                                                                          row:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-flex",
                                                                          [
                                                                            _c(
                                                                              "reo_table_markets",
                                                                              {
                                                                                attrs: {
                                                                                  cube:
                                                                                    "cube_Main_Region",
                                                                                  table_id:
                                                                                    "Main_Region_cube"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : [
                                              _vm.$store.state.tradeDSM
                                                .loading_indicator &&
                                              _vm.current_report_to_run ===
                                                "MPMC"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ml-4 blink"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Loading Countries REO table"
                                                          )
                                                        ]
                                                      ),
                                                      _c("v-skeleton-loader", {
                                                        staticClass:
                                                          "mt-3 mb-4",
                                                        attrs: {
                                                          type:
                                                            "article, actions"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                      ],
                                      2
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { sm12: "" } },
                                      [_c("reoCubeWrapper")],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.is_run_cube_valid
                              ? _c(
                                  "v-layout",
                                  [_c("v-flex", [_c("reoFrontendTable")], 1)],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                    .combinations.markets_count === 0 &&
                  _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                    .combinations.products_count === 0
                    ? [
                        _c(
                          "p",
                          { staticClass: "outline mt-12 text-h5 pa-4" },
                          [
                            _vm._v(
                              " There are no results for the current set of applied filters, product and market selections. More details about the current applied selections can be found in the 'Details' tab above. "
                            ),
                            _c("br"),
                            _c("br"),
                            _vm.$store.state.tradeDSM.dsm_filter_setttings
                              .Filters.META.filter_in_progress === true &&
                            _vm.$store.state.tradeDSM.dsm_filter_setttings
                              .Filters.META.combinations.combinations === 0
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "red white--text",
                                    on: { click: _vm.run_cube_clear_filters }
                                  },
                                  [_vm._v("Reset Filters and Rerun ")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _vm.is_run_cube_valid
            ? _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    { staticClass: "xs12 sm12 lg12 ml-5 mr-8 mb-9 mt-12" },
                    [_c("reoFrontendTable")],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" >")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }