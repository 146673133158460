import { render, staticRenderFns } from "./trade-dsm-application.vue?vue&type=template&id=70f5f181&"
import script from "./trade-dsm-application.vue?vue&type=script&lang=js&"
export * from "./trade-dsm-application.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VFlex,VLayout,VProgressLinear,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('70f5f181')) {
      api.createRecord('70f5f181', component.options)
    } else {
      api.reload('70f5f181', component.options)
    }
    module.hot.accept("./trade-dsm-application.vue?vue&type=template&id=70f5f181&", function () {
      api.rerender('70f5f181', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/trade-dsm-application/trade-dsm-application.vue"
export default component.exports