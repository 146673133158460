var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { bg: "", fluid: "" } },
        [
          _c("v-layout", { staticClass: "justify-start", attrs: { row: "" } }),
          _c("v-row", { staticClass: "mt-0 pa-0" }, [
            _c("iframe", { staticClass: "pa-0", attrs: { id: "report_html" } })
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }