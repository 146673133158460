var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        [
          _c("vue-plotly", {
            staticClass: "pa-0 ma-0",
            attrs: {
              data: _vm.plotData,
              layout: _vm.layout,
              options: _vm.options
            }
          }),
          _c(
            "v-container",
            {
              staticClass: "ma-0 pa-0",
              attrs: { "data-html2canvas-ignore": "" }
            },
            [
              _c(
                "v-row",
                { staticClass: "ma-0 pa-0", attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { staticClass: "ma-0 pa-0 text-left ml-8" }, [
                    _c(
                      "span",
                      {
                        key: _vm.hover_text,
                        staticClass:
                          "trade-blue-text font-weight-bold hs8_code mt-0 pt-0"
                      },
                      [_vm._v(_vm._s(_vm.hover_text))]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "align-center" },
        [
          _c("v-img", {
            staticClass: "align-center",
            attrs: {
              "max-height": "100",
              "max-width": "65",
              src: "/reo_pics/error.png"
            }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }