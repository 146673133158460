var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panel",
    { staticClass: "mt-1" },
    [
      _c(
        "v-expansion-panel-header",
        {
          staticClass: "trade-blue ma-0",
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function() {
                return [
                  _c("v-icon", { attrs: { color: "yellow" } }, [
                    _vm._v("$expand")
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("span", [_vm._v(" " + _vm._s(_vm.filter_data["name"]) + " ")]),
          _c(
            "span",
            {
              staticClass:
                "yellow--text caption text-right font-italic ma-0 pa-0"
            },
            [
              _vm._v(
                " " + _vm._s(_vm.advanced ? " (advanced)" : " (basic)") + " "
              )
            ]
          )
        ]
      ),
      _c(
        "v-expansion-panel-content",
        { staticClass: "mt-2" },
        [
          _vm._l(_vm.filter_data["entries"], function(entry) {
            return [
              entry.filter_class === "basic" ||
              entry.filter_class === "basic" ||
              (entry.filter_class === "advanced" && _vm.advanced)
                ? _c(
                    "v-main",
                    { key: entry.id, staticClass: "ma-0 pa-0 filterItem" },
                    [
                      _c(
                        "v-container",
                        { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass:
                                "pa-0 ma-0 justify-space-between align-center",
                              class: {
                                "orange lighten-2": entry.last_selected
                              },
                              attrs: { row: "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ma-0 pa-0",
                                  staticStyle: { width: "5px" }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "green--text text-h6" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            entry.selected_value.length > 0
                                              ? "✓"
                                              : ""
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass:
                                    "ma-0 pa-0 body-2 font-weight-thin sm5"
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        top: "",
                                        color: "success",
                                        "max-width": "200",
                                        disabled: entry.explanation.length <= 11
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    {
                                                      class: {
                                                        filter_heading:
                                                          entry.indent === 1 ||
                                                          entry.indent === 0,
                                                        filter_sub:
                                                          entry.indent === 2
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(entry.name) +
                                                        " " +
                                                        _vm._s(
                                                          entry.display_unit ===
                                                            "currency"
                                                            ? "[" +
                                                                _vm.currencyString +
                                                                "]"
                                                            : ""
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(entry.explanation))
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { staticClass: "ma-0 pa-0  sm6" },
                                [
                                  entry.type === "dropdown"
                                    ? _c(
                                        "dropdown_filter",
                                        {
                                          staticClass: "ma-0 pa-0 ml-12",
                                          attrs: {
                                            entry: entry,
                                            filter_name: _vm.filter_data.key,
                                            filters_updating_status:
                                              _vm.filters_updating_status,
                                            multiple:
                                              entry.type === "dropdown"
                                                ? true
                                                : false,
                                            filters_changed: _vm.filters_changed
                                          }
                                        },
                                        [_vm._v('" ')]
                                      )
                                    : entry.type === "yesno"
                                    ? _c("yes_no_filter", {
                                        staticClass: "ma-0 pa-0 ml-12",
                                        attrs: {
                                          entry: entry,
                                          filter_name: _vm.filter_data.key,
                                          filters_updating_status:
                                            _vm.filters_updating_status
                                        }
                                      })
                                    : _c(
                                        "span",
                                        { staticClass: "text-center" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(entry.value) + " "
                                          )
                                        ]
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }