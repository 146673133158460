var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "no-gutters" },
    [
      _c(
        "v-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "v-card",
            { staticClass: "pa-2 green--text", attrs: { elevation: "0" } },
            [
              _vm.get_meta_data_message !== ""
                ? _c(
                    "div",
                    { staticClass: "mb-2" },
                    [
                      _c("v-icon", { staticClass: "mr-1 orange--text" }, [
                        _vm._v("mdi-alert")
                      ]),
                      _vm._v(" " + _vm._s(_vm.get_meta_data_message()) + " ")
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-icon", { staticClass: "mr-1" }, [_vm._v("mdi-earth")]),
              _vm.$store.state.tradeData.report_selection === "EMF_Overview"
                ? [
                    _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                      .report_type_to_generate === "1PMC"
                      ? _c("span", [
                          _vm._v(
                            "Filtered markets = " +
                              _vm._s(
                                _vm.$store.state.tradeDSM.dsm_filter_setttings
                                  .Filters.META.combinations.markets_count
                              ) +
                              " "
                          )
                        ])
                      : _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters
                          .META.report_type_to_generate === "1P1C" ||
                        _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters
                          .META.report_type_to_generate === "MP1C"
                      ? _c("span", { staticClass: "font-weight-bold " }, [
                          _vm._v(" " + _vm._s(_vm.selected_market()))
                        ])
                      : _vm._e()
                  ]
                : [
                    _c("span", [
                      _vm._v(
                        " All traded markets. (Excluding Re-Imports and Re-Exports)"
                      )
                    ])
                  ]
            ],
            2
          ),
          _c(
            "v-card",
            { staticClass: "pa-2 green--text", attrs: { elevation: "0" } },
            [
              _c("v-icon", { staticClass: "mr-1" }, [_vm._v("mdi-dolly")]),
              _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .report_type_to_generate === "1PMC" ||
              _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .report_type_to_generate === "1P1C"
                ? _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      "[" +
                        _vm._s(_vm.code_to()["HS6_Code"]) +
                        "] " +
                        _vm._s(_vm.code_to()["HS6_Description"])
                    )
                  ])
                : _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                    .report_type_to_generate === "MP1C"
                ? _c("span", [
                    _vm._v(
                      " Filtered products =" +
                        _vm._s(
                          _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters
                            .META.combinations.products_count
                        ) +
                        " "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }