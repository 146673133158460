var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { row: "" } },
        [
          _c("v-flex", { staticClass: "xs12 sm12 lg12" }, [
            _c(
              "div",
              { staticClass: "body-2 font-weight-bold nav nav-tabs white" },
              [
                _c("li", {}, [
                  _c(
                    "a",
                    [
                      _vm._v(
                        "Current selection (1 product x 1 target market) "
                      ),
                      _c("help-link", {
                        staticStyle: { float: "right" },
                        attrs: { help_id: "11" }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          ])
        ],
        1
      ),
      _c("div", { staticClass: "tab-content" }, [
        _c(
          "div",
          { staticClass: "tab-pane active white" },
          [
            _c(
              "v-container",
              { attrs: { fluid: "" } },
              [
                _c(
                  "v-layout",
                  {
                    staticClass: "justify-space-between align-center mt-0 mr-1",
                    attrs: { row: "" }
                  },
                  [
                    _c(
                      "v-flex",
                      { staticClass: "ml-2", attrs: { sm12: "" } },
                      [_c("applied_filters_box", { staticClass: "mb-3" })],
                      1
                    ),
                    _c(
                      "v-flex",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.$store.getters.selectionCounts.products > 0,
                            expression:
                              "$store.getters.selectionCounts.products > 0"
                          }
                        ],
                        staticClass: "result_box_bottom_border mb-1",
                        attrs: { sm1: "" }
                      },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.$store.commit(
                                                "toggleProductsFilter",
                                                true
                                              )
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [_vm._v("mdi-dolly ")]
                                    ),
                                    _vm._v(" :dd ")
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _c("span", [
                              _vm._v("Click to open product filters")
                            ])
                          ]
                        ),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$store.getters.getDsmAppState
                                  .dsm_filter_setttings["HS6_Code"]
                              ) +
                              " "
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      {
                        staticClass: "result_box_bottom_border ml-5",
                        attrs: { sm12: "" }
                      },
                      [
                        _c(
                          "span",
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.$store.commit(
                                                    "toggleProductsFilter",
                                                    true
                                                  )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [_vm._v("mdi-earth")]
                                        ),
                                        _vm._v(": ")
                                      ]
                                    }
                                  }
                                ])
                              },
                              [
                                _c("span", [
                                  _vm._v("Click to open market filters")
                                ])
                              ]
                            ),
                            _vm.$store.state.tradeDSM.dsm_filter_setttings
                              .Filters.META.selections.markets.C_Code.length >
                              0 &&
                            _vm.$store.state.tradeDSM.dsm_filter_setttings
                              .Filters.META.selections.products.HS6_Code
                              .length > 0
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { left: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c("span", [
                                                _c(
                                                  "a",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "align-center",
                                                        attrs: {
                                                          href:
                                                            _vm.itc_countries_url,
                                                          target: "_blank"
                                                        }
                                                      },
                                                      "a",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-img", {
                                                      staticClass:
                                                        "d-inline-block mb-auto mt-auto",
                                                      attrs: {
                                                        contain: "",
                                                        "max-width": "40",
                                                        src: require("../../../assets/img/itc-logo.gif")
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      535389571
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Click to view ITC tariff information"
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c("span", [_vm._v(" " + _vm._s(_vm.showCountries()))])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-layout",
                  {
                    staticClass:
                      "justify-space-between align-end align-center mt-2 ml-2",
                    attrs: { row: "" }
                  },
                  [
                    _c(
                      "v-flex",
                      { staticClass: "result_box_no_border sm12" },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.$store.getters.selectionCounts
                                    .products === 0,
                                expression:
                                  "$store.getters.selectionCounts.products === 0"
                              }
                            ]
                          },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.$store.commit(
                                                    "toggleProductsFilter",
                                                    true
                                                  )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [_vm._v("mdi-dolly")]
                                        ),
                                        _vm._v(": ")
                                      ]
                                    }
                                  }
                                ])
                              },
                              [
                                _c("span", [
                                  _vm._v("Click to open product filters")
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.$store.getters.selectionCounts.products >
                                  0,
                                expression:
                                  "$store.getters.selectionCounts.products > 0"
                              }
                            ]
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.$store.commit(
                                      "toggleProductsFilter",
                                      true
                                    )
                                  }
                                }
                              },
                              [_vm._v("mdi-text-box-outline")]
                            ),
                            _vm._v(": ")
                          ],
                          1
                        ),
                        this.$store.state.tradeDSM.dsm_filter_setttings.Filters
                          .META.selections.products.HS6_Code.length > 0
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c("span", [
                                            _c(
                                              "a",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "align-center",
                                                    attrs: {
                                                      href:
                                                        _vm.itc_products_url,
                                                      target: "_blank"
                                                    }
                                                  },
                                                  "a",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-img", {
                                                  staticClass:
                                                    "d-inline-block mb-auto mt-auto",
                                                  attrs: {
                                                    contain: "",
                                                    "max-width": "40",
                                                    src: require("../../../assets/img/itc-logo.gif")
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2634003957
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "Click to view ITC product code history"
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        this.$store.state.tradeDSM.dsm_filter_setttings.Filters
                          .META.selections.products.HS6_Code.length > 0
                          ? _c("span", [
                              _vm._v(
                                " [" +
                                  _vm._s(_vm.single_product) +
                                  "] " +
                                  _vm._s(
                                    _vm.hs6_codeToDescription(
                                      _vm.single_product
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      !_vm.$store.state.tradeDSM.loading_indicator &&
      !_vm.$store.state.tradeDSM.productsOpen &&
      !_vm.$store.state.tradeDSM.countriesOpen &&
      _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.combinations
        .combinations > 0
        ? _c("DsmSingleTextFilters")
        : [
            _c(
              "p",
              { staticClass: "outline mt-12 text-h5 pa-4" },
              [
                _vm._v(
                  " There are no results for the current set of applied filters, product and market selections. More details about the current applied selections can be found in the 'Details' tab above. "
                ),
                _c("br"),
                _c("br"),
                _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                  .filter_in_progress === true &&
                _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                  .combinations.combinations === 0
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "red white--text",
                        on: { click: _vm.run_cube_clear_filters }
                      },
                      [_vm._v("Reset Filters and Rerun ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }