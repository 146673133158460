<template>
<!--  1PMC Countries Download -->
  <help-dialog-base>
    <template v-slot:card_title>
      <v-icon class="white--text mr-3">{{ icon() }}</v-icon>
      Download {{ ms_office_type }} data table
    </template>
    <template v-slot:card_text>

      <v-container>
        <v-row class="no-gutters">
          <div class="text-center mb-3">
            <h2 class="h3">Please select a report option below.</h2>
          </div>
        </v-row>
        <v-row class="outline rounded no-gutters pa-5">
          <v-col sm="4">
            <currency-changer></currency-changer>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-slot:card_actions>
      <v-btn
          class="trade-blue white--text"
          text
          @click="download_handler"
      >
        Download Report

      </v-btn>
    </template>
  </help-dialog-base>
</template>

<script>

import helpDialogBase from "@/components/application-level/helper-components/help-dialog-base.vue";
import currencyChanger from "@/components/trade-dsm-application/mixins/currency-changer.vue";
import {mapGetters} from "vuex";

export default {
  name: "dialog-excel-tables-countries",
  props:
      {
        ms_office_type: String,
        download_func: Function,
        clear_tracking: Boolean,
        download_xls: Boolean,
        cube_data: Array,

      },
  components: {
    helpDialogBase,
    currencyChanger
  },
  computed: {
    ...mapGetters(['user']),


  },
  methods: {
    closeMe() {
      this.$store.state.app.activeDialogComponent = null
    },
    download_handler() {
      this.closeMe()
      this.download_func(this.clear_tracking, this.download_xls, this.cube_data)
    },
    document_download_type() {
     if (this.ms_office_type === 'Word') {
        return "word"
      } else if (this.ms_office_type === 'Powerpoint') {
        return "pptx"
      }
    },
    icon() {
      if (this.ms_office_type === 'Word') {
        return "mdi-microsoft-word"
      } else if (this.ms_office_type === 'Powerpoint') {
        return "mdi-microsoft-powerpoint"
      } else if (this.ms_office_type === 'Excel') {
        return "mdi-microsoft-excel"
      } else return "mdi-microsoft-word"
    }

  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>