import _ from 'lodash';
import {eventBus} from '@/main';

export function ProductFilterBox(search_boxes, state) {
  // Object to hold filter box generation
  // constructor
  // this.SearchAndSelectList = SearchAndSelectList;
  this.search_box_text = search_boxes[0].code === 'HS6_Code' ? 'products' : 'markets';

  this.search_boxes = _.cloneDeep(search_boxes);

  // methods
  this.build_base_list = function() {
    // console.log('made it here');

    for (let j = 0; j < 4; j++) {
      // each box iterator
      let search_box = this.search_boxes[j];

      search_box.code_lookup = {};
      let in_results = [];
      let selected = [];
      let hash_table = {};
      if (search_box.code === 'HS6_Code') {
        hash_table = state.tradeDSM.hs6_to_desc_hash_map;
        selected =
          state.tradeDSM.dsm_filter_setttings.Filters.META.selections.products.HS6_Code;
        in_results =
          state.tradeDSM.dsm_filter_setttings.Filters.META.query_results.products;
      } else if (search_box.code === 'HS4_Code') {
        in_results = Object.keys(state.tradeDSM.hs4_to_desc_hash_map);
        selected = [];
        hash_table = state.tradeDSM.hs4_to_desc_hash_map;
      } else if (search_box.code === 'HS2_Code') {
        in_results = Object.keys(state.tradeDSM.hs2_to_desc_hash_map);
        selected = [];
        hash_table = state.tradeDSM.hs2_to_desc_hash_map;
      } else if (search_box.code === 'HS2_Groups') {
        in_results = Object.keys(state.tradeDSM.hs2_group_to_desc_hash_map);
        selected = [];
        hash_table = state.tradeDSM.hs2_group_to_desc_hash_map;
      }
      let total_array = Object.keys(hash_table);

      for (let i = 0; i < total_array.length; i++) {
        const object_rerence_name = total_array[i];

        let item_selected = false;
        if (selected.includes(object_rerence_name)) {
          item_selected = true;
        }

        let in_cube = true;
        in_cube = in_results.includes(object_rerence_name);

        let desription = hash_table[object_rerence_name];

        //temp if
        let items = [];
        if (search_box.code === 'HS6_Code') {
          items = [i];
        }
        if (search_box.code === 'HS4_Code') {
          items = _.uniq(state.tradeDSM.hs4_to_hs6_map[object_rerence_name]);
        }
        if (search_box.code === 'HS2_Code') {
          items = _.uniq(state.tradeDSM.hs2_to_hs4_map[object_rerence_name]);
        }
        if (search_box.code === 'HS2_Groups') {
          items = _.uniq(state.tradeDSM.hs2_groups_to_hs2_map[object_rerence_name]);
        }

        search_box.code_lookup[object_rerence_name] = {
          description: desription, // HS6_Description
          selected: item_selected, // Selected?
          in_cube: in_cube, // In_Cube
          partial_selected_from_above: false, // am i partialy selected from above?
          partials_list: [], // am i partialy selected from above?
          code_above: search_box.code_above, // What box is above me
          code_below: search_box.code_below, // What box is below me
          items: items, // [ add code ] to accociated items
        };
      }
    }

    this.set_all_partial_down();
  };
  /////
  this.box_entry_codes = function(box_id, search_key = null) {
    let search_box = this.search_boxes[box_id].code_lookup;
    let all_keys = Object.keys(search_box);

    //remove home market
    // if (all_keys[0].includes('C')) {
    //   all_keys = all_keys.filter(v => v !== this.home_market);
    // }

    all_keys = all_keys.filter(
      v =>
        search_box[v].description.toLowerCase().includes(search_key.toLowerCase()) ||
        v.toLowerCase().includes(search_key.toLowerCase()),
    );

    let selected_in_cube = all_keys
      .filter(v => search_box[v].selected && search_box[v].in_cube)
      .sort();

    let partial_selected_from_above = all_keys
      .filter(v => search_box[v].partials_list.length > 0 && search_box[v].in_cube)
      .sort();

    let selected_not_cube = all_keys
      .filter(v => search_box[v].selected && !search_box[v].in_cube)
      .sort();

    let unselected_in_cube = all_keys
      .filter(v => !search_box[v].selected && search_box[v].in_cube)
      .sort();

    let unselected_not_in_cube = all_keys
      .filter(v => !search_box[v].selected && !search_box[v].in_cube)
      .sort();

    return _.uniq([
      ...selected_in_cube,
      ...partial_selected_from_above,
      ...selected_not_cube,
      ...unselected_in_cube,
      ...unselected_not_in_cube,
    ]);
  };

  this.box_entries_count = function(box_id, allow_out_of_cube_items_selections) {
    let search_box = this.search_boxes[box_id].code_lookup;
    if (Object.keys(search_box).length > 0) {
      let all_keys = Object.keys(search_box);
      if (allow_out_of_cube_items_selections === true) {
        return all_keys.length;
      } else {
        let count = 0;
        for (const value of Object.values(search_box)) {
          if (value.in_cube === true) {
            count = count + 1;
          }
        }
        return count;
      }
    } else return 0;
  };

  this.entries_selected_count = function(box_id) {
    let search_box = this.search_boxes[box_id].code_lookup;
    if (Object.keys(search_box).length > 0) {
      let count = 0;
      for (const value of Object.values(search_box)) {
        if (value.selected === true) {
          count = count + 1;
        }
      }
      return count;
    } else return 0;
  };

  this.partial_select_all_box_click_1 = function(box_id, item) {
    let above_items_to_keep_ix = this.box_code_dict(box_id)[item].items;
    let above_keys = this.box_keys(box_id - 1);
    let above_dict = this.box_code_dict(box_id - 1);
    //set above
    above_keys.forEach((above_key, above_ix) => {
      let above_item = above_dict[above_key];
      if (above_item.selected) {
        if (above_items_to_keep_ix.includes(above_ix)) {
          above_item.selected = true;
        } else {
          above_item.selected = false;
        }
      }
    });
  };

  this.partial_keep_only_selectopn = function(box_id, item) {
    let click_dict = this.box_code_dict(box_id);
    let click_keys = this.box_keys(box_id);
    click_keys.forEach(key => {
      let click_item = click_dict[key];
      if (key !== item) {
        click_item.partials_list = [];
      }
    });
  };

  this.clear_all_partials = function() {
    for (let i = 0; i < 4; i++) {
      let box = this.box_code_dict(i);
      let box_entries = this.box_keys(i);
      box_entries.forEach(code => {
        box[code].partials_list = [];
        box[code].selected = false;
      });
    }
  };

  this.partial_select_all = function(box_id, item) {
    eventBus.$emit('CLEAR_PRODUCTS_TOP_LIST');
    // get a list of all the accociated top indexes
    // Remove all the selected in box 0

    let keep_list = [];
    let box_1_selected = [];
    let box_2_selected = [];

    if (box_id === 1) {
      keep_list = this.box_code_dict(1)[item].partials_list; //what indexes to set above
    } else if (box_id === 2) {
      let box_2_keep_list = this.box_code_dict(2)[item].items;
      let box_1_keys = this.box_keys(1);
      let box_1_items = this.box_code_dict(1);
      box_2_keep_list.forEach(box2_ix => {
        let key = box_1_keys[box2_ix];
        if (box_1_items[key].selected) {
          box_1_selected.push(key);
          keep_list = keep_list.concat(box_1_items[key].items);
        } else {
          keep_list = keep_list.concat(box_1_items[key].partials_list);
        }
      });
    } else if (box_id === 3) {
      let box_3_keep_list = this.box_code_dict(3)[item].items;
      let box_2_keep_list = [];
      let box_2_keys = this.box_keys(2);
      let box_2_items = this.box_code_dict(2);
      box_3_keep_list.forEach(box3_ix => {
        let key = box_2_keys[box3_ix];
        if (box_2_items[key].selected) {
          box_2_selected.push(key);
          box_2_keep_list = box_2_keep_list.concat(box_2_items[key].items);
        } else box_2_keep_list = box_2_keep_list.concat(box_2_items[key].partials_list);
      });

      let box_1_keys = this.box_keys(1);
      let box_1_items = this.box_code_dict(1);
      box_2_keep_list.forEach(box2_ix => {
        let key = box_1_keys[box2_ix];
        if (box_1_items[key].selected) {
          box_1_selected.push(key);
          keep_list = keep_list.concat(box_1_items[key].items);
        } else keep_list = keep_list.concat(box_1_items[key].partials_list);
      });
    }

    this.clear_all_partials();

    let top_keys = this.box_keys(0); // top list
    keep_list.forEach(ix_to_set => {
      let key = top_keys[ix_to_set];
      this.entry_click(0, key, true);
    });

    box_1_selected.forEach(v => (this.box_code_dict(1)[v].selected = true));
    box_2_selected.forEach(v => (this.box_code_dict(2)[v].selected = true));
  };

  this.partial_unselect_all = function(box_id, item) {
    eventBus.$emit('CLEAR_PRODUCTS_TOP_LIST');

    // get a list of all the accociated top indexes
    // Remove all the selected in box 0

    let remove_list = [];

    if (box_id === 1) {
      remove_list = this.box_code_dict(1)[item].partials_list; //what indexes to set above
    } else if (box_id === 2) {
      let box_2_remove_list = this.box_code_dict(2)[item].items;
      let box_1_keys = this.box_keys(1);
      let box_1_items = this.box_code_dict(1);
      box_2_remove_list.forEach(box2_ix => {
        let key = box_1_keys[box2_ix];
        if (box_1_items[key].selected) {
          remove_list = remove_list.concat(box_1_items[key].items);
        } else remove_list = remove_list.concat(box_1_items[key].partials_list);
      });
    } else if (box_id === 3) {
      let box_3_keep_list = this.box_code_dict(3)[item].items;
      let box_2_keep_list = [];
      let box_2_keys = this.box_keys(2);
      let box_2_items = this.box_code_dict(2);
      box_3_keep_list.forEach(box3_ix => {
        let key = box_2_keys[box3_ix];

        if (box_2_items[key].selected) {
          box_2_keep_list = box_2_keep_list.concat(box_2_items[key].items);
        } else box_2_keep_list = box_2_keep_list.concat(box_2_items[key].partials_list);
      });

      let box_1_keys = this.box_keys(1);
      let box_1_items = this.box_code_dict(1);
      box_2_keep_list.forEach(box2_ix => {
        let key = box_1_keys[box2_ix];
        if (box_1_items[key].selected) {
          remove_list = remove_list.concat(box_1_items[key].items);
        } else remove_list = remove_list.concat(box_1_items[key].partials_list);
      });
    }

    let top_keys = this.box_keys(0); // top list
    let top_dict = this.box_code_dict(0);
    remove_list.forEach(ix_to_set => {
      let key = top_keys[ix_to_set];
      top_dict[key].selected = false;
    });

    //reset list and then run
    let remember_selected = this.box_keys(0).filter(
      key => this.box_code_dict(0)[key].selected,
    );
    this.clear_all_partials();

    remember_selected.forEach(key => this.entry_click(0, key, true));
  };

  this.box_code_dict = function(box_id) {
    return this.search_boxes[box_id].code_lookup;
  };

  this.box_keys = function(box_id) {
    return Object.keys(this.box_code_dict(box_id));
  };

  this.click_index = function(box_id, item) {
    return Object.keys(this.search_boxes[box_id].code_lookup).indexOf(item);
  };

  this.set_partial_box_below = function(click_index, box_id, new_value) {
    let index_where_found = null;
    let box_belolw_keys = this.box_keys(box_id + 1);
    box_belolw_keys.forEach((key, ix) => {
      let v = this.box_code_dict(box_id + 1)[key];
      if (v.items.includes(click_index)) {
        if (new_value) {
          v.partials_list.push(click_index);
        } else {
          v.partials_list = _.difference(v.partials_list, [click_index]);
        }
        v.partials_list = _.uniq(v.partials_list);
        index_where_found = ix;
      }
    });
    return index_where_found;
  };

  this.set_partial_down = function(box_id, item, new_value) {
    // get all the items that was clicked
    // console.log(new_value);

    let click_index_0 = this.click_index(box_id, item);
    let click_index_1 = this.set_partial_box_below(click_index_0, box_id, new_value);
    let click_index_2 = this.set_partial_box_below(click_index_1, box_id + 1, new_value);
    this.set_partial_box_below(click_index_2, box_id + 2, new_value);
  };

  // products
  this.set_all_partial_down = function(new_value = true) {
    this.box_keys(0).forEach(item => {
      if (this.search_boxes[0].code_lookup[item].selected) {
        this.set_partial_down(0, item, new_value);
      }
    });
  };

  this.entry_click = function(box_id, item, allow_out_of_cube_items_selections) {
    let new_value = !this.search_boxes[box_id].code_lookup[item].selected;
    eventBus.$emit('CLEAR_PRODUCTS_TOP_LIST');
    if (
      this.search_boxes[box_id].code_lookup[item].in_cube ||
      allow_out_of_cube_items_selections
    ) {
      this.search_boxes[box_id].code_lookup[item].selected = new_value;

      // now select all above
      if (box_id > 0) {
        let above_iterator = [];

        // remember box 1 above when clickjed in 2
        let remember_box_1 = [];
        if (box_id === 2) {
          above_iterator = [1];
          let above_ids = Object.keys(this.search_boxes[1].code_lookup);
          above_ids.forEach(v => {
            if (this.search_boxes[1].code_lookup[v].selected) {
              remember_box_1.push(v);
            }
          });
        }

        if (box_id === 3) {
          above_iterator = [2, 1];
        }

        if (new_value) {
          // set the true
          this.select_above(box_id, item, new_value, allow_out_of_cube_items_selections);
          above_iterator.forEach(above_box_id => {
            let above_ids = Object.keys(this.search_boxes[above_box_id].code_lookup);
            above_ids.forEach(v => {
              if (this.search_boxes[above_box_id].code_lookup[v].selected) {
                this.select_above(
                  above_box_id,
                  v,
                  new_value,
                  allow_out_of_cube_items_selections,
                );
              }
            });
          });
        } else {
          // box 2

          if (box_id === 1) {
            let selected_item_ix = _.uniq(this.search_boxes[1].code_lookup[item].items); //Codes affected in box 0
            selected_item_ix.forEach(ix => {
              let code = Object.keys(this.search_boxes[0].code_lookup)[ix];
              this.search_boxes[0].code_lookup[code].selected = new_value; //Codes affected in box 0
            });
          }

          if (box_id === 2) {
            let selected_item_ix = _.uniq(this.search_boxes[2].code_lookup[item].items); //Codes affected in box 0
            selected_item_ix.forEach(ix => {
              let code = Object.keys(this.search_boxes[1].code_lookup)[ix];
              let selected_item_ix = this.search_boxes[1].code_lookup[code].items;
              this.search_boxes[1].code_lookup[code].selected = new_value; //Codes affected in box 0
              this.search_boxes[1].code_lookup[code].partials_list = []; //Codes affected in box 0
              selected_item_ix.forEach(c_code_ix => {
                let c_code = Object.keys(this.search_boxes[0].code_lookup)[c_code_ix];
                this.search_boxes[0].code_lookup[c_code].selected = new_value;
                this.search_boxes[0].code_lookup[c_code].partials_list = [];
              });
            });
          }

          if (box_id === 3) {
            let selected_item_ix_box3 = _.uniq(
              this.search_boxes[3].code_lookup[item].items,
            ); //Codes affected in box 0

            this.search_boxes[3].code_lookup[item].partials_list = [];

            //ieterate box 3
            selected_item_ix_box3.forEach(box3_ix => {
              let box_2_code = Object.keys(this.search_boxes[2].code_lookup)[box3_ix];
              let selected_item_ix_box2 = _.uniq(
                this.search_boxes[2].code_lookup[box_2_code].items,
              );
              this.search_boxes[2].code_lookup[box_2_code].selected = new_value; //Codes affected in box 0
              this.search_boxes[2].code_lookup[box_2_code].partials_list = []; //Codes affected in box 0

              // iterate box 2
              selected_item_ix_box2.forEach(box2_ix => {
                let box_1_code = Object.keys(this.search_boxes[1].code_lookup)[box2_ix];
                this.search_boxes[1].code_lookup[box_1_code].selected = new_value;
                this.search_boxes[1].code_lookup[box_1_code].partials_list = [];

                let selected_item_ix_box1 = _.uniq(
                  this.search_boxes[1].code_lookup[box_1_code].items,
                );

                selected_item_ix_box1.forEach(box1_ix => {
                  let box_0_code = Object.keys(this.search_boxes[0].code_lookup)[box1_ix];
                  this.search_boxes[0].code_lookup[box_0_code].selected = new_value;
                  this.search_boxes[0].code_lookup[box_0_code].partials_list = [];
                });
              });
            });
          }
        }
      }
    }

    // this.set_all_partial_down();
    this.set_partial_down(box_id, item, new_value);

    eventBus.$emit('CLEAR_PRODUCTS_TOP_LIST');
  };

  this.select_above = function(
    box_id,
    item,
    new_value,
    allow_out_of_cube_items_selections,
  ) {
    if (this.search_boxes[box_id].code_above !== null) {
      let items = this.search_boxes[box_id].code_lookup[item]['items'];
      let box_above_id = box_id - 1;
      let search_boxes_above = this.search_boxes[box_above_id].code_lookup;
      let all_keys = Object.keys(search_boxes_above);
      items.forEach(v => {
        let key = all_keys[v];
        if (!search_boxes_above[key].in_cube && allow_out_of_cube_items_selections) {
          search_boxes_above[key].selected = new_value;
        } else if (search_boxes_above[key].in_cube) {
          search_boxes_above[key].selected = new_value;
        }
      });
    }
  };

  this.select_all = function(box_id, new_value, allow_out_of_cube_items_selections) {
    let iterator = [];
    eventBus.$emit('CLEAR_PRODUCTS_TOP_LIST');
    // TODO generalise this. Count back from len of serch boxes and build this
    if (new_value) {
      if (box_id === 0) {
        iterator = [0];
      }
      if (box_id === 1) {
        iterator = [0, 1];
      }
      if (box_id === 2) {
        iterator = [0, 1, 2];
      }
      if (box_id === 3) {
        iterator = [0, 1, 2, 3];
      }
    } else {
      // only three boxes for market codes
      search_boxes[0].code === 'HS6_Code'
        ? (iterator = [0, 1, 2, 3])
        : (iterator = [0, 1, 2]);
    }

    iterator.forEach(box_id_ix => {
      let search_box = this.search_boxes[box_id_ix].code_lookup;
      for (let key of Object.keys(search_box)) {
        if (new_value === true) {
          if (
            (!search_box[key].in_cube && allow_out_of_cube_items_selections) ||
            search_box[key].in_cube
          ) {
            search_box[key].selected = new_value;
          }
        } else {
          search_box[key].selected = false;
          search_box[key].partials_list = [];
        }
      }
    });
  };

  this.show_select_all = function(box_id, allow_out_of_cube_items_selections) {
    // let selected_count = this.master_list.filter(v => v.C_Code_Selected === true).length
    return (
      this.entries_selected_count(box_id) !==
      this.box_entries_count(box_id, allow_out_of_cube_items_selections)
    );
  };

  this.show_unselect_all = function(box_id) {
    // let selected_count = this.master_list.filter(v => v.C_Code_Selected === true).length
    return this.entries_selected_count(box_id) > 0;
  };

  this.select_keys = function(box_id, items, new_value) {
    let search_box = this.search_boxes[box_id].code_lookup;
    items.forEach(v => {
      // this block makes sure that if the item in the pre setup
      // list is not in the total list anymore, that it skips it
      try {
        search_box[v].selected = new_value;
      } catch (err) {
        console.log('Item not in list.');
      }
    });
  };

  this.selected_items = function() {
    let search_box = this.search_boxes[0].code_lookup;
    let selected = [];
    for (let key of Object.keys(search_box)) {
      if (search_box[key].selected) {
        selected.push(key);
      }
    }
    return selected;
  };

  // setup code
  // create the master list
  this.build_base_list();
}
export function MarketFilterBox(SearchAndSelectList, search_boxes, state) {
  // Object to hold filter box generation
  // constructor
  this.SearchAndSelectList = SearchAndSelectList;
  this.search_box_text = search_boxes[0].code === 'HS6_Code' ? 'products' : 'markets';
  this.home_market = state.tradeDSM.dsm_filter_setttings.Filters.META.home_market;

  this.search_boxes = _.cloneDeep(search_boxes);

  // methods
  this.build_base_list = function() {
    // make a clone of the current status of the cube()
    let master_list = _.cloneDeep(this.SearchAndSelectList);

    // loop through each search box and build its items
    for (let j = 0; j < this.search_boxes.length; j++) {
      // console.log('In build');
      // console.log(j);

      let search_box = this.search_boxes[j];
      search_box.code_lookup = {};
      // build each box lookup lust
      for (let i = 0; i < master_list.length; i++) {
        //add hs6 Code as only one in list
        let object_referene_i = i;
        let object_rerence_name = master_list[i][search_box.code];

        // decide if it is in the cube
        let in_cube = null;
        if (search_box.code === 'C_Code') {
          in_cube = master_list[i].in_cube;
        }
        if (search_box.code === 'Sub_Region') {
          in_cube = master_list[i].Sub_Region_in_cube;
        }
        if (search_box.code === 'Main_Region') {
          in_cube = master_list[i].Main_Region_in_cube;
        }

        //
        if (!(object_rerence_name in search_box.code_lookup)) {
          // no key yet
          search_box.code_lookup[object_rerence_name] = {
            description: master_list[i][search_box.description],
            selected: master_list[i][`${search_box.code}_Selected`],
            partial_selected_from_above: false, // am i partialy selected from above?
            partials_list: [], // am i partialy selected from above?
            in_cube: in_cube,
            code_above: master_list[i][search_box.code_above],
            code_below: master_list[i][search_box.code_below],
            items: in_cube ? [object_referene_i] : [], // item number in the top most array, so this will be the C_code or HS6Cde array number
          };
        } else {
          // add it
          if (
            !search_box.code_lookup[object_rerence_name]['items'].includes(
              object_referene_i,
            )
          ) {
            search_box.code_lookup[object_rerence_name]['items'].push(object_referene_i);
          }
        }
      }
    }

    this.set_all_partial_down();
  };
  /////

  // return the list of sorted markets codes for the scroller
  this.box_entry_codes = function(box_id, search_key = null) {
    let search_box = this.search_boxes[box_id].code_lookup;

    //sort
    const entries = Object.entries(search_box);
    let sortedEntries = entries.sort(function(a, b) {
      // lower chase to cater for names e.g. eSwatini
      if (a[1].description.toLowerCase() < b[1].description.toLowerCase()) {
        return -1;
      }
      if (a[1].description.toLowerCase() > b[1].description.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    let new_search_box = Object.fromEntries(sortedEntries);

    let all_keys = Object.keys(new_search_box);

    all_keys = all_keys.filter(
      v =>
        search_box[v].description.toLowerCase().includes(search_key.toLowerCase()) ||
        v.toLowerCase().includes(search_key.toLowerCase()),
    );

    let selected_in_cube = all_keys.filter(
      v => search_box[v].selected && search_box[v].in_cube,
    );

    let partial_selected_from_above = all_keys.filter(
      v => search_box[v].partials_list.length > 0 && search_box[v].in_cube,
    );

    let selected_not_cube = all_keys
      .filter(v => search_box[v].selected && !search_box[v].in_cube)
      .sort();

    let unselected_in_cube = all_keys.filter(
      v => !search_box[v].selected && search_box[v].in_cube,
    );

    let unselected_not_in_cube = all_keys.filter(
      v => !search_box[v].selected && !search_box[v].in_cube,
    );

    return _.uniq([
      ...selected_in_cube,
      ...partial_selected_from_above,
      ...selected_not_cube,
      ...unselected_in_cube,
      ...unselected_not_in_cube,
    ]);
  };

  this.box_entries_count = function(box_id, allow_out_of_cube_items_selections) {
    let search_box = this.search_boxes[box_id].code_lookup;
    if (Object.keys(search_box).length > 0) {
      let all_keys = Object.keys(search_box);
      if (allow_out_of_cube_items_selections === true) {
        return all_keys.length;
      } else {
        let count = 0;
        for (const value of Object.values(search_box)) {
          if (value.in_cube === true) {
            count = count + 1;
          }
        }
        return count;
      }
    } else return 0;
  };

  this.entries_selected_count = function(box_id) {
    let search_box = this.search_boxes[box_id].code_lookup;
    if (Object.keys(search_box).length > 0) {
      let count = 0;
      for (const value of Object.values(search_box)) {
        if (value.selected === true) {
          count = count + 1;
        }
      }
      return count;
    } else return 0;
  };

  this.partial_select_all = function(box_id, item) {
    // list of valid values
    let items_to_check = this.search_boxes[box_id].code_lookup[item].items;
    let items_to_remember = [];
    eventBus.$emit('CLEAR_MARKETS_TOP_LIST');

    // iterate through the top box
    // if a value is in the items to check and true, keep true
    Object.keys(this.search_boxes[0].code_lookup).forEach(code => {
      // if a value is in the items to check and true, keep true
      if (
        this.search_boxes[0].code_lookup[code].selected &&
        items_to_check.includes(this.search_boxes[0].code_lookup[code].items[0])
      ) {
        this.search_boxes[0].code_lookup[code].selected = true;
        items_to_remember.push(this.search_boxes[0].code_lookup[code].items[0]);
      } else {
        // alse make it false
        this.search_boxes[0].code_lookup[code].selected = false;
      }
    });

    // now just keep the partials on for the box selected
    for (let box_id_ix = 1; box_id_ix < 3; box_id_ix++) {
      Object.keys(this.search_boxes[box_id_ix].code_lookup).forEach(code => {
        let current_list = this.search_boxes[box_id_ix].code_lookup[code].partials_list;
        this.search_boxes[box_id_ix].code_lookup[code].selected = false;
        this.search_boxes[box_id_ix].code_lookup[code].partials_list = _.intersection(
          current_list,
          items_to_remember,
        );
      });
    }
  };

  this.partial_unselect_all = function(box_id, item) {
    let items = this.search_boxes[box_id].code_lookup[item].items;
    eventBus.$emit('CLEAR_MARKETS_TOP_LIST');
    for (let box_id = 0; box_id < 3; box_id++) {
      Object.keys(this.search_boxes[box_id].code_lookup).forEach(v => {
        this.search_boxes[box_id].code_lookup[v].partials_list = _.difference(
          this.search_boxes[box_id].code_lookup[v].partials_list,
          items,
        );
      });
    }

    let top_codes = Object.keys(this.search_boxes[0].code_lookup);
    items.forEach(v => {
      this.search_boxes[0].code_lookup[top_codes[v]].selected = false;
    });

    for (let box_id_ix = 1; box_id_ix < 3; box_id_ix++) {
      Object.keys(this.search_boxes[box_id_ix].code_lookup).forEach(v => {
        console.log(v);
        if (
          _.intersection(this.search_boxes[box_id_ix].code_lookup[v].items, items)
            .length > 0
        ) {
          this.search_boxes[box_id_ix].code_lookup[v].selected = false;
        }
      });
    }
  };

  this.set_partial_down = function(box_id, item, new_value) {
    // get all the items
    let items = this.search_boxes[box_id].code_lookup[item].items;

    if (box_id > 0) {
      this.search_boxes[box_id].code_lookup[item].partial_selected_from_above = new_value;
    }

    Object.keys(this.search_boxes[box_id + 1].code_lookup).forEach(code => {
      if (this.search_boxes[box_id + 1].code_lookup[code].items.includes(items[0])) {
        this.search_boxes[box_id + 1].code_lookup[
          code
        ].partial_selected_from_above = new_value;

        if (new_value) {
          this.search_boxes[box_id + 1].code_lookup[code].partials_list = _.uniq(
            this.search_boxes[box_id + 1].code_lookup[code].partials_list.concat(items),
          );
        } else {
          this.search_boxes[box_id + 1].code_lookup[code].partials_list = _.difference(
            this.search_boxes[box_id + 1].code_lookup[code].partials_list,
            items,
          );
        }
      }
    });

    Object.keys(this.search_boxes[box_id + 2].code_lookup).forEach(code => {
      if (this.search_boxes[box_id + 2].code_lookup[code].items.includes(items[0])) {
        this.search_boxes[box_id + 2].code_lookup[
          code
        ].partial_selected_from_above = new_value;
        if (new_value) {
          this.search_boxes[box_id + 2].code_lookup[code].partials_list = _.uniq(
            this.search_boxes[box_id + 2].code_lookup[code].partials_list.concat(items),
          );
        } else {
          this.search_boxes[box_id + 2].code_lookup[code].partials_list = _.difference(
            this.search_boxes[box_id + 2].code_lookup[code].partials_list,
            items,
          );
        }
      }
    });
  };

  // markets
  this.set_all_partial_down = function() {
    Object.keys(this.search_boxes[0].code_lookup).forEach(item => {
      if (this.search_boxes[0].code_lookup[item].selected) {
        this.set_partial_down(0, item, true);
      } else {
        this.set_partial_down(0, item, false);
      }
    });
  };

  // markets
  this.entry_click = function(box_id, item, allow_out_of_cube_items_selections) {
    let new_value = !this.search_boxes[box_id].code_lookup[item].selected;
    eventBus.$emit('CLEAR_MARKETS_TOP_LIST');

    // do up click
    if (
      this.search_boxes[box_id].code_lookup[item].in_cube ||
      allow_out_of_cube_items_selections
    ) {
      // box selected
      this.search_boxes[box_id].code_lookup[item].selected = new_value;

      // now select all above
      if (box_id > 0) {
        let above_iterator = [];
        if (box_id === 2) {
          above_iterator = [1];
        }
        let remember_box_1 = [];

        // remember selection but this will remember everything above
        above_iterator.forEach(above_box_id => {
          let above_ids = Object.keys(this.search_boxes[above_box_id].code_lookup);
          above_ids.forEach(v => {
            if (this.search_boxes[above_box_id].code_lookup[v].selected) {
              remember_box_1.push(v);
            }
          });
        });

        // select box imediate above selection
        // this needs to happen after remember above
        this.select_above(box_id, item, new_value, allow_out_of_cube_items_selections);

        // in this case this will be the markets box
        if (remember_box_1.length === 0 || new_value) {
          above_iterator.forEach(above_box_id => {
            let above_ids = Object.keys(this.search_boxes[above_box_id].code_lookup);
            above_ids.forEach(v => {
              if (this.search_boxes[above_box_id].code_lookup[v].selected) {
                this.select_above(
                  above_box_id,
                  v,
                  new_value,
                  allow_out_of_cube_items_selections,
                );
              }
            });
          });
        } else {
          // just unselect all the relevant C_Codes here as it is in the Main_Region.items
          let selected_item_ix = this.search_boxes[box_id].code_lookup[item].items; //Codes affected in box 0
          selected_item_ix.forEach(c_code_ix => {
            let c_code = Object.keys(this.search_boxes[0].code_lookup)[c_code_ix];
            this.search_boxes[0].code_lookup[c_code].selected = new_value;
          });
        }
      }

      // this.set_all_partial_down();
      this.set_partial_down(box_id, item, new_value);
    }

    // do down click
  };

  this.select_above = function(
    box_id,
    item,
    new_value,
    allow_out_of_cube_items_selections,
  ) {
    if (this.search_boxes[box_id].code_above !== null) {
      let box_above_id = box_id - 1;
      // console.log(`Looking at box  = ${box_above_id}`);
      let search_boxes_above = this.search_boxes[box_above_id].code_lookup;
      // console.log(search_boxes_above[0]);
      for (let key of Object.keys(search_boxes_above)) {
        if (
          (item === search_boxes_above[key].code_below &&
            search_boxes_above[key].in_cube) ||
          (!search_boxes_above[key].in_cube && allow_out_of_cube_items_selections)
        ) {
          search_boxes_above[key].selected = new_value;

          if (box_id !== 0 && !new_value) {
            search_boxes_above[key].partial_selected_from_above = false;
          }
        }
      }
    }
  };

  this.select_all = function(box_id, new_value, allow_out_of_cube_items_selections) {
    let iterator = [];
    eventBus.$emit('CLEAR_MARKETS_TOP_LIST');
    // TODO generalise this. Count back from len of serch boxes and build this
    if (new_value) {
      if (box_id === 0) {
        iterator = [0];
      }
      if (box_id === 1) {
        iterator = [0, 1];
      }
      if (box_id === 2) {
        iterator = [0, 1, 2];
      }
      if (box_id === 3) {
        iterator = [0, 1, 2, 3];
      }
    } else {
      // only three boxes for market codes
      search_boxes[0].code === 'HS6_Code'
        ? (iterator = [0, 1, 2, 3])
        : (iterator = [0, 1, 2]);
    }

    iterator.forEach(box_id_ix => {
      let search_box = this.search_boxes[box_id_ix].code_lookup;
      for (let key of Object.keys(search_box)) {
        if (new_value === true) {
          if (
            (!search_box[key].in_cube && allow_out_of_cube_items_selections) ||
            search_box[key].in_cube
          ) {
            search_box[key].selected = new_value;
          }
        } else {
          search_box[key].selected = false;
          search_box[key].partials_list = [];
        }
      }
    });
  };

  this.show_select_all = function(box_id, allow_out_of_cube_items_selections) {
    // let selected_count = this.master_list.filter(v => v.C_Code_Selected === true).length
    return (
      this.entries_selected_count(box_id) !==
      this.box_entries_count(box_id, allow_out_of_cube_items_selections)
    );
  };

  this.show_unselect_all = function(box_id) {
    // let selected_count = this.master_list.filter(v => v.C_Code_Selected === true).length
    return this.entries_selected_count(box_id) > 0;
  };

  this.select_keys = function(box_id, items, new_value) {
    let search_box = this.search_boxes[box_id].code_lookup;
    // this block makes sure that if the item in the pre setup
    // list is not in the total list anymore, that it skips it
    items.forEach(v => {
      try {
        search_box[v].selected = new_value;
      } catch (err) {
        console.log('Item not in list.');
      }
    });
  };

  this.selected_items = function() {
    let search_box = this.search_boxes[0].code_lookup;
    let selected = [];
    for (let key of Object.keys(search_box)) {
      if (search_box[key].selected) {
        selected.push(key);
      }
    }
    return selected;
  };

  // setup code
  // create the master list
  this.build_base_list();
}
