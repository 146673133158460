var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-5 mr-0" },
    [
      _c(
        "v-layout",
        {
          staticClass: "pa-0 mt-0 ml-1 align-center",
          class: { "justify-center": _vm.$store.state.app.big_map },
          attrs: { row: "" }
        },
        [
          _c("v-img", {
            staticClass: "ml-1 mr-1",
            attrs: {
              src: "/flags/" + _vm.HOME_MARKET_CODE.toLowerCase() + ".svg",
              alt: "",
              "max-height": "50px",
              "max-width": "50px"
            }
          }),
          _c("h4", { staticClass: "justify-center align-center" }, [
            _vm._v("Home market:")
          ]),
          _c("h4", { staticClass: "font-italic justify-center align-center" }, [
            _vm._v(" " + _vm._s(_vm.HOME_MARKET) + " ")
          ])
        ],
        1
      ),
      _vm.codeToMarket(_vm.SELECTED_MARKETS[0])
        ? _c(
            "v-layout",
            {
              staticClass: "pa-0 mt-0 ml-1 mr-6 align-center",
              class: { "justify-center": _vm.$store.state.app.big_map },
              attrs: { row: "" }
            },
            [
              _c("v-img", {
                staticClass: "ml-1 mr-1",
                attrs: {
                  src:
                    "/flags/" +
                    _vm.codeToISO(_vm.SELECTED_MARKETS[0]).toLowerCase() +
                    ".svg",
                  alt: "",
                  "max-height": "50px",
                  "max-width": "50px"
                }
              }),
              _c("h4", { staticClass: "align-center" }, [
                _vm._v("Target market:")
              ]),
              _c("h4", { staticClass: "font-italic" }, [
                _vm._v(
                  " " + _vm._s(_vm.codeToMarket(_vm.SELECTED_MARKETS[0])) + " "
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.show_printable
        ? [
            _c("span", {
              staticClass: "ma-0 pa-0 caption",
              attrs: { id: "png_placeholder" }
            })
          ]
        : _vm._e(),
      _c("div", { attrs: { id: "1p1c_map" } }, [
        _c(
          "div",
          { staticClass: "chartWithOverlay" },
          [
            _c("GChart", {
              ref: "gChart_1p1c",
              staticClass: "chart pa-0 ma-0",
              attrs: {
                data: _vm.create_country_plot_array,
                events: _vm.chartEvents,
                options: _vm.options,
                resizeDebounce: 0,
                settings: {
                  packages: ["geochart"],
                  mapsApiKey: _vm.mapsApiKey,
                  tooltip: { isHtml: true }
                },
                type: "GeoChart"
              },
              on: { ready: _vm.chartReady }
            }),
            _c("trade-map-image", {
              attrs: { show_map_ta_overlay: _vm.show_map_ta_overlay }
            })
          ],
          1
        )
      ]),
      _vm.show_map_ta_overlay
        ? _c(
            "v-layout",
            {
              staticClass: "pa-0 mt-0 justify-lg-space-around",
              attrs: { row: "", "data-html2canvas-ignore": "" }
            },
            [
              _c("v-switch", {
                staticClass: "sm-4 mb-3 ml-lg-5",
                attrs: {
                  label:
                    _vm.display_mode_var === "region"
                      ? "Show bubbles"
                      : "Highlight countries",
                  color: "green",
                  dense: "",
                  flat: "",
                  inset: "",
                  value: "region"
                },
                on: {
                  change: function($event) {
                    _vm.options.displayMode =
                      _vm.display_mode_var === "region" ? "region" : "markers"
                  }
                },
                model: {
                  value: _vm.display_mode_var,
                  callback: function($$v) {
                    _vm.display_mode_var = $$v
                  },
                  expression: "display_mode_var"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.show_map_ta_overlay
        ? _c(
            "v-layout",
            {
              staticClass: "pa-0 mt-0 justify-center align-center",
              attrs: { row: "", "data-html2canvas-ignore": "" }
            },
            [
              _c(
                "v-toolbar-title",
                { staticClass: "ml-2" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { "max-width": "200", top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "sm-4 ml-3 mb-3 v-btn--outlined green lighten-3 pa-4 pl-1 pr-1",
                                      attrs: {
                                        disabled:
                                          _vm.options.region === "world",
                                        small: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.options.region = "world"
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [_vm._v("World ")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4138071968
                      )
                    },
                    [_c("span", [_vm._v("Click to select world map.")])]
                  )
                ],
                1
              ),
              _c(
                "v-toolbar-title",
                { staticClass: "ml-2" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { "max-width": "200", top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "sm-4 ml-3 mb-3 v-btn--outlined green lighten-3 pa-4 pl-1 pr-1",
                                      attrs: {
                                        disabled:
                                          typeof _vm.SELECTED_MARKETS[0] !==
                                          "undefined"
                                            ? _vm.options.region ===
                                              _vm.pad_with_zeroes(
                                                _vm.codeToMain_Region(
                                                  _vm.SELECTED_MARKETS[0]
                                                ),
                                                3
                                              )
                                            : true,
                                        small: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.options.region = _vm.pad_with_zeroes(
                                            _vm.codeToMain_Region(
                                              _vm.SELECTED_MARKETS[0]
                                            ),
                                            3
                                          )
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _vm._v("Target Market"),
                                    _c("br"),
                                    _vm._v(" Region ")
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1936840520
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Click to zoom into the target market (" +
                            _vm._s(_vm.codeToMarket(_vm.SELECTED_MARKETS[0])) +
                            ") region."
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-toolbar-title",
                { staticClass: "ml-2" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { "max-width": "200", top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "sm-4 ml-3 mb-3 v-btn--outlined green lighten-3 pa-4 pl-1 pr-1",
                                      attrs: {
                                        disabled:
                                          typeof _vm.SELECTED_MARKETS[0] !==
                                          "undefined"
                                            ? _vm.options.region ===
                                              _vm.pad_with_zeroes(
                                                _vm.codeToSub_Region_Code(
                                                  _vm.SELECTED_MARKETS[0]
                                                ),
                                                3
                                              )
                                            : true,
                                        small: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.options.region = _vm.pad_with_zeroes(
                                            _vm.codeToSub_Region_Code(
                                              _vm.SELECTED_MARKETS[0]
                                            ),
                                            3
                                          )
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _vm._v("Target Market"),
                                    _c("br"),
                                    _vm._v("Sub Region ")
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        549506092
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Click to zoom into the target market (" +
                            _vm._s(_vm.codeToMarket(_vm.SELECTED_MARKETS[0])) +
                            ") subregion."
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c("help-link", { staticClass: "ml-12", attrs: { help_id: "4" } })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }