<template>
  <v-container>
    <div v-show="updating">
      <div class="ml-4 blink">Loading Countries REO table</div>
      <v-skeleton-loader type="list-item-avatar-three-line, image, article" />
    </div>

    <template v-show="!updating">
      <v-row no-gutters class="mt-1">
        <v-col class="shrink">
          <v-icon>mdi-file-tree-outline</v-icon>
        </v-col>

        <v-col sm="11" class="grow">
          <v-expansion-panels class="ml-4">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>
                  Click to view applied aggregations ({{
                    reo_product_table_click_tracking.length
                  }})
                  <v-btn
                    v-if="reo_product_table_click_tracking.length > 0"
                    @click="clear_all_previous_reo_selections"
                    class="yellow ml-2"
                    x-small
                    >Clear selections
                  </v-btn>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="reo_product_table_click_tracking_path['headers']"
                  :items="reo_product_table_click_tracking_path['items']"
                  class="elevation-1 ma-0 pa-0"
                  :items-per-page="5"
                  dense
                >
                  <template v-slot:item.selection="{item}">
                    <ul>
                      <li v-for="(sel, ix) in item.selection" :key="sel.id">
                        <v-row>
                          <v-col sm="2">
                            <div>{{ sel }}</div>
                          </v-col>
                          <v-col>
                            <div class="ml-2">{{ item.description[ix] }}</div>
                          </v-col>
                        </v-row>
                      </li>
                    </ul>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs
            v-model="tab_selected"
            background-color="grey lighten-4"
            active-class="active_tab_class"
            dense
            class="mb-2 text--black"
          >
            <template v-for="tab in tab_def">
              <template v-if="'hover_text' in tab && tab.hover_text.length > 0">
                <v-tooltip top :key="tab.id">
                  <template v-slot:activator="{on, attrs}">
                    <v-tab v-bind="attrs" v-on="on" @click="change_tab(tab, 0)">
                      {{ tab.tab_name }}
                    </v-tab>
                  </template>
                  <span>{{ tab.hover_text }}</span>
                </v-tooltip>
              </template>
              <v-tab v-else :key="tab.id" @click="change_tab(tab, 0)">
                {{ tab.tab_name }}
              </v-tab>
            </template>
          </v-tabs>
          <v-tabs-items v-model="tab_selected">
            <template v-for="tab in tab_def">
              <v-tab-item :key="tab.id">
                <v-tabs
                  v-model="sub_tab_selected_dict[tab.tab_name]['sub_tab_selected']"
                  active-class="trade-blue"
                  background-color="tab_header_color"
                  dark
                  dense
                  class="mt-1"
                >
                  <template v-for="(sub_tab, sub_tab_ix) in tab.sub_tabs">
                    <v-tooltip bottom :key="sub_tab.id" v-if="'hover_text' in sub_tab">
                      <template v-slot:activator="{on, attrs}">
                        <v-tab
                          v-bind="attrs"
                          v-on="on"
                          @click="change_tab(tab, sub_tab_ix)"
                        >
                          {{ sub_tab.tab_name }}
                        </v-tab>
                      </template>
                      <span>{{ sub_tab.hover_text }}</span>
                    </v-tooltip>
                    <v-tab v-else :key="sub_tab.id" @change="change_tab(tab, sub_tab_ix)">
                      {{ sub_tab.tab_name }}
                    </v-tab>
                  </template>
                </v-tabs>
                <v-tabs-items
                  v-model="sub_tab_selected_dict[tab.tab_name]['sub_tab_selected']"
                >
                  <template v-for="sub_tab in tab.sub_tabs">
                    <v-tab-item :key="sub_tab.id">
                      <v-skeleton-loader
                        v-if="updating === true"
                        type="list-item-avatar-three-line, image, article"
                      >
                      </v-skeleton-loader>
                      <v-data-table
                        v-else
                        v-model="sub_tab_selected_dict[tab.tab_name]['selected_items']"
                        :headers="cube_data(tab, sub_tab)['headers']"
                        :items="cube_data(tab, sub_tab)['items']"
                        :items-per-page="5"
                        sort-by="A"
                        :sort-desc="true"
                        must-sort
                        class="elevation-1 ml-1 mt-1 mr-1"
                        dense
                        :show-select="
                          sub_tab['enable_selection'] &&
                            cube_data(tab, sub_tab)['items'].length > 1
                        "
                        :id="sub_tab.aggregation_col"
                        :header-props="{
                          'sort-icon': 'mdi-arrow-up-circle',
                          options: {
                            'multi-sort': true,
                          },
                        }"
                        :footer-props="{
                          'items-per-page-options': calc_items_per_page(
                            cube_data(tab, sub_tab)['items'].length,
                          ),
                        }"
                      >
                        <template v-slot:header.data-table-select="{item}">
                          <th
                            class="pb-4 caption justify-center text-"
                            style="font-weight: 900"
                          >
                            Select
                            <v-btn
                              v-if="
                                (sub_tab_selected_dict[tab.tab_name]['selected_items']
                                  .length &&
                                  sub_tab['enable_selection'] === true &&
                                  sub_tab_selected_dict[tab.tab_name]['selected_items']
                                    .length !==
                                    cube_data(tab, sub_tab)['items'].length) ||
                                  cube_data(tab, sub_tab)['items'][0][
                                    'Opportunities_agg'
                                  ] <
                                    $store.state.tradeDSM.dsm_filter_setttings.Filters
                                      .META.combinations.combinations
                              "
                              @click="apply_multi_select(tab, sub_tab)"
                              x-small
                              class="yellow ml-1"
                              >Apply ({{
                                sub_tab_selected_dict[tab.tab_name]['selected_items']
                                  .length
                              }})
                            </v-btn>
                          </th>
                        </template>

                        <!-- define header in general and test for keys so you can set individual columns-->
                        <template
                          v-for="header in cube_data(tab, sub_tab)['headers']"
                          v-slot:[`header.${header.value}`]="{header}"
                        >
                          <!--download-->

                          <v-icon
                            v-if="header.value === 'id'"
                            @click.stop.prevent="
                              html_to_xls_download_dialog_mp1c_product(
                                false,
                                true,
                                cube_data(tab, sub_tab),
                              )
                            "
                            :key="header.id"
                            class="green--text ma-0 pa-0 ml-0 pl-0 mb-6 pr-0 text-center"
                          >
                            mdi-microsoft-excel
                          </v-icon>

                          <!--  enable selection shortcut-->
                          <v-checkbox
                            small
                            :key="header.id"
                            v-model="sub_tab['enable_selection']"
                            v-if="
                              header.value === 'description' &&
                                cube_data(tab, sub_tab)['items'].length > 1
                            "
                            @change="enable_disable_clicked"
                          >
                            <template v-slot:label>
                              <span class="caption mr-2">{{
                                !sub_tab['enable_selection']
                                  ? 'Enable Selection Shortcuts'
                                  : 'Disable Selection Shortcuts'
                              }}</span>
                            </template>
                          </v-checkbox>

                          <template
                            v-if="sub_tab[report_type]['style_def'] !== undefined"
                          >
                            <template
                              v-if="
                                sub_tab[report_type]['style_def'][header.value] !==
                                  undefined
                              "
                            >
                              <div
                                :class="
                                  sub_tab[report_type]['style_def'][header.value][
                                    'active_class'
                                  ]
                                "
                                :key="header.id"
                                :style="
                                  sub_tab[report_type]['style_def'][header.value][
                                    'active_style'
                                  ]
                                "
                                v-html="header.text"
                              ></div>
                            </template>
                            <template v-else>
                              <div
                                class="text-center font-weight-bold"
                                v-html="header.text"
                                :key="header.id"
                              ></div>
                            </template>
                          </template>
                          <template v-else>
                            <div
                              class="text-center font-weight-bold"
                              v-html="header.text"
                              :key="header.id"
                            ></div>
                          </template>

                          <div
                            v-if="header.value === 'opportunities'"
                            :key="header.id"
                            class="text-center font-weight-black"
                            v-html="header_val(tab, sub_tab, 0, 'Opportunities_agg')"
                          ></div>

                          <div
                            v-if="header.value === 'A'"
                            :key="header.id"
                            class="text-center font-weight-black"
                            v-html="header_val(tab, sub_tab, 0, 'A_agg')"
                          ></div>
                          <div
                            v-if="header.value === 'A1'"
                            :key="header.id"
                            class="text-center font-weight-black"
                            v-html="header_val(tab, sub_tab, 0, 'A1_agg')"
                          ></div>
                          <div
                            v-if="header.value === 'B'"
                            :key="header.id"
                            class="text-center font-weight-black"
                            v-html="header_val(tab, sub_tab, 0, 'B_agg')"
                          ></div>
                          <div
                            v-if="header.value === 'C'"
                            :key="header.id"
                            class="text-center font-weight-black"
                            v-html="header_val(tab, sub_tab, 0, 'C_agg')"
                          ></div>
                          <div
                            v-if="header.value === 'D'"
                            :key="header.id"
                            class="text-center font-weight-black"
                            v-html="header_val(tab, sub_tab, 0, 'D_agg')"
                          ></div>
                          <div
                            v-if="header.value === 'E'"
                            :key="header.id"
                            class="text-center font-weight-black"
                            v-html="header_val(tab, sub_tab, 0, 'E_agg')"
                          ></div>
                          <div
                            v-if="header.value === 'F'"
                            :key="header.id"
                            class="text-center font-weight-black"
                            v-html="header_val(tab, sub_tab, 0, 'F_agg')"
                          ></div>
                          <div
                            v-if="header.value === 'G'"
                            :key="header.id"
                            class="text-center font-weight-black"
                            v-html="header_val(tab, sub_tab, 0, 'G_agg')"
                          ></div>
                        </template>

                        <!--            Code columns-->
                        <template v-slot:[`item.${sub_tab.aggregation_col}`]="{item}">
                          <div
                            v-if="sub_tab['enable_selection']"
                            class="reo-table-selector font-weight-bold text-right"
                            @click="
                              summary_click(
                                tab,
                                sub_tab,
                                [item[sub_tab.aggregation_col]],
                                [item.description],
                              )
                            "
                          >
                            {{ item[sub_tab.aggregation_col] }}
                          </div>
                          <div v-else class="text-right">
                            {{ item[sub_tab.aggregation_col] }}
                          </div>
                        </template>

                        <!--                      Description-->
                        <template v-slot:item.description="{item}">
                          <div
                            v-if="sub_tab['enable_selection']"
                            class="reo-table-selector font-weight-bold"
                            @click="
                              summary_click(
                                tab,
                                sub_tab,
                                [item[sub_tab.aggregation_col]],
                                [item.description],
                              )
                            "
                          >
                            {{ item.description }}
                          </div>
                          <div v-else>{{ item.description }}</div>
                        </template>

                        <!--                      // REOxy-->
                        <template v-slot:item.reoxy="{item}">
                          <div class="text-center" v-html="item.reoxy"></div>
                        </template>

                        <!--                      // Tariff-->
                        <template v-slot:item.tariff="{item}">
                          <v-tooltip
                            left
                            v-if="
                              Math.round(parseFloat(item.tariff) * 100 * 100) / 100 > 0
                            "
                          >
                            <template v-slot:activator="{on, attrs}">
                              <div class="text-center" v-on="on">
                                <a :href="itc_url(item)" target="_blank" class="no-link">
                                  {{ (parseFloat(item.tariff) / 1).toFixed(2) }}</a
                                >
                              </div>
                            </template>
                            <span>Click to view ITC tariff information</span>
                          </v-tooltip>
                          <span v-else class="ml-2">-</span>
                        </template>

                        <!--                      // Rank-->
                        <template v-slot:item.rank="{item}">
                          <div
                            :class="{'red--text': is_zero_rank(item.rank)}"
                            v-html="item.rank"
                          ></div>
                        </template>
                        <!--                      // Rank out off-->
                        <template v-slot:item.rank_out_of="{item}">
                          <div
                            :class="{'red--text': is_zero_rank(item.rank)}"
                            v-html="item.rank_out_of"
                          ></div>
                        </template>

                        <template v-slot:item.opportunities="{item}">
                          <div class="text-center">{{ item.opportunities }}</div>
                        </template>

                        <template v-slot:item.share="{item}">
                          <div class="text-center">
                            {{ val_format(vFmt(item.share, 2)) }}
                          </div>
                        </template>
                        <!--                      // A to G-->
                        <template v-slot:item.A="{item}">
                          <div class="text-center">
                            {{ val_format(vFmt(item.A * value_convertion, 2)) }}
                          </div>
                        </template>
                        <template v-slot:item.A1="{item}">
                          <div class="text-center">
                            {{ val_format(vFmt(item.A1 * value_convertion, 2)) }}
                          </div>
                        </template>
                        <template v-slot:item.B="{item}">
                          <div class="text-center">
                            {{ val_format(vFmt(item.B * value_convertion, 2)) }}
                          </div>
                        </template>
                        <template v-slot:item.C="{item}">
                          <div class="text-center">{{ val_format_perc(item.C) }}</div>
                        </template>
                        <template v-slot:item.D="{item}">
                          <div class="text-center">{{ val_format_perc(item.D) }}</div>
                        </template>
                        <template v-slot:item.E="{item}">
                          <div class="text-center ">
                            {{ val_format(vFmt(item.E * value_convertion, 2)) }}
                          </div>
                        </template>
                        <template v-slot:item.F="{item}">
                          <div class="text-center">
                            {{ val_format(vFmt(item.F * value_convertion, 2)) }}
                          </div>
                        </template>
                        <template v-slot:item.G="{item}">
                          <div class="text-center">
                            {{ val_format(vFmt(item.G * value_convertion, 2)) }}
                          </div>
                        </template>

                        //
                        <template v-slot:item.HM_TW_RCA="{item}">
                          <div class="text-center">
                            {{ parseFloat(item.HM_TW_RCA.toFixed(4)) }}
                          </div>
                        </template>

                        //
                        <template v-slot:item.HM_TW_RTA="{item}">
                          <div class="text-center">
                            {{ parseFloat(item.HM_TW_RTA.toFixed(4)) }}
                          </div>
                        </template>
                        //
                      </v-data-table>
                    </v-tab-item>
                  </template>
                </v-tabs-items>
              </v-tab-item>
            </template>
          </v-tabs-items>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {mapMutations} from 'vuex';
import swal from 'sweetalert';
import {eventBus} from '@/main';
import FileDownload from 'js-file-download';
import dialogExcelTablesProducts from '@/components/application-level/helper-components/dialog-excel-tables-products.vue';
import _ from 'lodash';
import Swal from 'sweetalert2';

const {
  html_to_xls_download,
} = require('@/components/application-level/js_functions/jsFunctions');

const {
  calc_items_per_page,
} = require('@/components/trade-dsm-application/mixins/calc_items_per_page');

export default {
  name: 'reo-cube-wrapper',

  mounted() {
    // console.log('REO table is being mounted');
    // this.load_reo_definitions();

    eventBus.$on('MARKETS_ENABLE_SELECTION_CLICKED', async () => {
      console.log('MARKETS_ENABLE_SELECTION_CLICKED');
      // for tab in this.tab_def
      // for sub_tab in tab.sub_tabs
      //  sub_tab['enable_selection'] = false
      for (let tab of this.tab_def) {
        for (let sub_tab of tab['sub_tabs']) {
          if (sub_tab['enable_selection'] === true) {
            Swal.fire({
              title: 'Note',
              html: `<h3>Clicking markets selection shortcut will
          ignore any product selections. Do you want to continue?</h3>`,
              width: 800,
              icon: 'warning',
              showCancelButton: true, // Enable the Cancel button
              confirmButtonText: 'Continue', // Change the text to "Continue"
              cancelButtonText: 'Cancel', // Add "Cancel" button text
            }).then(result => {
              if (result.isConfirmed) {
                sub_tab['enable_selection'] = false;
              } else if (result.isDismissed) {
                eventBus.$emit('MARKETS_CLICK_CANCELLED');
                console.log('Cancel button was pressed'); // Log message when Cancel is pressed
              }
            });
          }
        }
      }
    });

    eventBus.$on('PRODUCTS_CLICK_CANCELLED', async () => {
      for (let tab of this.tab_def) {
        for (let sub_tab of tab['sub_tabs']) {
          sub_tab['enable_selection'] = false;
        }
      }
    });

    eventBus.$on('UPDATE_REO_TABLE', async () => {
      await this.update_cube_and_load_defs();
    });
  },
  destroyed() {
    eventBus.$off('UPDATE_REO_TABLE');
  },

  computed: {
    ...mapGetters(['productSearchAndSelectedList', 'is_run_cube_valid', 'user']),
    reo_product_table_click_tracking_path() {
      return {
        headers: [
          {
            text: '#',
            value: 'entry_number',
            width: '10px',
            class: 'grey lighten-3 align-end',
          },
          {
            text: 'Main Grouping',
            value: 'tab_name',
            class: 'grey lighten-3',
            width: '100px',
          },
          {
            text: 'Sub Grouping',
            value: 'sub_tab_name',
            class: 'grey lighten-3',
            width: '100px',
          },
          {
            text: 'Selection / Description',
            value: 'selection',
            class: 'grey lighten-3',
          },
          // {
          //   text: 'Description',
          //   value: 'description'
          // }
        ],
        items: this.reo_product_table_click_tracking.map((v, ix) => {
          return {
            entry_number: ix + 1,
            tab_name: v.tab_name,
            sub_tab_name: v.sub_tab_name,
            selection: v.selection,
            description: v.description,
          };
        }),
      };
    },
    report_type() {
      return this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
        .report_type_to_generate;
    },
    products_list() {
      if (
        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.products
          .HS6_Code.length > 0
      ) {
        return this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections
          .products.HS6_Code;
      }
      // return this.productSearchAndSelectedList
      //   .filter(v => v.in_cube === true)
      //   .map(v => v.HS6_Code);
      else
        return this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.query_results
          .products;
    },
    markets_list() {
      return this.countriesSearchAndSelectedList
        .filter(v => v.in_cube === true)
        .map(v => v.C_Code)
        .filter(
          c =>
            c !==
            this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.home_market,
        );
    },
  },

  methods: {
    ...mapMutations([
      'clear_circ_ref_error',
      'set_HS6_Code_selection',
      'set_C_Code_selection',
    ]),
    calc_items_per_page: calc_items_per_page,
    html_to_xls_download: html_to_xls_download,

    enable_disable_clicked() {
      // sub_tab['enable_selection'] = !sub_tab['enable_selection'];
      eventBus.$emit('PRODUCTS_ENABLE_SELECTION_CLICKED');
    },
    is_zero_rank(rank) {
      return this.regex.test(rank);
    },
    async clear_all_previous_reo_selections() {
      let current_selections = this.$store.state.tradeDSM.dsm_filter_setttings.Filters
        .META.selections.products.HS6_Code;
      if (current_selections.length > 0) {
        // clear the selection of products
        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.products.HS6_Code = [];
        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.products.HS4_Code = [];
        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.products.HS2_Code = [];
        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.products.HS2_Groups = [];
        // clear the actual tracking list in the reo table
        this.reo_product_table_click_tracking = [];
        this.$forceUpdate();
        await this.update_results_cube();
      }
    },
    apply_multi_select(tab, sub_tab) {
      this.$store.state.app.disable_getters = true;

      const selection = this.sub_tab_selected_dict[tab.tab_name]['selected_items'].map(
        v => v[sub_tab.aggregation_col],
      );
      const description = this.sub_tab_selected_dict[tab.tab_name]['selected_items'].map(
        v => v.description,
      );
      this.clear_sub_tab_selected();
      this.summary_click(tab, sub_tab, selection, description);
    },
    async change_tab(tab, sub_tab_ix) {
      await this.clear_sub_tab_selected();
      await this.clear_enable_selection();
      this.$nextTick(async () => {
        // this.sub_tab_selected_dict[tab.tab_name] = {}
        this.sub_tab_selected_dict[tab.tab_name]['sub_tab_selected'] = sub_tab_ix;
        this.sub_tab_selected_dict[tab.tab_name]['selected_items'] = [];
        this.$forceUpdate();
      });
    },
    async clear_sub_tab_selected() {
      for (const tab of this.tab_def) {
        this.sub_tab_selected_dict[tab.tab_name]['selected_items'] = [];
      }
      this.$forceUpdate();
    },
    async clear_enable_selection() {
      for (let tab_ix = 0; tab_ix < this.tab_def.length; tab_ix++) {
        for (
          let sub_tab_ix = 0;
          sub_tab_ix < this.tab_def[tab_ix]['sub_tabs'].length;
          sub_tab_ix++
        ) {
          // console.log(this.tab_def[tab_ix])
          this.tab_def[tab_ix]['sub_tabs'][sub_tab_ix]['enable_selection'] = false;
        }
      }
    },
    async load_reo_definitions() {
      // this.clear_circ_ref_error()
      let data = {
        institution_id: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
          .instituion_id,
      };
      this.updating = true;
      await this.axios
        .post(this.$store.getters.baseUrl + 'load_reo_definitions/', data)
        .then(response => {
          this.tab_def = response.data['tab_def'];
          for (const tab of this.tab_def) {
            this.sub_tab_selected_dict[tab.tab_name] = {};
            this.sub_tab_selected_dict[tab.tab_name]['sub_tab_selected'] = [];
          }
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.updating = true;
          this.tab_selected = 0;
          this.sub_tab_selected = 0;
        });
    },
    header_val(tab, sub_tab, sub_tab_ix, header_key) {
      if (header_key === 'Opportunities_agg') {
        //Opportunities_agg is just a number
        return this.cube_data(tab, sub_tab)['items'][sub_tab_ix][header_key];
      }
      if (header_key !== 'C_agg' && header_key !== 'D_agg') {
        if (this.cube_data(tab, sub_tab).items.length > 0) {
          // A, A1, B, C, E, F is a currency formatted string
          return `${this.currencyString} <br> ${this.val_format(
            this.vFmt(
              this.cube_data(tab, sub_tab)['items'][sub_tab_ix][header_key] *
                this.value_convertion,
              2,
            ),
          )}<br>${this.multiplerStringLongName}`;
        } else {
          // no value
          return '-';
        }
      } else {
        // C_agg and D_agg are percentages
        return `${(
          this.cube_data(tab, sub_tab)['items'][sub_tab_ix][header_key] * 100
        ).toFixed(1)}%`;
      }
    },
    itc_url(item) {
      if ('HS6_Code' in item) {
        let home_market_code = '';

        if ('Home_Market' in this.$store.state.tradeDSM.dsm_filter_setttings) {
          home_market_code = this.$store.state.tradeDSM.dsm_filter_setttings.Home_Market.slice(
            1,
          );
        }

        let target_market_code = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.markets.C_Code[0].slice(
          1,
        );
        let hs6_code = item['HS6_Code'].slice(2);
        let year = 'none';
        try {
          year = item['ad_valorem_tariff_year'];
        } catch (e) {
          year = 2020;
        }
        return `https://macmap.org/en/query/customs-duties?reporter=${
          target_market_code === '158' ? '490' : target_market_code
        }&year=${year}&partner=${home_market_code}&product=${hs6_code}&level=6`;
        // https://macmap.org/en/query/customs-duties?reporter=792&year=2019&partner=036&product=010229&level=6
      }
    },
    cube_data(tab, sub_tab) {
      if (this.cube_headers_items !== null) {
        if (this.cube_headers_items[tab['tab_name']][sub_tab['tab_name']] !== undefined) {
          // console.log(this.cube_headers_items[tab['tab_name']][sub_tab['tab_name']])
          return this.cube_headers_items[tab['tab_name']][sub_tab['tab_name']];
        } else
          return {
            headers: [],
            items: [],
          };
      } else
        return {
          headers: [],
          items: [],
        };
    },
    val_format(val) {
      // console.log('val is ' + val)
      // console.log(val === '-0.00' || val.includes('-') || val<0)
      return val === '-0.00' || val.includes('-') || val < 0 ? '-' : val;
    },
    val_format_perc(val) {
      // console.log('val is ' + val)
      // console.log(val === '-0.00' || val.includes('-') || val<0)
      return val < 0 ? '-' : (val * 100).toFixed(2);
    },
    async get_frontend_cube_data(data) {
      await this.clear_circ_ref_error();
      this.updating = true;

      swal('Updating REO tables ... ', {
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        icon: 'info',
      });

      await this.axios
        .post(this.$store.getters.baseUrl + 'get_reo_supplemental_cubes/', data)
        .then(response => {
          this.cube_headers_items = response.data['cube_headers_items'];
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.updating = false;
          this.clear_sub_tab_selected();
          this.clear_enable_selection();
          swal.close();
          this.loading = false;
        });
    },
    async get_frontend_cube_xlsx(data) {
      this.axios({
        method: 'post',
        baseURL: this.$store.getters.baseUrl,
        url: 'reo_download_excel_object/',
        responseType: 'blob',
        data: data,
      })
        .then(response => {
          const tab_name = this.tab_def[this.tab_selected]['tab_name']
            .replace(' ', '_')
            .replace('(', '')
            .replace(')', '');
          const sub_tab_selected = this.sub_tab_selected_dict[
            this.tab_def[this.tab_selected].tab_name
          ]['sub_tab_selected'];
          const sub_tab_name = this.tab_def[this.tab_selected]['sub_tabs'][
            sub_tab_selected
          ]['tab_name']
            .replace(' ', '_')
            .replace('(', '')
            .replace(')', '');
          FileDownload(response.data, `${tab_name}_${sub_tab_name}.xlsx`);
          swal('REO table data downloaded to downloads folder.', {
            buttons: false,
            timer: 2000,
          });
        })
        .catch(() => {
          swal('An error occurred during the REO table generation.', {
            buttons: false,
            timer: 2000,
          });
        });
    },

    html_to_xls_download_dialog_mp1c_product(
      clear_tracking = false,
      download_xls = false,
      cube_data = [],
    ) {
      this.$store.state.app.activeDialogComponentProps = {
        ms_office_type: 'Excel',
        download_func: this.update_cube,
        clear_tracking: clear_tracking,
        download_xls: download_xls,
        cube_data: cube_data,
      };
      this.$store.state.app.activeDialogComponent = dialogExcelTablesProducts;
    },

    async update_cube_and_load_defs() {
      this.load_reo_definitions().then(this.update_cube);
    },
    async update_cube(clear_tracking = false, download_xls = false, cube_data = []) {
      this.$store.state.app.disable_getters = false;
      if (this.is_run_cube_valid) {
        if (clear_tracking === true) {
          this.reo_product_table_click_tracking = [];
        }
        await this.clear_circ_ref_error();
        // this.set_results_cube_to_empty();
        let data = {
          institution_id: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .instituion_id,
          institution_template_dir: this.$store.state.user.userServerInfo.user.fields
            .institution[0].fields.template_location,
          // institution_reo_table_columns_to_keep : [],
          tab_def: this.tab_def,
          products: this.products_list,
          markets: this.markets_list,
          markets_names: this.markets_list.map(v => this.codeToMarket(v)),
          tw_rate: this.$store.state.app.exchange_rate,
          report_type_to_generate: this.$store.state.tradeDSM.dsm_filter_setttings.Filters
            .META.report_type_to_generate,
          home_market_code: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .home_market,
          home_market_name: this.codeToMarket(
            this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.home_market,
          ),
          model_number: this.$store.state.app.selectedModelVersion,
          model_name: this.$store.state.app.selectedModelName,
          data: this.$store.state.tradeDSM.dsm_filter_setttings.Filters,
          table_header_class: this.table_header_class,
          current_selected_cube_date: cube_data,
        };

        if (!download_xls) {
          this.updating = true;
          await this.clear_circ_ref_error();
          await this.get_frontend_cube_data(data);
          this.$forceUpdate();
        } else {
          swal('Downloading REO table data.', {
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            icon: 'info',
          });
          data['tab_selected'] = this.tab_selected;
          data['sub_tab_selected'] = this.sub_tab_selected_dict[
            this.tab_def[this.tab_selected].tab_name
          ]['sub_tab_selected'];

          await this.get_frontend_cube_xlsx(data);
          swal.close();
        }
      }
    },
    async summary_click(tab, sub_tab, selection, description) {
      if (
        this.is_run_cube_valid === true &&
        !this.$store.state.tradeDSM.loading_indicator
      ) {
        // add this click to the tracking store
        this.reo_product_table_click_tracking.push({
          tab_name: tab.tab_name,
          sub_tab_name: sub_tab.tab_name,
          selection: selection,
          description: description,
        });

        this.clear_circ_ref_error();
        // this.set_results_cube_to_empty();

        let data = {
          tab_def: this.tab_def,
          products: this.products_list,
          markets: this.markets_list,
          aggregation_col: sub_tab.aggregation_col,
          selection: selection,
          home_market_code: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .home_market,
          model_number: this.$store.state.app.selectedModelVersion,
          model_name: this.$store.state.app.selectedModelName,
          data: _.cloneDeep(this.$store.state.tradeDSM.dsm_filter_setttings.Filters),
        };

        console.log('Before and after');
        console.log(JSON.stringify(data).length);
        data['data']['META']['query_results'] = {};
        console.log(JSON.stringify(data).length);
        this.updating = true;
        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.advanced_filters_is_dirty = true;
        swal({
          text:
            'Loading selection products and markets.\n\nPlease be patient. This step can take some time.',
          buttons: false,
          closeOnClickOutside: false,
          icon: 'info',
          className: 'swal-modal',
          closeOnEsc: false,
        });

        // remove this
        // todo rememebr
        this.$store.state.app.disable_getters = true;

        await this.axios
          .post(this.$store.getters.baseUrl + 'get_reo_supplemental_cube_click/', data)
          .then(async response => {
            console.log('The response is here');
            let products = response.data['products'];
            let markets = response.data['markets'];
            // this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.products.HS6_Code = products
            // this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.markets.C_Code = markets
            console.log('starting to set the arrays');
            await this.set_HS6_Code_selection(products);
            await this.set_C_Code_selection(markets);
            // this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.products.HS6_Code.length = 0;
            // this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.markets.C_Code.length = 0;
            //
            // this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.products.HS6_Code = [
            //   ...products,
            // ];
            // this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.markets.C_Code = [
            //   ...markets,
            // ];

            console.log('arrays have been set');

            await this.clear_sub_tab_selected();
            await this.update_results_cube();
          })
          .catch(e => {
            console.log(e);
            this.updating = true;
          })
          .finally(() => {
            this.updating = true;
          });
      }
    },
  },
  data: () => {
    return {
      regex: /^0\//,
      tab_def: [], //tab def gets loader from server on mount
      reo_product_table_click_tracking: [], //tracks the clicks in the reo table
      tick_box_selections: [],
      sort_asc: true,
      updating: false,
      cube_headers_items: null, //gets loaded when a cube it pulled - this is the data for all the tables in 'tab_def'
      tab_selected: 0,
      sub_tab_selected: 0,
      sub_tab_selected_dict: {},
      table_header_class: 'bottomone grey lighten-3', //injects the following classes into the table header
    };
  },
};
</script>

<style scoped>
.active_tab_class {
  background: #1f3c5f;
  color: #c4d1e7;
}

.inactive_tab_class {
  background: white;
  color: black;
}

.reo-table-selector:hover {
  color: yellow;
  cursor: pointer;
  padding: 2px 2px 2px 5px;
  background-color: #1f3c5f;
}

::v-deep .v-data-table-header {
  background-color: #eeeeee !important;
  vertical-align: bottom !important;
  align-content: end !important;
}
</style>
