var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-expansion-panel",
        { staticClass: "mt-1" },
        [
          _c("h3", { staticClass: "text-left mt-2 ml-2 trade-blue-text" }, [
            _vm._v("REO Market Characteristics:")
          ]),
          _c(
            "v-expansion-panel-header",
            {
              staticClass: "trade-blue ma-0 ml-2",
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _c("v-icon", { attrs: { color: "yellow" } }, [
                        _vm._v("$expand")
                      ])
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("span", [_vm._v(" Target Market Demand Characteristics ")]),
              _c(
                "span",
                {
                  staticClass:
                    "yellow--text caption text-right font-italic ma-0 pa-0"
                },
                [_vm._v(" (basic) ")]
              )
            ]
          ),
          _c(
            "v-expansion-panel-content",
            { staticClass: "mt-2" },
            [
              _c(
                "v-main",
                { staticClass: "ma-0 pa-0 " },
                [
                  _c(
                    "v-container",
                    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "filterItem",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _c("span", [_vm._v("Not classified as REO")])
                          ]),
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c(
                              "span",
                              [
                                _c("v-checkbox", {
                                  staticClass: "d-inline",
                                  attrs: { disabled: _vm.set_disabled },
                                  on: { change: _vm.update_array },
                                  model: {
                                    value: _vm.reo_col_row.row_0,
                                    callback: function($$v) {
                                      _vm.$set(_vm.reo_col_row, "row_0", $$v)
                                    },
                                    expression: "reo_col_row.row_0"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "filterItem",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _vm._v("1-Large but Stable Market")
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cvols: "4" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "d-inline",
                                attrs: { disabled: _vm.set_disabled },
                                on: { change: _vm.update_array },
                                model: {
                                  value: _vm.reo_col_row.row_1,
                                  callback: function($$v) {
                                    _vm.$set(_vm.reo_col_row, "row_1", $$v)
                                  },
                                  expression: "reo_col_row.row_1"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "filterItem",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _vm._v("2-Growing Market (Short & Long term)")
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cvols: "4" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "d-inline",
                                attrs: { disabled: _vm.set_disabled },
                                on: { change: _vm.update_array },
                                model: {
                                  value: _vm.reo_col_row.row_2,
                                  callback: function($$v) {
                                    _vm.$set(_vm.reo_col_row, "row_2", $$v)
                                  },
                                  expression: "reo_col_row.row_2"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "filterItem",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _vm._v("3-Large Growing Market (Short term)")
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cvols: "4" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "d-inline",
                                attrs: { disabled: _vm.set_disabled },
                                on: { change: _vm.update_array },
                                model: {
                                  value: _vm.reo_col_row.row_3,
                                  callback: function($$v) {
                                    _vm.$set(_vm.reo_col_row, "row_3", $$v)
                                  },
                                  expression: "reo_col_row.row_3"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "filterItem",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _vm._v("4-Large Growing Market (Long term)")
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cvols: "4" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "d-inline",
                                attrs: { disabled: _vm.set_disabled },
                                on: { change: _vm.update_array },
                                model: {
                                  value: _vm.reo_col_row.row_4,
                                  callback: function($$v) {
                                    _vm.$set(_vm.reo_col_row, "row_4", $$v)
                                  },
                                  expression: "reo_col_row.row_4"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "filterItem",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _vm._v("5-Large Growing Market (Short & Long term)")
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cvols: "4" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "d-inline",
                                attrs: { disabled: _vm.set_disabled },
                                on: { change: _vm.update_array },
                                model: {
                                  value: _vm.reo_col_row.row_5,
                                  callback: function($$v) {
                                    _vm.$set(_vm.reo_col_row, "row_5", $$v)
                                  },
                                  expression: "reo_col_row.row_5"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-expansion-panel",
        { staticClass: "mt-1 ml-2" },
        [
          _c(
            "v-expansion-panel-header",
            {
              staticClass: "trade-blue ma-0 mt-2",
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _c("v-icon", { attrs: { color: "yellow" } }, [
                        _vm._v("$expand")
                      ])
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("span", [_vm._v(" Home Market Supply Characteristics ")]),
              _c(
                "span",
                {
                  staticClass:
                    "yellow--text caption text-right font-italic ma-0 pa-0"
                },
                [_vm._v(" (basic) ")]
              )
            ]
          ),
          _c(
            "v-expansion-panel-content",
            { staticClass: "mt-2" },
            [
              _c(
                "v-main",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-container",
                    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "filterItem",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _vm._v("1-Small Market Share (0 ≤ S < 5%)")
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cvols: "4" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "d-inline",
                                attrs: { disabled: _vm.set_disabled },
                                on: { change: _vm.update_array },
                                model: {
                                  value: _vm.reo_col_row.col_1,
                                  callback: function($$v) {
                                    _vm.$set(_vm.reo_col_row, "col_1", $$v)
                                  },
                                  expression: "reo_col_row.col_1"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "filterItem",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _vm._v(
                              "2-Intermediate Small Market Share (5 ≤ S < 15%)"
                            )
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cvols: "4" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "d-inline",
                                attrs: { disabled: _vm.set_disabled },
                                on: { change: _vm.update_array },
                                model: {
                                  value: _vm.reo_col_row.col_2,
                                  callback: function($$v) {
                                    _vm.$set(_vm.reo_col_row, "col_2", $$v)
                                  },
                                  expression: "reo_col_row.col_2"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "filterItem",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _vm._v(
                              "3-Intermediate Large Market Share (15 ≤ S < 25%)"
                            )
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cvols: "4" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "d-inline",
                                attrs: { disabled: _vm.set_disabled },
                                on: { change: _vm.update_array },
                                model: {
                                  value: _vm.reo_col_row.col_3,
                                  callback: function($$v) {
                                    _vm.$set(_vm.reo_col_row, "col_3", $$v)
                                  },
                                  expression: "reo_col_row.col_3"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "filterItem",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _vm._v("4-Large Market Share (25% < S)")
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cvols: "4" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "d-inline",
                                attrs: { disabled: _vm.set_disabled },
                                on: { change: _vm.update_array },
                                model: {
                                  value: _vm.reo_col_row.col_4,
                                  callback: function($$v) {
                                    _vm.$set(_vm.reo_col_row, "col_4", $$v)
                                  },
                                  expression: "reo_col_row.col_4"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }