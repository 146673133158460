var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { row: "" } },
        [
          _c("v-flex", { staticClass: "xs12 sm12 lg12" }, [
            _c("div", { staticClass: "body-2 font-weight-bold nav nav-tabs" }, [
              _c("li", [
                _c("a", [
                  _vm._v("Summary "),
                  _c(
                    "span",
                    { staticClass: "yellow--text" },
                    [
                      _c("help-link", {
                        staticStyle: { float: "right" },
                        attrs: { help_id: "12" }
                      }),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(
                            !_vm.$store.state.tradeDSM.dsm_filter_setttings
                              .Filters.META.filter_in_progress &&
                              _vm.current_report_to_run !== "1P1C"
                              ? "Note-Totals in summary might differ from totals in REO map due to filter selections"
                              : ""
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ],
        1
      ),
      _c("div", { staticClass: "tab-content" }, [
        _c(
          "div",
          { staticClass: "tab-pane active white" },
          [
            _c(
              "v-container",
              { staticClass: "ma-0 pa-0 pt-1 pb-1", attrs: { fluid: "" } },
              [
                _c(
                  "v-layout",
                  {
                    staticClass:
                      "justify-space-between no-gutters text-md-center black--text mb-1",
                    attrs: { row: "" }
                  },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-flex",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "ma-0 pa-0 sm2 ml-4 text-start"
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      { staticClass: "black--text" },
                                      [_vm._v("mdi-earth")]
                                    ),
                                    _vm.MarketProductCombinationsUpdating
                                      ? _c("v-progress-circular", {
                                          attrs: {
                                            size: 20,
                                            width: 3,
                                            color: "green",
                                            indeterminate: ""
                                          }
                                        })
                                      : _vm._e(),
                                    !_vm.MarketProductCombinationsUpdating
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "ma-0 pa-0",
                                            class: {
                                              "red--text":
                                                _vm.markets_exceed &&
                                                _vm.market_calc_count() !== 0
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.market_calc_count()
                                                ) +
                                                " "
                                            ),
                                            _vm.markets_exceed &&
                                            _vm.market_calc_count() !== 0
                                              ? _c(
                                                  "span",
                                                  { staticClass: "caption" },
                                                  [
                                                    _c("br"),
                                                    _vm._v(
                                                      "(max " +
                                                        _vm._s(
                                                          _vm.$store.state.user
                                                            .userServerInfo
                                                            .app_config
                                                            .max_markets
                                                        ) +
                                                        ")"
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [_c("span", [_vm._v("Markets count")])]
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-flex",
                                  _vm._g({ staticClass: "ma-0 pa-0 sm2" }, on),
                                  [
                                    _c(
                                      "v-icon",
                                      { staticClass: "black--text" },
                                      [_vm._v("mdi-dolly")]
                                    ),
                                    _vm.MarketProductCombinationsUpdating
                                      ? _c("v-progress-circular", {
                                          attrs: {
                                            size: 20,
                                            width: 3,
                                            color: "green",
                                            indeterminate: ""
                                          }
                                        })
                                      : _vm._e(),
                                    !_vm.MarketProductCombinationsUpdating
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "ma-0 pa-0",
                                            class: {
                                              "red--text":
                                                _vm.products_exceed &&
                                                _vm.product_calc_count() !== 0
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.product_calc_count()
                                                ) +
                                                " "
                                            ),
                                            _vm.products_exceed &&
                                            _vm.product_calc_count() !== 0
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "caption ml-6"
                                                  },
                                                  [
                                                    _c("br"),
                                                    _vm._v(
                                                      "(max " +
                                                        _vm._s(
                                                          _vm.$store.state.user
                                                            .userServerInfo
                                                            .app_config
                                                            .max_products
                                                        ) +
                                                        ")"
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [_c("span", [_vm._v("Products count")])]
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-flex",
                                  _vm._g({ staticClass: "ma-0 pa-0 sm3" }, on),
                                  [
                                    _c(
                                      "v-icon",
                                      { staticClass: "black--text" },
                                      [_vm._v("mdi-sigma")]
                                    ),
                                    _vm.MarketProductCombinationsUpdating
                                      ? _c("v-progress-circular", {
                                          attrs: {
                                            size: 20,
                                            width: 3,
                                            color: "green",
                                            indeterminate: ""
                                          }
                                        })
                                      : _vm._e(),
                                    !_vm.MarketProductCombinationsUpdating
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "ma-0 pa-0",
                                            class: {
                                              "red--text":
                                                _vm.combinations_exceed
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.vFmt(
                                                    _vm.$store.state.tradeDSM
                                                      .dsm_filter_setttings
                                                      .Filters.META.combinations
                                                      .combinations,
                                                    0
                                                  ) || 0
                                                ) +
                                                " "
                                            ),
                                            _vm.combinations_exceed
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "caption ml-4"
                                                  },
                                                  [
                                                    _c("br"),
                                                    _vm._v(
                                                      "(max " +
                                                        _vm._s(
                                                          _vm.$store.state.user
                                                            .userServerInfo
                                                            .app_config
                                                            .max_dsm_results
                                                        ) +
                                                        ")"
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [_c("span", [_vm._v("Number of combinations")])]
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-flex",
                                  _vm._g(
                                    { staticClass: "ma-0 text-start" },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      { staticClass: "black--text" },
                                      [_vm._v("mdi-cash")]
                                    ),
                                    _vm.MarketProductCombinationsUpdating
                                      ? _c("v-progress-circular", {
                                          attrs: {
                                            size: 20,
                                            width: 3,
                                            color: "green",
                                            indeterminate: ""
                                          }
                                        })
                                      : _vm._e(),
                                    !_vm.MarketProductCombinationsUpdating
                                      ? _c(
                                          "span",
                                          { staticClass: "ma-0 pa-0" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.totalValueString(
                                                    _vm.$store.state.tradeDSM
                                                      .dsm_filter_setttings
                                                      .Filters.META.combinations
                                                      .trade_value
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [_c("span", [_vm._v("Untapped potential")])]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm.$store.state.tradeDSM.dsm_filter_setttings.Product_Combinations
        .results.markets_count === 0 &&
      _vm.$store.state.tradeDSM.dsm_filter_setttings.Product_Combinations
        .results.products_count === 0 &&
      _vm.$store.state.tradeDSM.dsm_filter_setttings
        .list_manager_selections_country.length > 0 &&
      _vm.$store.state.tradeDSM.dsm_filter_setttings
        .list_manager_selections_product.length === 0
        ? _c(
            "v-layout",
            {
              staticClass: "justify-space-between no-gutters",
              attrs: { row: "" }
            },
            [
              _c(
                "h2",
                {
                  staticClass:
                    "outline pa-2 mt-3 tab-blue lighten-3 text-center"
                },
                [
                  _vm._v(
                    " Unfortunately this country does not have representative data for all elements of the TRADE-DSM methodology – therefore could not be modelled. Please select a different country to analyse. "
                  )
                ]
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }