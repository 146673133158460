var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.state.tradeData.report_selection !==
    _vm.$store.state.tradeData.report_selections[0]["value"]
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-container",
            { staticClass: "white", attrs: { fluid: "" } },
            [
              !_vm.$store.state.tradeData.trade_data_cube_loading
                ? [
                    _c(
                      "v-row",
                      { staticClass: "white mr-12" },
                      [
                        _c(
                          "v-col",
                          { attrs: { sm: "12", md: "6", lg: "4" } },
                          [
                            _c("abs_export_year", {
                              attrs: {
                                graph_data:
                                  _vm.$store.state.tradeData.trade_data_cube[
                                    "export_year"
                                  ]
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "12", md: "6", lg: "4" } },
                          [
                            _c("abs_export_year", {
                              attrs: {
                                graph_data:
                                  _vm.$store.state.tradeData.trade_data_cube[
                                    "imports_year"
                                  ]
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "12", md: "6", lg: "4" } },
                          [
                            _c("abs_export_year", {
                              attrs: {
                                graph_data:
                                  _vm.$store.state.tradeData.trade_data_cube[
                                    "trade_balance_year"
                                  ]
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "12", md: "6", lg: "4" } },
                          [
                            _c("abs_export_year", {
                              attrs: {
                                graph_data:
                                  _vm.$store.state.tradeData.trade_data_cube[
                                    "export_q"
                                  ]
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "12", md: "6", lg: "4" } },
                          [
                            _c("abs_export_year", {
                              attrs: {
                                graph_data:
                                  _vm.$store.state.tradeData.trade_data_cube[
                                    "import_q"
                                  ]
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "12", md: "6", lg: "4" } },
                          [
                            _c("abs_export_year", {
                              attrs: {
                                graph_data:
                                  _vm.$store.state.tradeData.trade_data_cube[
                                    "trade_balance_q"
                                  ]
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "12", md: "6", lg: "4" } },
                          [
                            _c("abs_stacked_chart", {
                              attrs: {
                                graph_data:
                                  _vm.$store.state.tradeData.trade_data_cube[
                                    "hs8_exports_annual_value"
                                  ],
                                hover_text: _vm.currentHover
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "12", md: "6", lg: "4" } },
                          [
                            _c("abs_stacked_chart", {
                              attrs: {
                                graph_data:
                                  _vm.$store.state.tradeData.trade_data_cube[
                                    "hs8_exports_annual_volume"
                                  ],
                                hover_text: _vm.currentHover
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "12", md: "6", lg: "4" } },
                          [
                            _c("abs_line_chart", {
                              attrs: {
                                graph_data:
                                  _vm.$store.state.tradeData.trade_data_cube[
                                    "unitary_export_values"
                                  ],
                                hover_text: _vm.currentHover
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "12", md: "6", lg: "4" } },
                          [
                            _c("abs_stacked_chart", {
                              attrs: {
                                graph_data:
                                  _vm.$store.state.tradeData.trade_data_cube[
                                    "hs8_exports_q_value"
                                  ],
                                hover_text: _vm.currentHover
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "12", md: "6", lg: "4" } },
                          [
                            _c("abs_stacked_chart", {
                              attrs: {
                                graph_data:
                                  _vm.$store.state.tradeData.trade_data_cube[
                                    "hs8_exports_q_volume"
                                  ],
                                hover_text: _vm.currentHover
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "12", md: "6", lg: "4" } },
                          [
                            _c("abs_pie_chart", {
                              attrs: {
                                graph_data:
                                  _vm.$store.state.tradeData.trade_data_cube[
                                    "hs8_exports_5yr_value_pie"
                                  ],
                                hover_text: _vm.currentHover
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.$store.state.tradeData.trade_data_cube["export_codes"]
                      .length > 0 &&
                    _vm.$store.state.tradeData.trade_data_cube["import_codes"]
                      .length > 0
                      ? _c(
                          "v-row",
                          {
                            staticClass:
                              "black--text grey lighten-2 mt-10 caption outline"
                          },
                          [
                            _c("v-col", [
                              _c("span", { staticClass: "text-h6 text" }, [
                                _vm._v("Exports")
                              ]),
                              _c(
                                "ul",
                                _vm._l(
                                  _vm.$store.state.tradeData.trade_data_cube[
                                    "export_codes"
                                  ],
                                  function(li) {
                                    return _c(
                                      "li",
                                      {
                                        key: li.id,
                                        class: {
                                          "trade-blue-text":
                                            li.HS8_Code ===
                                            _vm.currentHover.slice(0, 10)
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v(_vm._s(li.HS8_Code) + " ")]
                                        ),
                                        _vm._v(
                                          " -" +
                                            _vm._s(li.HS8_Description) +
                                            " "
                                        )
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            ]),
                            _c("v-col", [
                              _c("span", { staticClass: "text-h6" }, [
                                _vm._v("Imports")
                              ]),
                              _c(
                                "ul",
                                _vm._l(
                                  _vm.$store.state.tradeData.trade_data_cube[
                                    "import_codes"
                                  ],
                                  function(li) {
                                    return _c("li", { key: li.id }, [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [_vm._v(_vm._s(li.HS8_Code))]
                                      ),
                                      _vm._v(
                                        " -" + _vm._s(li.HS8_Description) + " "
                                      )
                                    ])
                                  }
                                ),
                                0
                              )
                            ])
                          ],
                          1
                        )
                      : _c(
                          "v-row",
                          {
                            staticClass:
                              "black--text grey lighten-2 mt-10 caption outline font-weight-bold pa-4 text-h5 "
                          },
                          [
                            _c("div", [
                              _vm._v(
                                " There where no HS8 Codes found that is accociated with the selected HS6 Codes "
                              )
                            ])
                          ]
                        )
                  ]
                : _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-skeleton-loader", {
                            attrs: {
                              type:
                                "table-heading, list-item-two-line, image, table-tfoot"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }