<template>
  <v-container class="pa-0 ma-0">
    <v-row no-gutters class="pa-0 ma-0" id="finished_loaded_id">
      <v-col lg="6" xs="12" sm="12" md="12" class="pa-0 ma-">
        <exporter-graph-all-market-selection
          query_type="all_imports_exports"
          v-show="!cube_eq_reo_cube"
        />
      </v-col>
      <v-col lg="6" xs="12" sm="12" md="12" class="pa-0 ma-0">
        <exporter-graph-all-market-selection query_type="reo_imports_exports" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import exporterGraphAllMarketSelection from '@/components/trade-dsm-application/result-components/mpmc-graphs-under-graph/exporter-graph-all-market-selection.vue';
import calc_reo_table_values from '@/components/trade-dsm-application/mixins/calc_reo_table_values';

export default {
  name: 'mpmc-graphs-importer-exporter',
  mixins: [calc_reo_table_values],
  components: {
    exporterGraphAllMarketSelection,
  },
  computed: {
    cube_eq_reo_cube() {
      const combinations = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
        .combinations.combinations;
      const reo_combinations = this.$store.state.tradeDSM.dsm_filter_setttings.Filters
        .META.combinations.reo_summary_values_reo_filtered['Avg6Major__count'];
      const l = this.$store.state.tradeDSM.mpmc_graph_for_comparison_left_vs_right
        .left_value;

      const r = this.$store.state.tradeDSM.mpmc_graph_for_comparison_left_vs_right
        .right_value;
      let val_bool =
        l[0] === r[0] && l[1] === r[1] && l[2] === r[2] && l[3] === r[3] && l[4] === r[4];

      return reo_combinations === combinations && val_bool;
    },
  },
};
</script>

<style scoped></style>
