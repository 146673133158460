<template>
  <v-dialog
      v-model="dialog"
      width="800"
      id="helpdialog"
  >
    <v-card>
      <!--      title <slot-->
      <v-card-title class="text-h5 trade-blue lighten-2">
        <slot name="card_title"></slot>
      </v-card-title>

        <!--  body and main slot -->
      <v-card-text>
       <slot name="card_text"></slot>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="card_actions"></slot>
        <v-btn
           class="trade-blue white--text"
            text
            @click="closeMe"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "help-dialog-base",
  data() {
    return {
      dialog: false,
    }
  },
  mounted() {
    this.dialog = true

  },
  methods: {
    closeMe() {
      this.dialog = false
      this.$store.state.app.activeDialogComponent = null
    }
  }
}
</script>

<style scoped>

</style>