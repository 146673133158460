var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    staticClass: "pr-1 font-weight-light ml-4 mt-3",
    attrs: {
      id: "display_currency",
      disabled: !_vm.get_selectors_state,
      items: _vm.get_exchange_rate,
      "background-color": "white",
      color: "green",
      dense: "",
      "hide-details": "true",
      "item-color": "green",
      "item-text": "CurrencyName",
      label: "Display Currency",
      outlined: "",
      "return-object": ""
    },
    on: {
      change: function($event) {
        return _vm.change_exchange_rate($event)
      },
      "click:prepend": function($event) {
        return _vm.sync_selectors()
      }
    },
    model: {
      value: _vm.exchange_rate_start,
      callback: function($$v) {
        _vm.exchange_rate_start = $$v
      },
      expression: "exchange_rate_start"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }