<template>
  <v-dialog
    v-model="select_custom_filter_open"
    persistent
    width="800"
    retain-focus
    class="rounded"
  >
    <v-card class="grey lighten-3" dark outlined rounded="lg">
      <v-card-title class="trade-blue-text text-h5 ml-2">
        <v-flex>
          <span>
            Please select custom filter to apply:
          </span>
          <help-link help_id="6" class="mr-2" style="float: right"></help-link>
        </v-flex>
      </v-card-title>

      <v-card-subtitle class="ml-1 mt-1 outline mr-8 ml-8 align grey lighten-2 mb-0 pb-2">
        <div class="trade-blue-text text-h6 pt-2">
          Current selection:
          {{
            custom_filers_definition.length > 0
              ? custom_filers_definition[selected_custom_filer]['name']
              : ''
          }}
        </div>

        <div
          class="trade-blue-text subtitle-1"
          v-html="
            custom_filers_definition.length > 0
              ? custom_filers_definition[selected_custom_filer]['hover_description']
              : ''
          "
        ></div>
      </v-card-subtitle>

      <v-card-text>
        <v-row no-gutters>
          <v-col>
            <v-radio-group
              v-model="selected_custom_filer"
              :value="select_custom_filter_open"
              v-if="custom_filers_definition.length > 0"
              hide-details
              class="mt-0"
            >
              <div class="overflow-y-auto pa-2 rounded" style="max-height: 400px">
                <template v-for="(filter, ix) in custom_filers_definition">
                  <v-tooltip
                    left
                    color="success"
                    max-width="200px"
                    :key="filter.id"
                    :disabled="filter['hover_description'].length === 0"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <div v-on="on" class="filterItem trade-blue-text outline">
                        <v-row no-gutters>
                          <v-col class="trade-blue-text">
                            <v-radio
                              :key="filter.id + filter.id + 1"
                              class="caption trade-blue-text"
                              color="#1f3c5f"
                            >
                              <template v-slot:label>
                                <span class="trade-blue-text"
                                  >{{ `[${ix + 1}] : ${filter.name}` }}
                                </span>
                                <span class="trade-blue-text font-weight-bold">{{
                                  ix === customer_filters_copy['current_value_list_ix']
                                    ? '&nbsp; (*current selection))'
                                    : ''
                                }}</span>
                                <span class="trade-blue-text font-weight-bold">{{
                                  ix === customer_filters_copy['startup_value_list_ix']
                                    ? '&nbsp; (*default)'
                                    : ''
                                }}</span>
                              </template>
                            </v-radio>
                          </v-col>
                        </v-row>
                      </div>
                    </template>
                    <span>{{ hover_text(filter.hover_description) }}</span>
                  </v-tooltip>
                </template>
              </div>
            </v-radio-group>
            <template v-else class="trade-blue-text">
              <h3 class="trade-blue-text ml-8">
                No custom filters found.
              </h3>
            </template>
          </v-col>
          <v-col sm="6" v-if="false">
            <v-card>
              <v-card-text class="trade-blue-text">
                <div
                  class="mt-1 "
                  v-html="
                    custom_filers_definition.length > 0
                      ? custom_filers_definition[selected_custom_filer][
                          'hover_description'
                        ]
                      : ''
                  "
                ></div>
                <pre
                  class="white trade-blue-text pa-2 rounded caption"
                  v-html="
                    json_formattter(
                      custom_filers_definition.length > 0
                        ? custom_filers_definition[selected_custom_filer]['query']
                        : '',
                    )
                  "
                ></pre>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-subtitle class="ml-1 outline mr-8 ml-8 align grey lighten-2 mb-2">
        <div class="trade-blue-text subtitle-1 pt-2">
          Note this will override current applied method filters.<br />
          Current Market and Products selections will be retained.
        </div>
      </v-card-subtitle>

      <div class="text-right mr-8 mt-1 mb-1">
        <v-btn
          class="trade-blue mr-2 v-btn--outlined"
          :disabled="!custom_filers_definition.length > 0"
          @click="load_custom_filter_cube(false)"
          >Apply
        </v-btn>
        <v-btn
          class="trade-blue mr-2 v-btn--outlined"
          :disabled="!custom_filers_definition.length > 0"
          @click="load_custom_filter_cube(true)"
          >Set as default
        </v-btn>

        <v-btn class="trade-blue v-btn--outlined" @click="close_window">Close </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import swal from 'sweetalert';
import helpLink from '@/components/application-level/help-system/help-link';

export default {
  name: 'custom_filter_selection',
  props: {
    select_custom_filter_open: {},
  },
  components: {
    helpLink,
  },
  mounted() {
    this.load_custom_filters();
  },
  data: () => ({
    custom_filers_definition: [],
    customer_filters_copy: null,
    selected_custom_filer: 0, // the current selected id of the filer to run
    applied_filter: null,
    apply_default: false,
    selected_standard_filter_id: 1,
  }),

  watch: {
    select_custom_filter_open() {
      this.selected_custom_filer = JSON.parse(
        localStorage.getItem('custom_filters_selected'),
      )['current_value_list_ix'];
      this.customer_filters_copy = JSON.parse(
        localStorage.getItem('custom_filters_selected'),
      );
    },
  },
  computed: {},
  methods: {
    hover_text(t) {
      return t.replace(/<\/?[^>]+(>|$)/g, '');
    },
    json_formattter(x) {
      return JSON.stringify(x, null, 6).replace(/\n( *)/g, function(match, p1) {
        return '<br>' + '&nbsp;'.repeat(p1.length);
      });
    },
    close_window() {
      this.select_custom_filter_open = false;
      this.applied_filter = null;
      this.sync_open_window();
    },
    sync_open_window() {
      this.$emit('update:select_custom_filter_open', this.select_custom_filter_open);
    },
    async load_custom_filters() {
      this.applied_filter = null;
      let data = {
        institution_id: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
          .instituion_id,
      };
      await this.axios
        .post(this.$store.getters.baseUrl + 'load_custom_filters/', data)
        .then(response => {
          this.custom_filers_definition = response.data['custom_filters_def'];
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          // console.log('finally blocks')
        });
    },
    async sync_filter_selections() {
      // select the filters in the main filters to show the applicable ticks
      let filter_headings = ['F1', 'F2', 'F3', 'F4'];

      let custom_filters = this.custom_filers_definition[this.selected_custom_filer][
        'query'
      ]['main_filters'];
      let custom_filers_keys = Object.keys(custom_filters);
      //we need to remove the __ to the end from each filter if it exists

      // none - set [min,max] -> value
      // done below

      // in  --- ignore there is no such filter
      // not implemented

      // lte --- set [min, x] -> min=value
      // not implemented

      // gte --- set [x, max ] -> max = value
      // not implemented

      // console.log(custom_filers_keys);
      let backup_keys = custom_filers_keys;

      await filter_headings.forEach(heading => {
        let Filter_Values = this.$store.state.tradeDSM.dsm_filter_setttings.Filters[
          heading
        ]['entries'];
        Filter_Values.forEach(Filter => {
          if (custom_filers_keys.includes(Filter['col_name'])) {
            let v = custom_filters[Filter['col_name']] * 1;
            // console.log(`${Filter['col_name']} should be set to ${v},${v}`)
            Filter['selected_value'] = [v, v];
            backup_keys.splice(backup_keys.indexOf(Filter['col_name']), 1);
          }
        });

        // backup_keys.forEach(Filter => {
        //   // console.log(Filter.replace('__lte', '').replace('__gte', ''));
        // });
      });
    },

    // apply
    // apply & log out
    // cancel
    async get_load_later_decition() {
      await swal(
        'The selected filter options will be applied.\n\n' +
          'Please note that the selected filter \n will become the default option next time you log in.\n' +
          'To activate the new default immediately, please log out and log in again.',
        {
          closeOnClickOutside: false,
          closeOnEsc: false,
        },
      );
      return true;
    },
    async load_custom_filter_cube(set_as_default = false) {
      if (set_as_default === true) {
        const user_decition = await this.get_load_later_decition();
        console.log(user_decition);
      }

      if (set_as_default === true) {
        await this.$setItem('selected_standard_filter_id', this.selected_custom_filer);
        localStorage.setItem(
          'custom_filters_selected',
          JSON.stringify({
            startup_value_list_ix: this.selected_custom_filer,
            current_value_list_ix: this.selected_custom_filer,
          }),
        );
      } else {
        localStorage.setItem(
          'custom_filters_selected',
          JSON.stringify({
            startup_value_list_ix: this.customer_filters_copy['startup_value_list_ix'],
            current_value_list_ix: this.selected_custom_filer,
          }),
        );
      }

      //save current selections
      const products_save = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
        .selections.products.HS6_Code;
      const markets_save = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
        .selections.markets.C_Code;
      // set app back to base state
      await this.reset_filters_to_raw_state();
      // load selections
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.products.HS6_Code = products_save;
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.markets.C_Code = markets_save;

      const query_params = this.custom_filers_definition[this.selected_custom_filer][
        'query'
      ];
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META['custom_query'] =
        query_params['main_filters'];

      if (this.$store.state.app.selectedModelName !== 'National') {
        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META[
          'PROVINCIAL_FILTER_VALUE'
        ] = query_params['provincial_share'];
      }

      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.reo_clicked.REO_col =
        query_params['REO_col'];
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.reo_clicked.REO_row =
        query_params['REO_row'];
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.presets.load_presets =
        query_params['load_presets'];
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.filter_in_progress = true;
      this.applied_filter = this.selected_custom_filer;
      this.close_window(); //chat to parent
      await this.sync_filter_selections();

      // wrap with another update filters selection -> this is needed when the run cube is valid under new filters
      this.update_results_cube().then(async () => {
        await this.update_filters_selection();
      });
    },
  },
};
</script>
