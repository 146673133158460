<template>
  <td
    @click="set_reo_options()"
    v-if="data"
    class="caption cell"
    :class="{
      'no-pointer': !(data.val > 0),
      // cell: hover,
    }"
  >
    <v-tooltip bottom :disabled="!hover">
      <template v-slot:activator="{on, attrs}">
        <span v-on="on" class="font-weight-bold" v-if="data.val > 0">
          {{ vFmt(data.val, 0) }} <br />
          <span v-if="hover">[{{ Math.round(data.pct * 10000) / 100 }}%] <br /></span>
          {{ multiplerString }} {{ currencyString }} <br />
          {{ calc_value(data.val_monetary) }} <br />
          <span v-if="hover">[{{ Math.round(data.pct_monetary * 10000) / 100 }}%]</span>
        </span>
        <span v-else class="no-pointer">-</span>
      </template>
      <span>Click to see product and markets details</span>
    </v-tooltip>
  </td>
  <td v-else>-</td>
</template>

<script>
export default {
  name: 'reo-td',
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    hover: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  methods: {
    calc_value(v) {
      return this.vFmt(v * this.value_convertion, 1);
    },
    set_reo_options() {
      this.$emit('newReoParameters', {
        markets: this.data.markets,
        products: this.data.products,
      });
    },
  },
};
</script>

<style scoped>
.cell {
}
.cell:hover {
  background-color: yellow;
}

.no-pointer {
  pointer-events: none !important;
}
</style>
