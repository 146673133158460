<template>
  <div>
    <!--    <v-btn x-small text @click="loadData">u</v-btn>-->
    <div v-if="!error">
      <v-skeleton-loader
        v-if="data_loading"
        class="mx-auto ml-3 mr-3"
        type="card"
        height="100%"
      >
      </v-skeleton-loader>
      <v-row v-else no-gutters>
        <v-col cols="12">
          <!--          <div class="red">-->
          <!--            {{ this.graph_data['traces'][1]['y_vals'] }}-->
          <!--          </div>-->

          <vue-plotly
            id="mpmc-exporter-graph-all-line"
            :data="plotData"
            :layout="layout"
            :options="options"
          />
        </v-col>
        <v-col cols="12">
          <vue-plotly
            id="mpmc-exporter-graph-all-bar"
            :data="plotBarData"
            :layout="layout_bar"
            :options="options_bar"
          />
        </v-col>
      </v-row>
    </div>
    <div v-else>{{ error_message }}</div>
  </div>
</template>

<script>
import VuePlotly from '@statnett/vue-plotly';
import {config} from '@/store/config';
import {eventBus} from '@/main';
import {mapMutations} from 'vuex';
import _ from 'lodash';

const main_title_size = 11;
const bar_title_size = 11;
const multiplier_size = 10;
const top_graph_height = 300;
const bottom_graph_height = (250 / 0.6) * 0.5;
const axis_text_size = 10;
export default {
  name: 'exporter-graph-all-markets-selection',
  props: ['query_type'], //all_imports_exports = left, markets_products = right
  components: {
    VuePlotly,
  },
  mounted() {
    this.setup_event_listener();
  },
  beforeDestroy() {
    this.delete_event_lisner();
  },
  computed: {
    loading() {
      return this.graph_data.length === 0;
    },
    plotData() {
      return [
        {
          y: this.array_value_conversion(this.graph_data['traces'][0]['y_vals'], null),
          x: this.graph_data['traces'][0]['x_vals'],
          showlegend: false,
          mode: 'lines',
          line: {
            color: config.plot_red,
            width: config.plot_line_width,
            dash: this.query_type === 'all_imports_exports' ? 'dash' : 'solid',
          },
        },
        {
          y: this.array_value_conversion(this.graph_data['traces'][1]['y_vals'], null),
          x: this.graph_data['traces'][1]['x_vals'],
          mode: 'lines',
          showlegend: false,
          line: {
            color: config.plot_blue,
            width: config.plot_line_width,
            dash: this.query_type === 'all_imports_exports' ? 'dash' : 'solid',
          },
        },
      ];
    },
    plotBarData() {
      return [
        {
          y: this.array_value_conversion(this.graph_data['traces'][2]['y_vals'], null),
          x: this.graph_data['traces'][2]['x_vals'],
          name: this.graph_data['traces'][2]['name'],
          type: 'bar',
          showlegend: false,
          marker: {
            color: this.bar_colors,
          },
        },
        {
          y: [0, 0, 0, 0, 0],
          x: this.graph_data['traces'][2]['x_vals'],
          name: this.graph_data['traces'][0]['name'],
          mode: 'lines',
          line: {
            color: config.plot_red,
            width: 1,
            dash: 'solid',
          },
        },
        {
          y: [0, 0, 0, 0, 0],
          x: this.graph_data['traces'][2]['x_vals'],
          name: this.graph_data['traces'][1]['name'],
          mode: 'lines',
          line: {
            color: config.plot_blue,
            width: 1,
            dash: 'solid',
          },
        },
      ];
    },
    layout() {
      return {
        title: {
          text: this.graph_data['plot_title_line'],
          // TODO - Need to inject the Filters in the right hand side.
          font: {
            size: main_title_size,
          },
          xref: 'container',
          yref: 'continer',
          x: 0.5,
          y: 0.95,
          automargin: true,
        },
        height: top_graph_height,
        margin: {
          l: 35,
          r: 30,
          b: 40,
          t: 70,
          pad: 3,
        },
        yaxis: {
          title: '',
          rangemode: 'tozero',
          tickfont: {
            size: axis_text_size,
          },
          zeroline: false,
        },
        xaxis: {
          tickmode: 'linear',
          tickfont: {
            size: axis_text_size,
          },
          visible: false,
        },
        annotations: [
          {
            x: '0.10',
            y: '0.99',
            xref: 'paper',
            yref: 'paper',
            xanchor: 'right',
            yanchor: 'bottom',
            text:
              this.app_data.value_mulitplier['prefix'] +
              ' ' +
              this.app_data.exchange_rate['Currency'].slice(0, 3),
            showarrow: false,
            font: {
              size: multiplier_size,
            },
          },
        ],
      };
    },
    layout_bar() {
      return {
        title: {
          text: this.graph_data['plot_title_bar'],
          font: {
            size: bar_title_size,
          },
        },
        height: bottom_graph_height,
        margin: {
          l: 30,
          r: 30,
          b: 65,
          t: 20,
          pad: 1,
        },
        yaxis: {
          showgrid: true,
          title: '',
          rangemode: 'tozero',
          tickfont: {
            size: axis_text_size,
          },
        },
        xaxis: {
          tickmode: 'linear',
          tickfont: {
            size: axis_text_size,
          },
        },
        legend: {
          orientation: 'h',
          font: {size: 9},
          automargin: true,
        },
        annotations: [
          {
            xref: 'paper',
            yref: 'paper',
            x: 0,
            y: -0.45,
            xanchor: 'left',
            yanchor: 'bottom',
            text: config.CEPII_Source,
            showarrow: false,
            font: {
              family: 'Arial',
              size: 9,
              color: 'rgb(150,150,150)',
            },
          },
        ],
      };
    },
    options() {
      return {
        responsive: true,
        displayModeBar: false,
      };
    },
    options_bar() {
      return {
        responsive: true,
        displayModeBar: false,
      };
    },
    bar_colors() {
      let colors = [];

      let vals = this.graph_data['traces'][2]['y_vals'];

      vals.forEach(v => {
        if (v >= 0) {
          colors.push(config.plot_bar_blue);
        } else {
          colors.push(config.plot_red);
        }
      });
      return colors;
    },
  },
  data() {
    return {
      data_loading: false,
      graph_data: {
        traces: [
          {
            x_vals: [2016, 2017, 2018, 2019, 2020],
            y_vals: [200000, 200000, 200000, 200000, 200000],
            name: 'Imports',
          },
          {
            x_vals: [2016, 2017, 2018, 2019, 2020],
            y_vals: [100000, 100000, 100000, 100000, 100000],
            name: 'Exports',
          },
          {
            x_vals: [2016, 2017, 2018, 2019, 2020],
            y_vals: [100000, 100000, 100000, 100000, -100000],
            name: '',
          },
        ],
        plot_title_line:
          'South Africa trade trends with selected markets <br>(Australia x 5,067 products: 5,067 combinations)',
        plot_title_bar: 'Temp Title Bar',
      },

      error: false,
      error_message: '',
    };
  },
  methods: {
    ...mapMutations(['clear_circ_ref_error']),

    setup_event_listener() {
      if (this.query_type === 'all_imports_exports') {
        eventBus.$on('TRIGGER_ALL_EXPORTER_IMPORTER_GRAPHS', () => {
          this.loadData();
        });
      } else {
        eventBus.$on('TRIGGER_REO_EXPORTER_IMPORTER_GRAPHS', () => {
          this.loadData();
        });
      }
    },

    delete_event_lisner() {
      eventBus.$off('TRIGGER_ALL_EXPORTER_IMPORTER_GRAPHS');
      eventBus.$off('TRIGGER_REO_EXPORTER_IMPORTER_GRAPHS');
    },
    async loadData() {
      this.$store.state.app.callsPending = 0;
      if (this.current_report_to_run !== '1P1C') {
        this.data_loading = true;

        // get min and max products for startup
        let temp_data = await JSON.parse(localStorage.getItem('FILTERS'))['META'][
          'combinations'
        ];
        // console.log(temp_data)

        this.error = false;
        this.error_message = '';
        this.clear_circ_ref_error();
        let data = {
          max_markets_count: temp_data['markets_count'],
          max_products_count: temp_data['products_count'],
          max_combinations: temp_data['combinations'],
          query_type: this.query_type,
          home_market: this.marketToCode(this.$store.state.app.selectedHomeCountry),
          model_code: this.$store.state.app.selectedModelVersion,
          home_market_name: this.$store.state.app.selectedHomeCountry,
          selected_countries: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .selections.markets.C_Code,
          selected_country_name: this.codeToMarket(
            this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections
              .markets.C_Code[0],
          ),

          selected_products: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .selections.products.HS6_Code,
          combinations: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .combinations,
          META: this.$store.state.tradeDSM.dsm_filter_setttings.Filters,
        };

        await this.axios({
          method: 'post',
          baseURL: this.$store.getters.baseUrl,
          url: 'mpmc_import_exporter_view/',
          data: data,
        })
          .then(response => {
            this.graph_data = response.data;

            this.data_loading = false;
            if (
              this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.combinations
                .combinations === 0
            ) {
              this.error = true;
              this.error_message =
                'There is no results for this selection and filter combinations.';
            }
          })
          .catch(e => {
            console.log(e);
            this.error = true;

            if (
              this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.combinations
                .combinations === 0
            ) {
              this.error_message =
                'There is no results for this selection and filter combinations.';
            } else this.error_message = e;
          })
          .finally(() => {
            if (this.query_type === 'all_imports_exports') {
              // set left value
              this.$store.state.tradeDSM.mpmc_graph_for_comparison_left_vs_right.left_value = _.clone(
                this.graph_data['traces'][1].y_vals,
              ).sort();
            } else {
              // set right value
              this.$store.state.tradeDSM.mpmc_graph_for_comparison_left_vs_right.right_value = _.clone(
                this.graph_data['traces'][1].y_vals,
              ).sort();
            }
          });
      }
    },
  },
};
</script>

<style scoped></style>
