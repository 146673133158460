import { render, staticRenderFns } from "./applied-filters-box.vue?vue&type=template&id=5d0038f0&scoped=true&"
import script from "./applied-filters-box.vue?vue&type=script&lang=js&"
export * from "./applied-filters-box.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d0038f0",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VContainer,VDataTable,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VFlex,VIcon,VLayout,VRow,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d0038f0')) {
      api.createRecord('5d0038f0', component.options)
    } else {
      api.reload('5d0038f0', component.options)
    }
    module.hot.accept("./applied-filters-box.vue?vue&type=template&id=5d0038f0&scoped=true&", function () {
      api.rerender('5d0038f0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/trade-dsm-application/results-pages/applied-filters-box.vue"
export default component.exports