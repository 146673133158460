import {mapGetters} from 'vuex';

export default {
  methods: {
    calc_reo_data(cube_name = '') {
      if (
        this.current_report_to_run === 'MP1C' &&
        this.is_run_cube_valid === true &&
        cube_name !== null
      ) {
        return this.calc_MP1C_cube(cube_name);
      } else if (
        this.current_report_to_run === '1PMC' &&
        this.is_run_cube_valid === true &&
        cube_name !== ''
      ) {
        return this.calc_1PMC_cube(cube_name);
      } else if (
        this.current_report_to_run === 'MPMC' &&
        this.is_run_cube_valid &&
        cube_name === 'cube'
      ) {
        return this.calc_1PMC_cube(cube_name);
      } else if (
        this.current_report_to_run === 'MPMC' &&
        this.is_run_cube_valid &&
        cube_name === 'cube_HS6_Code'
      ) {
        return this.calc_MP1C_cube(cube_name);
      } else if (
        this.current_report_to_run === 'MPMC' ||
        this.current_report_to_run === '1PMC' ||
        (this.current_report_to_run === 'MP1C' && !this.is_run_cube_valid)
      ) {
        return this.calc_general_cube();
      }
    },
    calc_general_cube() {
      let vals = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
        .combinations['reo_summary_values'];

      if (vals.length > 0) {
        let reo_return_data = {};
        reo_return_data['reo_values'] = {};
        let total_count = 0;
        let total = 0;
        for (const val of vals) {
          if (val['REO_row'] > 0 && val['REO_col'] > 0) {
            // only count the values and count if a REO.
            // non reos are calculated elsewere
            total_count = total_count + val['Avg6Major__count'];
            total = total + val['Avg6Major__sum'];
          }
        }

        let Avg6MajorCount_not = 0;
        let Avg6Major_not = 0;

        reo_return_data['1_T'] = {
          val: 0,
          products: [],
          pct: 0,
          val_monetary: 0,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };
        reo_return_data['2_T'] = {
          val: 0,
          products: [],
          pct: 0,
          val_monetary: 0,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };
        reo_return_data['3_T'] = {
          val: 0,
          products: [],
          pct: 0,
          val_monetary: 0,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };
        reo_return_data['4_T'] = {
          val: 0,
          products: [],
          pct: 0,
          val_monetary: 0,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };
        reo_return_data['5_T'] = {
          val: 0,
          products: [],
          pct: 0,
          val_monetary: 0,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };
        reo_return_data['T_1'] = {
          val: 0,
          products: [],
          pct: 0,
          val_monetary: 0,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };
        reo_return_data['T_2'] = {
          val: 0,
          products: [],
          pct: 0,
          val_monetary: 0,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };
        reo_return_data['T_3'] = {
          val: 0,
          products: [],
          pct: 0,
          val_monetary: 0,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };
        reo_return_data['T_4'] = {
          val: 0,
          products: [],
          pct: 0,
          val_monetary: 0,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };

        // set the non values starting values
        reo_return_data['T1_0'] = {
          val: 0,
          products: [],
          pct: 0,
          val_monetary: 0,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };
        reo_return_data['T2_0'] = {
          val: 0,
          products: [],
          pct: 0,
          val_monetary: 0,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };
        reo_return_data['T3_0'] = {
          val: 0,
          products: [],
          pct: 0,
          val_monetary: 0,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };
        reo_return_data['T4_0'] = {
          val: 0,
          products: [],
          pct: 0,
          val_monetary: 0,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };
        reo_return_data['TT_0'] = {
          val: 0,
          products: [],
          pct: 0,
          val_monetary: 0,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };

        for (const val of vals) {
          let Avg6MajorCount = val['Avg6Major__count'];
          let Avg6Major = val['Avg6Major__sum'];
          let REO_row = val['REO_row'];
          let REO_col = val['REO_col'];
          //
          if (REO_row > 0 && REO_col > 0) {
            // This is an opportunity
            reo_return_data[`${REO_col}_${REO_row}`] = {
              val: Avg6MajorCount,
              products: [],
              pct: Avg6MajorCount / total_count,
              val_monetary: Avg6Major,
              pct_monetary: Avg6Major / total,
              val_monetary_str: 1,
              markets: [],
            };

            reo_return_data[`${REO_row}_T`]['val'] =
              reo_return_data[`${REO_row}_T`]['val'] + Avg6MajorCount;
            reo_return_data[`${REO_row}_T`]['val_monetary'] =
              reo_return_data[`${REO_row}_T`]['val_monetary'] + Avg6Major;
            reo_return_data[`${REO_row}_T`]['pct'] =
              reo_return_data[`${REO_row}_T`]['val'] / total_count;
            reo_return_data[`${REO_row}_T`]['pct_monetary'] =
              reo_return_data[`${REO_row}_T`]['val_monetary'] / total;

            reo_return_data[`T_${REO_col}`]['val'] =
              reo_return_data[`T_${REO_col}`]['val'] + Avg6MajorCount;
            reo_return_data[`T_${REO_col}`]['val_monetary'] =
              reo_return_data[`T_${REO_col}`]['val_monetary'] + Avg6Major;
            reo_return_data[`T_${REO_col}`]['pct'] =
              reo_return_data[`T_${REO_col}`]['val'] / total_count;
            reo_return_data[`T_${REO_col}`]['pct_monetary'] =
              reo_return_data[`T_${REO_col}`]['val_monetary'] / total;
          } else {
            // this is not A REO portion
            Avg6MajorCount_not = Avg6MajorCount_not + Avg6MajorCount;
            Avg6Major_not = Avg6Major_not + Avg6Major;

            let key = 'T' + REO_col.toString() + '_0';

            reo_return_data[key]['val'] = reo_return_data[key]['val'] + Avg6MajorCount;

            reo_return_data[key]['val_monetary'] =
              reo_return_data[key]['val_monetary'] + Avg6Major;

            reo_return_data[key]['pct'] = reo_return_data[key]['val'] / total_count;
            reo_return_data[key]['pct_monetary'] =
              reo_return_data[key]['val_monetary'] / total;

            //Total
            reo_return_data['TT_0']['val'] =
              reo_return_data['TT_0']['val'] + Avg6MajorCount;
            reo_return_data['TT_0']['val_monetary'] =
              reo_return_data['TT_0']['val_monetary'] + Avg6Major;
            reo_return_data['TT_0']['pct'] = reo_return_data['TT_0']['val'] / total_count;
            reo_return_data['TT_0']['pct_monetary'] =
              reo_return_data['TT_0']['val_monetary'] / total;
          }
        }
        //

        // TODO fix total
        reo_return_data['T_T'] = {
          val: total_count,
          products: [],
          pct: 1,
          val_monetary: total,
          pct_monetary: 1,
          val_monetary_str: 1,
          markets: [],
        };

        //add grand total
        reo_return_data['TT0_TT0'] = {
          val: reo_return_data['T_T'].val + reo_return_data['TT_0'].val,
          products: [],
          pct: 0,
          val_monetary:
            reo_return_data['T_T'].val_monetary + reo_return_data['TT_0'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };
        //add grand total

        reo_return_data['TT_TT'] = {
          val: reo_return_data['T_T'].val + reo_return_data['TT_0'].val,
          products: [],
          pct: 0,
          val_monetary:
            reo_return_data['T_T'].val_monetary + reo_return_data['TT_0'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };
        reo_return_data['TT1_TT'] = {
          val: reo_return_data['T1_0'].val + reo_return_data['T_1'].val,
          products: [],
          pct: 0,
          val_monetary:
            reo_return_data['T1_0'].val_monetary + reo_return_data['T_1'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };
        reo_return_data['TT2_TT'] = {
          val: reo_return_data['T2_0'].val + reo_return_data['T_2'].val,
          products: [],
          pct: 0,
          val_monetary:
            reo_return_data['T2_0'].val_monetary + reo_return_data['T_2'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };
        reo_return_data['TT3_TT'] = {
          val: reo_return_data['T3_0'].val + reo_return_data['T_3'].val,
          products: [],
          pct: 0,
          val_monetary:
            reo_return_data['T3_0'].val_monetary + reo_return_data['T_3'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };

        reo_return_data['TT4_TT'] = {
          val: reo_return_data['T4_0'].val + reo_return_data['T_4'].val,
          products: [],
          pct: 0,
          val_monetary:
            reo_return_data['T4_0'].val_monetary + reo_return_data['T_4'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: [],
        };

        return reo_return_data;
      } else return [];
    },
    calc_MP1C_cube(cube_name) {
      let reo_return_data = {};
      reo_return_data['reo_values'] = {};

      this.clear_circ_ref_error();
      let data = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
        .query_results[cube_name];

      // let total_trade = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
      //   .combinations.trade_value;

      reo_return_data['T1_0'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };
      reo_return_data['T2_0'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };
      reo_return_data['T3_0'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };
      reo_return_data['T4_0'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };
      reo_return_data['TT_0'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };
      reo_return_data['TT_TT'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };
      reo_return_data['TT1_TT'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };
      reo_return_data['TT2_TT'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };
      reo_return_data['TT3_TT'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };
      reo_return_data['TT4_TT'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };

      // just count to reo totals
      let total_trade_reo = 0;
      let total_reo_count = 0;
      for (let i = 0; i < data.length; i++) {
        let val = data[i];
        let REO_row = val['REO_row'];
        let REO_col = val['REO_col'];
        if (REO_row > 0 && REO_col > 0) {
          total_trade_reo = total_trade_reo + val['Avg6Major'];
          total_reo_count = total_reo_count + 1;
        }
      }

      for (let i = 0; i < data.length; i++) {
        let val = data[i];

        reo_return_data['reo_values'][val['HS6_Code']] = val['Avg6Major'];
        // let REO_row = val['REO_row'];
        let REO_col = val['REO_col'];

        // console.log(val);
        if (val['REO_row'] > 0 && val['REO_col'] > 0) {
          let index_string = +val['REO_col'] + '_' + val['REO_row'];
          let row_total_string = val['REO_row'] + '_' + 'T';
          let col_total_string = 'T' + '_' + val['REO_col'];

          if (reo_return_data.hasOwnProperty('T_T')) {
            reo_return_data['T_T'].val = reo_return_data['T_T'].val + 1;
            reo_return_data['T_T']['products'].push(val['HS6_Code']);
          } else {
            reo_return_data['T_T'] = {
              val: 1,
              products: [val['HS6_Code']],
              pct: 1,
              val_monetary: total_trade_reo,
              pct_monetary: 1,
              val_monetary_str: this.totalValueString(total_trade_reo),
              markets: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .selections.markets.C_Code,
            };
          }

          if (reo_return_data.hasOwnProperty(row_total_string)) {
            reo_return_data[row_total_string].val =
              reo_return_data[row_total_string].val + 1;

            reo_return_data[row_total_string].pct =
              reo_return_data[row_total_string].val / total_reo_count;

            reo_return_data[row_total_string].val_monetary =
              reo_return_data[row_total_string].val_monetary + val['Avg6Major'];
            reo_return_data[row_total_string].pct_monetary =
              reo_return_data[row_total_string].val_monetary / total_trade_reo;

            reo_return_data[row_total_string].val_monetary_str = this.totalValueString(
              reo_return_data[row_total_string].val_monetary,
            );

            reo_return_data[row_total_string]['products'].push(val['HS6_Code']);
          } else {
            reo_return_data[row_total_string] = {
              val: 1,
              pct: 1 / total_reo_count,

              val_monetary: val['Avg6Major'],
              pct_monetary: val['Avg6Major'] / total_trade_reo,
              val_monetary_str: this.totalValueString(val['Avg6Major']),
              products: [val['HS6_Code']],
              markets: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .selections.markets.C_Code,
            };
          }

          if (reo_return_data.hasOwnProperty(col_total_string)) {
            reo_return_data[col_total_string].val =
              reo_return_data[col_total_string].val + 1;

            reo_return_data[col_total_string].pct =
              reo_return_data[col_total_string].val / total_reo_count;

            reo_return_data[col_total_string].val_monetary =
              reo_return_data[col_total_string].val_monetary + val['Avg6Major'];
            reo_return_data[col_total_string].pct_monetary =
              reo_return_data[col_total_string].val_monetary / total_trade_reo;

            reo_return_data[col_total_string].val_monetary_str = this.totalValueString(
              reo_return_data[col_total_string].val_monetary,
            );

            reo_return_data[col_total_string]['products'].push(val['HS6_Code']);
          } else {
            reo_return_data[col_total_string] = {
              val: 1,
              pct: 1 / total_reo_count,
              val_monetary: val['Avg6Major'],
              pct_monetary: val['Avg6Major'] / total_trade_reo,
              val_monetary_str: this.totalValueString(val['Avg6Major']),
              products: [val['HS6_Code']],
              markets: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .selections.markets.C_Code,
            };
          }

          if (reo_return_data.hasOwnProperty(index_string)) {
            reo_return_data[index_string].val = reo_return_data[index_string].val + 1;
            reo_return_data[index_string]['products'].push(val['HS6_Code']);

            reo_return_data[index_string].pct =
              reo_return_data[index_string].val / total_reo_count;

            reo_return_data[index_string].val_monetary =
              reo_return_data[index_string].val_monetary + val['Avg6Major'];
            reo_return_data[index_string].pct_monetary =
              reo_return_data[index_string].val_monetary / total_trade_reo;

            reo_return_data[index_string].val_monetary_str = this.totalValueString(
              reo_return_data[index_string].val_monetary,
            );
          } else {
            reo_return_data[index_string] = {
              val: 1,
              pct: 1 / total_reo_count,
              val_monetary: val['Avg6Major'],
              pct_monetary: val['Avg6Major'] / total_trade_reo,
              val_monetary_str: this.totalValueString(val['Avg6Major']),
              products: [val['HS6_Code']],
              markets: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .selections.markets.C_Code,
            };
          }
        } else {
          // add the non reo values
          let key = 'T' + REO_col.toString() + '_0';
          reo_return_data[key]['val'] = reo_return_data[key]['val'] + 1;
          reo_return_data[key]['val_monetary'] =
            reo_return_data[key]['val_monetary'] + val['Avg6Major'];
          reo_return_data[key][
            'markets'
          ] = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.markets.C_Code;

          reo_return_data[key]['products'].push(val['HS6_Code']);
          //
          reo_return_data['TT_0']['val'] = reo_return_data['TT_0']['val'] + 1;
          reo_return_data['TT_0']['val_monetary'] =
            reo_return_data['TT_0']['val_monetary'] + val['Avg6Major'];

          reo_return_data['TT_0'][
            'markets'
          ] = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.markets.C_Code;

          reo_return_data['TT_0']['products'].push(val['HS6_Code']);
        }
      }

      //fix total reo
      if (reo_return_data.hasOwnProperty('T_T')) {
        reo_return_data['T_T']['val_monetary'] = total_trade_reo;
        reo_return_data['T_T']['val_monetary_str'] = this.totalValueString(
          total_trade_reo,
        );
      }

      try {
        reo_return_data['TT1_TT'] = {
          val: reo_return_data['T1_0'].val + reo_return_data['T_1'].val,
          products: reo_return_data['T1_0'].products.concat(
            reo_return_data['T_1'].products,
          ),
          pct: 0,
          val_monetary:
            reo_return_data['T1_0'].val_monetary + reo_return_data['T_1'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections
            .markets.C_Code,
        };
      } catch (e) {
        reo_return_data['TT1_TT'] = {
          val: reo_return_data['T1_0'].val,
          products: reo_return_data['T1_0'].products,
          pct: 0,
          val_monetary: reo_return_data['T1_0'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections
            .markets.C_Code,
        };
      }

      try {
        reo_return_data['TT2_TT'] = {
          val: reo_return_data['T2_0'].val + reo_return_data['T_2'].val,
          products: reo_return_data['T2_0'].products.concat(
            reo_return_data['T_2'].products,
          ),
          pct: 0,
          val_monetary:
            reo_return_data['T2_0'].val_monetary + reo_return_data['T_2'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections
            .markets.C_Code,
        };
      } catch (e) {
        reo_return_data['TT2_TT'] = {
          val: reo_return_data['T2_0'].val,
          products: reo_return_data['T2_0'].products,
          pct: 0,
          val_monetary: reo_return_data['T2_0'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections
            .markets.C_Code,
        };
      }

      try {
        reo_return_data['TT3_TT'] = {
          val: reo_return_data['T3_0'].val + reo_return_data['T_3'].val,
          products: reo_return_data['T3_0'].products.concat(
            reo_return_data['T_3'].products,
          ),
          pct: 0,
          val_monetary:
            reo_return_data['T3_0'].val_monetary + reo_return_data['T_3'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections
            .markets.C_Code,
        };
      } catch (e) {
        reo_return_data['TT3_TT'] = {
          val: reo_return_data['T3_0'].val,
          products: reo_return_data['T3_0'].products,
          pct: 0,
          val_monetary: reo_return_data['T3_0'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections
            .markets.C_Code,
        };
      }
      try {
        reo_return_data['TT4_TT'] = {
          val: reo_return_data['T4_0'].val + reo_return_data['T_4'].val,
          products: reo_return_data['T4_0'].products.concat(
            reo_return_data['T_4'].products,
          ),
          pct: 0,
          val_monetary:
            reo_return_data['T4_0'].val_monetary + reo_return_data['T_4'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections
            .markets.C_Code,
        };
      } catch (e) {
        reo_return_data['TT4_TT'] = {
          val: reo_return_data['T4_0'].val,
          products: reo_return_data['T4_0'].products,
          pct: 0,
          val_monetary: reo_return_data['T4_0'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections
            .markets.C_Code,
        };
      }

      try {
        reo_return_data['TT_TT'] = {
          val: reo_return_data['T_T'].val + reo_return_data['TT_0'].val,
          products: reo_return_data['T_T'].products.concat(
            reo_return_data['TT_0'].products,
          ),
          pct: 0,
          val_monetary:
            reo_return_data['T_T'].val_monetary + reo_return_data['TT_0'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections
            .markets.C_Code,
        };
      } catch (e) {
        reo_return_data['TT_TT'] = {
          val: reo_return_data['TT_0'].val,
          products: reo_return_data['TT_0'].products,
          pct: 0,
          val_monetary: reo_return_data['TT_0'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections
            .markets.C_Code,
        };
      }
      return reo_return_data;
    },
    calc_1PMC_cube(cube_name) {
      let reo_return_data = {};
      reo_return_data['reo_values'] = {};

      this.clear_circ_ref_error();
      let data = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
        .query_results[cube_name];
      //
      // let total_trade = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
      //   .combinations.trade_value;

      // set the non values starting values
      reo_return_data['T1_0'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };
      reo_return_data['T2_0'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };
      reo_return_data['T3_0'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };
      reo_return_data['T4_0'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };
      reo_return_data['TT_0'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };
      reo_return_data['TT_TT'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };
      reo_return_data['TT1_TT'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };
      reo_return_data['TT2_TT'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };
      reo_return_data['TT3_TT'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };
      reo_return_data['TT4_TT'] = {
        val: 0,
        products: [],
        pct: 0,
        val_monetary: 0,
        pct_monetary: 0,
        val_monetary_str: 0,
        markets: [],
      };

      // just count to reo totals
      let total_trade_reo = 0;
      let total_reo_count = 0;
      for (let i = 0; i < data.length; i++) {
        let val = data[i];
        let REO_row = val['REO_row'];
        let REO_col = val['REO_col'];
        if (REO_row > 0 && REO_col > 0) {
          total_trade_reo = total_trade_reo + val['Avg6Major'];
          total_reo_count = total_reo_count + 1;
        }
      }

      for (let i = 0; i < data.length; i++) {
        let val = data[i];
        reo_return_data['reo_values'][val['C_Code_x']] = val['Avg6Major'];
        let REO_row = val['REO_row'];
        let REO_col = val['REO_col'];
        // console.log(val);
        if (REO_row > 0 && REO_col > 0) {
          let index_string = +val['REO_col'] + '_' + val['REO_row'];
          let row_total_string = val['REO_row'] + '_' + 'T';
          let col_total_string = 'T' + '_' + val['REO_col'];
          if (reo_return_data.hasOwnProperty('T_T')) {
            // TO DO
            reo_return_data['T_T'].val = reo_return_data['T_T'].val + 1;
            reo_return_data['T_T']['markets'].push(val['C_Code_x']);
          } else {
            reo_return_data['T_T'] = {
              val: 1,
              pct: 1,
              val_monetary: total_trade_reo,
              pct_monetary: 1,
              val_monetary_str: this.totalValueString(total_trade_reo),
              markets: [val['C_Code_x']],
              products: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .selections.products.HS6_Code,
            };
          }

          if (reo_return_data.hasOwnProperty(row_total_string)) {
            reo_return_data[row_total_string].val =
              reo_return_data[row_total_string].val + 1;

            reo_return_data[row_total_string].pct =
              reo_return_data[row_total_string].val / total_reo_count;

            reo_return_data[row_total_string].val_monetary =
              reo_return_data[row_total_string].val_monetary + val['Avg6Major'];
            reo_return_data[row_total_string].pct_monetary =
              reo_return_data[row_total_string].val_monetary / total_trade_reo;

            reo_return_data[row_total_string].val_monetary_str = this.totalValueString(
              reo_return_data[row_total_string].val_monetary,
            );

            reo_return_data[row_total_string]['markets'].push(val['C_Code_x']);
          } else {
            reo_return_data[row_total_string] = {
              val: 1,
              pct: 1 / total_reo_count,

              val_monetary: val['Avg6Major'],
              pct_monetary: val['Avg6Major'] / total_trade_reo,
              val_monetary_str: this.totalValueString(val['Avg6Major']),
              markets: [val['C_Code_x']],
              products: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .selections.products.HS6_Code,
            };
          }

          if (reo_return_data.hasOwnProperty(col_total_string)) {
            reo_return_data[col_total_string].val =
              reo_return_data[col_total_string].val + 1;

            reo_return_data[col_total_string].pct =
              reo_return_data[col_total_string].val / total_reo_count;

            reo_return_data[col_total_string].val_monetary =
              reo_return_data[col_total_string].val_monetary + val['Avg6Major'];
            reo_return_data[col_total_string].pct_monetary =
              reo_return_data[col_total_string].val_monetary / total_trade_reo;

            reo_return_data[col_total_string].val_monetary_str = this.totalValueString(
              reo_return_data[col_total_string].val_monetary,
            );

            reo_return_data[col_total_string]['markets'].push(val['C_Code_x']);
          } else {
            reo_return_data[col_total_string] = {
              val: 1,
              pct: 1 / total_reo_count,
              val_monetary: val['Avg6Major'],
              pct_monetary: val['Avg6Major'] / total_trade_reo,
              val_monetary_str: this.totalValueString(val['Avg6Major']),
              markets: [val['C_Code_x']],
              products: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .selections.products.HS6_Code,
            };
          }

          if (reo_return_data.hasOwnProperty(index_string)) {
            reo_return_data[index_string].val = reo_return_data[index_string].val + 1;
            reo_return_data[index_string]['markets'].push(val['C_Code_x']);

            reo_return_data[index_string].pct =
              reo_return_data[index_string].val / total_reo_count;

            reo_return_data[index_string].val_monetary =
              reo_return_data[index_string].val_monetary + val['Avg6Major'];
            reo_return_data[index_string].pct_monetary =
              reo_return_data[index_string].val_monetary / total_trade_reo;

            reo_return_data[index_string].val_monetary_str = this.totalValueString(
              reo_return_data[index_string].val_monetary,
            );
          } else {
            reo_return_data[index_string] = {
              val: 1,
              pct: 1 / total_reo_count,
              val_monetary: val['Avg6Major'],
              pct_monetary: val['Avg6Major'] / total_trade_reo,
              val_monetary_str: this.totalValueString(val['Avg6Major']),
              markets: [val['C_Code_x']],
              products: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .selections.products.HS6_Code,
            };
          }
        } else {
          // add the non reo values
          let key = 'T' + REO_col.toString() + '_0';
          reo_return_data[key]['val'] = reo_return_data[key]['val'] + 1;
          reo_return_data[key]['val_monetary'] =
            reo_return_data[key]['val_monetary'] + val['Avg6Major'];

          reo_return_data[key][
            'products'
          ] = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.products.HS6_Code;

          reo_return_data[key]['markets'].push(val['C_Code_x']);
          //
          reo_return_data['TT_0']['val'] = reo_return_data['TT_0']['val'] + 1;
          reo_return_data['TT_0']['val_monetary'] =
            reo_return_data['TT_0']['val_monetary'] + val['Avg6Major'];

          reo_return_data['TT_0'][
            'products'
          ] = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.products.HS6_Code;

          reo_return_data['TT_0']['markets'].push(val['C_Code_x']);
        }
        // set totals
      }

      // fix reo total
      if (reo_return_data.hasOwnProperty('T_T')) {
        reo_return_data['T_T']['val_monetary'] = total_trade_reo;
        reo_return_data['T_T']['val_monetary_str'] = this.totalValueString(
          total_trade_reo,
        );
      }

      try {
        reo_return_data['TT1_TT'] = {
          val: reo_return_data['T1_0'].val + reo_return_data['T_1'].val,
          products: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .selections.products.HS6_Code,
          pct: 0,
          val_monetary:
            reo_return_data['T1_0'].val_monetary + reo_return_data['T_1'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: reo_return_data['T1_0'].markets.concat(reo_return_data['T_1'].markets),
        };
      } catch (e) {
        reo_return_data['TT1_TT'] = {
          val: reo_return_data['T1_0'].val,
          products: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .selections.products.HS6_Code,
          pct: 0,
          val_monetary: reo_return_data['T1_0'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: reo_return_data['T1_0'],
        };
      }

      try {
        reo_return_data['TT2_TT'] = {
          val: reo_return_data['T2_0'].val + reo_return_data['T_2'].val,
          products: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .selections.products.HS6_Code,
          pct: 0,
          val_monetary:
            reo_return_data['T2_0'].val_monetary + reo_return_data['T_2'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: reo_return_data['T2_0'].markets.concat(reo_return_data['T_2'].markets),
        };
      } catch (e) {
        reo_return_data['TT2_TT'] = {
          val: reo_return_data['T2_0'].val,
          products: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .selections.products.HS6_Code,
          pct: 0,
          val_monetary: reo_return_data['T2_0'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: reo_return_data['T2_0'],
        };
      }
      try {
        reo_return_data['TT3_TT'] = {
          val: reo_return_data['T3_0'].val + reo_return_data['T_3'].val,
          products: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .selections.products.HS6_Code,
          pct: 0,
          val_monetary:
            reo_return_data['T3_0'].val_monetary + reo_return_data['T_3'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: reo_return_data['T3_0'].markets.concat(reo_return_data['T_3'].markets),
        };
      } catch (e) {
        reo_return_data['TT3_TT'] = {
          val: reo_return_data['T3_0'].val,
          products: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .selections.products.HS6_Code,
          pct: 0,
          val_monetary: reo_return_data['T3_0'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: reo_return_data['T3_0'],
        };
      }
      try {
        reo_return_data['TT4_TT'] = {
          val: reo_return_data['T4_0'].val + reo_return_data['T_4'].val,
          products: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .selections.products.HS6_Code,
          pct: 0,
          val_monetary:
            reo_return_data['T4_0'].val_monetary + reo_return_data['T_4'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: reo_return_data['T4_0'].markets.concat(reo_return_data['T_4'].markets),
        };
      } catch (e) {
        reo_return_data['TT4_TT'] = {
          val: reo_return_data['T4_0'].val,
          products: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .selections.products.HS6_Code,
          pct: 0,
          val_monetary: reo_return_data['T4_0'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: reo_return_data['T4_0'],
        };
      }

      try {
        reo_return_data['TT_TT'] = {
          val: reo_return_data['T_T'].val + reo_return_data['TT_0'].val,
          products: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .selections.products.HS6_Code,
          pct: 0,
          val_monetary:
            reo_return_data['T_T'].val_monetary + reo_return_data['TT_0'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: reo_return_data['T_T'].markets.concat(reo_return_data['TT_0'].markets),
        };
      } catch (e) {
        console.log('no key', e);
        reo_return_data['TT_TT'] = {
          val: reo_return_data['TT_0'].val,
          products: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .selections.products.HS6_Code,
          pct: 0,
          val_monetary: reo_return_data['TT_0'].val_monetary,
          pct_monetary: 0,
          val_monetary_str: 0,
          markets: reo_return_data['T_T'],
        };
      }

      return reo_return_data;
    },
  },

  computed: {
    ...mapGetters(['is_run_cube_valid']),
  },
};
