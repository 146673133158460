<template>
  <v-container
    fluid
    v-if="
      $store.state.tradeData.report_selection !==
        $store.state.tradeData.report_selections[0]['value']
    "
  >
    <v-container class="white" fluid>
      <!--      <v-btn @click="test">Enable Hover Text</v-btn>-->
      <template v-if="!$store.state.tradeData.trade_data_cube_loading">
        <v-row class="white mr-12">
          <v-col sm="12" md="6" lg="4">
            <abs_export_year
              :graph_data="$store.state.tradeData.trade_data_cube['export_year']"
            ></abs_export_year>
          </v-col>
          <v-col sm="12" md="6" lg="4">
            <abs_export_year
              :graph_data="$store.state.tradeData.trade_data_cube['imports_year']"
            ></abs_export_year>
          </v-col>
          <v-col sm="12" md="6" lg="4">
            <abs_export_year
              :graph_data="$store.state.tradeData.trade_data_cube['trade_balance_year']"
            ></abs_export_year>
          </v-col>

          <v-col sm="12" md="6" lg="4">
            <abs_export_year
              :graph_data="$store.state.tradeData.trade_data_cube['export_q']"
            ></abs_export_year>
          </v-col>
          <v-col sm="12" md="6" lg="4">
            <abs_export_year
              :graph_data="$store.state.tradeData.trade_data_cube['import_q']"
            ></abs_export_year>
          </v-col>
          <v-col sm="12" md="6" lg="4">
            <abs_export_year
              :graph_data="$store.state.tradeData.trade_data_cube['trade_balance_q']"
            ></abs_export_year>
          </v-col>

          <v-col sm="12" md="6" lg="4">
            <abs_stacked_chart
              :graph_data="
                $store.state.tradeData.trade_data_cube['hs8_exports_annual_value']
              "
              :hover_text="currentHover"
            ></abs_stacked_chart>
          </v-col>
          <v-col sm="12" md="6" lg="4">
            <abs_stacked_chart
              :graph_data="
                $store.state.tradeData.trade_data_cube['hs8_exports_annual_volume']
              "
              :hover_text="currentHover"
            ></abs_stacked_chart>
          </v-col>
          <v-col sm="12" md="6" lg="4">
            <abs_line_chart
              :graph_data="
                $store.state.tradeData.trade_data_cube['unitary_export_values']
              "
              :hover_text="currentHover"
            ></abs_line_chart>
          </v-col>

          <v-col sm="12" md="6" lg="4">
            <abs_stacked_chart
              :graph_data="$store.state.tradeData.trade_data_cube['hs8_exports_q_value']"
              :hover_text="currentHover"
            ></abs_stacked_chart>
          </v-col>
          <v-col sm="12" md="6" lg="4">
            <abs_stacked_chart
              :graph_data="$store.state.tradeData.trade_data_cube['hs8_exports_q_volume']"
              :hover_text="currentHover"
            ></abs_stacked_chart>
          </v-col>
          <v-col sm="12" md="6" lg="4">
            <abs_pie_chart
              :graph_data="
                $store.state.tradeData.trade_data_cube['hs8_exports_5yr_value_pie']
              "
              :hover_text="currentHover"
            ></abs_pie_chart>
          </v-col>
        </v-row>

        <v-row
          v-if="
            $store.state.tradeData.trade_data_cube['export_codes'].length > 0 &&
              $store.state.tradeData.trade_data_cube['import_codes'].length > 0
          "
          class="black--text grey lighten-2 mt-10 caption outline"
        >
          <v-col>
            <span class="text-h6 text">Exports</span>
            <ul>
              <li
                v-for="li in $store.state.tradeData.trade_data_cube['export_codes']"
                :key="li.id"
                :class="{'trade-blue-text': li.HS8_Code === currentHover.slice(0, 10)}"
              >
                <span class="font-weight-bold">{{ li.HS8_Code }} </span>
                -{{ li.HS8_Description }}
              </li>
            </ul>
          </v-col>
          <v-col>
            <span class="text-h6">Imports</span>
            <ul>
              <li
                v-for="li in $store.state.tradeData.trade_data_cube['import_codes']"
                :key="li.id"
              >
                <span class="font-weight-bold">{{ li.HS8_Code }}</span> -{{
                  li.HS8_Description
                }}
              </li>
            </ul>
          </v-col>
        </v-row>
        <v-row
          v-else
          class="black--text grey lighten-2 mt-10 caption outline font-weight-bold pa-4 text-h5 "
        >
          <div>
            There where no HS8 Codes found that is accociated with the selected HS6 Codes
          </div>
        </v-row>
      </template>
      <v-row v-else>
        <v-col>
          <v-skeleton-loader
            type="table-heading, list-item-two-line, image, table-tfoot"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import abs_export_year from '@/components/trade_data/abs_graphs/abs_export_year';
import abs_stacked_chart from '@/components/trade_data/abs_graphs/abs_stacked_chart';
import abs_line_chart from '@/components/trade_data/abs_graphs/abs_line_chart';
import abs_pie_chart from '@/components/trade_data/abs_graphs/abs_pie_chart';

export default {
  name: 'abs_summaries',
  components: {
    abs_export_year,
    abs_stacked_chart,
    abs_line_chart,
    abs_pie_chart,
  },
  data() {
    return {
      currentHover: 'Hover on HS8 Code in graph to see description',
    };
  },
  updated() {
    // wait for the element (this would be all graphs on this page) to be updated, then attach the mouseover event
    this.$nextTick(function() {
      this.attach_hs8_mouseover();
    });
  },
  methods: {
    attach_hs8_mouseover() {
      if (this.$store.state.tradeData.report_selection !== 'EMF_Overview') {
        let traces = Array.from(document.getElementsByClassName('traces'));
        for (let i = 0; i < traces.length; i++) {
          let sub_el = traces[i];
          let text_el = Array.from(sub_el.getElementsByClassName('legendtext '))[0];
          traces[i].addEventListener('mouseenter', () => {
            let hover_text = this.$store.state.tradeData.trade_data_cube[
              'export_codes'
            ].filter(v => {
              return v['HS8_Code'] === text_el.innerHTML;
            })[0];
            this.currentHover =
              hover_text['HS8_Code'] + ' - ' + hover_text['HS8_Description'];
          });

          traces[i].addEventListener('mouseleave', () => {
            this.currentHover = 'Hover on HS8 Code in graph to see description';
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.iconPM {
  animation: blink 3s ease-in infinite;
}

@keyframes blink {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
</style>
