var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "pa-0 ma-0" },
        [
          _vm.display_advanved_filters_applied.items.length > 0
            ? _c(
                "v-layout",
                { attrs: { row: "", "align-center": "" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "result_box_no_border sm1 no-gutters pa-0 mr-0 ml-4"
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          staticClass: "mt-3 mr-4",
                                          on: {
                                            click: function($event) {
                                              return _vm.$store.commit(
                                                "toggleMainFilter",
                                                true
                                              )
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [_vm._v("mdi-filter-variant-plus ")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            854511339
                          )
                        },
                        [
                          _c("span", [
                            _vm._v("Click to open filters selection")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "mt-1 no-gutters ml-3",
                      attrs: { sm9: "", m9: "", lg10: "", xl11: "" }
                    },
                    [
                      _c(
                        "v-expansion-panels",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c(
                            "v-expansion-panel",
                            { staticClass: "elevation-0 ml-0" },
                            [
                              _c("v-expansion-panel-header", {
                                staticClass: "ma-0 pa-0",
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var open = ref.open
                                        return [
                                          open
                                            ? _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "justify-space-between align-center"
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass: "ml-5",
                                                      attrs: { sm10: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "ma-0 pa-0 ml-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Current active method filters "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            "x-small": "",
                                                            text: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              _vm.html_to_xls_download(
                                                                "results_current_filters"
                                                              )
                                                              open = true
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "green--text"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-microsoft-excel"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "ml-2" },
                                                [
                                                  _vm._v(
                                                    "Click to view applied method filters (" +
                                                      _vm._s(
                                                        _vm
                                                          .display_advanved_filters_applied[
                                                          "items"
                                                        ].length
                                                      ) +
                                                      ")"
                                                  )
                                                ]
                                              )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1163126881
                                )
                              }),
                              _c(
                                "v-expansion-panel-content",
                                {
                                  staticClass: "ma-0 pa-0",
                                  attrs: { eager: "" }
                                },
                                [
                                  _c("v-data-table", {
                                    staticClass: "elevation-1 ma-0 pa-0",
                                    attrs: {
                                      id: "results_current_filters",
                                      headers:
                                        _vm.display_advanved_filters_applied[
                                          "headers"
                                        ],
                                      items:
                                        _vm.display_advanved_filters_applied[
                                          "items"
                                        ],
                                      dense: "",
                                      "footer-props": {
                                        "items-per-page-options": [
                                          _vm.display_advanved_filters_applied[
                                            "items"
                                          ].length
                                        ]
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-layout",
                {
                  staticClass: "ml-0 mr-1 green--text",
                  attrs: { row: "", "align-center": "" }
                },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "mt-1 no-gutters ml-3",
                      attrs: { sm9: "", m9: "", lg10: "", xl10: "" }
                    },
                    [
                      _c("v-icon", { staticClass: "ml-0" }, [
                        _vm._v("mdi-filter-variant-plus")
                      ]),
                      _c("span", { staticClass: "ml-6" }, [
                        _vm._v("No filters selected")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }