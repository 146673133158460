var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.is_run_cube_valid
    ? _c(
        "div",
        [
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c("v-flex", { staticClass: "xs12 sm12 lg12" }, [
                _c(
                  "div",
                  { staticClass: "body-2 font-weight-bold nav nav-tabs" },
                  [
                    _c("li", {}, [
                      _c(
                        "a",
                        { staticClass: "pt-1" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$store.state.tradeData.report_selections.filter(
                                function(v) {
                                  return (
                                    v.value ===
                                    _vm.$store.state.tradeData.report_selection
                                  )
                                }
                              )[0]["text"]
                            ) + " "
                          ),
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2 white--text",
                              staticStyle: { float: "left" },
                              attrs: { "data-html2canvas-ignore": "" },
                              on: {
                                click: function($event) {
                                  return _vm.element2clipboard("mp1c-graphs")
                                }
                              }
                            },
                            [_vm._v("mdi-camera ")]
                          ),
                          _c("help-link", {
                            staticStyle: { float: "right" },
                            attrs: { help_id: "9" }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              ])
            ],
            1
          ),
          _c("div", { staticClass: "tab-content" }, [
            _vm._v(" aaa "),
            _c(
              "div",
              {
                staticClass: "tab-pane active white",
                attrs: { id: "mp1c-graphs" }
              },
              [
                _vm.$store.state.tradeData.report_selection ===
                _vm.$store.state.tradeData.report_selections[0]["value"]
                  ? _c(
                      "v-container",
                      { attrs: { fluid: "" } },
                      [
                        _c(
                          "v-layout",
                          {
                            staticClass:
                              "justify-space-around align-center black--text\\",
                            attrs: { row: "" }
                          },
                          [
                            _c(
                              "v-flex",
                              { staticClass: "xs12 sm12 md12 lg6 xl3" },
                              [
                                _c("HistImpexpgraphTmMp1c", {
                                  staticClass: "d-flex justify-center"
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { staticClass: "xs12 sm12 md12 lg6 xl3" },
                              [
                                _c("histImpexpGraphTmTotalMp1c", {
                                  staticClass: "d-flex justify-center"
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { staticClass: "xs12 sm12 md12 lg6 xl3" },
                              [
                                _c("total-imports-exports-mp1c", {
                                  staticClass: "d-flex justify-center"
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { staticClass: "xs12 sm12 md12 lg6 xl3" },
                              [
                                _c("trade-deficit-mp1c", {
                                  staticClass: "d-flex justify-center"
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass:
                                  "xs12 sm12 lg12 ml-5 mr-8 mb-9 mt-12"
                              },
                              [
                                _c("reoFrontendTable", {
                                  attrs: { cube_name: "cube_HS6_Code" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }