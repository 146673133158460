var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ml-10 mr-10" }, [
    _c("div", { staticClass: "body-2 font-weight-bold nav nav-tabs" }, [
      _c("li", {}, [
        _c(
          "a",
          { staticClass: "pt-1" },
          [
            _vm._v(_vm._s(_vm.caption) + " "),
            _c(
              "v-icon",
              {
                staticClass: "mr-2 white--text",
                staticStyle: { float: "left" },
                attrs: { "data-html2canvas-ignore": "" },
                on: {
                  click: function($event) {
                    return _vm.element2clipboard("1pmc-graphs")
                  }
                }
              },
              [_vm._v("mdi-camera ")]
            ),
            _c("help-link", {
              staticClass: "ml-1",
              staticStyle: { float: "right" },
              attrs: { help_id: _vm.help_id }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "tab-content" }, [
      _c(
        "div",
        { staticClass: "tab-pane active white", attrs: { id: "1pmc-graphs" } },
        [
          _c(
            "v-tabs",
            {
              staticClass: "pa-1",
              attrs: {
                "active-class": "trade-blue",
                "background-color": "tab_header_color",
                "slider-color": "green",
                dark: "",
                dense: "",
                height: "30px"
              },
              on: {
                change: function($event) {
                  return _vm.change_report(_vm.selected_tab)
                }
              },
              model: {
                value: _vm.selected_tab,
                callback: function($$v) {
                  _vm.selected_tab = $$v
                },
                expression: "selected_tab"
              }
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", "max-width": "400" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c("v-tab", _vm._g({}, on), [_vm._v("Results")])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters
                          .META.Data_Models.Trade_History.data_message
                      )
                    )
                  ])
                ]
              ),
              _vm._l(
                _vm.$store.state.tradeData.report_selections.length - 1,
                function(i) {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        key: i,
                        attrs: { bottom: "", "max-width": "400" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c("v-tab", _vm._g({}, on), [
                                    _vm._v(" " + _vm._s(_vm.tab_name(i)) + " ")
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$store.state.tradeData.report_selections[i]
                                  .hover_text
                              )
                          )
                        ])
                      ]
                    )
                  ]
                }
              ),
              _c(
                "v-tab-item",
                { staticClass: "outline" },
                [
                  _c("tabbed-graph-top-view-text", {
                    staticClass: "ml-10 pt-5"
                  }),
                  _vm.current_report_to_run === "1P1C"
                    ? _c("DSMSingleGraphs", {
                        staticClass: "mt-1 ml-3 mr-5 mb-4 white"
                      })
                    : _vm._e(),
                  _vm.current_report_to_run === "1PMC"
                    ? _c("DSM1PMCGraphs")
                    : _vm._e()
                ],
                1
              ),
              _vm._l(
                _vm.$store.state.tradeData.report_selections.length - 1,
                function(i) {
                  return [
                    _c(
                      "v-tab-item",
                      { key: i, staticClass: "outline" },
                      [
                        _vm.data_available || _vm.loading
                          ? [
                              _vm.loading
                                ? _c(
                                    "div",
                                    { staticClass: "ml-10 blink pt-4" },
                                    [
                                      _c("span", { staticClass: "mt-6 pt-6" }, [
                                        _vm._v(" Loading data ...")
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.loading
                                ? _c("tabbed-graph-top-view-text", {
                                    staticClass: "ml-10 pt-5"
                                  })
                                : _vm._e(),
                              _c("abs_summaries")
                            ]
                          : _c("div", [
                              _c("span", [
                                _vm._v(
                                  " There is no data for " +
                                    _vm._s(
                                      _vm.$store.state.tradeDSM
                                        .dsm_filter_setttings.Filters.META
                                        .selections.products.HS6_Code[0]
                                    )
                                )
                              ])
                            ])
                      ],
                      2
                    )
                  ]
                }
              )
            ],
            2
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }