var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0" },
    [
      _vm.display_advanved_filters_applied.items.length > 0
        ? _c(
            "v-layout",
            { staticClass: "ml-1 mr-1", attrs: { row: "" } },
            [
              _c(
                "div",
                { staticClass: "result_box_no_border pa-0 mr-6" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { left: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    {
                                      staticClass: "mt-1",
                                      on: {
                                        click: function($event) {
                                          return _vm.$store.commit(
                                            "toggleCountriesFilter",
                                            true
                                          )
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [_vm._v("mdi-earth ")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        409298637
                      )
                    },
                    [_c("span", [_vm._v("Click to open market filters")])]
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "mt-1" },
                [
                  _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                    .report_type_to_generate !== "MP1C"
                    ? _c(
                        "v-expansion-panels",
                        { staticClass: "ma-0 pa-0 ml-0" },
                        [
                          _c(
                            "v-expansion-panel",
                            { staticClass: "elevation-0 mr-1" },
                            [
                              _c("v-expansion-panel-header", {
                                staticClass: "ma-o pa-0",
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var open = ref.open
                                        return [
                                          open
                                            ? _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "justify-space-between align-center"
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass: "ml-9",
                                                      attrs: { sm10: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "ma-0 pa-0 ml-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$store.state
                                                                .tradeDSM
                                                                .dsm_filter_setttings
                                                                .Filters.META
                                                                .selections
                                                                .markets.C_Code
                                                                .length > 0
                                                                ? "Selected"
                                                                : "Filtered"
                                                            ) + " markets "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            "x-small": "",
                                                            text: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              _vm.html_to_xls_download(
                                                                "results_current_markets"
                                                              )
                                                              open = true
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "green--text"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-microsoft-excel"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "ml-2" },
                                                [
                                                  _vm._v(
                                                    "Click to view " +
                                                      _vm._s(
                                                        _vm.$store.state
                                                          .tradeDSM
                                                          .dsm_filter_setttings
                                                          .Filters.META
                                                          .selections.markets
                                                          .C_Code.length > 0
                                                          ? "selected"
                                                          : "filtered"
                                                      ) +
                                                      " markets (" +
                                                      _vm._s(
                                                        _vm.$store.getters
                                                          .selectionCounts
                                                          .results.markets_count
                                                      ) +
                                                      ")"
                                                  )
                                                ]
                                              )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  229596084
                                )
                              }),
                              _c(
                                "v-expansion-panel-content",
                                { staticClass: "ma-0 pa-0" },
                                [
                                  _c("v-data-table", {
                                    staticClass: "elevation-1 ma-0 pa-0",
                                    attrs: {
                                      id: "results_current_markets",
                                      headers:
                                        _vm.display_advanved_filters_applied[
                                          "headers"
                                        ],
                                      items:
                                        _vm.display_advanved_filters_applied[
                                          "items"
                                        ],
                                      "items-per-page": 5,
                                      dense: "",
                                      "footer-props": {
                                        "items-per-page-options": _vm.calc_items_per_page(
                                          _vm.display_advanved_filters_applied[
                                            "items"
                                          ].length,
                                          4,
                                          _vm.$store.state.user.userServerInfo
                                            .app_config.max_markets
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "sm11 green--text mt-0 text-left" },
                        [_vm._v(" " + _vm._s(_vm.country_name()) + " ")]
                      )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }