import { render, staticRenderFns } from "./product-filter-optimsed_v2.vue?vue&type=template&id=7dcdf3f1&scoped=true&"
import script from "./product-filter-optimsed_v2.vue?vue&type=script&lang=js&"
export * from "./product-filter-optimsed_v2.vue?vue&type=script&lang=js&"
import style0 from "./product-filter-optimsed_v2.vue?vue&type=style&index=0&id=7dcdf3f1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dcdf3f1",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VBtn,VCard,VCardTitle,VCol,VIcon,VLayout,VNavigationDrawer,VProgressCircular,VRow,VTextField,VTooltip,VVirtualScroll})


/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7dcdf3f1')) {
      api.createRecord('7dcdf3f1', component.options)
    } else {
      api.reload('7dcdf3f1', component.options)
    }
    module.hot.accept("./product-filter-optimsed_v2.vue?vue&type=template&id=7dcdf3f1&scoped=true&", function () {
      api.rerender('7dcdf3f1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/trade-dsm-application/left-side-bar/products/product-filter-optimsed_v2.vue"
export default component.exports