import { render, staticRenderFns } from "./tab-mpmc.vue?vue&type=template&id=1b8a6529&scoped=true&"
import script from "./tab-mpmc.vue?vue&type=script&lang=js&"
export * from "./tab-mpmc.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b8a6529",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCard,VCol,VContainer,VFlex,VLayout,VRow,VSkeletonLoader,VTab,VTabItem,VTabs})


/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b8a6529')) {
      api.createRecord('1b8a6529', component.options)
    } else {
      api.reload('1b8a6529', component.options)
    }
    module.hot.accept("./tab-mpmc.vue?vue&type=template&id=1b8a6529&scoped=true&", function () {
      api.rerender('1b8a6529', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/trade-dsm-application/results-pages/tabs/tab-mpmc.vue"
export default component.exports