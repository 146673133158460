var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.updating,
              expression: "updating"
            }
          ]
        },
        [
          _c("div", { staticClass: "ml-4 blink" }, [
            _vm._v("Loading Countries REO table")
          ]),
          _c("v-skeleton-loader", {
            attrs: { type: "list-item-avatar-three-line, image, article" }
          })
        ],
        1
      ),
      [
        _c(
          "v-row",
          { staticClass: "mt-1", attrs: { "no-gutters": "" } },
          [
            _c(
              "v-col",
              { staticClass: "shrink" },
              [_c("v-icon", [_vm._v("mdi-file-tree-outline")])],
              1
            ),
            _c(
              "v-col",
              { staticClass: "grow", attrs: { sm: "11" } },
              [
                _c(
                  "v-expansion-panels",
                  { staticClass: "ml-4" },
                  [
                    _c(
                      "v-expansion-panel",
                      [
                        _c("v-expansion-panel-header", [
                          _c(
                            "div",
                            [
                              _vm._v(
                                " Click to view applied aggregations (" +
                                  _vm._s(
                                    _vm.reo_product_table_click_tracking.length
                                  ) +
                                  ") "
                              ),
                              _vm.reo_product_table_click_tracking.length > 0
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "yellow ml-2",
                                      attrs: { "x-small": "" },
                                      on: {
                                        click:
                                          _vm.clear_all_previous_reo_selections
                                      }
                                    },
                                    [_vm._v("Clear selections ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]),
                        _c(
                          "v-expansion-panel-content",
                          [
                            _c("v-data-table", {
                              staticClass: "elevation-1 ma-0 pa-0",
                              attrs: {
                                headers:
                                  _vm.reo_product_table_click_tracking_path[
                                    "headers"
                                  ],
                                items:
                                  _vm.reo_product_table_click_tracking_path[
                                    "items"
                                  ],
                                "items-per-page": 5,
                                dense: ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.selection",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "ul",
                                        _vm._l(item.selection, function(
                                          sel,
                                          ix
                                        ) {
                                          return _c(
                                            "li",
                                            { key: sel.id },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "2" } },
                                                    [
                                                      _c("div", [
                                                        _vm._v(_vm._s(sel))
                                                      ])
                                                    ]
                                                  ),
                                                  _c("v-col", [
                                                    _c(
                                                      "div",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.description[ix]
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              [
                _c(
                  "v-tabs",
                  {
                    staticClass: "mb-2 text--black",
                    attrs: {
                      "background-color": "grey lighten-4",
                      "active-class": "active_tab_class",
                      dense: ""
                    },
                    model: {
                      value: _vm.tab_selected,
                      callback: function($$v) {
                        _vm.tab_selected = $$v
                      },
                      expression: "tab_selected"
                    }
                  },
                  [
                    _vm._l(_vm.tab_def, function(tab) {
                      return [
                        "hover_text" in tab && tab.hover_text.length > 0
                          ? [
                              _c(
                                "v-tooltip",
                                {
                                  key: tab.id,
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-tab",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.change_tab(
                                                          tab,
                                                          0
                                                        )
                                                      }
                                                    }
                                                  },
                                                  "v-tab",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(tab.tab_name) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [_c("span", [_vm._v(_vm._s(tab.hover_text))])]
                              )
                            ]
                          : _c(
                              "v-tab",
                              {
                                key: tab.id,
                                on: {
                                  click: function($event) {
                                    return _vm.change_tab(tab, 0)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(tab.tab_name) + " ")]
                            )
                      ]
                    })
                  ],
                  2
                ),
                _c(
                  "v-tabs-items",
                  {
                    model: {
                      value: _vm.tab_selected,
                      callback: function($$v) {
                        _vm.tab_selected = $$v
                      },
                      expression: "tab_selected"
                    }
                  },
                  [
                    _vm._l(_vm.tab_def, function(tab) {
                      return [
                        _c(
                          "v-tab-item",
                          { key: tab.id },
                          [
                            _c(
                              "v-tabs",
                              {
                                staticClass: "mt-1",
                                attrs: {
                                  "active-class": "trade-blue",
                                  "background-color": "tab_header_color",
                                  dark: "",
                                  dense: ""
                                },
                                model: {
                                  value:
                                    _vm.sub_tab_selected_dict[tab.tab_name][
                                      "sub_tab_selected"
                                    ],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.sub_tab_selected_dict[tab.tab_name],
                                      "sub_tab_selected",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "sub_tab_selected_dict[tab.tab_name]['sub_tab_selected']"
                                }
                              },
                              [
                                _vm._l(tab.sub_tabs, function(
                                  sub_tab,
                                  sub_tab_ix
                                ) {
                                  return [
                                    "hover_text" in sub_tab
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            key: sub_tab.id,
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-tab",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.change_tab(
                                                                    tab,
                                                                    sub_tab_ix
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            "v-tab",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                sub_tab.tab_name
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(sub_tab.hover_text))
                                            ])
                                          ]
                                        )
                                      : _c(
                                          "v-tab",
                                          {
                                            key: sub_tab.id,
                                            on: {
                                              change: function($event) {
                                                return _vm.change_tab(
                                                  tab,
                                                  sub_tab_ix
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(sub_tab.tab_name) +
                                                " "
                                            )
                                          ]
                                        )
                                  ]
                                })
                              ],
                              2
                            ),
                            _c(
                              "v-tabs-items",
                              {
                                model: {
                                  value:
                                    _vm.sub_tab_selected_dict[tab.tab_name][
                                      "sub_tab_selected"
                                    ],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.sub_tab_selected_dict[tab.tab_name],
                                      "sub_tab_selected",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "sub_tab_selected_dict[tab.tab_name]['sub_tab_selected']"
                                }
                              },
                              [
                                _vm._l(tab.sub_tabs, function(sub_tab) {
                                  return [
                                    _c(
                                      "v-tab-item",
                                      { key: sub_tab.id },
                                      [
                                        _vm.updating === true
                                          ? _c("v-skeleton-loader", {
                                              attrs: {
                                                type:
                                                  "list-item-avatar-three-line, image, article"
                                              }
                                            })
                                          : _c(
                                              "v-data-table",
                                              {
                                                staticClass:
                                                  "elevation-1 ml-1 mt-1 mr-1",
                                                attrs: {
                                                  headers: _vm.cube_data(
                                                    tab,
                                                    sub_tab
                                                  )["headers"],
                                                  items: _vm.cube_data(
                                                    tab,
                                                    sub_tab
                                                  )["items"],
                                                  "items-per-page": 5,
                                                  "sort-by": "A",
                                                  "sort-desc": true,
                                                  "must-sort": "",
                                                  dense: "",
                                                  "show-select":
                                                    sub_tab[
                                                      "enable_selection"
                                                    ] &&
                                                    _vm.cube_data(tab, sub_tab)[
                                                      "items"
                                                    ].length > 1,
                                                  id: sub_tab.aggregation_col,
                                                  "header-props": {
                                                    "sort-icon":
                                                      "mdi-arrow-up-circle",
                                                    options: {
                                                      "multi-sort": true
                                                    }
                                                  },
                                                  "footer-props": {
                                                    "items-per-page-options": _vm.calc_items_per_page(
                                                      _vm.cube_data(
                                                        tab,
                                                        sub_tab
                                                      )["items"].length
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key:
                                                        "header.data-table-select",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "th",
                                                            {
                                                              staticClass:
                                                                "pb-4 caption justify-center text-",
                                                              staticStyle: {
                                                                "font-weight":
                                                                  "900"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Select "
                                                              ),
                                                              (_vm
                                                                .sub_tab_selected_dict[
                                                                tab.tab_name
                                                              ][
                                                                "selected_items"
                                                              ].length &&
                                                                sub_tab[
                                                                  "enable_selection"
                                                                ] === true &&
                                                                _vm
                                                                  .sub_tab_selected_dict[
                                                                  tab.tab_name
                                                                ][
                                                                  "selected_items"
                                                                ].length !==
                                                                  _vm.cube_data(
                                                                    tab,
                                                                    sub_tab
                                                                  )["items"]
                                                                    .length) ||
                                                              _vm.cube_data(
                                                                tab,
                                                                sub_tab
                                                              )["items"][0][
                                                                "Opportunities_agg"
                                                              ] <
                                                                _vm.$store.state
                                                                  .tradeDSM
                                                                  .dsm_filter_setttings
                                                                  .Filters.META
                                                                  .combinations
                                                                  .combinations
                                                                ? _c(
                                                                    "v-btn",
                                                                    {
                                                                      staticClass:
                                                                        "yellow ml-1",
                                                                      attrs: {
                                                                        "x-small":
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.apply_multi_select(
                                                                            tab,
                                                                            sub_tab
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Apply (" +
                                                                          _vm._s(
                                                                            _vm
                                                                              .sub_tab_selected_dict[
                                                                              tab
                                                                                .tab_name
                                                                            ][
                                                                              "selected_items"
                                                                            ]
                                                                              .length
                                                                          ) +
                                                                          ") "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    _vm._l(
                                                      _vm.cube_data(
                                                        tab,
                                                        sub_tab
                                                      )["headers"],
                                                      function(header) {
                                                        return {
                                                          key:
                                                            "header." +
                                                            header.value,
                                                          fn: function(ref) {
                                                            var header =
                                                              ref.header
                                                            return [
                                                              header.value ===
                                                              "id"
                                                                ? _c(
                                                                    "v-icon",
                                                                    {
                                                                      key:
                                                                        header.id,
                                                                      staticClass:
                                                                        "green--text ma-0 pa-0 ml-0 pl-0 mb-6 pr-0 text-center",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          $event.preventDefault()
                                                                          _vm.html_to_xls_download_dialog_mp1c_product(
                                                                            false,
                                                                            true,
                                                                            _vm.cube_data(
                                                                              tab,
                                                                              sub_tab
                                                                            )
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " mdi-microsoft-excel "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              header.value ===
                                                                "description" &&
                                                              _vm.cube_data(
                                                                tab,
                                                                sub_tab
                                                              )["items"]
                                                                .length > 1
                                                                ? _c(
                                                                    "v-checkbox",
                                                                    {
                                                                      key:
                                                                        header.id,
                                                                      attrs: {
                                                                        small:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.enable_disable_clicked
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "label",
                                                                            fn: function() {
                                                                              return [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "caption mr-2"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        !sub_tab[
                                                                                          "enable_selection"
                                                                                        ]
                                                                                          ? "Enable Selection Shortcuts"
                                                                                          : "Disable Selection Shortcuts"
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            },
                                                                            proxy: true
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                      model: {
                                                                        value:
                                                                          sub_tab[
                                                                            "enable_selection"
                                                                          ],
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            sub_tab,
                                                                            "enable_selection",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "sub_tab['enable_selection']"
                                                                      }
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              sub_tab[
                                                                _vm.report_type
                                                              ]["style_def"] !==
                                                              undefined
                                                                ? [
                                                                    sub_tab[
                                                                      _vm
                                                                        .report_type
                                                                    ][
                                                                      "style_def"
                                                                    ][
                                                                      header
                                                                        .value
                                                                    ] !==
                                                                    undefined
                                                                      ? [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              key:
                                                                                header.id,
                                                                              class:
                                                                                sub_tab[
                                                                                  _vm
                                                                                    .report_type
                                                                                ][
                                                                                  "style_def"
                                                                                ][
                                                                                  header
                                                                                    .value
                                                                                ][
                                                                                  "active_class"
                                                                                ],
                                                                              style:
                                                                                sub_tab[
                                                                                  _vm
                                                                                    .report_type
                                                                                ][
                                                                                  "style_def"
                                                                                ][
                                                                                  header
                                                                                    .value
                                                                                ][
                                                                                  "active_style"
                                                                                ],
                                                                              domProps: {
                                                                                innerHTML: _vm._s(
                                                                                  header.text
                                                                                )
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      : [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              key:
                                                                                header.id,
                                                                              staticClass:
                                                                                "text-center font-weight-bold",
                                                                              domProps: {
                                                                                innerHTML: _vm._s(
                                                                                  header.text
                                                                                )
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                  ]
                                                                : [
                                                                    _c("div", {
                                                                      key:
                                                                        header.id,
                                                                      staticClass:
                                                                        "text-center font-weight-bold",
                                                                      domProps: {
                                                                        innerHTML: _vm._s(
                                                                          header.text
                                                                        )
                                                                      }
                                                                    })
                                                                  ],
                                                              header.value ===
                                                              "opportunities"
                                                                ? _c("div", {
                                                                    key:
                                                                      header.id,
                                                                    staticClass:
                                                                      "text-center font-weight-black",
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        _vm.header_val(
                                                                          tab,
                                                                          sub_tab,
                                                                          0,
                                                                          "Opportunities_agg"
                                                                        )
                                                                      )
                                                                    }
                                                                  })
                                                                : _vm._e(),
                                                              header.value ===
                                                              "A"
                                                                ? _c("div", {
                                                                    key:
                                                                      header.id,
                                                                    staticClass:
                                                                      "text-center font-weight-black",
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        _vm.header_val(
                                                                          tab,
                                                                          sub_tab,
                                                                          0,
                                                                          "A_agg"
                                                                        )
                                                                      )
                                                                    }
                                                                  })
                                                                : _vm._e(),
                                                              header.value ===
                                                              "A1"
                                                                ? _c("div", {
                                                                    key:
                                                                      header.id,
                                                                    staticClass:
                                                                      "text-center font-weight-black",
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        _vm.header_val(
                                                                          tab,
                                                                          sub_tab,
                                                                          0,
                                                                          "A1_agg"
                                                                        )
                                                                      )
                                                                    }
                                                                  })
                                                                : _vm._e(),
                                                              header.value ===
                                                              "B"
                                                                ? _c("div", {
                                                                    key:
                                                                      header.id,
                                                                    staticClass:
                                                                      "text-center font-weight-black",
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        _vm.header_val(
                                                                          tab,
                                                                          sub_tab,
                                                                          0,
                                                                          "B_agg"
                                                                        )
                                                                      )
                                                                    }
                                                                  })
                                                                : _vm._e(),
                                                              header.value ===
                                                              "C"
                                                                ? _c("div", {
                                                                    key:
                                                                      header.id,
                                                                    staticClass:
                                                                      "text-center font-weight-black",
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        _vm.header_val(
                                                                          tab,
                                                                          sub_tab,
                                                                          0,
                                                                          "C_agg"
                                                                        )
                                                                      )
                                                                    }
                                                                  })
                                                                : _vm._e(),
                                                              header.value ===
                                                              "D"
                                                                ? _c("div", {
                                                                    key:
                                                                      header.id,
                                                                    staticClass:
                                                                      "text-center font-weight-black",
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        _vm.header_val(
                                                                          tab,
                                                                          sub_tab,
                                                                          0,
                                                                          "D_agg"
                                                                        )
                                                                      )
                                                                    }
                                                                  })
                                                                : _vm._e(),
                                                              header.value ===
                                                              "E"
                                                                ? _c("div", {
                                                                    key:
                                                                      header.id,
                                                                    staticClass:
                                                                      "text-center font-weight-black",
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        _vm.header_val(
                                                                          tab,
                                                                          sub_tab,
                                                                          0,
                                                                          "E_agg"
                                                                        )
                                                                      )
                                                                    }
                                                                  })
                                                                : _vm._e(),
                                                              header.value ===
                                                              "F"
                                                                ? _c("div", {
                                                                    key:
                                                                      header.id,
                                                                    staticClass:
                                                                      "text-center font-weight-black",
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        _vm.header_val(
                                                                          tab,
                                                                          sub_tab,
                                                                          0,
                                                                          "F_agg"
                                                                        )
                                                                      )
                                                                    }
                                                                  })
                                                                : _vm._e(),
                                                              header.value ===
                                                              "G"
                                                                ? _c("div", {
                                                                    key:
                                                                      header.id,
                                                                    staticClass:
                                                                      "text-center font-weight-black",
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        _vm.header_val(
                                                                          tab,
                                                                          sub_tab,
                                                                          0,
                                                                          "G_agg"
                                                                        )
                                                                      )
                                                                    }
                                                                  })
                                                                : _vm._e()
                                                            ]
                                                          }
                                                        }
                                                      }
                                                    ),
                                                    {
                                                      key:
                                                        "item." +
                                                        sub_tab.aggregation_col,
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          sub_tab[
                                                            "enable_selection"
                                                          ]
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "reo-table-selector font-weight-bold text-right",
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.summary_click(
                                                                        tab,
                                                                        sub_tab,
                                                                        [
                                                                          item[
                                                                            sub_tab
                                                                              .aggregation_col
                                                                          ]
                                                                        ],
                                                                        [
                                                                          item.description
                                                                        ]
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item[
                                                                          sub_tab
                                                                            .aggregation_col
                                                                        ]
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-right"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item[
                                                                          sub_tab
                                                                            .aggregation_col
                                                                        ]
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.description",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          sub_tab[
                                                            "enable_selection"
                                                          ]
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "reo-table-selector font-weight-bold",
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.summary_click(
                                                                        tab,
                                                                        sub_tab,
                                                                        [
                                                                          item[
                                                                            sub_tab
                                                                              .aggregation_col
                                                                          ]
                                                                        ],
                                                                        [
                                                                          item.description
                                                                        ]
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.description
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            : _c("div", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.description
                                                                  )
                                                                )
                                                              ])
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.reoxy",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c("div", {
                                                            staticClass:
                                                              "text-center",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.reoxy
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.tariff",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          Math.round(
                                                            parseFloat(
                                                              item.tariff
                                                            ) *
                                                              100 *
                                                              100
                                                          ) /
                                                            100 >
                                                          0
                                                            ? _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    left: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              _vm._g(
                                                                                {
                                                                                  staticClass:
                                                                                    "text-center"
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "a",
                                                                                  {
                                                                                    staticClass:
                                                                                      "no-link",
                                                                                    attrs: {
                                                                                      href: _vm.itc_url(
                                                                                        item
                                                                                      ),
                                                                                      target:
                                                                                        "_blank"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          (
                                                                                            parseFloat(
                                                                                              item.tariff
                                                                                            ) /
                                                                                            1
                                                                                          ).toFixed(
                                                                                            2
                                                                                          )
                                                                                        )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Click to view ITC tariff information"
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ml-2"
                                                                },
                                                                [_vm._v("-")]
                                                              )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.rank",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c("div", {
                                                            class: {
                                                              "red--text": _vm.is_zero_rank(
                                                                item.rank
                                                              )
                                                            },
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.rank
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.rank_out_of",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c("div", {
                                                            class: {
                                                              "red--text": _vm.is_zero_rank(
                                                                item.rank
                                                              )
                                                            },
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.rank_out_of
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.opportunities",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-center"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.opportunities
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.share",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-center"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.val_format(
                                                                      _vm.vFmt(
                                                                        item.share,
                                                                        2
                                                                      )
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.A",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-center"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.val_format(
                                                                      _vm.vFmt(
                                                                        item.A *
                                                                          _vm.value_convertion,
                                                                        2
                                                                      )
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.A1",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-center"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.val_format(
                                                                      _vm.vFmt(
                                                                        item.A1 *
                                                                          _vm.value_convertion,
                                                                        2
                                                                      )
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.B",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-center"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.val_format(
                                                                      _vm.vFmt(
                                                                        item.B *
                                                                          _vm.value_convertion,
                                                                        2
                                                                      )
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.C",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-center"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.val_format_perc(
                                                                    item.C
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.D",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-center"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.val_format_perc(
                                                                    item.D
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.E",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-center "
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.val_format(
                                                                      _vm.vFmt(
                                                                        item.E *
                                                                          _vm.value_convertion,
                                                                        2
                                                                      )
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.F",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-center"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.val_format(
                                                                      _vm.vFmt(
                                                                        item.F *
                                                                          _vm.value_convertion,
                                                                        2
                                                                      )
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.G",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-center"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.val_format(
                                                                      _vm.vFmt(
                                                                        item.G *
                                                                          _vm.value_convertion,
                                                                        2
                                                                      )
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.HM_TW_RCA",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-center"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    parseFloat(
                                                                      item.HM_TW_RCA.toFixed(
                                                                        4
                                                                      )
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.HM_TW_RTA",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-center"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    parseFloat(
                                                                      item.HM_TW_RTA.toFixed(
                                                                        4
                                                                      )
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value:
                                                    _vm.sub_tab_selected_dict[
                                                      tab.tab_name
                                                    ]["selected_items"],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.sub_tab_selected_dict[
                                                        tab.tab_name
                                                      ],
                                                      "selected_items",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "sub_tab_selected_dict[tab.tab_name]['selected_items']"
                                                }
                                              },
                                              [
                                                _vm._v(" // "),
                                                _vm._v(" // "),
                                                _vm._v(" // ")
                                              ]
                                            )
                                      ],
                                      1
                                    )
                                  ]
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ]
                    })
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }