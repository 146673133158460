<template>
  <div>
    <div>
      <v-row no-gutters>
        <v-col sm="auto">
          <v-btn
            text
            @click="html_to_xls_download('reo_matrix', display_advanved_filters_applied)"
            class="pb-3"
          >
            <v-icon class="green--text mr-2">mdi-microsoft-excel</v-icon>
          </v-btn></v-col
        >
        <v-col sm="auto"
          ><input
            type="checkbox"
            id="enable_zoom"
            name="enable_zoom"
            value="enable_zoom"
            v-model="enable_zoom"
            @change="zoom_func('reo_matrix', zoom)"/>
          <label for="enable_zoom" class="mr-2">
            Zoom {{ enable_zoom ? ' in ' : ' out ' }}</label
          ><br
        /></v-col>
        <v-col sm="2">
          <input
            type="checkbox"
            class="caption ml-1"
            id="enable_select"
            name="enable_select"
            value="enable_select"
            v-model="enable_select"
          />
          <label for="enable_select">
            {{ !enable_select ? 'Enable ' : 'Disable ' }}selection</label
          >
        </v-col>
      </v-row>
    </div>

    <div class="ma-2 overflow" :key="re_render">
      <table id="reo_matrix" class="caption " :key="re_render">
        <thead>
          <tr>
            <th class="font-italic">{{ multiplerString }}-{{ currencyString }}</th>
            <th class="rotate "><div></div></th>
            <th class="rotate">
              <div>Products</div>
            </th>
            <template v-for="product in get_all_products">
              <v-tooltip
                color="trade-blue text-subtitle-1"
                max-width="400px"
                top
                :key="product"
              >
                <template v-slot:activator="{on}">
                  <th
                    class="rotate"
                    v-on="on"
                    :class="{cell: enable_select}"
                    @click="handle_product_click(product)"
                  >
                    <div
                      class="ma-0 pa-0 stick"
                      :class="{'select-accent': enable_select, cell: enable_select}"
                      @click="handle_product_click(product)"
                    >
                      {{ product }}
                    </div>
                  </th>
                </template>
                <span
                  >{{ product }}:{{
                    $store.state.tradeDSM.hs6_to_desc_hash_map[product]
                  }}</span
                >
              </v-tooltip>
            </template>
          </tr>
        </thead>

        <tr>
          <td class="full-border pa-1 stick font-weight-bold"></td>

          <!--          <td class="text-center ma-1"></td>-->
          <td class="text-center ma-1"></td>
          <td class="text-center ma-1">Product Description</td>
          <template v-for="product in get_all_products">
            <td :key="product" class="text-center">
              {{ $store.state.tradeDSM.hs6_to_desc_hash_map[product] }}
            </td></template
          >
        </tr>

        <tr>
          <td class="full-border pa-1 stick font-weight-bold"></td>
          <!--          <td class="text-center ma-1"></td>-->
          <td class="text-center ma-1"></td>
          <td class="text-center ma-1">Count ({{ product_count }})</td>
          <template v-for="product in get_all_products">
            <td :key="product" class="text-center">
              {{ count_products(product) }}
            </td></template
          >
        </tr>

        <tr>
          <td class="pa-1 font-weight-bold stick">Country</td>
          <td class="text-center pa-1">Count ({{ market_count }})</td>
          <td class="text-center">
            Value&nbsp;<br />
            ({{ val_monetary }})
          </td>
          <template v-for="product in get_all_products">
            <td :key="product" class="text-center">
              {{ vFmt(sum_products_value(product), 2) }}
            </td>
          </template>
        </tr>

        <template v-for="market in get_all_markets">
          <tr :key="market" class="ma-0 pa-0">
            <td class="stick" :class="{cell: enable_select}">
              <p
                class="ma-1"
                @click="handle_country_click(market)"
                :class="{'select-accent': enable_select}"
              >
                {{ codeToMarket(market) }}
              </p>
            </td>

            <td class="text-center">
              {{ Object.keys(base_values[market]).length }}
            </td>
            <td class="text-center">
              {{ vFmt(sum_markets_value(market), 2) }}
            </td>

            <template v-for="product in get_all_products">
              <td
                v-if="
                  Object.keys(base_values[market]).includes(product) &&
                    base_values[market][product] !== 'NaN'
                "
                :key="product"
                :class="cell_color(base_values[market][product])"
              >
                <p
                  class="ma-0 pa-1 text-center"
                  @click="handle_cell_click(product, market)"
                >
                  {{ vFmt(Math.round(base_values[market][product] * 100, 2) / 100, 2) }}
                </p>
              </td>
              <td
                v-else
                :key="product"
                class="ma-0 pa-0 "
                :class="{empty_hover: enable_select}"
                @click="handle_cell_click(product, market)"
              ></td>
            </template>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import {download_reo_matrix_as_excel} from '@/components/application-level/js_functions/jsFunctions';
import vFmt from '@/components/application-level/mixins/vFmt';
import display_advanved_filters_applied from '@/components/trade-dsm-application/mixins/applied_filters';

export default {
  name: 'reo-market-product-matrix',
  props: ['combos_list', 'market_count', 'product_count', 'val_monetary'],
  mixins: [vFmt, display_advanved_filters_applied],
  mounted() {
    this.build_base_list();
  },

  watch: {
    currencyString() {
      this.build_base_list();
    },
    mulitplier() {
      this.build_base_list();
    },
    combos_list() {
      this.build_base_list();
    },
  },
  computed: {
    get_all_markets() {
      return Object.keys(this.base_values);
    },
    get_all_products() {
      let products_list = [];
      this.get_all_markets.forEach(market => {
        products_list.push(...Object.keys(this.base_values[market]));
      });
      return _.uniq(products_list);
    },
  },
  methods: {
    html_to_xls_download: download_reo_matrix_as_excel,

    zoom_func(id, scale) {
      if (!this.enable_zoom) {
        scale = 1;
      }
      document.getElementById(id).style.transform = 'scale(' + scale + ')';
      document.getElementById(id).style.transformOrigin = '0% 0% 0px';
    },
    async handle_cell_click(HS6_Code, C_Code) {
      if (this.enable_select) {
        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.products.HS6_Code = [
          HS6_Code,
        ];
        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.markets.C_Code = [
          C_Code,
        ];
        await this.update_results_cube();
      }
    },

    handle_country_click(C_Code) {
      if (this.enable_select) {
        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.products.HS6_Code = Object.keys(
          this.base_values[C_Code],
        );
        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.markets.C_Code = [
          C_Code,
        ];
        this.update_results_cube();
      }
    },
    handle_product_click(HS6_Code) {
      let return_markets = [];
      if (this.enable_select) {
        Object.keys(this.base_values).forEach(market => {
          let products = Object.keys(this.base_values[market]);
          if (products.includes(HS6_Code)) {
            return_markets.push(market);
          }
        });

        this.$set(
          this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.products
            .HS6_Code,
          0,
          HS6_Code,
        );

        // this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.products.HS6_Code = [
        //   HS6_Code,
        // ];
        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.markets.C_Code = return_markets;
        this.update_results_cube();
      }
    },
    count_products(HS6_Code) {
      let cnt = 0;
      // console.log(this.base_values);
      Object.keys(this.base_values).forEach(key => {
        let products = Object.keys(this.base_values[key]);
        if (products.includes(HS6_Code)) {
          cnt += 1;
        }
      });
      return cnt;
    },
    sum_markets_value(C_Code) {
      let val = 0;
      let products = Object.keys(this.base_values[C_Code]);
      products.forEach(product => {
        val += this.base_values[C_Code][product];
      });

      return Math.round(val * 100, 2) / 100;
    },
    sum_products_value(HS6_Code) {
      let val = 0;
      Object.keys(this.base_values).forEach(key => {
        let products = Object.keys(this.base_values[key]);
        if (products.includes(HS6_Code)) {
          val += this.base_values[key][HS6_Code];
        }
      });
      return Math.round(val * 100, 2) / 100;
    },
    cell_color(value) {
      // let c1 = '#FF1122';
      // let c1 = '#b0ff11';
      // let c1 = '#ecdfe0';
      let intervals = 3;
      let total_interval = this.max_val - this.min_val;
      let sub_interval = total_interval / intervals;

      return {
        'green-three': value >= this.min_val && value < total_interval - 2 * sub_interval,
        'green-two':
          value >= total_interval - 2 * sub_interval &&
          value < total_interval - sub_interval,
        'green-one': value >= total_interval - sub_interval && value <= this.max_val,
      };
    },
    build_base_list() {
      let build = {};
      for (let i = 0; i < this.combos_list.length; i++) {
        let item = this.combos_list[i];

        if (item.selected) {
          let market = item.market;
          let product = item.product;
          let reo_val = this.value_convertion * item.reo_monetary_val;

          if (reo_val > this.max_val) {
            this.max_val = reo_val;
          }
          if (reo_val < this.min_val) {
            this.min_val = reo_val;
          }

          // test if new country
          if (Object.keys(build).includes(market)) {
            if (Object.keys(build[market]).includes(product)) {
              build[market][product] += reo_val;
            } else {
              build[market][product] = reo_val;
            }
          } else {
            build[market] = {};
            build[market][product] = reo_val;
          }
        }
      }
      // console.log(build);
      this.base_values = build;
    },
  },
  data() {
    return {
      min_val: Infinity,
      max_val: 0,
      product_limit: 37,
      base_values: {},
      enable_select: false,
      enable_zoom: false,
      re_render: 0,
      zoom: 0.5,
    };
  },
};
</script>

<style scoped>
table {
  font-family: 'Fraunces', serif;
  font-size: 125%;
  white-space: nowrap;
  margin: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  border: 1px solid black;
}
table td,
table th {
  border: 1px solid black;
  padding: 0.5rem 1rem;
}
table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}
table td {
  background: #fff;
  padding: 1px 1px;
  text-align: center;
}

table tbody th {
  font-weight: 100;
  font-style: italic;
  text-align: left;
  position: relative;
}
table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
table tbody th {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
}

thead:first-child > tr:first-child > th:first-child {
  background: lightgray;
}
thead:first-child > tr:first-child > th:nth-child(3) {
  background: lightgray;
}

thead:first-child > tr:first-child > th:nth-child(2) {
  background: lightgray;
}

tr:not(:first-child) > td:nth-child(2) {
  background: lightgray;
}
tr:not(:first-child) > td:nth-child(3) {
  background: lightgray;
}

tr:nth-child(2) > td {
  background: lightgray;
}
tr:nth-child(4) > td {
  background: lightgray;
}

td:nth-child(1) {
  text-align: left;
}

tr:nth-child(3) > td {
  background: lightgray;
}

tr:nth-child(2) > td {
  background: red;
  display: none;
}

caption {
  text-align: left;
  padding: 0.25rem;
  position: sticky;
  left: 0;
}

[role='region'][aria-labelledby][tabindex] {
  width: 100%;
  max-height: 98vh;
  overflow: auto;
}
[role='region'][aria-labelledby][tabindex]:focus {
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  outline: 0;
}
th.rotate {
  /* Something you can count on */
  height: 80px;
}

th.rotate > div {
  transform: rotate(-90deg);
  white-space: nowrap;
}

.stick {
  position: sticky;
  left: 0;
  background: white;
}

.overflow {
  max-height: 550px;
  max-width: 1100px;
  overflow: auto;
}

.left-top {
  border-bottom: 1px solid white;
  border-left: 1px solid white;
  border-top: 1px solid white;
  background: white;
}

.left-top-with-bottom {
  border-right: 1px solid white;
  border-left: 1px solid white;
  border-top: 1px solid white;
}

.full-border {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-top: 1px solid black;
}

.cell {
}
.cell:hover {
  background-color: yellow;
  cursor: pointer;
}
.cell:hover > * {
  background-color: yellow;
  cursor: pointer;
}

.cell div:hover,
.cell div:hover ~ div {
  background-color: yellow;
  cursor: pointer;
}

.green-one {
  background: #78ab46;
}

.green-one:hover {
  background: yellow;
  cursor: pointer;
}
.green-two {
  background: #b1ce85;
}
.green-two:hover {
  background: yellow;
  cursor: pointer;
}

.green-three {
  background: #ddeec9;
}
.green-three:hover {
  background: yellow;
  cursor: pointer;
}

.empty_hover:hover {
  background: yellow;
  cursor: pointer;
}

.no-pointer {
  pointer-events: none !important;
}

.select-accent {
  font-weight: bolder;
  font-style: italic;
}
</style>
