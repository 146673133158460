export function calc_items_per_page(total, intervals = 4, max = -1) {
    // function to calculate the table dropdown increments
    // const intervals = 4;

    let limit = total;

    if (max < total && max !== -1) {
        limit = max;
    }

    const delta = Math.round(limit / intervals);
    if (limit <= 5) {
        return [];
    }
    if (limit <= 10) {
        return [5, limit];
    }
    if (limit <= 15) {
        return [5, 10, limit];
    }
    if (limit > 15) {
        return [5, delta, delta * 2, delta * 3, limit];
    }
}
