var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.FilterBox !== null
    ? _c(
        "div",
        { staticClass: "no-select" },
        [
          _c(
            "v-navigation-drawer",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.drawerOpen,
                  expression: "drawerOpen"
                }
              ],
              staticClass: "grey lighten-4 pl-2 pr-2 font-weight-light",
              attrs: {
                app: "",
                clipped: "",
                fixed: "",
                stateless: "stateless",
                temporary: "",
                width: "570"
              },
              model: {
                value: _vm.drawerOpen,
                callback: function($$v) {
                  _vm.drawerOpen = $$v
                },
                expression: "drawerOpen"
              }
            },
            [
              _c(
                "v-row",
                { staticClass: "mt-1", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    [
                      !_vm.loading
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "mt-0 pt-0 mb-0 pb-0  justify-space-around ml-auto",
                                  attrs: { sm: "auto" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      class: _vm.apply_button_class,
                                      attrs: {
                                        disabled:
                                          (_vm.product_selections_changed ||
                                            _vm.temp_dsm_combinations_updating) &&
                                          !_vm.active_from_outside,
                                        elevation: "0",
                                        small: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.apply_btn_clicked.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("mdi-exit-to-app")
                                      ]),
                                      _vm._v(" Apply ")
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { right: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _vm.$store.state.tradeDSM
                                                  .dsm_filter_setttings.Filters
                                                  .META.filter_in_progress
                                                  ? _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          class:
                                                            _vm.filters_button_class,
                                                          attrs: {
                                                            elevation: "0",
                                                            small: "",
                                                            disabled:
                                                              _vm.temp_dsm_combinations_updating
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.clear_preset_filters(
                                                                false
                                                              )
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { left: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-filter-variant-plus"
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " Clear Filters "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          staticClass: "ml-2",
                                                          class:
                                                            _vm.filters_button_class,
                                                          attrs: {
                                                            small: "",
                                                            disabled:
                                                              _vm.temp_dsm_combinations_updating
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.reset_filters_to_preset_state(
                                                                true
                                                              )
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { left: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-filter-variant-minus"
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" Load Filters ")
                                                      ],
                                                      1
                                                    )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1341031727
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.filters_load_text))
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "v-btn--outlined trade-blue white--text ml-1",
                                      attrs: { elevation: "0", small: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.cancel_btn_clicked.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("mdi-cancel")
                                      ]),
                                      _vm._v(" Cancel ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "v-row",
                            { staticClass: "justify-end" },
                            [
                              _c("v-progress-circular", {
                                staticClass: "mr-11 mb-1",
                                attrs: {
                                  width: 2,
                                  color: "green",
                                  indeterminate: ""
                                }
                              })
                            ],
                            1
                          ),
                      _c(
                        "v-row",
                        { staticClass: "mr-1 mt-1 mb-0 pb-0" },
                        [
                          _c("v-text-field", {
                            staticClass: "trade-blue-text mb-0 ml-4 pa-0",
                            attrs: {
                              placeholder:
                                "Refine search within " +
                                _vm.box_entries_count(
                                  0,
                                  _vm.allow_out_of_cube_items_selections
                                ) +
                                " " +
                                _vm.search_box_text(),
                              "append-icon":
                                _vm.searchQuery.length > 0 ? "mdi-close" : null,
                              "prepend-inner-icon":
                                _vm.selector_type === "markets"
                                  ? "mdi-earth"
                                  : "mdi-dolly",
                              "background-color": "white",
                              dense: "",
                              flat: "",
                              outlined: "",
                              solo: "",
                              "hide-details": "true"
                            },
                            on: {
                              "click:append": function($event) {
                                _vm.searchQuery = ""
                              }
                            },
                            model: {
                              value: _vm.searchQuery,
                              callback: function($$v) {
                                _vm.searchQuery = $$v
                              },
                              expression: "searchQuery"
                            }
                          })
                        ],
                        1
                      ),
                      _c("temp-dsm-combintations", {
                        staticClass: "ml-1 mr-1 mt-1 outline",
                        class: {
                          outline_focus: !_vm.product_selections_changed
                        },
                        attrs: {
                          combinations: _vm.current_transaction.combinations,
                          updating: _vm.temp_dsm_combinations_updating,
                          active: true
                        }
                      }),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "pt-1 pb-2 outline mt-2 ml-1 mr-1  lighten-2",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c("v-col", { attrs: { sm: "auto" } }),
                          _c(
                            "v-col",
                            { attrs: { sm: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "yellow trade-blue--text ml-2",
                                  attrs: {
                                    disabled:
                                      _vm.temp_dsm_combinations_updating ||
                                      _vm.product_selections_changed,
                                    "x-small": ""
                                  },
                                  on: {
                                    click: _vm.update_temp_dsm_combinations
                                  }
                                },
                                [_vm._v(" Update Summary ")]
                              )
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { sm: "auto" } }, [
                            _c("form", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.allow_out_of_cube_items_selections,
                                    expression:
                                      "allow_out_of_cube_items_selections"
                                  }
                                ],
                                staticClass: "ml-2 mt-1",
                                attrs: { type: "checkbox", id: "checkbox_id" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.allow_out_of_cube_items_selections
                                  )
                                    ? _vm._i(
                                        _vm.allow_out_of_cube_items_selections,
                                        null
                                      ) > -1
                                    : _vm.allow_out_of_cube_items_selections
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a =
                                          _vm.allow_out_of_cube_items_selections,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.allow_out_of_cube_items_selections = $$a.concat(
                                              [$$v]
                                            ))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.allow_out_of_cube_items_selections = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.allow_out_of_cube_items_selections = $$c
                                      }
                                    },
                                    function($event) {
                                      return _vm.allow_out_of_cube_items_selections_changed(
                                        _vm.allow_out_of_cube_items_selections
                                      )
                                    }
                                  ]
                                }
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "caption ml-2",
                                  attrs: { for: "checkbox_id" }
                                },
                                [_vm._v(_vm._s(_vm.checkbox_text))]
                              ),
                              _c("br")
                            ])
                          ]),
                          _c("v-col", { attrs: { sm: "auto" } }, [
                            _c("form", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.show_hover,
                                    expression: "show_hover"
                                  }
                                ],
                                staticClass: "ml-2 mt-1",
                                attrs: { type: "checkbox", id: "checkbox_id" },
                                domProps: {
                                  checked: Array.isArray(_vm.show_hover)
                                    ? _vm._i(_vm.show_hover, null) > -1
                                    : _vm.show_hover
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.show_hover,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.show_hover = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.show_hover = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.show_hover = $$c
                                    }
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "caption ml-2",
                                  attrs: { for: "checkbox_id" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.show_hover
                                        ? "Hide Hover"
                                        : "Show Hover"
                                    )
                                  )
                                ]
                              ),
                              _c("br")
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm._l(_vm.FilterBox.search_boxes, function(search_box) {
                        return [
                          _c(
                            "v-card",
                            {
                              key: search_box.id,
                              staticClass: "flex-grow-1 mt-2",
                              attrs: { height: search_box.box_height + "vh" }
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "trade-blue white--text pa-0 pl-2 mt-3 font-weight-light"
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { staticClass: "pl-4 pr-4 mb-0 pb-0" },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c("v-col", { attrs: { sm: "3" } }, [
                                            _c(
                                              "span",
                                              { staticClass: "subtitle-2" },
                                              [_vm._v(_vm._s(search_box.name))]
                                            )
                                          ]),
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "1" } },
                                            [
                                              _vm.FilterBox.show_select_all(
                                                search_box.id,
                                                _vm.allow_out_of_cube_items_selections
                                              )
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { right: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _vm.FilterBox.show_select_all(
                                                                  search_box.id,
                                                                  _vm.allow_out_of_cube_items_selections
                                                                )
                                                                  ? _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "yellow trade-blue--text mt-2 mb-2",
                                                                          attrs: {
                                                                            "x-small":
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.FilterBox.select_all(
                                                                                search_box.id,
                                                                                true,
                                                                                _vm.allow_out_of_cube_items_selections
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-check "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Select all " +
                                                            _vm._s(
                                                              search_box.name
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "1" } },
                                            [
                                              _vm.FilterBox.show_unselect_all(
                                                search_box.id
                                              )
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { right: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _vm.FilterBox.show_unselect_all(
                                                                  search_box.id
                                                                )
                                                                  ? _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "yellow trade-blue--text mt-2 mb-2 ml-1",
                                                                          attrs: {
                                                                            "x-small":
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.FilterBox.select_all(
                                                                                search_box.id,
                                                                                false,
                                                                                _vm.allow_out_of_cube_items_selections
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-close "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Unselect all " +
                                                            _vm._s(
                                                              search_box.name
                                                            )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c("v-col", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "caption float-right mt-2"
                                              },
                                              [
                                                _vm._v(
                                                  "Selected: " +
                                                    _vm._s(
                                                      _vm.FilterBox.entries_selected_count(
                                                        search_box.id
                                                      )
                                                    ) +
                                                    "/" +
                                                    _vm._s(
                                                      _vm.box_entries_count(
                                                        search_box.id,
                                                        _vm.allow_out_of_cube_items_selections
                                                      )
                                                    )
                                                )
                                              ]
                                            )
                                          ]),
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "1" } },
                                            [
                                              _c("help-link", {
                                                staticClass: "ml-6 mt-5",
                                                attrs: {
                                                  help_id: search_box.help_id
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-virtual-scroll", {
                                attrs: {
                                  items: _vm.FilterBox.box_entry_codes(
                                    search_box.id,
                                    _vm.searchQuery
                                  ),
                                  "item-height": "21",
                                  bench: "30",
                                  height: search_box.scroller_height + "vh"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var index = ref.index
                                        var item = ref.item
                                        return [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                disabled: !_vm.show_hover,
                                                right: "",
                                                "max-width": "400px"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "div",
                                                          _vm._g({}, on),
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                attrs: {
                                                                  "no-gutters":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols:
                                                                        search_box.id ===
                                                                        0
                                                                          ? 12
                                                                          : 11
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "listItem p_overflow name",
                                                                        class: {
                                                                          active:
                                                                            search_box
                                                                              .code_lookup[
                                                                              item
                                                                            ]
                                                                              .selected,
                                                                          "ml-2": !(
                                                                            index >=
                                                                            9
                                                                          ),
                                                                          not_selected:
                                                                            (!search_box
                                                                              .code_lookup[
                                                                              item
                                                                            ]
                                                                              .in_cube ||
                                                                              (!search_box
                                                                                .code_lookup[
                                                                                item
                                                                              ]
                                                                                .selected &&
                                                                                !search_box
                                                                                  .code_lookup[
                                                                                  item
                                                                                ]
                                                                                  .partials_list
                                                                                  .length ===
                                                                                  0) ||
                                                                              (search_box
                                                                                .code_lookup[
                                                                                item
                                                                              ]
                                                                                .partials_list
                                                                                .length ===
                                                                                0 &&
                                                                                search_box.id !==
                                                                                  0) ||
                                                                              (search_box.id ===
                                                                                0 &&
                                                                                !search_box
                                                                                  .code_lookup[
                                                                                  item
                                                                                ]
                                                                                  .selected)) &&
                                                                            !search_box
                                                                              .code_lookup[
                                                                              item
                                                                            ]
                                                                              .selected,
                                                                          "grey lighten-2": !search_box
                                                                            .code_lookup[
                                                                            item
                                                                          ]
                                                                            .in_cube
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.entry_click(
                                                                              search_box.id,
                                                                              item
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "ml-2 font-italic"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                search_box
                                                                                  .code_lookup[
                                                                                  item
                                                                                ]
                                                                                  .selected
                                                                                  ? "✓"
                                                                                  : index +
                                                                                      1
                                                                              ) +
                                                                                " "
                                                                            ),
                                                                            search_box.code !==
                                                                            search_box.description
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    domProps: {
                                                                                      innerHTML: _vm._s(
                                                                                        _vm.highlight(
                                                                                          item,
                                                                                          _vm.searchQuery
                                                                                        )
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          item
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "mr-2 ml-1",
                                                                            domProps: {
                                                                              innerHTML: _vm._s(
                                                                                _vm.highlight(
                                                                                  search_box.code_lookup[
                                                                                    item
                                                                                  ].description.toString(),
                                                                                  _vm.searchQuery
                                                                                )
                                                                              )
                                                                            }
                                                                          }
                                                                        ),
                                                                        !search_box
                                                                          .code_lookup[
                                                                          item
                                                                        ]
                                                                          .in_cube
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "[not in results set]"
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                search_box.id >
                                                                0
                                                                  ? [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          staticClass:
                                                                            "white"
                                                                        },
                                                                        [
                                                                          search_box
                                                                            .code_lookup[
                                                                            item
                                                                          ]
                                                                            .partials_list
                                                                            .length >
                                                                            0 &&
                                                                          !search_box
                                                                            .code_lookup[
                                                                            item
                                                                          ]
                                                                            .selected
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "listItem hand",
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.partial_select_all(
                                                                                        search_box.id,
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mini_box"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " mdi-check "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                          search_box
                                                                            .code_lookup[
                                                                            item
                                                                          ]
                                                                            .partials_list
                                                                            .length >
                                                                            0 &&
                                                                          !search_box
                                                                            .code_lookup[
                                                                            item
                                                                          ]
                                                                            .selected
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "listItem hand",
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.FilterBox.partial_unselect_all(
                                                                                        search_box.id,
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mini_box"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " mdi-close "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e()
                                                                        ]
                                                                      )
                                                                    ]
                                                                  : _vm._e()
                                                              ],
                                                              2
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _c(
                                                  "span",
                                                  { staticClass: "d-block" },
                                                  [
                                                    _vm._v(
                                                      " To select or unselect and item, or all sub-items belonging to an item, click directly on the item (yellow) line."
                                                    ),
                                                    _c("br")
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "d-block mt-3"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " To further sub-select (or unselect) items that belongs to only a specific sub-item within the current already selected list of items, use the "
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mini_box"
                                                      },
                                                      [_vm._v("mdi-check")]
                                                    ),
                                                    _vm._v(" (select) or "),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mini_box"
                                                      },
                                                      [_vm._v("mdi-close")]
                                                    ),
                                                    _vm._v(" (unselect).")
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "d-block mt-3"
                                                  },
                                                  [
                                                    _vm._v(" See "),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "grey lighten-2 rounded-circle pa-1 black--text help_box"
                                                      },
                                                      [_vm._v(" mdi-help")]
                                                    ),
                                                    _vm._v(
                                                      " (help) for more information."
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "blue d-block mt-3 pa-2"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item) +
                                                        " - " +
                                                        _vm._s(
                                                          search_box
                                                            .code_lookup[item]
                                                            .description
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ])
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }