var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        {
          staticClass: "justify-space-around mt-2 pb-2 pr-2 pl-0",
          attrs: { row: "" }
        },
        [
          _c("MapContainer", [_c("WorldMap_MPMC")], 1),
          _c(
            "v-flex",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.$store.state.app.big_map,
                  expression: "!$store.state.app.big_map"
                }
              ],
              staticClass: "mt-2",
              attrs: { lg7: "", md12: "", sm12: "", xl6: "" }
            },
            [
              _c("top-summary-results", { staticClass: "mb-2" }),
              _c(
                "div",
                {},
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c("v-flex", { staticClass: "xs12 sm12 lg12" }, [
                        _c(
                          "div",
                          {
                            staticClass: "body-2 font-weight-bold nav nav-tabs"
                          },
                          [
                            _c("li", {}, [
                              _c(
                                "a",
                                { staticClass: "disabled" },
                                [
                                  _vm._v(
                                    "Details (" +
                                      _vm._s(
                                        _vm.$store.getters.selectionCounts
                                          .results.markets_count
                                      ) +
                                      " target market x " +
                                      _vm._s(
                                        _vm.$store.getters.selectionCounts
                                          .results.products_count
                                      ) +
                                      " products) "
                                  ),
                                  _c("help-link", {
                                    staticStyle: { float: "right" },
                                    attrs: { help_id: "14" }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "tab-content" }, [
                    _c(
                      "div",
                      { staticClass: "tab-pane active white" },
                      [
                        _c(
                          "v-container",
                          { attrs: { fluid: "" } },
                          [
                            _c(
                              "v-layout",
                              {
                                staticClass:
                                  "justify-space-between mt-0 ml-2 mr-1 black--text",
                                attrs: { row: "" }
                              },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { sm12: "" } },
                                  [
                                    _c("applied_filters_box", {
                                      staticClass: "mr-3 ml-0 mb-1"
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { staticClass: "ml-0", attrs: { sm12: "" } },
                                  [_c("products_filtered_box")],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "ml-1 mt-1",
                                    attrs: { sm12: "" }
                                  },
                                  [_c("countries_filtered_box")],
                                  1
                                ),
                                _c("hr")
                              ],
                              1
                            ),
                            _c(
                              "v-layout",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.is_run_cube_valid,
                                    expression: "is_run_cube_valid"
                                  }
                                ],
                                staticClass:
                                  "justify-space-between mt-0 ml-2 mr-1 black--text",
                                attrs: { row: "" }
                              },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { sm12: "" } },
                                  [_c("reoCubeWrapper")],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: false,
                                        expression: "false"
                                      }
                                    ],
                                    attrs: { sm12: "" }
                                  },
                                  [
                                    !this.$store.state.tradeDSM
                                      .loading_indicator &&
                                    _vm.$store.state.tradeDSM
                                      .dsm_filter_setttings.Filters.META
                                      .query_results.cube.length > 0 &&
                                    !this.$store.state.app.disable_getters
                                      ? _c(
                                          "v-card",
                                          { staticClass: "pt-2" },
                                          [
                                            _c(
                                              "v-tabs",
                                              {
                                                attrs: {
                                                  "active-class": "trade-blue",
                                                  "background-color":
                                                    "tab_header_color",
                                                  dark: "",
                                                  dense: ""
                                                },
                                                model: {
                                                  value: _vm.product_tabs,
                                                  callback: function($$v) {
                                                    _vm.product_tabs = $$v
                                                  },
                                                  expression: "product_tabs"
                                                }
                                              },
                                              [
                                                _c("v-tab", [
                                                  _vm._v(" Code (HS6)")
                                                ]),
                                                _c(
                                                  "v-tab-item",
                                                  [
                                                    _c(
                                                      "v-card",
                                                      { attrs: { flat: "" } },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            staticClass: "pa-2",
                                                            attrs: { row: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              [
                                                                _c(
                                                                  "reo_table_products",
                                                                  {
                                                                    attrs: {
                                                                      cube:
                                                                        "cube_HS6_Code",
                                                                      table_id:
                                                                        "reo_hs6_code"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c("v-flex", {
                                              staticClass:
                                                "xs12 sm12 lg12 ml-5 mr-8 mb-9 mt-12"
                                            })
                                          ],
                                          1
                                        )
                                      : [
                                          _vm.$store.state.tradeDSM
                                            .loading_indicator
                                            ? _c("v-skeleton-loader", {
                                                staticClass: "mt-3 mb-4",
                                                attrs: {
                                                  type: "article, actions"
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                  ],
                                  2
                                )
                              ],
                              1
                            ),
                            !_vm.is_run_cube_valid
                              ? _c(
                                  "v-layout",
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass:
                                          "xs12 sm12 lg12 ml-5 mr-8 mb-9 mt-12"
                                      },
                                      [
                                        _c("reoFrontendTable", {
                                          attrs: { cube_name: "cube_HS6_Code" }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.$store.state.tradeDSM.loading_indicator &&
      _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.query_results
        .cube.length > 0
        ? _c(
            "div",
            [
              !_vm.$store.state.tradeDSM.loading_indicator &&
              _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .query_results.cube.length > 0
                ? _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      !_vm.$store.state.app.big_map
                        ? _c(
                            "v-flex",
                            { staticClass: "xs12 sm12 lg12 ml-5 mr-8 mb-9" },
                            [
                              _vm.is_run_cube_valid
                                ? _c("DSMMP1CGraphs")
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }