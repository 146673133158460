<template>
  <div
    v-show="show_reo"
    class="outline"
    :key="
      $store.state.tradeDSM.dsm_filter_setttings.Filters.META.combinations.combinations
    "
  >
    <v-container fluid>
      <v-row class="ma-0 pa-0 no-gutters">
        <v-col class="ma-0 pa-0 no-gutters">
          <span class="black--text ml-1">
            Map of Realistic Export Opportunities (REOs):
            {{ market_or_product() }}
            <!--            &#45;&#45; Last cell clikced: {{ last_cell_clicked }} <br />-->
            <!--            {{ this.$store.state.tradeDSM.last_clicked_REO_map_cell }}-->
          </span>
          <v-icon
            @click="element2clipboard('REO_FULL_TABLE')"
            style="float: left"
            data-html2canvas-ignore
            class="black--text float-right mr-12"
            >mdi-camera
          </v-icon>
        </v-col>
        <v-col v-if="is_run_cube_valid" sm="12" md="12" lg="7" xl="7" class="mt-0 pt-0">
          <p class="black--text ml-2">
            Click in a REO cell on the left for specific REO column information
            <help-link help_id="57" />
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          sm="12"
          md="12"
          :lg="is_run_cube_valid ? 5 : 12"
          :xl="is_run_cube_valid ? 5 : 12"
          id="REO_FULL_TABLE"
        >
          <table>
            <tbody>
              <tr>
                <td colspan="2" rowspan="3">
                  No. <br />
                  [%of total no.]<br />
                  {{ multiplerString }} {{ currencyString }} <br />
                  Untapped potential value <br />
                  [% of total value]
                </td>
                <td colspan="4">Home market relative share of target market imports</td>
                <td class="noborder-topright"></td>
              </tr>
              <tr>
                <td rowspan="2">Small (x1)</td>
                <td colspan="2">Intermediate</td>
                <td rowspan="2">Large (x4)</td>
                <td rowspan="2">Row Totals</td>
              </tr>
              <tr>
                <td>Small (x2)</td>
                <td>Large (x3)</td>
              </tr>
              <tr>
                <td rowspan="5">Product x Country market size and growth</td>
                <td>Large <br />(y1)</td>

                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large | Home market - Small',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large | Home market - Small',
                      [1],
                      [1],
                    )
                  "
                  :data="calc_reo_data(cube_name)['1_1']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large | Home market - Intermediate - Small',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large | Home market - Intermediate - Small',
                      [1],
                      [2],
                    )
                  "
                  :data="calc_reo_data(cube_name)['2_1']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large | Home market - Intermediate - Large',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large | Home market - Intermediate - Large',
                      [1],
                      [3],
                    )
                  "
                  :data="calc_reo_data(cube_name)['3_1']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large | Home market - Large',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large | Home market - Large',
                      [1],
                      [4],
                    )
                  "
                  :data="calc_reo_data(cube_name)['4_1']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked === 'Product x Country - Large - Total',
                  }"
                  @newReoParameters="
                    cellClicked($event, 'Product x Country - Large - Total', [1], [])
                  "
                  :data="calc_reo_data(cube_name)['1_T']"
                ></reo-td>
              </tr>
              <tr>
                <td>Growing (y2) (Short &amp; Long term)</td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Growing - Short & Long term | Home market - Small',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Growing - Short & Long term | Home market - Small',
                      [2],
                      [1],
                    )
                  "
                  :data="calc_reo_data(cube_name)['1_2']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Growing - Short & Long term | Home market - Intermediate - Small',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Growing - Short & Long term | Home market - Intermediate - Small',
                      [2],
                      [2],
                    )
                  "
                  :data="calc_reo_data(cube_name)['2_2']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Growing - Short & Long term | Home market - Intermediate - Large',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Growing - Short & Long term | Home market - Intermediate - Large',
                      [2],
                      [3],
                    )
                  "
                  :data="calc_reo_data(cube_name)['3_2']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Growing - Short & Long term | Home market - Large',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Growing - Short & Long term | Home market - Large',
                      [2],
                      [4],
                    )
                  "
                  :data="calc_reo_data(cube_name)['4_2']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Growing - Short & Long term - Total',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Growing - Short & Long term - Total',
                      [2],
                      [],
                    )
                  "
                  :data="calc_reo_data(cube_name)['2_T']"
                ></reo-td>
              </tr>
              <tr>
                <td>Large and growing (y3) (Short term)</td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large and Growing - Short term | Home market - Small',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large and Growing - Short term | Home market - Small',
                      [3],
                      [1],
                    )
                  "
                  :data="calc_reo_data(cube_name)['1_3']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large and Growing - Short term | Home market - Intermediate - Small',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large and Growing - Short term | Home market - Intermediate - Small',
                      [3],
                      [2],
                    )
                  "
                  :data="calc_reo_data(cube_name)['2_3']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large and Growing - Short term | Home market - Intermediate - Large',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large and Growing - Short term | Home market - Intermediate - Large',
                      [3],
                      [3],
                    )
                  "
                  :data="calc_reo_data(cube_name)['3_3']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large and Growing - Short term |  Home market - Large',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large and Growing - Short term |  Home market - Large',
                      [3],
                      [4],
                    )
                  "
                  :data="calc_reo_data(cube_name)['4_3']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large and Growing - Short term - Total',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large and Growing - Short term - Total',
                      [3],
                      [],
                    )
                  "
                  :data="calc_reo_data(cube_name)['3_T']"
                ></reo-td>
              </tr>
              <tr>
                <td>Large and growing (y4) (Long term)</td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large and Growing - Long term |  Home market - Small',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large and Growing - Long term |  Home market - Small',
                      [4],
                      [1],
                    )
                  "
                  :data="calc_reo_data(cube_name)['1_4']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large and Growing - Long term |  Home market - Intermediate - Small',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large and Growing - Long term |  Home market - Intermediate - Small',
                      [4],
                      [2],
                    )
                  "
                  :data="calc_reo_data(cube_name)['2_4']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large and Growing - Long term |  Home market - Intermediate - Large',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large and Growing - Long term |  Home market - Intermediate - Large',
                      [4],
                      [3],
                    )
                  "
                  :data="calc_reo_data(cube_name)['3_4']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large and Growing - Long term |  Home market - Large',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large and Growing - Long term |  Home market - Large',
                      [4],
                      [4],
                    )
                  "
                  :data="calc_reo_data(cube_name)['4_4']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large and Growing - Long term - Total',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large and Growing - Long term - Total',
                      [4],
                      [],
                    )
                  "
                  :data="calc_reo_data(cube_name)['4_T']"
                ></reo-td>
              </tr>
              <tr>
                <td>Large and growing (y5) (Short &amp; Long term)</td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large and Growing - Short and Long term |  Home market - Small',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large and Growing - Short and Long term |  Home market - Small',
                      [5],
                      [1],
                    )
                  "
                  :data="calc_reo_data(cube_name)['1_5']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large and Growing - Short and Long term |  Home market - Intermediate - Small',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large and Growing - Short and Long term |  Home market - Intermediate - Small',
                      [5],
                      [2],
                    )
                  "
                  :data="calc_reo_data(cube_name)['2_5']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large and Growing - Short and Long term |  Home market - Intermediate - Large',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large and Growing - Short and Long term |  Home market - Intermediate - Large',
                      [5],
                      [3],
                    )
                  "
                  :data="calc_reo_data(cube_name)['3_5']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large and Growing - Short and Long term |  Home market - Large',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large and Growing - Short and Long term |  Home market - Large',
                      [5],
                      [4],
                    )
                  "
                  :data="calc_reo_data(cube_name)['4_5']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked ===
                      'Product x Country - Large and Growing - Short and Long term - Total',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Product x Country - Large and Growing - Short and Long term - Total',
                      [5],
                      [],
                    )
                  "
                  :data="calc_reo_data(cube_name)['5_T']"
                ></reo-td>
              </tr>
              <tr>
                <td class="noborder-bottomleft">
                  <v-checkbox
                    v-model="show_REO_totals"
                    label="Show  all totals"
                    dense
                  ></v-checkbox>
                </td>
                <td>REO Totals</td>
                <reo-td
                  :class="{
                    'seleted-box': last_cell_clicked === 'Home market - Small - Total',
                  }"
                  @newReoParameters="
                    cellClicked($event, 'Home market - Small - Total', [], [1])
                  "
                  :data="calc_reo_data(cube_name)['T_1']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked === 'Home market - Intermediate Small - Total',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Home market - Intermediate Small - Total',
                      [],
                      [2],
                    )
                  "
                  :data="calc_reo_data(cube_name)['T_2']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box':
                      last_cell_clicked === 'Home market - Intermediate Large - Total',
                  }"
                  @newReoParameters="
                    cellClicked(
                      $event,
                      'Home market - Intermediate Large - Total',
                      [],
                      [3],
                    )
                  "
                  :data="calc_reo_data(cube_name)['T_3']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box': last_cell_clicked === 'Home market - Large - Total',
                  }"
                  @newReoParameters="
                    cellClicked($event, 'Home market - Large - Total', [], [4])
                  "
                  :data="calc_reo_data(cube_name)['T_4']"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box': last_cell_clicked === 'All opportunities',
                  }"
                  @newReoParameters="cellClicked($event, 'All opportunities', [], [])"
                  :data="calc_reo_data(cube_name)['T_T']"
                ></reo-td>
              </tr>

              <!--            -->
              <tr class="yellow lighten-3" v-if="show_REO_totals">
                <td class="noborder-bottomleft"></td>
                <td>Non REO Totals</td>
                <reo-td
                  :class="{
                    'seleted-box': last_cell_clicked === 'T1_0',
                  }"
                  :hover="false"
                  :data="calc_reo_data(cube_name)['T1_0']"
                  @newReoParameters="total_non_reo_cells_clicked([1], 'T1_0')"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box': last_cell_clicked === 'T2_0',
                  }"
                  :hover="false"
                  :data="calc_reo_data(cube_name)['T2_0']"
                  @newReoParameters="total_non_reo_cells_clicked([2], 'T2_0')"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box': last_cell_clicked === 'T3_0',
                  }"
                  :hover="false"
                  :data="calc_reo_data(cube_name)['T3_0']"
                  @newReoParameters="total_non_reo_cells_clicked([3], 'T3_0')"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box': last_cell_clicked === 'T4_0',
                  }"
                  :hover="false"
                  :data="calc_reo_data(cube_name)['T4_0']"
                  @newReoParameters="total_non_reo_cells_clicked([4], 'T4_0')"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box': last_cell_clicked === 'TT_0',
                  }"
                  :hover="false"
                  :data="calc_reo_data(cube_name)['TT_0']"
                  @newReoParameters="total_non_reo_cells_clicked([1, 2, 3, 4], 'TT_0')"
                ></reo-td>
              </tr>
              <!--            -->
              <!--            -->
              <tr class="yellow lighten-3" v-if="show_REO_totals">
                <td class="noborder-bottomleft"></td>
                <td>Grand Total</td>
                <reo-td
                  :class="{
                    'seleted-box': last_cell_clicked === 'TT1_TT',
                  }"
                  :hover="false"
                  :data="calc_reo_data(cube_name)['TT1_TT']"
                  @newReoParameters="grand_total_cells_clicked([1], 'TT1_TT')"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box': last_cell_clicked === 'TT2_TT',
                  }"
                  :hover="false"
                  :data="calc_reo_data(cube_name)['TT2_TT']"
                  @newReoParameters="grand_total_cells_clicked([2], 'TT2_TT')"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box': last_cell_clicked === 'TT3_TT',
                  }"
                  :hover="false"
                  :data="calc_reo_data(cube_name)['TT3_TT']"
                  @newReoParameters="grand_total_cells_clicked([3], 'TT3_TT')"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box': last_cell_clicked === 'TT4_TT',
                  }"
                  :hover="false"
                  :data="calc_reo_data(cube_name)['TT4_TT']"
                  @newReoParameters="grand_total_cells_clicked([4], 'TT4_TT')"
                ></reo-td>
                <reo-td
                  :class="{
                    'seleted-box': last_cell_clicked === 'TT_TT',
                  }"
                  :hover="false"
                  :data="calc_reo_data(cube_name)['TT_TT']"
                  @newReoParameters="grand_total_cells_clicked([1, 2, 3, 4], 'TT_TT')"
                ></reo-td>
              </tr>
              <!--            -->
            </tbody>
          </table>
        </v-col>

        <v-col v-if="is_run_cube_valid" sm="12" md="12" lg="7" xl="7" class="mt-0 pt-0">
          <v-card class="mx-auto outline-red rounded-0">
            <div class="ml-1 pl-2 mr-2 text-h7 ">
              <v-row no-gutters>
                <v-col sm="12">
                  <div class="font-weight-bold mt-2">
                    Product x Country market size and growth:
                  </div>
                  <div class="ml-3">
                    {{ get_cell_name()['product_market_selection_text'] }}
                  </div>
                  <div class="mt-1 font-weight-bold">
                    Home market relative share of target market imports:
                  </div>
                  <div class="ml-3">
                    {{ get_cell_name()['home_market_selection_text'] }}
                  </div>
                </v-col>
                <!--                  <div>{{ last_cell_clicked }} -</div>-->
                <v-col>
                  <div class="font-weight-bold mt-1">
                    {{ products_and_markets_count_string }}
                  </div>
                  <div
                    v-if="current_report_to_run === 'MP1C'"
                    class="font-weight-bold mt-1"
                  >
                    Map of Realistic Export Opportunities with
                    {{
                      codeToMarket(
                        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                          .selections.markets.C_Code[0],
                      )
                    }}
                  </div>
                  <div
                    v-else-if="current_report_to_run === '1PMC'"
                    class="font-weight-bold mt-1"
                  >
                    Map of Realistic Export Opportunities for
                    {{
                      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                        .selections.products.HS6_Code[0]
                    }}
                    -
                    {{
                      hs6_codeToDescription(
                        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                          .selections.products.HS6_Code[0],
                      )
                    }}
                  </div>
                </v-col>
              </v-row>

              <p v-if="which_list.length === 0" class="ml-4 font-weight-bold"></p>
              <template v-else>
                <v-row no-gutters>
                  <v-col>
                    <v-radio-group v-model="showlist" row class="ml-2 ">
                      <v-tooltip top color="trade-blue text-subtitle-1" max-width="200px">
                        <template v-slot:activator="{on, attrs}">
                          <!--                          <v-btn @click="update_MPMC_cube_data(false)">Update</v-btn>-->
                          <!--                          {{ last_clicked_REO_row }}-{{ last_clicked_REO_row }}&#45;&#45;{{-->
                          <!--                            last_cell_clicked-->
                          <!--                          }}-->
                          <v-radio
                            v-on="on"
                            label="Matrix"
                            value="market_product_summary"
                            v-if="combos_list.length > 0"
                          ></v-radio>
                        </template>
                        <span
                          >Click to view results as product and market combinations</span
                        >
                      </v-tooltip>

                      <v-tooltip top color="trade-blue text-subtitle-1" max-width="200px">
                        <template v-slot:activator="{on, attrs}">
                          <v-radio label="List" value="table" v-on="on"></v-radio>
                        </template>
                        <span>Click to view results by resuts as a list</span>
                      </v-tooltip>

                      <v-tooltip top color="trade-blue text-subtitle-1" max-width="200px">
                        <template v-slot:activator="{on, attrs}">
                          <v-radio label="Graph" value="graph" v-on="on"></v-radio>
                        </template>
                        <span>Click to view results as a bar graph</span>
                      </v-tooltip>
                    </v-radio-group></v-col
                  >
                  <v-col>
                    <v-card-actions v-if="which_list.length > 0">
                      <v-btn
                        outlined
                        text
                        class="trade-blue white--text"
                        @click="update_selection"
                        :disabled="hide_apply"
                        :class="{
                          grey: hide_apply,
                        }"
                      >
                        Apply this selection ({{
                          this.which_list.filter(v => v.selected).length
                        }})
                      </v-btn>

                      <v-btn
                        outlined
                        text
                        class="trade-blue white--text mr-2"
                        @click="clear_selection"
                      >
                        Clear this selection
                      </v-btn>
                    </v-card-actions></v-col
                  >
                </v-row>
              </template>

              <p class="mr-4">
                <span
                  v-if="!showlist === 'table' && last_cell_clicked"
                  class="float-right font-weight-bold"
                  >{{ multiplerString }} {{ currencyString }}</span
                >
              </p>
            </div>

            <!-- Graph-->
            <template v-if="showlist === 'graph'">
              <div class="mr-2 outline ml-1 pt-1">
                <v-row no-gutters>
                  <v-col>
                    <div>
                      <v-icon
                        @click="element2clipboard('REO_FULL_GRAPH')"
                        style="float: left"
                        data-html2canvas-ignore
                        class="mr-4 black--text float-right mr-3"
                        >mdi-camera
                      </v-icon>
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <div class="overflow overflow-x-hidden" id="REO_FULL_GRAPH">
                      <vue-plotly
                        id="plotid"
                        v-if="which_list.length > 0"
                        :data="plotData(cube_name)"
                        :layout="layout"
                        :options="options"
                        ref="reoTableRef"
                        class="mr-3"
                      />
                    </div>
                  </v-col>
                </v-row>
              </div>
            </template>

            <!-- List-->
            <template v-if="showlist === 'table' && which_list.length > 0">
              <div class="overflow-y-auto overflow-x-hidden  ml-2 mr-2 outline ">
                <v-container class="ma-0 pa-0 caption">
                  <v-row class="ma-0 pa-0 align-center" no-gutters>
                    <v-col sm="1">
                      <v-btn text @click="download_list()">
                        <v-icon class="green--text mr-2">mdi-microsoft-excel</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col sm="1">
                      <div>
                        <v-btn
                          outlined
                          text
                          x-small
                          class="trade-blue white--text"
                          :class="{green: sort_reo_by.last_clicked === 'code'}"
                          @click="sort_clicked('code')"
                          >{{ sort_reo_by['code'] ? '↓' : '↑' }}
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <v-btn
                        outlined
                        text
                        x-small
                        class="trade-blue white--text  pa-1"
                        :class="{green: sort_reo_by.last_clicked === 'description'}"
                        @click="sort_clicked('description')"
                        >{{ sort_reo_by['description'] ? '↑' : '↓' }}
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn
                        outlined
                        text
                        x-small
                        class="trade-blue white--text float-right mr-3"
                        :class="{green: sort_reo_by.last_clicked === 'reo_monetary_val'}"
                        @click="sort_clicked('reo_monetary_val')"
                        >{{ sort_reo_by['reo_monetary_val'] ? '↓' : '↑' }}
                      </v-btn>
                    </v-col>
                  </v-row>

                  <!--  Select all deselct                -->
                  <v-row class="ma-0 pa-0 align-center" no-gutters>
                    <v-col>
                      <v-tooltip right>
                        <template v-slot:activator="{on, attrs}">
                          <v-btn
                            x-small
                            v-on="on"
                            class="yellow trade-blue--text mt-2 mb-2"
                            @click="select_all"
                          >
                            <v-icon>
                              mdi-check
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Select all</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{on, attrs}">
                          <v-btn
                            x-small
                            v-on="on"
                            class="yellow trade-blue--text mt-2 mb-2 ml-2"
                            @click="unselect_all"
                          >
                            <v-icon>
                              mdi-close
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Unselect all</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>

                <p
                  v-for="(applied_list, ix) in which_list"
                  :key="applied_list.id"
                  class="ma-0 pa-0 trade-blue-text caption ma-1 listItem"
                >
                  <v-container class="ma-0 pa-0 caption">
                    <v-row class="ma-0 pa-0 align-center" no-gutters>
                      <v-col cols="2">
                        <div @click="handle_item_click(applied_list)">
                          <v-checkbox
                            hide-details
                            v-model="applied_list.selected"
                            class="shrink small mr-1 ma-0 pa-0 d-inline-block no-gutters"
                          >
                            <template v-slot:label class="hidden-md">
                              <span style="font-size: 13px; margin-top:3px"
                                >{{ ix + 1 }}-{{ applied_list.code }}</span
                              >
                            </template>
                          </v-checkbox>
                        </div>
                      </v-col>
                      <v-col cols="9">
                        <!--                        <span class="overflow-hidden">{{-->
                        <!--                          applied_list.description-->
                        <!--                        }}</span>-->
                        <span
                          class="overflow-hidden"
                          v-html="highlight(applied_list.description, searchkey)"
                          >{{ applied_list.description }}</span
                        >
                      </v-col>
                      <v-col cols="1" class="grey lighten-3">
                        <span class="float-right mr-3">{{ applied_list.reo_val }}</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </p>
              </div>
            </template>

            <!--            // matrix-->
            <template
              v-if="showlist === 'market_product_summary' && combos_list.length > 0"
            >
              <div class="ml-1 outline mr-1 pt-1 pa-1">
                <reo-market-product-matrix
                  :combos_list="combos_list"
                  :market_count="mpmc_unique_markets().length"
                  :product_count="mpmc_unique_products().length"
                  :val_monetary="get_cell_name()['val_monetary']"
                ></reo-market-product-matrix>
              </div>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VuePlotly from '@statnett/vue-plotly';
import reoTd from '@/components/trade-dsm-application/result-components/reo_table/reo-td';
import calc_reo_table_values from '@/components/trade-dsm-application/mixins/calc_reo_table_values';
import {mapGetters, mapMutations} from 'vuex';
import {config} from '@/store/config';
import swal from 'sweetalert';
import {eventBus} from '@/main';
const _ = require('lodash');
import filterHelpers from '@/components/trade-dsm-application/mixins/filter-helpers';
import reoMarketProductMatrix from '@/components/trade-dsm-application/result-components/reo_table/reo-market-product-matrix.vue';
import {csvExportREO} from '@/components/application-level/js_functions/jsFunctions';
import helpLink from '@/components/application-level/help-system/help-link.vue';

export default {
  name: 'reo-frontend-table',
  props: ['cube_name'],
  mixins: [calc_reo_table_values, filterHelpers],
  components: {
    helpLink,
    reoTd,
    reoMarketProductMatrix,
    VuePlotly,
  },
  async mounted() {
    if (this.$store.state.tradeDSM.last_clicked_REO_map_cell !== null) {
      if (this.$store.state.tradeDSM.last_clicked_REO_map_cell.cell !== '') {
        console.log(this.$store.state.tradeDSM.last_clicked_REO_map_cell.cell);

        this.last_cell_clicked = this.$store.state.tradeDSM.last_clicked_REO_map_cell.cell;
      }
    }

    await eventBus.$on('CLEAR_REO_TABLE', async () => {
      ///this event is triggered by the left sidebar to clear the reo table selections
      this.last_cell_clicked = '';
      this.$store.state.tradeDSM.last_clicked_REO_map_cell = null;
      this.$forceUpdate();
    });

    await eventBus.$on('RUN_REO_MPMC_SOLVE_NO_MOUNT', async () => {
      // this event is triggered by main.js -"update_results_cube" to ensure that the MPMC table is rendered.
      // only do this for MPMC since for 1PMC and MP1C the listing of products and markets is availabel since the
      // cube was valid and the listing is available. MPMC is concerend about the combinations so we have to
      // look at them seperately
      // await this.update_MPMC_cube_data(true);

      // await this.run_current_selection_new();
      await this.update_MPMC_cube_data(false);
    });

    if (this.current_report_to_run === '1PMC' || this.current_report_to_run === 'MP1C') {
      // only run this if mounted and 1PMC or MP1C
      await this.run_current_selection_new();
    }
  },
  data: function() {
    return {
      MPMC_matrix_is_updating: false, // loading matrix flag
      show_REO_totals: false,
      searchkey: '',
      dummy_key: 0,
      sort_reo_by: {
        last_clicked: 'reo_monetary_val', // can be hs6_code_up,  hs6_description_up, value_up
        code: true,
        description: true,
        reo_monetary_val: true,
      },
      number_selected: 0,
      last_cell_clicked: '',
      last_clicked_REO_col: [],
      last_clicked_REO_row: [],

      eventlister_set: false,
      hover_val: null,
      showlist: 'table',
      clickedVals: {
        markets: [],
        products: [],
      },
      current_unselected_status: {
        markets: [],
        products: [],
        combinations: [],
      },
      last_mpmc_results: [],
      options: {
        responsive: true,
        displayModeBar: false,
      },
    };
  },
  updated() {
    // wait for the element (this would be all graphs on this page) to be updated, then attach the mouseover event
    this.$nextTick(function() {
      this.attach_mouseover();
    });
  },

  computed: {
    ...mapGetters(['is_run_cube_valid']),

    // cube_changed() {
    //   return this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections;
    // },

    combinations() {
      return this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.combinations
        .combinations;
    },
    hide_apply() {
      if (this.cube_name === 'cube') {
        // markets only
        return this.market_list.length === this.current_unselected_status.markets.length;
      } else if (this.cube_name === 'cube_HS6_Code') {
        return (
          this.products_list.length === this.current_unselected_status.products.length
        );
      } else {
        return (
          this.combos_list.length === this.current_unselected_status.combinations.length
        );
      }
    },
    show_unselect_all() {
      let values = this.market_list;
      return values.length === values.filter(v => v.selected).length;
    },
    show_reo() {
      return (
        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.combinations
          .combinations > 0 &&
        this.$store.state.tradeData.report_selection === 'EMF_Overview'
      );
    },
    calculate_graph_height() {
      // Calculate dynamic graph height based on the number of entries.
      let len = this.which_list.filter(v => v.selected).length;
      let max = 2400;
      let height_per_item = parseInt(max / 170);
      return len * height_per_item + 100;
    },
    //
    products_and_markets_count_string() {
      let markets_count = 1;
      let products_count = 1;
      if (this.current_report_to_run === 'MPMC' && this.is_run_cube_valid) {
        markets_count = this.mpmc_unique_markets().length;
        products_count = this.mpmc_unique_products().length;
      } else {
        try {
          markets_count = this.market_list.length;
          markets_count = this.market_list.length;
          products_count = this.products_list.length;
        } catch (e) {
          console.log(e);
          console.log('no market list/product list length');
        }
      }
      return `Markets(${markets_count}) x Products(${products_count})`;
    },
    //

    //
    show_reo_click_area() {
      // alert( this.is_run_cube_valid)
      return (
        this.current_report_to_run === '1PMC' ||
        this.current_report_to_run === 'MPMC' ||
        (this.current_report_to_run === 'MP1C' && this.is_run_cube_valid)
      );
    },
    //
    which_list() {
      // decides which list to use based on the view - solved or not?
      if (this.cube_name === 'cube') {
        //markets obly
        let return_list = this.market_list;
        if (return_list.length > 0) {
          return_list.forEach(v => {
            if (this.current_unselected_status.markets.includes(v.code)) {
              v.selected = false;
            } else {
              v.selected = true;
            }
          });
        }
        return return_list;
      } else if (this.cube_name === 'cube_HS6_Code') {
        //products only
        let return_list = this.products_list;
        if (return_list.length > 0) {
          return_list.forEach(v => {
            if (this.current_unselected_status.products.includes(v.code)) {
              v.selected = false;
            } else {
              v.selected = true;
            }
          });
        }
        return return_list;
      } else {
        //combintaions
        let return_list = this.combos_list;
        if (return_list.length > 0) {
          return_list.forEach(v => {
            if (this.current_unselected_status.combinations.includes(v.code)) {
              v.selected = false;
            } else {
              v.selected = true;
            }
          });
        }
        return return_list;
      }
    },
    //
    combos_list() {
      let vals = this.last_mpmc_results.map(v => {
        return {
          code: v.HS6_Code + '/' + v['Target_Market'],
          description:
            this.codeToMarket(v['Target_Market']) +
            '/' +
            this.hs6_codeToDescription(v.HS6_Code),
          reo_monetary_val: v.Avg6Major,
          reo_val: this.vFmt(v.Avg6Major * this.value_convertion, 2),
          selected: true,
          product: v.HS6_Code,
          market: v['Target_Market'],
        };
      });

      let v = this.sort_reo_by.last_clicked;
      let up = this.sort_reo_by[v];

      return vals.sort(function(a, b) {
        if (up === false) {
          if (a[v] < b[v]) {
            return -1;
          }
          if (a[v] > b[v]) {
            return 1;
          }
        }
        if (up === true) {
          if (a[v] > b[v]) {
            return -1;
          }
          if (a[v] < b[v]) {
            return 1;
          }
        }
        return 0;
      });
    },
    products_list() {
      if (this.clickedVals.products.length > 0 && this.show_reo_click_area) {
        let products = this.clickedVals.products.map(v => {
          return {
            code: v,
            description: this.hs6_codeToDescription(v),
            reo_monetary_val: this.calc_reo_data(this.cube_name)['reo_values'][v],
            reo_val: this.vFmt(
              this.calc_reo_data(this.cube_name)['reo_values'][v] * this.value_convertion,
              2,
            ),
            selected: true,
          };
        });
        let v = this.sort_reo_by.last_clicked;
        let up = this.sort_reo_by[v];

        return products.sort(function(a, b) {
          if (up === false) {
            if (a[v] < b[v]) {
              return -1;
            }
            if (a[v] > b[v]) {
              return 1;
            }
          }
          if (up === true) {
            if (a[v] > b[v]) {
              return -1;
            }
            if (a[v] < b[v]) {
              return 1;
            }
          }

          return 0;
        });
      } else {
        return [];
      }
    },
    market_list() {
      if (this.clickedVals.markets.length > 0 && this.show_reo_click_area) {
        let markets = this.clickedVals.markets
          .filter(
            //remove home market
            c_code =>
              c_code !== this.marketToCode(this.$store.state.app.selectedHomeCountry),
          )
          .map(c_code => {
            // console.log(c_code);
            return {
              code: c_code,
              description: this.codeToMarket(c_code),
              reo_monetary_val: this.calc_reo_data(this.cube_name)['reo_values'][c_code],
              reo_val: this.vFmt(
                this.calc_reo_data(this.cube_name)['reo_values'][c_code] *
                  this.value_convertion,
                2,
              ),
              selected: true,
            };
          });
        let v = this.sort_reo_by.last_clicked;
        let up = this.sort_reo_by[v];

        return markets.sort(function(a, b) {
          if (up === false) {
            if (a[v] < b[v]) {
              return -1;
            }
            if (a[v] > b[v]) {
              return 1;
            }
          }
          if (up === true) {
            if (a[v] > b[v]) {
              return -1;
            }
            if (a[v] < b[v]) {
              return 1;
            }
          }
          return 0;
        });
      } else {
        return [];
      }
    },

    layout: function() {
      return {
        title: {
          automargin: false,
          text: `<b>Selected realistic export opportunities: </b> <br>${
            this.multiplerString
          } ${this.currencyString}`,
          font: {
            size: 15,
            color: 'black',
          },
          yref: 'paper',
          x: 0.1,
          y: 1.45,
          yanchor: 'bottom',
          pad: {
            b: 10,
          },
        },
        xaxis: {
          side: 'top',
          tickfont: 10,
          xaxis_range: [0, 15],
          font: {
            size: 10,
            color: 'black',
          },
        },
        yaxis: {
          tickmode: 'linear',
          autorange: 'reversed',
          tickfont: 8,
          font: {
            size: 8,
            color: 'black',
          },
        },
        autosize: true,
        height: this.calculate_graph_height,

        margin: {
          l: this.cube_name === 'cube_HS6_Code' ? 90 : 150,
          r:
            this.cube_name === 'cube_HS6_Code' || this.current_report_to_run === 'MPMC'
              ? 500
              : 50,
          b: 10,
          t: 80,
          pad: 4,
        },
        annotations: !(this.current_report_to_run === '1PMC')
          ? this.which_list
              .filter(v => v.selected)
              .map((code, i) => {
                return {
                  x: 1,
                  y: i - 0.6,
                  xref: 'paper',
                  yref: 'y',
                  xanchor: 'left',
                  yanchor: 'top',
                  text: this.graph_string(code),
                  showarrow: false,
                  font: {
                    size: 10,
                    color: 'black',
                  },
                };
              })
          : [],
      };
    },
  },
  beforeDestroy() {
    this.$store.state.tradeData.report_selection = 'EMF_Overview';
  },
  methods: {
    ...mapMutations(['clear_circ_ref_error']),

    calc_value(v) {
      return this.vFmt(v * this.value_convertion, 1);
    },

    async run_current_selection_new() {
      // Function to set the REO table state when this modle is mounted
      // Make sure that a mount occurs otherwise this code will not run
      // Test the store variable to see if a click was made or if the cube solved naturaly
      if (this.$store.state.tradeDSM.last_clicked_REO_map_cell !== null) {
        if ('cell' in this.$store.state.tradeDSM.last_clicked_REO_map_cell) {
          this.last_cell_clicked = this.$store.state.tradeDSM.last_clicked_REO_map_cell[
            'cell'
          ];
        }
      }

      // NON REO clicked
      // This is a legit click and we can overide the REO row/col filtrs whatever they might have been
      if (
        this.last_cell_clicked === 'T1_0' ||
        this.last_cell_clicked === 'T2_0' ||
        this.last_cell_clicked === 'T3_0' ||
        this.last_cell_clicked === 'T5_0' ||
        this.last_cell_clicked === 'TT_0' ||
        this.last_cell_clicked === 'TT1_TT' ||
        this.last_cell_clicked === 'TT2_TT' ||
        this.last_cell_clicked === 'TT3_TT' ||
        this.last_cell_clicked === 'TT_TT'
      ) {
        // This is a legit click and we can overide the REO row/col filtrs whatever they might have been
        await this.total_non_reo_cells_clicked(
          this.$store.state.tradeDSM.last_clicked_REO_map_cell['REO_col'],
          this.last_cell_clicked,
        );
      } else {
        // REO cells and totals except last two rows
        // only run this if the run qube is valid, otherwise we are still at the original cube
        if (this.is_run_cube_valid) {
          if (this.$store.state.tradeDSM.last_clicked_REO_map_cell !== null) {
            // If a click was made
            // we can overide the current REO row/col filters as a click was made
            this.last_cell_clicked = this.$store.state.tradeDSM.last_clicked_REO_map_cell[
              'cell'
            ];

            // since this was a click we come back here but the last clicked values do not know
            // yet wat the new set of markets and products is
            // should this not only be for 1pmc and mp1c
            let markets = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
              .query_results.countries;
            let products = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
              .query_results.products;

            this.$store.state.tradeDSM.last_clicked_REO_map_cell['arg'] = {
              markets: markets,
              products: products,
            };

            this.clickedVals = this.$store.state.tradeDSM.last_clicked_REO_map_cell[
              'arg'
            ];

            await this.cellClicked(
              this.$store.state.tradeDSM.last_clicked_REO_map_cell['arg'],
              this.$store.state.tradeDSM.last_clicked_REO_map_cell['cell'],
              this.$store.state.tradeDSM.last_clicked_REO_map_cell['REO_row'],
              this.$store.state.tradeDSM.last_clicked_REO_map_cell['REO_col'],
              true,
              true,
            );
          } else {
            // If a natural solve occured
            // If we get here we should not overide the REO rows and cols
            this.last_cell_clicked = null;
            if (this.current_report_to_run === 'MPMC') {
              // for MPMC we run below
              await this.cellClicked(
                {markets: [], products: []},
                'empty',
                [],
                [],
                true,
                false, // these are not clicks, so dont overide the REO vals in
              );
            } else {
              // for 1PMC and MP1C we run below
              // we buikd up the markets and products list needed for 1PMC and MP1C and send with cell clicked
              // const decide = 1;

              let markets = [];
              let products = [];

              // if (decide === 1) {
              // use the cube values
              markets = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .query_results.countries;
              products = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                .query_results.products;

              await this.cellClicked(
                {
                  markets: markets,
                  products: products,
                },
                'empty',
                [],
                [],
                true,
                false, // these are not clicks, so dont overide the REO vals in
              );
            }
          }

          // make the last clicked place holder null gain to wait for next event.
          this.$store.state.tradeDSM.last_clicked_REO_map_cell = null;
        }
      }
    },

    // async run_current_selection() {
    //   // does the auto trigger of the REO map with the Matrix(MPMC), List(All views), Graph (All views)
    //   if (this.is_run_cube_valid) {
    //     if (this.current_report_to_run === 'MPMC') {
    //       await this.update_MPMC_cube_data();
    //     } else {
    //       // only triggers if the currnent run cube is below the set limits (combinations)
    //
    //       // make a copy of the currently selected REO columns
    //       let REO_col = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
    //         .selections.reo_clicked.REO_col;
    //
    //       // make a copy of the currently selected REO rows
    //       let REO_row = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
    //         .selections.reo_clicked.REO_row;
    //
    //       const cell_info = this.get_cell_name();
    //       let text_name_original = cell_info['text_name_original']; // this is the long name with column and row descriptions
    //       let key = cell_info['lookup_key']; // e.g 1_T, 2_T etc
    //
    //       let markets = [];
    //       let products = [];
    //       let cell_obj = null;
    //
    //       try {
    //         cell_obj = this.calc_reo_data(this.cube_name)[key]; // get the info of the cell we are looking at products, markets etc
    //         markets = 'markets' in cell_obj ? cell_obj['markets'] : []; // get the markets attached to this cell
    //         products = 'products' in cell_obj ? cell_obj['products'] : []; // get the products attached to this cell
    //       } catch (error) {
    //         console.table(error);
    //       }
    //
    //       await this.cellClicked(
    //         // pretent now to click this cell and pass the markets and products to the click
    //         {
    //           markets: markets,
    //           products: products,
    //         },
    //         text_name_original,
    //         REO_row, // copy of the currently selected row
    //         REO_col, // copy of the currently selected col
    //         true, // we force the click becuse nothing was clicked at this point, so nothing to capare it agains
    //       );
    //     }
    //   }
    // },
    get_cell_name() {
      // let REO_col = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
      //   .selections.reo_clicked.REO_col;
      //
      // let REO_row = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
      //   .selections.reo_clicked.REO_row;

      let REO_col = this.last_clicked_REO_col;
      let REO_row = this.last_clicked_REO_row;

      let col_key = REO_col.toString();
      if (col_key.length > 1) {
        col_key = 'total';
      }

      let row_key = REO_row.toString();
      if (row_key.length > 1) {
        row_key = 'total';
      }

      if (
        this.last_cell_clicked.includes('Total') &&
        this.last_cell_clicked.includes('Product x Country')
      ) {
        col_key = 'total';
      }
      if (
        this.last_cell_clicked.includes('Total') &&
        this.last_cell_clicked.includes('Home market')
      ) {
        row_key = 'total';
      }

      if (this.last_cell_clicked.includes('All')) {
        col_key = 'total';
        row_key = 'total';
      }

      let key = col_key + '_' + row_key;

      let cell_vals = {
        '1_1': 'Product x Country - Large | Home market - Small',
        '2_1': 'Product x Country - Large | Home market - Intermediate - Small',
        '3_1': 'Product x Country - Large | Home market - Intermediate - Large',
        '4_1': 'Product x Country - Large | Home market - Large',
        total_1: 'Product x Country - Large - Total',
        //
        '1_2': 'Product x Country - Growing - Short & Long term | Home market - Small',
        '2_2':
          'Product x Country - Growing - Short & Long term | Home market - Intermediate - Small',
        '3_2':
          'Product x Country - Growing - Short & Long term | Home market - Intermediate - Large',
        '4_2': 'Product x Country - Growing - Short & Long term | Home market - Large',
        total_2: 'Product x Country - Growing - Short & Long term - Total',
        //
        '1_3': 'Product x Country - Large and Growing - Short term | Home market - Small',
        '2_3':
          'Product x Country - Large and Growing - Short term | Home market - Intermediate - Small',
        '3_3':
          'Product x Country - Large and Growing - Short term | Home market - Intermediate - Large',
        '4_3':
          'Product x Country - Large and Growing - Short term |  Home market - Large',
        total_3: 'Product x Country - Large and Growing - Short term - Total',
        //
        '1_4': 'Product x Country - Large and Growing - Long term |  Home market - Small',
        '2_4':
          'Product x Country - Large and Growing - Long term |  Home market - Intermediate - Small',
        '3_4':
          'Product x Country - Large and Growing - Long term |  Home market - Intermediate - Large',
        '4_4': 'Product x Country - Large and Growing - Long term |  Home market - Large',
        total_4: 'Product x Country - Large and Growing - Long term - Total',
        //
        '1_5':
          'Product x Country - Large and Growing - Short and Long term |  Home market - Small',
        '2_5':
          'Product x Country - Large and Growing - Short and Long term |  Home market - Intermediate - Small',
        '3_5':
          'Product x Country - Large and Growing - Short and Long term |  Home market - Intermediate - Large',
        '4_5':
          'Product x Country - Large and Growing - Short and Long term |  Home market - Large',
        total_5: 'Product x Country - Large and Growing - Short and Long term - Total',
        //
        // Total Row
        '1_total': 'Home market - Small - Total',
        '2_total': 'Home market - Intermediate Small - Total',
        '3_total': 'Home market - Intermediate Large - Total',
        '4_total': 'Home market - Large - Total',
        _: 'All opportunities',
      };

      let product_market_selection_text = {
        1: 'Large (y1)',
        2: 'Growing (y2) (Short & Long term)',
        3: 'Large and growing (y4) (Long term)',
        4: 'Large and growing (y4) (Long term)',
        5: 'Large and growing (y4) (Short & Long term)',
        total: 'Column Totals',
      };

      let home_market_selection_text = {
        1: 'Small (x1)',
        2: 'Intermediate - Small (x2)',
        3: 'Intermediate - Large (x3)',
        4: 'Large (x4)',
        total: 'Row Totals',
      };

      let return_key = key;

      if (return_key === 'total_1') {
        return_key = '1_T';
      }
      if (return_key === 'total_2') {
        return_key = '2_T';
      }
      if (return_key === 'total_3') {
        return_key = '3_T';
      }
      if (return_key === 'total_4') {
        return_key = '4_T';
      }
      if (return_key === 'total_5') {
        return_key = '5_T';
      }
      if (return_key === '_') {
        return_key = 'T_T';
      }

      if (return_key === '1_total') {
        return_key = 'T_1';
      }
      if (return_key === '2_total') {
        return_key = 'T_2';
      }
      if (return_key === '3_total') {
        return_key = 'T_3';
      }
      if (return_key === '4_total') {
        return_key = 'T_4';
      }

      //get the cell value
      let val_monetary = 0;

      try {
        val_monetary = this.calc_value(
          this.calc_reo_data(this.cube_name)[
            return_key === 'total_total' ? 'T_T' : return_key
          ]['val_monetary'],
        );
      } catch (e) {
        console.log('error was handled');
        console.log(e);
        val_monetary = 0;
      }

      return {
        text_name_original: cell_vals[key],
        product_market_selection_text:
          product_market_selection_text[row_key] || 'Column Totals',
        home_market_selection_text: home_market_selection_text[col_key] || 'Row Totals',
        lookup_key: return_key,
        val_monetary: val_monetary,
      };
    },

    download_list() {
      csvExportREO('export_reo_data.csv', this.which_list);
    },
    handle_item_click(item) {
      console.log(item);

      if (this.cube_name === 'cube') {
        // markets only
        if (!item.selected) {
          this.current_unselected_status.markets.push(item.code);
          this.current_unselected_status.markets = _.uniq(
            this.current_unselected_status.markets,
          );
        } else {
          this.current_unselected_status.markets.splice(
            this.current_unselected_status.markets.indexOf(item.code),
            1,
          );
        }
      } else if (this.cube_name === 'cube_HS6_Code') {
        //products
        if (!item.selected) {
          this.current_unselected_status.products.push(item.code);
          this.current_unselected_status.products = _.uniq(
            this.current_unselected_status.products,
          );
        } else {
          this.current_unselected_status.products.splice(
            this.current_unselected_status.products.indexOf(item.code),
            1,
          );
        }
      } else {
        //combos
        console.log(item);
        if (!item.selected) {
          this.current_unselected_status.markets.push(item.market);
          this.current_unselected_status.products.push(item.product);
          this.current_unselected_status.combinations.push(item.code);
          this.current_unselected_status.combinations = _.uniq(
            this.current_unselected_status.combinations,
          );
        } else {
          this.current_unselected_status.markets.splice(
            this.current_unselected_status.markets.indexOf(item.market),
            1,
          );
          this.current_unselected_status.products.splice(
            this.current_unselected_status.products.indexOf(item.product),
            1,
          );
          this.current_unselected_status.combinations.splice(
            this.current_unselected_status.combinations.indexOf(item.code),
            1,
          );
        }
      }
    },
    unselect_all() {
      this.current_unselected_status.markets = [];
      this.current_unselected_status.products = [];
      this.current_unselected_status.combinations = [];

      if (this.cube_name === 'cube') {
        // markets only
        this.market_list.forEach(item => {
          this.current_unselected_status.markets.push(item.code);
        });
      } else if (this.cube_name === 'cube_HS6_Code') {
        // markets only
        this.products_list.forEach(item => {
          this.current_unselected_status.products.push(item.code);
        });
      } else {
        //combos
        this.combos_list.forEach(item => {
          this.current_unselected_status.markets.push(item.market);
        });
        this.combos_list.forEach(item => {
          this.current_unselected_status.products.push(item.product);
        });
        this.combos_list.forEach(item => {
          this.current_unselected_status.combinations.push(item.code);
        });
      }
    },
    select_all() {
      if (this.cube_name === 'cube') {
        // markets only
        this.current_unselected_status.markets.splice(0);
      } else if (this.cube_name === 'cube_HS6_Code') {
        // markets only
        this.current_unselected_status.products.splice(0);
        return [];
      } else {
        //combos
        this.current_unselected_status.markets.splice(0);
        this.current_unselected_status.products.splice(0);
        this.current_unselected_status.combinations.splice(0);
      }
    },
    mpmc_unique_markets() {
      return _.uniq(this.combos_list.filter(v => v.selected === true).map(v => v.market));
    },
    //
    mpmc_unique_products() {
      return _.uniq(
        this.combos_list.filter(v => v.selected === true).map(v => v.product),
      );
    },

    graph_string(code) {
      if (this.current_report_to_run === 'MPMC') {
        return (
          code.market +
          '-' +
          this.codeToMarket(code.market) +
          ' - ' +
          this.hs6_codeToDescription(code.product)
        );
      } else if (this.cube_name === 'cube_HS6_Code') {
        return code.code + '-' + this.hs6_codeToDescription(code.code);
      } else if (this.current_report_to_run === '1PMC') {
        return [];
      }
    },
    plotData: function(cube_name) {
      if (this.show_reo_click_area === true) {
        let vals = [];
        let y = [];
        if (cube_name === 'cube_HS6_Code') {
          vals = this.get_product_list();
          y = vals.filter(v => v.selected).map(v => v.code);
        } else if (cube_name === 'cube') {
          vals = this.get_market_list();
          y = vals.filter(v => v.selected).map(v => this.codeToMarket(v.code));
        } else if (this.which_list.length > 0) {
          vals = this.combos_list;
          y = vals.filter(v => v.selected).map(v => v.code);
        }
        return [
          {
            type: 'bar',
            y: y,
            x: vals
              .filter(v => v.selected)
              .map(v => this.value_convertion * v.reo_monetary_val),
            orientation: 'h',
            marker: {
              color: config.plot_bar_green,
            },
          },
        ];
      } else return [];
    },

    market_or_product() {
      if (
        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.markets
          .C_Code.length === 1
      ) {
        return this.codeToMarket(
          this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.markets
            .C_Code[0],
        );
      } else if (
        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.products
          .HS6_Code.length === 1
      ) {
        return this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections
          .products.HS6_Code[0];
      }
    },
    async take_reo_selfie() {
      await this.clear_selection();
      await this.element2clipboard('REO_FULL_TABLE', true);
    },
    sort_clicked(event) {
      this.sort_reo_by.last_clicked = event;
      // if (this.combos_list.length > 0) {
      //   this.sort_reo_by.last_clicked = event;
      // }
      this.sort_reo_by[this.sort_reo_by.last_clicked] = !this.sort_reo_by[
        this.sort_reo_by.last_clicked
      ];
    },
    get_product_list() {
      if (this.cube_name === 'cube_HS6_Code') {
        return this.products_list;
      } else return [];
    },
    get_market_list() {
      if (this.cube_name === 'cube') {
        return this.market_list;
      } else return [];
    },
    async update_MPMC_cube_data(set_REO_filters = false) {
      if (this.current_report_to_run === 'MPMC' && this.is_run_cube_valid) {
        // only for the case of MPMC where it is solved now need to get the info?

        if (set_REO_filters) {
          // when te reo table renders and the click is forces, then there were no changes to the REO_Row and Cols
          // so dont update them.
          this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.reo_clicked.REO_col = this.last_clicked_REO_col;
          this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.reo_clicked.REO_row = this.last_clicked_REO_row;
        }

        this.clear_circ_ref_error();

        let data = {
          META: this.$store.state.tradeDSM.dsm_filter_setttings.Filters,
        };

        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.advanced_filters_is_dirty = true;
        swal({
          text: 'Updating REO selection Cube',
          buttons: false,
          closeOnClickOutside: false,
          icon: 'info',
          className: 'swal-modal',
          closeOnEsc: false,
        });

        await this.axios
          .post(this.$store.getters.baseUrl + 'get_reo_markets_products/', data)
          .then(response => {
            this.last_mpmc_results = response.data['row_col_combinations'];
            this.showlist = 'market_product_summary';
            swal.close();
          })
          .catch(e => {
            swal.close();
            this.market_results = [];
            this.product_results = [];
            this.last_clicked = null;
            alert('reo_clicked_error' + e);
            console.log(e);
            // swal.close();
          });
      }
    },

    async total_non_reo_cells_clicked(col, cell_name) {
      this.last_clicked_REO_col = col;
      this.last_clicked_REO_row = [0, -1, -2];
      this.last_cell_clicked = cell_name;

      let data = this.calc_reo_data(this.cube_name)[cell_name];
      this.clickedVals.products = data.products;
      this.clickedVals.markets = data.markets;

      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.reo_clicked.REO_col = this.last_clicked_REO_col;
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.reo_clicked.REO_row = this.last_clicked_REO_row;

      this.$store.state.tradeDSM.last_clicked_REO_map_cell = {
        arg: {markets: this.clickedVals.markets, products: this.clickedVals.products},
        cell: cell_name,
        REO_row: this.last_clicked_REO_row,
        REO_col: this.last_clicked_REO_col,
        force_run: false,
      };

      if (
        // Run a normal unsolved cube for these
        // this will make a selection
        (this.current_report_to_run === 'MPMC' ||
          this.current_report_to_run === '1PMC' ||
          this.current_report_to_run === 'MP1C') &&
        !this.is_run_cube_valid
      ) {
        await this.update_results_cube();
      } else {
        // there was a normal click in the solved cube
        await this.update_MPMC_cube_data();
      }
    },
    async grand_total_cells_clicked(col, cell_name) {
      this.last_clicked_REO_col = col;
      this.last_clicked_REO_row = [0, -1, -2, 1, 2, 3, 4, 5];
      this.last_cell_clicked = cell_name;
      let data = this.calc_reo_data(this.cube_name)[cell_name];
      this.clickedVals.products = data.products;
      this.clickedVals.markets = data.markets;
      // alert(this.last_cell_clicked);
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.reo_clicked.REO_col = this.last_clicked_REO_col;
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.reo_clicked.REO_row = this.last_clicked_REO_row;

      this.$store.state.tradeDSM.last_clicked_REO_map_cell = {
        arg: {markets: this.clickedVals.markets, products: this.clickedVals.products},
        cell: cell_name,
        REO_row: this.last_clicked_REO_row,
        REO_col: this.last_clicked_REO_col,
        force_run: false,
      };

      if (
        // Run a normal unsolved cube for these
        // this will make a selection
        (this.current_report_to_run === 'MPMC' ||
          this.current_report_to_run === '1PMC' ||
          this.current_report_to_run === 'MP1C') &&
        !this.is_run_cube_valid
      ) {
        await this.update_results_cube();
      } else {
        // there was a normal click in the solved cube
        await this.update_MPMC_cube_data();
      }
    },
    async cellClicked(
      arg,
      cell,
      REO_row = null,
      REO_col = null,
      force_run = false,
      set_REO_filters = true,
    ) {
      console.log(arg);
      console.log(cell);
      console.log(REO_col);
      console.log(REO_row);
      console.log(force_run);
      console.log(set_REO_filters);

      if (this.last_cell_clicked !== cell || force_run) {
        //avoid a second click
        this.$store.state.tradeDSM.last_clicked_REO_map_cell = {
          arg: arg,
          cell: cell,
          REO_row: REO_row,
          REO_col: REO_col,
          force_run: force_run,
        };

        if (cell) {
          this.last_cell_clicked = cell;
          this.clickedVals = arg;
        }
        this.last_clicked = cell;

        this.current_unselected_status.markets = [];
        this.current_unselected_status.products = [];
        this.current_unselected_status.combinations = [];

        if (REO_row) {
          this.last_clicked_REO_row = REO_row;
        }
        if (REO_col) {
          this.last_clicked_REO_col = REO_col;
        }

        let cube = this.calc_reo_data(this.cube_name);

        if (cell === 'All opportunities') {
          let rows = [];
          let cols = [];
          Object.keys(cube).forEach(v => {
            let val = cube[v];
            if (val.val > 0 && !v.includes('T')) {
              rows.push(parseInt(v.slice(2, 3)));
              cols.push(parseInt(v.slice(0, 1)));
            }
          });
          rows = _.uniq(rows);
          cols = _.uniq(cols);
          this.last_clicked_REO_row = rows; //[3]
          this.last_clicked_REO_col = cols; //[1,2]
        }

        if (cell.includes('Total')) {
          // Row or col
          if (cell.includes('Home')) {
            let prefix = this.last_clicked_REO_col + '_';
            let rows = [];
            console.log(` Col clicked = ${prefix}`);
            console.log(cube);
            if (Object.keys(cube).includes(prefix + '1')) {
              rows.push(1);
            }
            if (Object.keys(cube).includes(prefix + '2')) {
              rows.push(2);
            }
            if (Object.keys(cube).includes(prefix + '3')) {
              rows.push(3);
            }
            if (Object.keys(cube).includes(prefix + '4')) {
              rows.push(4);
            }
            if (Object.keys(cube).includes(prefix + '5')) {
              rows.push(5);
            }

            this.last_clicked_REO_row = rows;
          } else if (cell.includes('Country')) {
            // now calc wich cols
            let prefix = '_' + this.last_clicked_REO_row;
            let cols = [];
            if (Object.keys(cube).includes('1' + prefix)) {
              cols.push(1);
            }
            if (Object.keys(cube).includes('2' + prefix)) {
              cols.push(2);
            }
            if (Object.keys(cube).includes('3' + prefix)) {
              cols.push(3);
            }
            if (Object.keys(cube).includes('4' + prefix)) {
              cols.push(4);
            }

            this.last_clicked_REO_col = cols;
          }
        }

        this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.advanced_filters_is_dirty = true;
        if (
          // Run a normal unsolved cube for these
          // this will make a selection
          (this.current_report_to_run === 'MPMC' ||
            this.current_report_to_run === '1PMC' ||
            this.current_report_to_run === 'MP1C') &&
          !this.is_run_cube_valid
        ) {
          this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.reo_clicked.REO_col = this.last_clicked_REO_col;
          this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.reo_clicked.REO_row = this.last_clicked_REO_row;

          await this.clear_circ_ref_error();
          await this.update_results_cube();
          this.last_cell_clicked = cell;
          await this.clear_circ_ref_error();
        } else {
          // there was a normal click in the solved cube
          await this.update_MPMC_cube_data(set_REO_filters);
        }
      }
    },
    async clear_selection() {
      this.last_cell_clicked = '';
      this.clickedVals = {
        markets: [],
        products: [],
      };
      this.showlist = false;
      this.last_mpmc_results = [];
    },
    async update_selection() {
      await this.clear_circ_ref_error();
      let list_to_apply_markets = _.uniq(
        this.market_list.filter(v => v.selected === true).map(v => v.code),
      );
      let list_to_apply_products = _.uniq(
        this.products_list.filter(v => v.selected === true).map(v => v.code),
      );

      let transaction_markets = [];
      let transaction_products = [];

      //deal with MPMC
      if (this.combos_list.length > 0) {
        console.log('Dealing with MPMC');
        transaction_markets = this.mpmc_unique_markets();
        transaction_products = this.mpmc_unique_products();
      } else if (list_to_apply_products.length > 0 || list_to_apply_markets.length > 0) {
        console.log('1PMC and MC1P Case');
        transaction_markets = list_to_apply_markets;
        transaction_products = list_to_apply_products;
      }

      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.markets.C_Code = transaction_markets;
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.products.HS6_Code = transaction_products;

      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.reo_clicked.REO_col = this.last_clicked_REO_col;
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.reo_clicked.REO_row = this.last_clicked_REO_row;

      await this.clear_circ_ref_error();
      await this.update_results_cube();
      // await this.run_current_selection();
      // await this.clear_circ_ref_error();
    },
    attach_mouseover() {
      if (this.is_run_cube_valid === true) {
        let _ = this;
        if (this.$refs.reoTableRef !== undefined) {
          let myPlot = document.getElementById('plotid');
          myPlot
            .once('plotly_hover', function(data) {
              var infotext = data.points.map(function(d) {
                return {
                  hover_code: d.y,
                  hover_val: d.x,
                };
              });
              _.hover_val = infotext[0]['hover_code'];
            })
            .once('plotly_unhover', function() {
              _.hover_val = null;
            });
        }
      }
    },
  },
};
</script>

<style scoped>
table {
  margin: 0 auto;
}

table,
td,
th {
  border: 2px solid #c00000;
  border-collapse: collapse;
  color: grey;
}

td,
th {
  padding: 3px;
  width: 200px;
  height: 25px;
  text-align: center;
}

th {
  background: #f0e6cc;
}

.rotate-text {
  transform: rotate(-90deg);
  height: 200px;
  width: 50px;
}

.noborder-topright {
  background: white;
  border-top: hidden !important;
  border-right: hidden !important;
  /*border-left: hidden !important;*/
  /*border: hidden !important;*/
}

.noborder-bottomleft {
  background: white;
  border-left: hidden !important;
  border-bottom: hidden !important;
  /*border-left: hidden !important;*/
  /*border: hidden !important;*/
}

.seleted-box {
  background-color: yellow;
}

.overflow {
  overflow-y: scroll;
  height: 55vh;
}

.v-input--checkbox .v-label {
  font-size: 30px;
}

.break-span {
  word-wrap: break-word;
  white-space: initial;
}

.matrix-height {
  height: 90%;
}

tr:last-child(2) > td {
  background: #0079c1 !important;
}
</style>
