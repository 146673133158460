import { render, staticRenderFns } from "./trade-left-side-bar.vue?vue&type=template&id=39ba5392&"
import script from "./trade-left-side-bar.vue?vue&type=script&lang=js&"
export * from "./trade-left-side-bar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VBtn,VFlex,VIcon,VLayout,VNavigationDrawer,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('39ba5392')) {
      api.createRecord('39ba5392', component.options)
    } else {
      api.reload('39ba5392', component.options)
    }
    module.hot.accept("./trade-left-side-bar.vue?vue&type=template&id=39ba5392&", function () {
      api.rerender('39ba5392', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/trade-dsm-application/left-side-bar/trade-left-side-bar.vue"
export default component.exports