import { render, staticRenderFns } from "./abs_export_year.vue?vue&type=template&id=b5237cb0&%3Aid=graph_data%5B'y'%5D%5B0%5D&"
import script from "./abs_export_year.vue?vue&type=script&lang=js&"
export * from "./abs_export_year.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})


/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b5237cb0')) {
      api.createRecord('b5237cb0', component.options)
    } else {
      api.reload('b5237cb0', component.options)
    }
    module.hot.accept("./abs_export_year.vue?vue&type=template&id=b5237cb0&%3Aid=graph_data%5B'y'%5D%5B0%5D&", function () {
      api.rerender('b5237cb0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/trade_data/abs_graphs/abs_export_year.vue"
export default component.exports