<template>
  <!--  <v-row :key="JSON.stringify(reo_col_row)">-->
  <v-row>
    <v-expansion-panel class="mt-1">
      <h3 class="text-left mt-2 ml-2 trade-blue-text">REO Market Characteristics:</h3>
      <v-expansion-panel-header class="trade-blue ma-0 ml-2">
        <span>
          Target Market Demand Characteristics
        </span>

        <span class="yellow--text caption text-right font-italic ma-0 pa-0">
          (basic)
        </span>

        <template v-slot:actions>
          <v-icon color="yellow">$expand</v-icon>
        </template>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="mt-2">
        <v-main class="ma-0 pa-0 ">
          <v-container class="ma-0 pa-0" fluid>
            <v-row no-gutters class="filterItem">
              <v-col cols="8"><span>Not classified as REO</span></v-col>

              <v-col cols="4">
                <span>
                  <v-checkbox
                    class="d-inline"
                    @change="update_array"
                    :disabled="set_disabled"
                    v-model="reo_col_row.row_0"
                  ></v-checkbox>
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="filterItem">
              <v-col cols="8">1-Large but Stable Market</v-col>
              <v-col cvols="4">
                <v-checkbox
                  class="d-inline"
                  @change="update_array"
                  :disabled="set_disabled"
                  v-model="reo_col_row.row_1"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row no-gutters class="filterItem">
              <v-col cols="8">2-Growing Market (Short & Long term)</v-col>
              <v-col cvols="4">
                <v-checkbox
                  class="d-inline"
                  @change="update_array"
                  :disabled="set_disabled"
                  v-model="reo_col_row.row_2"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row no-gutters class="filterItem">
              <v-col cols="8">3-Large Growing Market (Short term)</v-col>
              <v-col cvols="4">
                <v-checkbox
                  class="d-inline"
                  @change="update_array"
                  :disabled="set_disabled"
                  v-model="reo_col_row.row_3"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row no-gutters class="filterItem">
              <v-col cols="8">4-Large Growing Market (Long term)</v-col>
              <v-col cvols="4">
                <v-checkbox
                  class="d-inline"
                  @change="update_array"
                  :disabled="set_disabled"
                  v-model="reo_col_row.row_4"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row no-gutters class="filterItem">
              <v-col cols="8">5-Large Growing Market (Short & Long term)</v-col>
              <v-col cvols="4">
                <v-checkbox
                  class="d-inline"
                  @change="update_array"
                  :disabled="set_disabled"
                  v-model="reo_col_row.row_5"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-main>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel class="mt-1 ml-2">
      <v-expansion-panel-header class="trade-blue ma-0 mt-2">
        <span>
          Home Market Supply Characteristics
        </span>

        <span class="yellow--text caption text-right font-italic ma-0 pa-0">
          (basic)
        </span>

        <template v-slot:actions>
          <v-icon color="yellow">$expand</v-icon>
        </template>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="mt-2">
        <v-main class="ma-0 pa-0">
          <v-container class="ma-0 pa-0" fluid>
            <v-row no-gutters class="filterItem">
              <v-col cols="8">1-Small Market Share (0 &le; S &lt; 5%)</v-col>
              <v-col cvols="4">
                <v-checkbox
                  class="d-inline"
                  @change="update_array"
                  :disabled="set_disabled"
                  v-model="reo_col_row.col_1"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row no-gutters class="filterItem">
              <v-col cols="8"
                >2-Intermediate Small Market Share (5 &le; S &lt; 15%)</v-col
              >
              <v-col cvols="4">
                <v-checkbox
                  class="d-inline"
                  @change="update_array"
                  :disabled="set_disabled"
                  v-model="reo_col_row.col_2"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row no-gutters class="filterItem">
              <v-col cols="8"
                >3-Intermediate Large Market Share (15 &le; S &lt; 25%)</v-col
              >
              <v-col cvols="4">
                <v-checkbox
                  class="d-inline"
                  @change="update_array"
                  :disabled="set_disabled"
                  v-model="reo_col_row.col_3"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row no-gutters class="filterItem">
              <v-col cols="8">4-Large Market Share (25% &lt; S)</v-col>
              <v-col cvols="4">
                <v-checkbox
                  class="d-inline"
                  @change="update_array"
                  :disabled="set_disabled"
                  v-model="reo_col_row.col_4"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-main>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-row>
</template>

<script>
import {eventBus} from '@/main';
import _ from 'lodash';

export default {
  name: 'reo-filters',

  mounted() {
    this.set_reo_array_values();

    eventBus.$on('FILTER_CLEARED_EVENT', () => {
      this.reo_col_row = _.clone(this.reo_row_col_backup);
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.reo_clicked.REO_col.splice(
        0,
      );
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.reo_clicked.REO_row.splice(
        0,
      );
      this.set_reo_array_values();
    });
  },

  data() {
    return {
      reo_row_col_backup: {
        row_0: false,
        row_1: false,
        row_2: false,
        row_3: false,
        row_4: false,
        row_5: false,
        col_1: false,
        col_2: false,
        col_3: false,
        col_4: false,
      },
      row_0: false,
      reo_col_row: {
        row_0: false,
        row_1: false,
        row_2: false,
        row_3: false,
        row_4: false,
        row_5: false,
        col_1: false,
        col_2: false,
        col_3: false,
        col_4: false,
      },
    };
  },

  computed: {
    set_disabled() {
      // return this.$store.state.tradeDSM.updatingMainFilters === true || this.current_report_to_run === '1P1C'
      return this.$store.state.tradeDSM.updatingMainFilters;
    },
  },
  methods: {
    update_array() {
      let REO_row = [];
      let REO_col = [];
      if (this.reo_col_row.row_0 === true) {
        REO_row.push(0);
        REO_row.push(0, -1, -2);
      }
      if (this.reo_col_row.row_1 === true) {
        REO_row.push(1);
      }
      if (this.reo_col_row.row_2 === true) {
        REO_row.push(2);
      }
      if (this.reo_col_row.row_3 === true) {
        REO_row.push(3);
      }
      if (this.reo_col_row.row_4 === true) {
        REO_row.push(4);
      }
      if (this.reo_col_row.row_5 === true) {
        REO_row.push(5);
      }
      if (this.reo_col_row.col_1 === true) {
        REO_col.push(1);
      }
      if (this.reo_col_row.col_2 === true) {
        REO_col.push(2);
      }
      if (this.reo_col_row.col_3 === true) {
        REO_col.push(3);
      }
      if (this.reo_col_row.col_4 === true) {
        REO_col.push(4);
      }
      eventBus.$emit('FILTER_CHANGED');
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.advanced_filters_is_dirty = true;
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.reo_clicked.REO_col = REO_col;
      this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections.reo_clicked.REO_row = REO_row;
    },
    set_reo_array_values() {
      let REO_col = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
        .selections.reo_clicked.REO_col;
      let REO_row = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
        .selections.reo_clicked.REO_row;
      for (let i = 0; i < REO_row.length; i++) {
        let row = REO_row[i];
        if (row === 0) {
          this.reo_col_row.row_0 = true;
        }
        if (row === 1) {
          this.reo_col_row.row_1 = true;
        }
        if (row === 2) {
          this.reo_col_row.row_2 = true;
        }
        if (row === 3) {
          this.reo_col_row.row_3 = true;
        }
        if (row === 4) {
          this.reo_col_row.row_4 = true;
        }
        if (row === 5) {
          this.reo_col_row.row_5 = true;
        }
      }

      for (let i = 0; i < REO_col.length; i++) {
        let row = REO_col[i];

        if (row === 1) {
          this.reo_col_row.col_1 = true;
        }
        if (row === 2) {
          this.reo_col_row.col_2 = true;
        }
        if (row === 3) {
          this.reo_col_row.col_3 = true;
        }
        if (row === 4) {
          this.reo_col_row.col_4 = true;
        }
      }
    },
  },
};
</script>

<style scoped></style>
