var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        {
          staticClass: "justify-space-around mt-2 pb-2 pr-2 pl-0",
          attrs: { row: "" }
        },
        [
          _c("MapContainer", [_c("WorldMap_MPMC")], 1),
          _c(
            "v-flex",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.$store.state.app.big_map,
                  expression: "!$store.state.app.big_map"
                }
              ],
              staticClass: "mt-2",
              attrs: { lg7: "", md12: "", sm12: "", xl6: "" }
            },
            [
              _c("top-summary-results", { staticClass: "mb-2" }),
              _c(
                "div",
                {},
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c("v-flex", { staticClass: "xs12 sm12 lg12" }, [
                        _c(
                          "div",
                          {
                            staticClass: "body-2 font-weight-bold nav nav-tabs"
                          },
                          [
                            _c("li", {}, [
                              _c(
                                "a",
                                { staticClass: "disabled" },
                                [
                                  _vm._v(
                                    "Details (" +
                                      _vm._s(
                                        _vm.$store.getters.selectionCounts
                                          .results.markets_count
                                      ) +
                                      " target markets x " +
                                      _vm._s(
                                        _vm.$store.getters.selectionCounts
                                          .results.products_count
                                      ) +
                                      " product) "
                                  ),
                                  _c("help-link", {
                                    staticStyle: { float: "right" },
                                    attrs: { help_id: "16" }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "tab-content" }, [
                    _c(
                      "div",
                      { staticClass: "tab-pane active white" },
                      [
                        _c(
                          "v-container",
                          { attrs: { fluid: "" } },
                          [
                            _c(
                              "v-layout",
                              {
                                staticClass:
                                  "justify-space-between mt-0 ml-2 mr-1 black--text",
                                attrs: { row: "" }
                              },
                              [
                                _c(
                                  "v-flex",
                                  { staticClass: "mb-1", attrs: { sm12: "" } },
                                  [
                                    _c("applied_filters_box", {
                                      staticClass: "mr-3 mb-1 ml-0"
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { staticClass: "mb-2" },
                                  [_c("countries_filtered_box")],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { staticClass: "sm12 ml-1 mt-1" },
                                  [_c("products_filtered_box")],
                                  1
                                ),
                                _c("hr"),
                                _c(
                                  "v-flex",
                                  { staticClass: "mb-1", attrs: { sm12: "" } },
                                  [
                                    _c(
                                      "v-layout",
                                      { attrs: { row: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          { staticClass: "xs12 sm12 lg12" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "body-2 font-weight-bold nav nav-tabs mt-2"
                                              },
                                              [
                                                _c("li", [
                                                  _c(
                                                    "a",
                                                    [
                                                      _vm._v(
                                                        "Home market export characteristics for product "
                                                      ),
                                                      _c("help-link", {
                                                        staticClass: "ml-1",
                                                        staticStyle: {
                                                          float: "right"
                                                        },
                                                        attrs: { help_id: "13" }
                                                      }),
                                                      !_vm.all_open_selection
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "white--text mr-0 pr-0",
                                                              staticStyle: {
                                                                float: "right"
                                                              },
                                                              attrs: {
                                                                text: "",
                                                                "x-small": ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.all_open_selection = true
                                                                }
                                                              }
                                                            },
                                                            [
                                                              !_vm.all_open_selection
                                                                ? _c("v-icon", [
                                                                    _vm._v(
                                                                      " mdi-chevron-triple-down "
                                                                    )
                                                                  ])
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.all_open_selection
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "white--text mr-0 pr-0",
                                                              staticStyle: {
                                                                float: "right"
                                                              },
                                                              attrs: {
                                                                text: "",
                                                                "x-small": ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.all_open_selection = false
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm.all_open_selection
                                                                ? _c("v-icon", [
                                                                    _vm._v(
                                                                      " mdi-chevron-triple-up "
                                                                    )
                                                                  ])
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "tab-content" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tab-pane active white black--text pl-3"
                                        },
                                        [
                                          _c(
                                            "v-container",
                                            { attrs: { fluid: "" } },
                                            [
                                              _c("result_hover", {
                                                attrs: {
                                                  all_open:
                                                    _vm.all_open_selection,
                                                  explanation:
                                                    _vm.$store.state.tradeDSM
                                                      .dsm_filter_setttings
                                                      .Filters.META
                                                      .export_characteristics
                                                      .REO_F4_1_Text,
                                                  result:
                                                    _vm.get_yes_no(
                                                      _vm.$store.state.tradeDSM
                                                        .dsm_filter_setttings
                                                        .Filters.META
                                                        .export_characteristics
                                                        .REO_F4_1_Result
                                                    ) +
                                                    " <span class='ml-2 green--text rca_rta_text'>" +
                                                    _vm.share_text() +
                                                    "</span>",
                                                  heading:
                                                    "Home market is comparatively large exporter of product? [F4.1]"
                                                }
                                              }),
                                              _c("result_hover", {
                                                attrs: {
                                                  all_open:
                                                    _vm.all_open_selection,
                                                  explanation:
                                                    _vm.$store.state.tradeDSM
                                                      .dsm_filter_setttings
                                                      .Filters.META
                                                      .export_characteristics
                                                      .REO_F4_2_Text,
                                                  result:
                                                    _vm.get_yes_no(
                                                      _vm.$store.state.tradeDSM
                                                        .dsm_filter_setttings
                                                        .Filters.META
                                                        .export_characteristics
                                                        .REO_F4_2_Result
                                                    ) +
                                                    " <span class='ml-2 green--text rta_rta_text'>" +
                                                    _vm.rta_text() +
                                                    "</span>",
                                                  heading:
                                                    "Home market has product export production capacity? [F4.2]"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-layout",
                              {
                                staticClass:
                                  "justify-space-between mt-0 ml-2 mr-1 black--text",
                                attrs: { row: "" }
                              },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { sm12: "" } },
                                  [
                                    !this.$store.state.tradeDSM
                                      .loading_indicator &&
                                    _vm.$store.state.tradeDSM
                                      .dsm_filter_setttings.Filters.META
                                      .query_results.cube.length > 0
                                      ? _c(
                                          "v-card",
                                          { staticClass: "pt-2" },
                                          [
                                            _c(
                                              "v-tabs",
                                              {
                                                attrs: {
                                                  "active-class": "trade-blue",
                                                  "background-color":
                                                    "tab_header_color",
                                                  "slider-color": "green",
                                                  dark: "",
                                                  dense: ""
                                                },
                                                model: {
                                                  value: _vm.country_tabs,
                                                  callback: function($$v) {
                                                    _vm.country_tabs = $$v
                                                  },
                                                  expression: "country_tabs"
                                                }
                                              },
                                              [
                                                _c("v-tab", [
                                                  _vm._v(" Countries")
                                                ]),
                                                _c("v-tab", [
                                                  _vm._v(" Sub-Region")
                                                ]),
                                                _c("v-tab", [
                                                  _vm._v(" Main-Region")
                                                ]),
                                                _c(
                                                  "v-tab-item",
                                                  [
                                                    _c(
                                                      "v-card",
                                                      { attrs: { flat: "" } },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            staticClass: "pa-2",
                                                            attrs: { row: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              [
                                                                _c(
                                                                  "reo_table_markets",
                                                                  {
                                                                    attrs: {
                                                                      cube:
                                                                        "cube",
                                                                      table_id:
                                                                        "reo_country"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tab-item",
                                                  [
                                                    _c(
                                                      "v-layout",
                                                      {
                                                        staticClass: "pa-2",
                                                        attrs: { row: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          [
                                                            _c(
                                                              "reo_table_markets",
                                                              {
                                                                attrs: {
                                                                  cube:
                                                                    "cube_C_Region",
                                                                  table_id:
                                                                    "reo_region"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tab-item",
                                                  [
                                                    _c(
                                                      "v-card",
                                                      { attrs: { flat: "" } },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            staticClass: "pa-2",
                                                            attrs: { row: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              [
                                                                _c(
                                                                  "reo_table_markets",
                                                                  {
                                                                    attrs: {
                                                                      cube:
                                                                        "cube_Main_Region",
                                                                      table_id:
                                                                        "reo_main_region"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : [
                                          _vm.$store.state.tradeDSM
                                            .loading_indicator
                                            ? _c("v-skeleton-loader", {
                                                staticClass: "mt-3 mb-4",
                                                attrs: {
                                                  type: "article, actions"
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                  ],
                                  2
                                ),
                                !_vm.is_run_cube_valid
                                  ? _c(
                                      "v-layout",
                                      [
                                        _c("reoFrontendTable", {
                                          attrs: { cube_name: "cube" }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.$store.state.tradeDSM.loading_indicator &&
      _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.query_results
        .cube.length > 0
        ? _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c(
                "v-flex",
                [
                  _c("tabbed_graph_view", {
                    attrs: {
                      caption: "Trade statistics and trends",
                      help_id: "8"
                    }
                  })
                ],
                1
              ),
              _c("v-flex", [
                _c(
                  "div",
                  { staticClass: "outline ml-10 mr-10" },
                  [_c("reoFrontendTable", { attrs: { cube_name: "cube" } })],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }