var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.render_map, staticClass: "pa-0 white" },
    [
      _c(
        "v-container",
        { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "align-center", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c("v-img", {
                    attrs: {
                      src: "/flags/" + _vm.countryCode.toLowerCase() + ".svg",
                      "max-height": "50px",
                      "max-width": "50px",
                      height: "50px",
                      width: "50px"
                    }
                  })
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "10" } }, [
                _c("h4", { staticClass: "text-left ml-3" }, [
                  _vm._v(" Home market: "),
                  _c("span", { staticClass: "font-italic" }, [
                    _vm._v(_vm._s(_vm.$store.state.app.selectedHomeCountry))
                  ])
                ])
              ])
            ],
            1
          ),
          _vm.selectedCountry !== "none"
            ? _c(
                "v-row",
                { staticClass: "align-center", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("v-img", {
                        attrs: {
                          src:
                            "/flags/" +
                            _vm.selectedCountry.toLowerCase() +
                            ".svg",
                          "max-height": "50px",
                          "max-width": "50px",
                          height: "50px",
                          width: "50px"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "10" } }, [
                    _c("h4", { staticClass: "text-left ml-3" }, [
                      _vm._v(" Target market: "),
                      _c("span", { staticClass: "font-italic" }, [
                        _vm._v(_vm._s(_vm.target_market))
                      ])
                    ])
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.show_printable
        ? [
            _c("span", {
              staticClass: "ma-0 pa-0 caption",
              attrs: { id: "png_placeholder" }
            })
          ]
        : _vm._e(),
      _c(
        "div",
        { key: _vm.big_map, staticClass: "white", attrs: { id: "mpmc_map" } },
        [
          _c(
            "div",
            { key: _vm.refresh, staticClass: "chartWithOverlay" },
            [
              _c("GChart", {
                ref: "gChart",
                staticClass: "pa-0 ma-0 white",
                attrs: {
                  data: _vm.create_country_plot_array,
                  events: _vm.chartEvents,
                  options: _vm.options,
                  resizeDebounce: 0,
                  settings: {
                    packages: ["geochart"],
                    mapsApiKey: _vm.mapsApiKey,
                    tooltip: { isHtml: true }
                  },
                  type: "GeoChart"
                },
                on: { ready: _vm.chartReady }
              }),
              _c("trade-map-image", {
                attrs: { show_map_ta_overlay: _vm.show_map_ta_overlay }
              })
            ],
            1
          )
        ]
      ),
      _vm.show_map_ta_overlay
        ? _c(
            "v-layout",
            {
              staticClass:
                "pa-0 mt-0 justify-space-around align-center no-gutters",
              attrs: { row: "", "data-html2canvas-ignore": "" }
            },
            [
              _c(
                "v-flex",
                { staticClass: " mt-0 pt-0", attrs: { sm6: "" } },
                [
                  _c("v-switch", {
                    staticClass: "sm-4 mb-3 ml-lg-5 mt-0 pt-0",
                    attrs: {
                      label:
                        _vm.display_mode_var === "region"
                          ? "Show bubbles"
                          : "Highlight countries",
                      color: "green",
                      dense: "",
                      flat: "",
                      inset: "",
                      value: "region"
                    },
                    on: {
                      change: function($event) {
                        _vm.options.displayMode =
                          _vm.display_mode_var === "region"
                            ? "region"
                            : "markers"
                      }
                    },
                    model: {
                      value: _vm.display_mode_var,
                      callback: function($$v) {
                        _vm.display_mode_var = $$v
                      },
                      expression: "display_mode_var"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: " mt-0 pt-0", attrs: { sm4: "" } },
                [
                  _c(
                    "v-select",
                    {
                      staticClass: "mt-0 pt-0",
                      attrs: {
                        label: "Select zoom region",
                        items: [
                          {
                            text: "World",
                            value: "world"
                          },
                          {
                            text: "Africa",
                            value: "002"
                          },
                          {
                            text: "Europe",
                            value: "150"
                          },
                          {
                            text: "Americas",
                            value: "019"
                          },
                          {
                            text: "Northern America",
                            value: "021"
                          },
                          {
                            text: "Caribbean",
                            value: "029"
                          },
                          {
                            text: "Central America",
                            value: "013"
                          },
                          {
                            text: "South America",
                            value: "005"
                          },
                          {
                            text: "Asia",
                            value: "142"
                          },
                          {
                            text: "Oceania",
                            value: "009"
                          }
                        ]
                      },
                      on: {
                        change: function($event) {
                          _vm.options.region = $event
                        }
                      }
                    },
                    [_vm._v(" //https://en.wikipedia.org/wiki/UN_M49 ")]
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: " mt-0 pt-0", attrs: { sm1: "" } },
                [
                  _c("help-link", {
                    staticClass: "mt-0 pt-0",
                    staticStyle: { float: "left" },
                    attrs: { help_id: "5" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }