<template>
  <div class="ml-10 mr-10">
    <div class="body-2 font-weight-bold nav nav-tabs">
      <li class="">
        <a class="pt-1"
          >{{ caption }}
          <v-icon
            @click="element2clipboard('1pmc-graphs')"
            style="float: left"
            data-html2canvas-ignore
            class="mr-2 white--text"
            >mdi-camera
          </v-icon>
          <help-link :help_id="help_id" style="float: right" class="ml-1" />
        </a>
      </li>
    </div>
    <div class="tab-content">
      <!--      <view_selector></view_selector>-->
      <div class="tab-pane active white" id="1pmc-graphs">
        <v-tabs
          v-model="selected_tab"
          active-class="trade-blue"
          background-color="tab_header_color"
          slider-color="green"
          dark
          dense
          @change="change_report(selected_tab)"
          height="30px"
          class="pa-1"
        >
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{on}">
              <v-tab v-on="on">Results</v-tab>
            </template>
            <span>{{
              $store.state.tradeDSM.dsm_filter_setttings.Filters.META.Data_Models
                .Trade_History.data_message
            }}</span>
          </v-tooltip>

          <template v-for="i in $store.state.tradeData.report_selections.length - 1">
            <v-tooltip :key="i" bottom max-width="400">
              <template v-slot:activator="{on}">
                <v-tab v-on="on">
                  {{ tab_name(i) }}
                </v-tab>
              </template>
              <span> {{ $store.state.tradeData.report_selections[i].hover_text }}</span>
            </v-tooltip>
          </template>

          <v-tab-item class="outline">
            <tabbed-graph-top-view-text class="ml-10 pt-5" />
            <DSMSingleGraphs
              v-if="current_report_to_run === '1P1C'"
              class="mt-1 ml-3 mr-5 mb-4 white"
            />
            <DSM1PMCGraphs v-if="current_report_to_run === '1PMC'"></DSM1PMCGraphs>
          </v-tab-item>

          <template v-for="i in $store.state.tradeData.report_selections.length - 1">
            <v-tab-item :key="i" class="outline">
              <template v-if="data_available || loading">
                <div class="ml-10 blink pt-4" v-if="loading">
                  <span class="mt-6 pt-6"> Loading data ...</span>
                </div>
                <tabbed-graph-top-view-text v-if="!loading" class="ml-10 pt-5" />
                <abs_summaries />
              </template>
              <div v-else>
                <span>
                  There is no data for
                  {{
                    $store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections
                      .products.HS6_Code[0]
                  }}</span
                >
              </div>
            </v-tab-item></template
          >
        </v-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import abs_summaries from '@/components/trade_data/abs_graphs/abs_summaries';
import DSM1PMCGraphs from '@/components/trade-dsm-application/result-components/graphs-1pmc';
import swal from 'sweetalert';
import {mapMutations} from 'vuex';
import TabbedGraphTopViewText from '@/components/trade-dsm-application/results-pages/tabs/TabbedGraphTopViewText.vue';
import helpLink from '@/components/application-level/help-system/help-link.vue';
import DSMSingleGraphs from '@/components/trade-dsm-application/result-components/graphs-1p1c.vue';

export default {
  name: 'tabbed_graph_view',
  props: ['caption', 'help_id'],
  components: {
    DSMSingleGraphs,
    helpLink,
    TabbedGraphTopViewText,
    abs_summaries,
    DSM1PMCGraphs,
  },
  data() {
    return {
      selected_tab: 0,
      data_available: false,
      loading: false,
    };
  },
  methods: {
    ...mapMutations(['set_trade_date_cube']),

    tab_name(i) {
      let str = this.$store.state.tradeData.report_selections[i].value;
      return str.substring(0, str.indexOf('_'));
    },
    async generate_abs_frontend_views(url) {
      console.log('url -- ' + url);
      this.loading = true;
      await this.axios
        .post(this.$store.getters.baseUrl + url, {
          product_list: this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .selections.products.HS6_Code,
        })
        .then(response => {
          this.set_trade_date_cube(response.data);
          this.data_available = true;
          this.loading = false;
        })
        .catch(() => {
          this.data_available = false;
          this.loading = false;

          swal(
            `Please note that no reported import or export data for ${
              this.$store.state.app.selectedHomeCountry
            } was found for this selected product code: ${
              this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections
                .products.HS6_Code[0]
            }\n`,
          );

          this.$store.state.tradeData.report_selection = 'EMF_Overview';
          this.selected_tab = 0;
        });
    },
    async change_report(report_ix) {
      let report_name = this.$store.state.tradeData.report_selections[report_ix].value;
      this.$store.state.tradeData.report_selection = report_name;

      if (report_ix > 0) {
        this.data_available = false;
        if (this.$store.state.tradeData.trade_data_cube['export_year']) {
          this.$store.state.tradeData.trade_data_cube['export_year']['data_meta'][
            'data_message'
          ] = 'Loading...';
        }

        this.$store.state.tradeData.report_selection = report_name;
        this.$store.state.current_1PMC_report_seletion = report_name;
        if (this.$store.state.current_1PMC_report_seletion !== 'EMF_Overview') {
          this.$store.state.tradeData.trade_data_cube_loading = true;
          // get the views
          await this.generate_abs_frontend_views(
            // extract the report url
            this.$store.state.tradeData.report_selections.filter(
              v => v.value === this.$store.state.current_1PMC_report_seletion,
            )[0]['url'],
          );
          this.$store.state.tradeData.trade_data_cube_loading = false;
        } else {
          this.$store.state.tradeData.trade_data_cube['export_year']['data_meta'][
            'data_message'
          ] = this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.Data_Models.Trade_History.data_message;
        }
      }
    },
  },
};
</script>

<style scoped>
.blink {
  color: green;
  -webkit-animation: flash linear 1s infinite;
  animation: flash linear 2s infinite;
}
@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
/* Pulse class and keyframe animation */
.pulseit {
  -webkit-animation: pulse linear 0.5s infinite;
  animation: pulse linear 0.5s infinite;
}
@-webkit-keyframes pulse {
  0% {
    width: 200px;
  }
  50% {
    width: 340px;
  }
  100% {
    width: 200px;
  }
}
@keyframes pulse {
  0% {
    width: 200px;
  }
  50% {
    width: 340px;
  }
  100% {
    width: 200px;
  }
}
</style>
