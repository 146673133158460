var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { row: "" } },
        [_c("v-flex", { staticClass: "xs12 sm12 lg12" })],
        1
      ),
      _vm.$store.state.tradeData.report_selection ===
      _vm.$store.state.tradeData.report_selections[0]["value"]
        ? _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-layout",
                {
                  staticClass: "justify-space-around align-center black--text",
                  attrs: { row: "" }
                },
                [
                  _c(
                    "v-flex",
                    { staticClass: "xs12 sm12 md12 lg4 xl4" },
                    [
                      _c("Hist_impexpgraph_tm_1pmc", {
                        staticClass: "d-flex justify-center"
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "pa-2 xs12 sm12 md12 lg4 xl4" },
                    [
                      _c("Hist_impexpgraph_tm_total_1pmc", {
                        staticClass: "d-flex justify-center"
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "pa-2 xs12 sm12 md12 lg4 xl4" },
                    [
                      _c("total_imports_exports_1pmc", {
                        staticClass: "d-flex justify-center"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.$store.state.tradeData.trade_data_cube
                ? _c(
                    "v-layout",
                    {
                      staticClass: "justify-space-around align-center mt-5",
                      attrs: { row: "" }
                    },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "pa-1 xs12 sm12 md12 lg4 xl4" },
                        [
                          _c("trade_deficit_1pmc", {
                            staticClass: "d-flex justify-center"
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-2 xs12 sm12 md12 lg4 xl4" },
                        [
                          _c("top6Graph_1pmc", {
                            staticClass: "d-flex justify-center"
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pa-2 xs12 sm12 md12 lg4 xl4" },
                        [
                          _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters
                            .META.query_results["top6_export_trends"] !== null
                            ? _c("export_trends_1pmc", {
                                staticClass: "d-flex justify-center"
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v("\\ ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }