import { render, staticRenderFns } from "./dialog-download-word.vue?vue&type=template&id=88afbe08&scoped=true&"
import script from "./dialog-download-word.vue?vue&type=script&lang=js&"
export * from "./dialog-download-word.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88afbe08",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCheckbox,VCol,VContainer,VIcon,VRadio,VRadioGroup,VRow,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('88afbe08')) {
      api.createRecord('88afbe08', component.options)
    } else {
      api.reload('88afbe08', component.options)
    }
    module.hot.accept("./dialog-download-word.vue?vue&type=template&id=88afbe08&scoped=true&", function () {
      api.rerender('88afbe08', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/application-level/helper-components/dialog-download-word.vue"
export default component.exports