import { render, staticRenderFns } from "./hist-impexp-graph-1p1c.vue?vue&type=template&id=620e6908&"
import script from "./hist-impexp-graph-1p1c.vue?vue&type=script&lang=js&"
export * from "./hist-impexp-graph-1p1c.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})


/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('620e6908')) {
      api.createRecord('620e6908', component.options)
    } else {
      api.reload('620e6908', component.options)
    }
    module.hot.accept("./hist-impexp-graph-1p1c.vue?vue&type=template&id=620e6908&", function () {
      api.rerender('620e6908', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/trade-dsm-application/graphs/1p1c/hist-impexp-graph-1p1c.vue"
export default component.exports