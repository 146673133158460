var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "td",
        {
          staticClass: "caption cell",
          class: {
            "no-pointer": !(_vm.data.val > 0)
            // cell: hover,
          },
          on: {
            click: function($event) {
              return _vm.set_reo_options()
            }
          }
        },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "", disabled: !_vm.hover },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _vm.data.val > 0
                          ? _c(
                              "span",
                              _vm._g({ staticClass: "font-weight-bold" }, on),
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.vFmt(_vm.data.val, 0)) + " "
                                ),
                                _c("br"),
                                _vm.hover
                                  ? _c("span", [
                                      _vm._v(
                                        "[" +
                                          _vm._s(
                                            Math.round(_vm.data.pct * 10000) /
                                              100
                                          ) +
                                          "%] "
                                      ),
                                      _c("br")
                                    ])
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.multiplerString) +
                                    " " +
                                    _vm._s(_vm.currencyString) +
                                    " "
                                ),
                                _c("br"),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.calc_value(_vm.data.val_monetary)
                                    ) +
                                    " "
                                ),
                                _c("br"),
                                _vm.hover
                                  ? _c("span", [
                                      _vm._v(
                                        "[" +
                                          _vm._s(
                                            Math.round(
                                              _vm.data.pct_monetary * 10000
                                            ) / 100
                                          ) +
                                          "%]"
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          : _c("span", { staticClass: "no-pointer" }, [
                              _vm._v("-")
                            ])
                      ]
                    }
                  }
                ],
                null,
                false,
                2106125709
              )
            },
            [_c("span", [_vm._v("Click to see product and markets details")])]
          )
        ],
        1
      )
    : _c("td", [_vm._v("-")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }