var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-layout", { attrs: { row: "" } }),
      _vm.$store.state.tradeData.report_selection ===
      _vm.$store.state.tradeData.report_selections[0]["value"]
        ? _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-layout",
                {
                  staticClass: "justify-space-around align-center",
                  attrs: { row: "" }
                },
                [
                  _c(
                    "v-flex",
                    { staticClass: "pa-2 xs12 sm12 md4 lg6 xl6 align-center" },
                    [
                      _c("Top6graph", {
                        staticClass: "d-flex justify-center align-center"
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "pa-1 xs12 sm12 md6 lg6 xl6",
                      attrs: { id: "1p1c-hist_impexpgraph_tm" }
                    },
                    [
                      _c("Hist_impexpgraph_tm", {
                        staticClass: "d-flex justify-center"
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "pa-2 xs12 sm12 md6 lg6 xl6",
                      attrs: { id: "1p1c-hist_impexpgraph_tm_total" }
                    },
                    [
                      _c("Hist_impexpgraph_tm_total", {
                        staticClass: "d-flex justify-center"
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "pa-2 xs12 sm12 md6 lg6 xl6",
                      attrs: { id: "1p1c-hist_impexpgraph" }
                    },
                    [
                      _c("Hist_impexpgraph", {
                        staticClass: "d-flex justify-center"
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" ] ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }