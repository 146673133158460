var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("help-dialog-base", {
    scopedSlots: _vm._u([
      {
        key: "card_title",
        fn: function() {
          return [
            _c("v-icon", { staticClass: "white--text mr-3" }, [
              _vm._v(_vm._s(_vm.icon()))
            ]),
            _vm._v(" Download " + _vm._s(_vm.ms_office_type) + " data table ")
          ]
        },
        proxy: true
      },
      {
        key: "card_text",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c("v-row", { staticClass: "no-gutters" }, [
                  _c("div", { staticClass: "text-center mb-3" }, [
                    _c("h2", { staticClass: "h3" }, [
                      _vm._v("Please select a report option below.")
                    ])
                  ])
                ]),
                _c(
                  "v-row",
                  { staticClass: "outline rounded no-gutters pa-5" },
                  [
                    _c(
                      "v-col",
                      { attrs: { sm: "4" } },
                      [_c("currency-changer")],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "card_actions",
        fn: function() {
          return [
            _c(
              "v-btn",
              {
                staticClass: "trade-blue white--text",
                attrs: { text: "" },
                on: { click: _vm.download_handler }
              },
              [_vm._v(" Download Report ")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }