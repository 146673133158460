import { render, staticRenderFns } from "./abs_pie_chart.vue?vue&type=template&id=ca37b0aa&scoped=true&"
import script from "./abs_pie_chart.vue?vue&type=script&lang=js&"
export * from "./abs_pie_chart.vue?vue&type=script&lang=js&"
import style0 from "./abs_pie_chart.vue?vue&type=style&index=0&id=ca37b0aa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca37b0aa",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VImg,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ca37b0aa')) {
      api.createRecord('ca37b0aa', component.options)
    } else {
      api.reload('ca37b0aa', component.options)
    }
    module.hot.accept("./abs_pie_chart.vue?vue&type=template&id=ca37b0aa&scoped=true&", function () {
      api.rerender('ca37b0aa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/trade_data/abs_graphs/abs_pie_chart.vue"
export default component.exports