var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "ma-0 pa-0 grey lighten-3",
          attrs: {
            app: "",
            clipped: "",
            "mini-variant": "",
            "mini-variant-width": "67",
            permanent: ""
          }
        },
        [
          _c(
            "v-tooltip",
            {
              attrs: {
                disabled: _vm.get_selectors_state,
                color: "trade-blue text-subtitle-1"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-layout",
                        _vm._g(
                          { staticClass: "pr-2 pt-2 pl-1", attrs: { row: "" } },
                          on
                        ),
                        [
                          _c("v-flex", { staticClass: "d-flex xs12" }, [
                            _c(
                              "div",
                              _vm._g({}, on),
                              [
                                _c("help-link", {
                                  staticClass: "ml-6 mt-5",
                                  attrs: { help_id: "1" }
                                })
                              ],
                              1
                            )
                          ]),
                          _c(
                            "span",
                            { staticClass: "caption ml-5 mb-1 mt-4" },
                            [_vm._v("Filters")]
                          ),
                          [
                            _vm._l(
                              _vm.$store.state.tradeDSM.left_sidebar_selector,
                              function(item) {
                                return [
                                  _c(
                                    "v-flex",
                                    {
                                      key: item.id,
                                      staticClass: "d-flex xs12"
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    item.tip === "Products" ||
                                                    item.tip === "Countries"
                                                      ? _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "mt-0",
                                                              attrs: {
                                                                id: "side-icon",
                                                                disabled: !_vm.get_selectors_state,
                                                                text: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  return _vm.open_drawer(
                                                                    item.tip
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-badge",
                                                              {
                                                                attrs: {
                                                                  content:
                                                                    item.tip ===
                                                                    "Products"
                                                                      ? _vm.productsSelectedCount ||
                                                                        0
                                                                      : _vm.marketsSelectedCount ||
                                                                        0,
                                                                  value:
                                                                    item.tip ===
                                                                    "Products"
                                                                      ? _vm.productsSelectedCount ||
                                                                        0
                                                                      : _vm.marketsSelectedCount ||
                                                                        0,
                                                                  color:
                                                                    item.tip ===
                                                                    "Products"
                                                                      ? _vm.product_bubble_color()
                                                                      : _vm.market_bubble_color(),
                                                                  "offset-x":
                                                                    "10",
                                                                  overlap: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "trade-blue-text pa-0 ma-0"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.icon
                                                                      ) + " "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [_c("span", [_vm._v(_vm._s(item.tip))])]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            )
                          ]
                        ],
                        2
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("span", [
                _vm._v(
                  "The filters are disabled in this view. Select the Home button to refine report."
                )
              ])
            ]
          ),
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              [
                _c(
                  "v-tooltip",
                  {
                    key: _vm.filters_selected_text,
                    attrs: { right: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "mt-0 ml-1",
                                  attrs: {
                                    disabled: !_vm.get_selectors_state,
                                    text: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.open_drawer("DSM Filters")
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                _c(
                                  "v-badge",
                                  {
                                    attrs: {
                                      content:
                                        _vm.$store.state.tradeDSM
                                          .dsm_filter_setttings.Filters.META
                                          .filter_in_progress ||
                                        _vm.$store.state.tradeDSM
                                          .dsm_filter_setttings.Filters.META
                                          .advanced_filters_is_dirty
                                          ? "✓"
                                          : 0,
                                      value:
                                        _vm.$store.state.tradeDSM
                                          .dsm_filter_setttings.Filters.META
                                          .filter_in_progress ||
                                        _vm.$store.state.tradeDSM
                                          .dsm_filter_setttings.Filters.META
                                          .advanced_filters_is_dirty
                                          ? "✓"
                                          : 0,
                                      color: _vm.main_filter_badge_color,
                                      "offset-x": "10",
                                      overlap: ""
                                    }
                                  },
                                  [
                                    _c("v-icon", { staticClass: "pa-0 ma-0" }, [
                                      _vm._v("mdi-filter-variant")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.filters_selected_text))
                    ])
                  ]
                ),
                _c("span", { staticClass: "caption ml-5 mb-1" }, [
                  _vm._v("Presets")
                ]),
                _c(
                  "v-tooltip",
                  {
                    attrs: { right: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "mt-0 ml-1",
                                  attrs: {
                                    disabled: !_vm.get_selectors_state,
                                    text: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.select_custom_filter_open = true
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                _c("v-badge", {
                                  attrs: {
                                    content:
                                      _vm.$store.state.tradeDSM
                                        .dsm_filter_setttings.Filters.META
                                        .filter_in_progress &&
                                      !_vm.$store.state.tradeDSM
                                        .dsm_filter_setttings.Filters.META
                                        .advanced_filters_is_dirty
                                        ? "✓"
                                        : 0,
                                    value:
                                      _vm.$store.state.tradeDSM
                                        .dsm_filter_setttings.Filters.META
                                        .filter_in_progress &&
                                      !_vm.$store.state.tradeDSM
                                        .dsm_filter_setttings.Filters.META
                                        .advanced_filters_is_dirty
                                        ? "✓"
                                        : 0,
                                    color: "green",
                                    "offset-x": "-16",
                                    "offset-y": "3",
                                    overlap: ""
                                  }
                                }),
                                _c("v-icon", [
                                  _vm._v(" mdi-filter-variant-plus")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [_c("span", [_vm._v("Load preset filters")])]
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { right: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "mt-0 ml-1",
                                  attrs: {
                                    disabled: !_vm.get_selectors_state,
                                    text: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.clear_preset_filters(true)
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                _c("v-icon", [
                                  _vm._v(" mdi-filter-variant-minus")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [_c("span", [_vm._v("Clear preset filters")])]
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { right: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "mt-0 ml-1",
                                  attrs: {
                                    disabled: !_vm.get_selectors_state,
                                    text: ""
                                  },
                                  on: { click: _vm.load_product_exclusions }
                                },
                                on
                              ),
                              [
                                _c(
                                  "v-badge",
                                  {
                                    attrs: {
                                      content: _vm.$store.state.tradeDSM
                                        .dsm_filter_setttings.Filters.META
                                        .presets.load_presets
                                        ? "✓"
                                        : 0,
                                      value: _vm.$store.state.tradeDSM
                                        .dsm_filter_setttings.Filters.META
                                        .presets.load_presets
                                        ? "✓"
                                        : 0,
                                      color: "green",
                                      "offset-x": "10",
                                      overlap: ""
                                    }
                                  },
                                  [
                                    _c("v-icon", [_vm._v(" mdi-playlist-plus")])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters
                            .META.presets.load_presets
                            ? "Product exclusions are active"
                            : "Load product exclusions"
                        )
                      )
                    ])
                  ]
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { right: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "mt-0 ml-1",
                                  attrs: {
                                    disabled: !_vm.get_selectors_state,
                                    text: ""
                                  },
                                  on: { click: _vm.clear_product_exclusions }
                                },
                                on
                              ),
                              [_c("v-icon", [_vm._v(" mdi-playlist-minus")])],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [_c("span", [_vm._v("Clear product exclusions")])]
                ),
                _c("span", { staticClass: "caption ml-6" }, [_vm._v("Reset")]),
                _c(
                  "v-tooltip",
                  {
                    attrs: { right: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "ml-1",
                                  attrs: { text: "" },
                                  on: { click: _vm.reset_products_countries }
                                },
                                on
                              ),
                              [
                                _c("v-icon", [
                                  _vm._v("mdi-alpha-x-circle-outline")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "Clear Product and Market selections (no filter changes)."
                      )
                    ])
                  ]
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { right: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "mt-0 ml-1",
                                  attrs: {
                                    disabled: !_vm.get_selectors_state,
                                    text: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.reset_filters_to_preset_state(
                                        true
                                      )
                                    }
                                  }
                                },
                                on
                              ),
                              [_c("v-icon", [_vm._v(" mdi-sync")])],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("span", [
                      _vm._v(
                        " Clear user selections and reload default filters and exclusions. "
                      )
                    ])
                  ]
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { right: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "ml-1 ",
                                  attrs: {
                                    disabled: !_vm.get_selectors_state,
                                    text: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.reset_filters_to_raw_state()
                                    }
                                  }
                                },
                                on
                              ),
                              [_c("v-icon", [_vm._v(" mdi-delete-restore")])],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "Clear presets (filters & exclusions) and user selections."
                      )
                    ])
                  ]
                )
              ]
            ],
            2
          ),
          !_vm.$store.state.excel_report_generation_in_progress &&
          !_vm.$store.state.word_report_generation_in_progress &&
          !_vm.$store.state.email_report_generation_in_progress
            ? [
                (_vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                  .report_type_to_generate === "1PMC" ||
                  _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                    .report_type_to_generate === "1P1C" ||
                  _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                    .report_type_to_generate === "MP1C") &&
                this.is_run_cube_valid
                  ? _c("div", { staticClass: "caption ml-3 mt-1" }, [
                      _vm._v(" Report ")
                    ])
                  : _vm._e(),
                _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                  .report_type_to_generate === "1P1C" ||
                (_vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                  .report_type_to_generate === "1PMC" &&
                  _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                    .query_results.cube.length > 0)
                  ? [
                      _c(
                        "div",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { right: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "mt-0",
                                              attrs: { text: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.generate_report(
                                                    "word"
                                                  )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "trade-blue-text mr-3"
                                              },
                                              [_vm._v("mdi-microsoft-word")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                322746107
                              )
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Download " +
                                    _vm._s(
                                      _vm.$store.state.tradeDSM
                                        .dsm_filter_setttings.Filters.META
                                        .report_type_to_generate === "1P1C"
                                        ? "single product single country"
                                        : "single product multi-country"
                                    ) +
                                    " report"
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                  .report_type_to_generate === "MP1C" && _vm.is_run_cube_valid
                  ? [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { right: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mt-0",
                                          attrs: { text: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.generate_report("pptx")
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "trade-blue-text mr-3"
                                          },
                                          [_vm._v("mdi-microsoft-powerpoint")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            794477764
                          )
                        },
                        [_c("span", [_vm._v("Download Powerpoint Report")])]
                      )
                    ]
                  : _vm._e(),
                (_vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                  .report_type_to_generate === "1PMC" ||
                  _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                    .report_type_to_generate === "1P1C") &&
                _vm.$store.state.current_1PMC_report_seletion !== "EMF_Overview"
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { right: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "mt-0",
                                            attrs: { text: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.dialog_window_excel_report()
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "trade-blue-text mr-3"
                                            },
                                            [_vm._v("mdi-microsoft-excel")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3455677716
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  this.$store.state.tradeData.report_selections.filter(
                                    function(v) {
                                      return (
                                        v.value ===
                                        this$1.$store.state
                                          .current_1PMC_report_seletion
                                      )
                                    }
                                  )[0]["hover_text"]
                                ) + " "
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.crm_action_available()
                  ? _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _c("span", { staticClass: "caption ml-3 " }, [
                          _vm._v("CRM")
                        ]),
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              attrs: { text: "" },
                              on: { click: _vm.crm_apply_action }
                            },
                            _vm.on
                          ),
                          [
                            _c("v-icon", { staticClass: "trade-blue-text " }, [
                              _vm._v("mdi-arrow-expand-left")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _c("custom_filter_selection", {
            attrs: { select_custom_filter_open: _vm.select_custom_filter_open },
            on: {
              "update:select_custom_filter_open": function($event) {
                _vm.select_custom_filter_open = $event
              }
            }
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }