var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.active
    ? _c(
        "div",
        { staticClass: "grey lighten-2" },
        [
          _c(
            "v-layout",
            { staticClass: "align-center", attrs: { row: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "sm10 ml-5 mt-1 pb-1" },
                [
                  _c("v-icon", { attrs: { medium: "" } }, [
                    _vm._v(" mdi-earth ")
                  ]),
                  _c(
                    "span",
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "body-2 pr-2 grey lighten-2 font-italic pa-1"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.combinations["markets_count"] + " ")
                          )
                        ]
                      ),
                      _c("v-icon", { attrs: { medium: "" } }, [
                        _vm._v(" mdi-dolly ")
                      ]),
                      _c(
                        "span",
                        {
                          staticClass:
                            "body-2 pr-2 grey lighten-2 font-italic pa-1"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.combinations["products_count"] + " ")
                          )
                        ]
                      ),
                      _c("v-icon", { attrs: { medium: "" } }, [
                        _vm._v(" mdi-sigma ")
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "body-2 pr-2 grey lighten-2 font-italic",
                          class: {
                            "green--text":
                              _vm.combinations["combinations"] <=
                              _vm.$store.state.user.userServerInfo.app_config
                                .max_dsm_results
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              "" + _vm.vFmt(_vm.combinations["combinations"], 0)
                            )
                          )
                        ]
                      ),
                      _c("v-icon", { attrs: { medium: "" } }, [
                        _vm._v(" mdi-cash ")
                      ]),
                      _c(
                        "span",
                        {
                          staticClass:
                            "body-2 pr-2 grey lighten-2 font-italic pa-1"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                " " +
                                  this.currencyString +
                                  " " +
                                  this.vFmt(
                                    _vm.combinations["trade_value"] *
                                      this.value_convertion,
                                    2
                                  )
                              ) +
                              " "
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            {
                                              staticClass:
                                                "ml-1 font-weight-bold custom-button",
                                              on: {
                                                click: _vm.scroll_multiplier
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.multiplerString) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1529837815
                              )
                            },
                            [_c("span", [_vm._v(" Change multiplier ")])]
                          ),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.$store.state.tradeDSM
                                    .dsm_filter_setttings.Filters.META
                                    .filter_in_progress
                                    ? " - Filtered"
                                    : ""
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.updating
            ? _c("v-progress-linear", {
                attrs: {
                  color: "green lighten-3",
                  indeterminate: "",
                  disabled: "true"
                }
              })
            : _c("v-progress-linear", {
                attrs: { color: "grey lighten-4 mt-1", indeterminate: "" }
              })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }