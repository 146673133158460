<template>
  <span>
    <v-tooltip
      right
      :disabled="
        !this.$store.state.user.userServerInfo.app_config.help_dict[this.help_id][
          'show_help_text'
        ]
      "
    >
      <template v-slot:activator="{on, attrs}">
        <v-icon
          class="grey lighten-2 rounded-circle pa-1 black--text"
          x-small
          v-on="on"
          @click="open_help(help_id)"
        >
          mdi-help
        </v-icon>
      </template>
      <span>{{ show_link }}</span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  name: 'help-link',

  props: ['help_id'],

  computed: {
    show_link() {
      const show = this.$store.state.user.userServerInfo.app_config.help_dict[
        this.help_id
      ]['show_help_text'];
      if (show === true) {
        return (
          this.help_id +
          ': ' +
          this.$store.state.user.userServerInfo.app_config.help_dict[this.help_id][
            'help_text'
          ]
        );
      } else return '';
    },
  },
  methods: {
    open_help(id) {
      const link = this.$store.state.user.userServerInfo.app_config.help_dict[id][
        'help_path'
      ];
      // http://devapp.tradeadvisory.co.za:8999/dev/emf-documentation/build/default/
      let SERVER_TYPE = 'production';
      let SERVER_NAME = 'dsm.tradeadvisory.co.za';
      if (process.env.NODE_ENV === 'production') {
        SERVER_TYPE = 'production';
        // SERVER_NAME = "178.62.250.141"
        SERVER_NAME = 'dsm.tradeadvisory.co.za';
      } else {
        SERVER_TYPE = 'dev';
        SERVER_NAME = 'devapp.tradeadvisory.co.za';
      }

      let HELP_VERSION = this.$store.state.user.userServerInfo.user.fields.institution[0]
        .fields.help_version;
      let url = `http://${SERVER_NAME}:8999/${SERVER_TYPE}/emf-documentation/build/${HELP_VERSION}${link}`;
      console.log(url);
      window.open(url, 'myWindow', 'height=900,width=1150');
    },
  },
};
</script>

<style scoped></style>
