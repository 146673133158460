import { render, staticRenderFns } from "./mpmc-graphs-importer-exporter.vue?vue&type=template&id=86586a04&scoped=true&"
import script from "./mpmc-graphs-importer-exporter.vue?vue&type=script&lang=js&"
export * from "./mpmc-graphs-importer-exporter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86586a04",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('86586a04')) {
      api.createRecord('86586a04', component.options)
    } else {
      api.reload('86586a04', component.options)
    }
    module.hot.accept("./mpmc-graphs-importer-exporter.vue?vue&type=template&id=86586a04&scoped=true&", function () {
      api.rerender('86586a04', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/trade-dsm-application/result-components/mpmc-graphs-under-graph/mpmc-graphs-importer-exporter.vue"
export default component.exports