<template>
  <v-autocomplete
      id="display_currency"
      v-model="exchange_rate_start"
      :disabled="!get_selectors_state"
      :items="get_exchange_rate"
      background-color="white"
      class="pr-1 font-weight-light ml-4 mt-3"
      color="green"
      dense
      hide-details="true"
      item-color="green"
      item-text="CurrencyName"
      label="Display Currency"
      outlined
      return-object
      @change="change_exchange_rate($event)"
      @click:prepend="sync_selectors()"
  />
</template>

<script>

import {mapGetters} from "vuex";
import {mapMutations} from "vuex";
import {eventBus} from "@/main";

export default {
  name: "currency-changer",
  async mounted() {

    //check to see if a currency has been selected
    if (this.$store.state.app.exchange_rate.Currency === '') {
      if (localStorage.getItem('currency') === null) {
        // localStorage.setItem('currency', JSON.stringify(this.currency_setting));
        await localStorage.setItem('currency', JSON.stringify(this.$store.state.user.userServerInfo.exchange_rate[0]));
        this.exchange_rate_start = this.$store.state.user.userServerInfo.exchange_rate[0]

      } else {
        this.exchange_rate_start = JSON.parse(localStorage.getItem('currency'));
      }
    } else {
      this.exchange_rate_start = this.$store.state.app.exchange_rate
    }

    eventBus.$on('CURRENCY_CHANGED', () => {
      this.exchange_rate_start = this.$store.state.app.exchange_rate
    });

  },
  data() {
    return {
      exchange_rate_start: {
        id: null,
        T_Year: null,
        Version: null,
        C_Code: null,
        CurrencyName: null,
        Currency: null,
        TW_FX_Rate: null,
      },
    }
  },
  computed: {
    ...mapGetters([
      'get_selectors_state',
      'get_exchange_rate'
    ])
  },
  methods: {
    ...mapMutations([
      'set_exchange_rate',
    ]),
    change_exchange_rate(event) {
      this.set_exchange_rate(event);
      eventBus.$emit('CURRENCY_CHANGED')
      document.getElementById('display_currency').blur();
    },
    sync_selectors() {
      if (localStorage.getItem('magnitude') === null) {
        localStorage.setItem('magnitude', JSON.stringify(this.magnitude_start));
      } else {
        this.magnitude_start = JSON.parse(localStorage.getItem('magnitude'));
      }

      if (localStorage.getItem('currency') === null) {
        localStorage.setItem('currency', JSON.stringify(this.exchange_rate_start));
      } else {
        this.exchange_rate_start = JSON.parse(localStorage.getItem('currency'));
      }

      this.set_value_mulitplier(this.magnitude_start);
      this.set_exchange_rate(this.exchange_rate_start);
    },
  }
}
</script>

<style scoped>

</style>