var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("help-dialog-base", {
    scopedSlots: _vm._u([
      {
        key: "card_title",
        fn: function() {
          return [
            _c("v-icon", { staticClass: "white--text mr-3" }, [
              _vm._v(_vm._s(_vm.icon()))
            ]),
            _vm._v(" Download " + _vm._s(_vm.ms_office_type) + " Report ")
          ]
        },
        proxy: true
      },
      {
        key: "card_text",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  { staticClass: "justify-content-between" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "outline rounded", attrs: { sm: "6" } },
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              { staticClass: "mb-6", attrs: { sm: "12" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "h1 text-decoration-underline"
                                  },
                                  [_vm._v("Report Settings")]
                                )
                              ]
                            ),
                            _c(
                              "v-col",
                              { attrs: { sm: "10" } },
                              [_c("currency-changer")],
                              1
                            ),
                            _vm.show_sections
                              ? _c(
                                  "v-col",
                                  { attrs: { sm: "12" } },
                                  [
                                    _c("v-checkbox", {
                                      staticClass: "mb-0 pa-0",
                                      attrs: {
                                        label:
                                          " Include economic section in report?"
                                      },
                                      model: {
                                        value:
                                          _vm.$store.state.app
                                            .include_overview_sections_in_report,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.$store.state.app,
                                            "include_overview_sections_in_report",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "$store.state.app.include_overview_sections_in_report"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.show_mobile
                              ? _c(
                                  "v-col",
                                  { attrs: { sm: "12" } },
                                  [
                                    _c("v-checkbox", {
                                      staticClass: "ma-0",
                                      attrs: {
                                        label:
                                          "Include mobile number in report?"
                                      },
                                      model: {
                                        value:
                                          _vm.$store.state.user.userServerInfo
                                            .user.fields
                                            .include_cell_phone_in_report,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.$store.state.user.userServerInfo
                                              .user.fields,
                                            "include_cell_phone_in_report",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                  $store.state.user.userServerInfo.user.fields\n                    .include_cell_phone_in_report\n                "
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.report_setup_complete
                      ? _c(
                          "v-col",
                          {
                            staticClass: "outline rounded ",
                            attrs: { sm: "6" }
                          },
                          [
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "mb-6", attrs: { sm: "12" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "h1 text-decoration-underline"
                                      },
                                      [_vm._v("Available Reports")]
                                    )
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { sm: "12" } },
                                  [
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "ma-0 pa-0 ml-2",
                                        model: {
                                          value: _vm.report_selection_ix,
                                          callback: function($$v) {
                                            _vm.report_selection_ix = $$v
                                          },
                                          expression: "report_selection_ix"
                                        }
                                      },
                                      [
                                        _vm._l(_vm.report_list, function(
                                          report,
                                          ix
                                        ) {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                key: report.id,
                                                attrs: { right: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "div",
                                                            _vm._g(
                                                              {
                                                                key: report.id
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c("v-radio", {
                                                                key: report.id,
                                                                attrs: {
                                                                  label:
                                                                    report[
                                                                      "report_name"
                                                                    ],
                                                                  value: ix
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      report["description"]
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        })
                                      ],
                                      2
                                    ),
                                    _vm.report_list.length === 1
                                      ? _c("span", [
                                          _vm._v(
                                            " There is currently only one report available. Additional reports will become available in future. "
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "card_actions",
        fn: function() {
          return [
            _c(
              "v-btn",
              {
                staticClass: "trade-blue white--text",
                attrs: { text: "" },
                on: { click: _vm.download_handler }
              },
              [_vm._v(" Download Report ")]
            ),
            _vm.show_email_button
              ? _c(
                  "v-btn",
                  {
                    staticClass: "trade-blue white--text",
                    attrs: { text: "" },
                    on: { click: _vm.email_handler }
                  },
                  [_vm._v(" Email Report ")]
                )
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }