import { render, staticRenderFns } from "./text-filters-1p1c.vue?vue&type=template&id=9a528afa&scoped=true&"
import script from "./text-filters-1p1c.vue?vue&type=script&lang=js&"
export * from "./text-filters-1p1c.vue?vue&type=script&lang=js&"
import style0 from "./text-filters-1p1c.vue?vue&type=style&index=0&id=9a528afa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a528afa",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VContainer,VFlex,VIcon,VLayout,VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9a528afa')) {
      api.createRecord('9a528afa', component.options)
    } else {
      api.reload('9a528afa', component.options)
    }
    module.hot.accept("./text-filters-1p1c.vue?vue&type=template&id=9a528afa&scoped=true&", function () {
      api.rerender('9a528afa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/trade-dsm-application/result-components/text-filters-1p1c.vue"
export default component.exports