import { render, staticRenderFns } from "./main-filters.vue?vue&type=template&id=56ba486d&scoped=true&"
import script from "./main-filters.vue?vue&type=script&lang=js&"
export * from "./main-filters.vue?vue&type=script&lang=js&"
import style0 from "./main-filters.vue?vue&type=style&index=0&id=56ba486d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56ba486d",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VExpansionPanels,VFlex,VIcon,VLayout,VNavigationDrawer,VProgressCircular,VSwitch,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56ba486d')) {
      api.createRecord('56ba486d', component.options)
    } else {
      api.reload('56ba486d', component.options)
    }
    module.hot.accept("./main-filters.vue?vue&type=template&id=56ba486d&scoped=true&", function () {
      api.rerender('56ba486d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/trade-dsm-application/left-side-bar/filter_componenets/main-filters.vue"
export default component.exports