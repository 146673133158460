var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        resizemap: function($event) {
          return _vm.$forceUpdate()
        }
      }
    },
    [
      _c(
        "v-layout",
        {
          staticClass: "ma-0 pa-0 justify-center outline mr-0 white",
          attrs: { fluid: "", row: "", id: "trade-results-page" }
        },
        [
          _c(
            "v-tabs",
            {
              staticClass: "mt-0 pt-0",
              attrs: {
                "active-class": "active-class",
                "background-color": "white",
                dark: "",
                height: "0",
                light: ""
              },
              model: {
                value: _vm.get_current_selected_tab,
                callback: function($$v) {
                  _vm.get_current_selected_tab = $$v
                },
                expression: "get_current_selected_tab"
              }
            },
            [
              _vm.view_to_display === "1P1C"
                ? _c(
                    "v-tab",
                    {
                      staticClass: "trade-blue ma-0 font-weight-light",
                      on: {
                        change: function($event) {
                          _vm.map_rerender_workarount++
                        },
                        click: function($event) {
                          return _vm.set_selectors_state(false)
                        }
                      },
                      model: {
                        value: _vm.first_tab_selected,
                        callback: function($$v) {
                          _vm.first_tab_selected = $$v
                        },
                        expression: "first_tab_selected"
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "white--text x-large",
                          attrs: { left: "" }
                        },
                        [_vm._v("mdi-file-word-box-outline")]
                      ),
                      _vm._v(" 1xproduct-1xcountry\\ ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm.view_to_display === "MP1C"
                ? _c(
                    "v-tab",
                    {
                      staticClass: "trade-blue ma-0 font-weight-light",
                      on: {
                        change: function($event) {
                          _vm.map_rerender_workarount++
                        }
                      }
                    },
                    [_vm._v(" Multiple-Product-1xcountry ")]
                  )
                : _vm._e(),
              _vm.view_to_display === "1PMC"
                ? _c(
                    "v-tab",
                    {
                      staticClass: "trade-blue ma-0 font-weight-light",
                      on: {
                        change: function($event) {
                          _vm.map_rerender_workarount++
                        }
                      }
                    },
                    [_vm._v(" 1xProduct-Multiple-Country ")]
                  )
                : _vm._e(),
              _vm.view_to_display === "MPMC"
                ? _c(
                    "v-tab",
                    {
                      staticClass: "trade-blue ma-0 font-weight-light",
                      on: {
                        change: function($event) {
                          _vm.map_rerender_workarount++
                        }
                      }
                    },
                    [_vm._v(" Multiple-product-Multiple-country ")]
                  )
                : _vm._e(),
              _vm._v(" //1P1C Resulsts page "),
              true
                ? _c(
                    "v-tab",
                    {
                      staticClass: "trade-blue font-weight-light",
                      on: {
                        click: function($event) {
                          return _vm.set_selectors_state(true)
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "white--text x-large",
                          attrs: { left: "" }
                        },
                        [_vm._v("mdi-file-word-box-outline")]
                      ),
                      _vm._v(" View-Snapshot ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" //1PMC Resulsts page "),
              true
                ? _c(
                    "v-tab",
                    {
                      staticClass: "trade-blue font-weight-light",
                      on: {
                        click: function($event) {
                          return _vm.set_selectors_state(true)
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "white--text x-large",
                          attrs: { left: "" }
                        },
                        [_vm._v("mdi-file-word-box-outline")]
                      ),
                      _vm._v(" View-1PMC Snapshot ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" //MP1C Resulsts page "),
              true
                ? _c(
                    "v-tab",
                    {
                      staticClass: "trade-blue font-weight-light",
                      on: {
                        click: function($event) {
                          return _vm.set_selectors_state(true)
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "white--text x-large",
                          attrs: { left: "" }
                        },
                        [_vm._v("mdi-file-word-box-outline")]
                      ),
                      _vm._v(" View-MP1C Snapshot ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" //Admin "),
              _vm.$store.getters.user_type === "admin" &&
              _vm.$store.state.app.displayAdminPage
                ? _c(
                    "v-tab",
                    {
                      staticClass:
                        "trade-blue font-weight-light hidden-sm-and-down",
                      on: {
                        click: function($event) {
                          return _vm.set_selectors_state(false)
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "white--text x-large",
                          attrs: { left: "" }
                        },
                        [_vm._v("mdi-file-word-box-outline")]
                      ),
                      _vm._v(" Admin ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm.view_to_display === "1P1C"
                ? _c(
                    "v-tab-item",
                    {
                      staticClass: "ma-0 pa-0",
                      attrs: {
                        "reverse-transition": "fade-transition",
                        transition: "fade-transition"
                      }
                    },
                    [
                      _c("tab1p1c", {
                        attrs: {
                          map_rerender_workarount: _vm.map_rerender_workarount
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.view_to_display === "MP1C"
                ? _c(
                    "v-tab-item",
                    [
                      _c("tabmp1c", {
                        attrs: {
                          cube: _vm.cube,
                          "cube_-c_-region": _vm.cube_C_Region,
                          "cube_-main_-region": _vm.cube_Main_Region,
                          "cube_-trade_-block": _vm.cube_Trade_Block,
                          cubehs6code: _vm.cubehs6code,
                          cubehs4code: _vm.cubehs4code,
                          cubehs2code: _vm.cubehs2code,
                          cubesection: _vm.cubesection,
                          map_rerender_workarount: _vm.map_rerender_workarount
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.view_to_display === "1PMC"
                ? _c(
                    "v-tab-item",
                    [
                      _c("tab1pmc", {
                        attrs: {
                          cube: _vm.cube,
                          "cube_-c_-region": _vm.cube_C_Region,
                          "cube_-main_-region": _vm.cube_Main_Region,
                          "cube_-trade_-block": _vm.cube_Trade_Block,
                          map_rerender_workarount: _vm.map_rerender_workarount
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.view_to_display === "MPMC"
                ? _c(
                    "v-tab-item",
                    { staticClass: "ma-0 pa-0" },
                    [
                      _c("tabmpmc", {
                        attrs: {
                          cube: _vm.cube,
                          "cube_-c_-region": _vm.cube_C_Region,
                          "cube_-main_-region": _vm.cube_Main_Region,
                          "cube_-trade_-block": _vm.cube_Trade_Block,
                          cubehs6code: _vm.cubehs6code,
                          cubehs4code: _vm.cubehs4code,
                          cubehs2code: _vm.cubehs2code,
                          cubesection: _vm.cubesection,
                          map_rerender_workarount: _vm.map_rerender_workarount
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" //results page for 1P1C HTML PAge "),
              _c(
                "v-tab-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: true,
                      expression: "true"
                    }
                  ],
                  staticClass: "ma-0 pa-0",
                  attrs: {
                    "reverse-transition": "fade-transition",
                    transition: "fade-transition"
                  }
                },
                [
                  _c(
                    "v-main",
                    { staticClass: "ma-0 pa-0" },
                    [
                      _c(
                        "v-container",
                        {
                          staticClass: "ma-0 pa-0",
                          attrs: { bg: "", fluid: "" }
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "ma-0 pa-0",
                              attrs: {
                                "align-center": "",
                                "fill-height": "",
                                "justify-space-around": "",
                                row: ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "pr-5 ma-0 pt-0",
                                  attrs: { sm12: "" }
                                },
                                [
                                  _c("p_c_snapshot_report", {
                                    staticClass: "ml-1 mr-1 pe-1 pa-0",
                                    attrs: { update: _vm.updating }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" //results page for 1PMC "),
              true
                ? _c(
                    "v-tab-item",
                    {
                      staticClass: "ma-0 pa-0",
                      attrs: {
                        "reverse-transition": "fade-transition",
                        transition: "fade-transition"
                      }
                    },
                    [
                      _c(
                        "v-main",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c(
                            "v-container",
                            {
                              staticClass: "ma-0 pa-0",
                              attrs: { bg: "", fluid: "" }
                            },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "ma-0 pa-0",
                                  attrs: {
                                    "align-center": "",
                                    "fill-height": "",
                                    "justify-space-around": "",
                                    row: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "pr-5 ma-0 pt-0",
                                      attrs: { sm12: "" }
                                    },
                                    [
                                      _c("div", [
                                        _c("iframe", {
                                          staticClass: "pa-0",
                                          staticStyle: {
                                            height: "70vh",
                                            width: "100%"
                                          },
                                          attrs: { id: "report_html_1pmc" }
                                        })
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" //results page for MP1C "),
              true
                ? _c(
                    "v-tab-item",
                    {
                      staticClass: "ma-0 pa-0",
                      attrs: {
                        "reverse-transition": "fade-transition",
                        transition: "fade-transition"
                      }
                    },
                    [
                      _c(
                        "v-main",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c(
                            "v-container",
                            {
                              staticClass: "ma-0 pa-0",
                              attrs: { bg: "", fluid: "" }
                            },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "ma-0 pa-0",
                                  attrs: {
                                    "align-center": "",
                                    "fill-height": "",
                                    "justify-space-around": "",
                                    row: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "pr-5 ma-0 pt-0",
                                      attrs: { sm12: "" }
                                    },
                                    [
                                      _c("div", [
                                        _c("iframe", {
                                          staticClass: "pa-0",
                                          staticStyle: {
                                            height: "70vh",
                                            width: "100%"
                                          },
                                          attrs: { id: "report_html_mp1c" }
                                        })
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" // admin page "),
              _vm.$store.getters.user_type === "admin" &&
              _vm.$store.state.app.displayAdminPage
                ? _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "", outlined: "", tile: "" } },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass:
                                "justify-space-around ma-5 pa-5 align-center overflow-y-auto"
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.user.fields
                                        .include_cell_phone_in_report
                                    ) +
                                    " "
                                )
                              ]),
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$store.state.tradeDSM
                                        .dsm_filter_setttings
                                        .Product_Combinations.results
                                        .trade_value
                                    ) +
                                    " "
                                )
                              ]),
                              _c("h3", { staticClass: "bg-" }, [
                                _vm._v("DSM Result")
                              ]),
                              _c("p", { staticClass: "caption" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$store.state.tradeDSM
                                        .dsm_filter_setttings.results.dsmresult
                                    ) +
                                    " "
                                )
                              ]),
                              _c("h3", [_vm._v("Top 6 Results")]),
                              _c("p", { staticClass: "caption" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$store.state.tradeDSM
                                        .dsm_filter_setttings.results.top6
                                    ) +
                                    " "
                                )
                              ]),
                              _c("h3", [
                                _vm._v(
                                  "History imports/exp per code and target market"
                                )
                              ]),
                              _c("p", { staticClass: "caption" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$store.state.tradeDSM
                                        .dsm_filter_setttings.results
                                        .hist_impexp_tm
                                    ) +
                                    " "
                                )
                              ]),
                              _c("h3", [
                                _vm._v("History imports/exp per code")
                              ]),
                              _c("p", { staticClass: "caption" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$store.state.tradeDSM
                                        .dsm_filter_setttings.results
                                        .hist_impexp_total
                                    ) +
                                    " "
                                )
                              ]),
                              _c("p", [
                                _vm._v(
                                  "prod search " +
                                    _vm._s(
                                      _vm.$store.getters.get_product_search_term
                                    )
                                )
                              ]),
                              _c("p", [
                                _vm._v(
                                  "marked searcg " +
                                    _vm._s(
                                      _vm.$store.getters.get_market_search_term
                                    )
                                )
                              ]),
                              _c("p", [
                                _vm._v(
                                  "country " +
                                    _vm._s(
                                      _vm.$store.getters.dsmFilterSettings[
                                        "Destination_Country"
                                      ]
                                    )
                                )
                              ]),
                              _c("p", [
                                _vm._v(
                                  "product " +
                                    _vm._s(
                                      _vm.$store.getters.dsmFilterSettings[
                                        "HS6_Code"
                                      ]
                                    )
                                )
                              ]),
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$store.getters.countriesSelectorList
                                    ) +
                                    " "
                                )
                              ]),
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$store.state.user.userServerInfo.user
                                        .fields.institution[0].fields
                                        .institution_name
                                    ) +
                                    " "
                                )
                              ]),
                              _c("h3", [_vm._v("View type")]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$store.state.tradeDSM
                                      .dsm_filter_setttings.Product_Combinations
                                  )
                                )
                              ]),
                              _c("h3", [_vm._v("User Info-")]),
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$store.state.user.userServerInfo[
                                        "exchange_rate"
                                      ]
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }