<template>
  <help-dialog-base>
    <template v-slot:card_title>
      <v-icon class="white--text mr-3">{{ icon() }}</v-icon>
      Download {{ ms_office_type }} Report
    </template>
    <template v-slot:card_text>
      <v-container>
        <v-row class="justify-content-between">
          <v-col sm="6" class="outline rounded">
            <v-row no-gutters>
              <v-col sm="12" class="mb-6">
                <span class="h1 text-decoration-underline">Report Settings</span>
              </v-col>
              <v-col sm="10">
                <currency-changer></currency-changer>
              </v-col>
              <v-col sm="12" v-if="show_sections">
                <v-checkbox
                  v-model="$store.state.app.include_overview_sections_in_report"
                  label=" Include economic section in report?"
                  class="mb-0 pa-0"
                ></v-checkbox>
              </v-col>
              <v-col v-if="show_mobile" sm="12">
                <v-checkbox
                  v-model="
                    $store.state.user.userServerInfo.user.fields
                      .include_cell_phone_in_report
                  "
                  label="Include mobile number in report?"
                  class="ma-0"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>

          <v-col sm="6" v-if="report_setup_complete" class="outline rounded ">
            <v-row no-gutters>
              <v-col sm="12" class="mb-6">
                <span class="h1 text-decoration-underline">Available Reports</span>
              </v-col>
              <v-col sm="12">
                <v-radio-group v-model="report_selection_ix" class="ma-0 pa-0 ml-2">
                  <template v-for="(report, ix) in report_list">
                    <v-tooltip right :key="report.id">
                      <template v-slot:activator="{on}">
                        <div v-on="on" :key="report.id">
                          <v-radio
                            :label="report['report_name']"
                            :key="report.id"
                            :value="ix"
                          >
                          </v-radio>
                        </div>
                      </template>
                      <span>{{ report['description'] }}</span>
                    </v-tooltip>
                  </template>
                </v-radio-group>
                <span v-if="report_list.length === 1">
                  There is currently only one report available. Additional reports will
                  become available in future.
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-slot:card_actions>
      <v-btn class="trade-blue white--text" text @click="download_handler">
        Download Report
      </v-btn>
      <v-btn
        class="trade-blue white--text"
        text
        @click="email_handler"
        v-if="show_email_button"
      >
        Email Report
      </v-btn>
    </template>
  </help-dialog-base>
</template>

<script>
import helpDialogBase from '@/components/application-level/helper-components/help-dialog-base.vue';
import currencyChanger from '@/components/trade-dsm-application/mixins/currency-changer.vue';

export default {
  name: 'dialog-download-word',
  props: {
    ms_office_type: String,
    download_func: Function,
    email_func: Function,
    show_sections: Boolean,
    show_mobile: Boolean,
    show_email_button: Boolean,
  },
  components: {
    helpDialogBase,
    currencyChanger,
  },
  mounted() {
    this.save_overview_setting = this.$store.state.app.include_overview_sections_in_report;
    this.save_phone_setting = this.$store.state.user.userServerInfo.user.fields.include_cell_phone_in_report;
  },
  computed: {
    institution_report_setup_dict() {
      return JSON.parse(
        this.$store.state.user.userServerInfo.user.fields.institution[0].fields[
          'institution_report_setup'
        ],
      );
    },
    report_type_to_generate() {
      return this.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
        .report_type_to_generate;
    },
    report_setup_complete() {
      // temp to not show the selectors if the cube has not been set up
      let report_type_to_generate = this.$store.state.tradeDSM.dsm_filter_setttings
        .Filters.META.report_type_to_generate;
      let doc_type = this.document_download_type();
      // fields.institution[0].fields.'institution_report_setup'
      return (
        this.institution_report_setup_dict[report_type_to_generate][doc_type].length > 0
      );
    },
    report_list() {
      // temp to not show the selectors if the cube has not been set up
      let report_type_to_generate = this.$store.state.tradeDSM.dsm_filter_setttings
        .Filters.META.report_type_to_generate;
      let doc_type = this.document_download_type();
      // let institution_report_setup = JSON.parse(this.$store.state.user.userServerInfo.user.fields.institution[0].fields['institution_report_setup'])
      // console.log(institution_report_setup)
      return this.institution_report_setup_dict[report_type_to_generate][doc_type];
    },
  },
  methods: {
    async closeMe() {
      this.$store.state.app.activeDialogComponent = null;
      setTimeout(() => {
        this.$store.state.app.include_overview_sections_in_report = this.save_overview_setting;
        this.$store.state.user.userServerInfo.user.fields.include_cell_phone_in_report = this.save_phone_setting;
      }, 3000);
    },
    async download_handler() {
      if (this.report_setup_complete === true) {
        // there is a report setup blop in the DB
        this.download_func(
          this.document_download_type(),
          this.institution_report_setup_dict[this.report_type_to_generate][
            this.document_download_type()
          ][this.report_selection_ix],
        ).then(async () => {
          await this.closeMe();
        });
      } else {
        // setup not complted, thus just run as per normal
        this.download_func(this.document_download_type()).then(async () => {
          await this.closeMe();
        });
      }
    },
    async email_handler() {
      if (this.report_setup_complete === true) {
        // there is a report setup blop in the DB
        this.email_func(
          'email',
          this.institution_report_setup_dict[this.report_type_to_generate][
            this.document_download_type()
          ][this.report_selection_ix],
        ).then(async () => {
          await this.closeMe();
        });
      } else {
        // setup not complted, thus just run as per normal
        this.email_func(this.document_download_type()).then(async () => {
          await this.closeMe();
        });
      }
    },
    document_download_type() {
      if (this.ms_office_type === 'Word') {
        return 'word';
      } else if (this.ms_office_type === 'Powerpoint') {
        return 'pptx';
      } else if (this.ms_office_type === 'Excel') {
        return 'excel';
      }
    },
    icon() {
      if (this.ms_office_type === 'Word') {
        return 'mdi-microsoft-word';
      } else if (this.ms_office_type === 'Powerpoint') {
        return 'mdi-microsoft-powerpoint';
      } else if (this.ms_office_type === 'Excel') {
        return 'mdi-microsoft-excel';
      } else return 'mdi-microsoft-word';
    },
  },
  data() {
    return {
      save_phone_setting: false,
      save_overview_setting: false,
      report_selection_ix: 0,
    };
  },
};
</script>

<style scoped></style>
