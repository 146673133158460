var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa- ma-0 mr-12 mt-4 mb-1 ml-1", attrs: { fluid: "" } },
    [
      _c(
        "v-tooltip",
        {
          attrs: {
            disabled: _vm.get_selectors_state,
            color: "trade-blue text-subtitle-1"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-container",
                    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
                    [
                      _c("v-row"),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { sm: "4", md: "2" } },
                            [
                              _c("v-autocomplete", {
                                ref: "product_selector_ref",
                                staticClass: "pr-1 pb-0 font-weight-light mt-3",
                                attrs: {
                                  id: "product_selector",
                                  disabled: !_vm.get_selectors_state,
                                  items: _vm.productDropDownItems,
                                  "search-input": _vm.product_search,
                                  label: _vm.advanved_filter_in_progress
                                    ? "Products (" +
                                      _vm.$store.state.tradeDSM
                                        .dsm_filter_setttings
                                        .Product_Combinations.results
                                        .products_count +
                                      " filtered)"
                                    : "Product Code",
                                  loading:
                                    _vm.$store.getters.productsList.length ===
                                    0,
                                  value: _vm.productDropDownValue,
                                  "background-color": "white",
                                  "clear-icon": "$clear",
                                  "append-icon":
                                    _vm.productDropDownItems.length > 1
                                      ? "$dropdown"
                                      : null,
                                  clearable: "",
                                  color: "green",
                                  dense: "",
                                  "hide-details": "true",
                                  "hide-selected": "",
                                  "item-color": "green",
                                  outlined: "",
                                  "prepend-icon": "mdi-dolly"
                                },
                                on: {
                                  "update:searchInput": function($event) {
                                    _vm.product_search = $event
                                  },
                                  "update:search-input": function($event) {
                                    _vm.product_search = $event
                                  },
                                  change: function($event) {
                                    return _vm.productsDropDownChanged($event)
                                  },
                                  "click:clear": _vm.productsDropDownClear
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c("span", {
                                                          staticClass:
                                                            "caption",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.highlight(
                                                                item.text,
                                                                _vm.product_search
                                                              )
                                                            )
                                                          }
                                                        })
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [_c("span", [_vm._v("hover")])]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { sm: "4", md: "2" } },
                            [
                              _c("v-autocomplete", {
                                ref: "market_selector_ref",
                                staticClass: "ml-2 font-weight-light mt-3",
                                attrs: {
                                  id: "country_selector",
                                  "flatten-search-results": true,
                                  disabled: !_vm.get_selectors_state,
                                  items: _vm.countriesDropDownItems,
                                  label: _vm.advanved_filter_in_progress
                                    ? "Markets (" +
                                      _vm.$store.state.tradeDSM
                                        .dsm_filter_setttings
                                        .Product_Combinations.results
                                        .markets_count +
                                      " filtered)"
                                    : "Markets",
                                  value: _vm.countriesDropDownValue,
                                  "background-color": "white",
                                  "clear-icon": "$clear",
                                  "append-icon":
                                    _vm.countriesDropDownItems.length > 1
                                      ? "$dropdown"
                                      : null,
                                  clearable: "",
                                  color: "green",
                                  "hide-details": "true",
                                  "hide-selected": "",
                                  "item-color": "green",
                                  outlined: "",
                                  "prepend-icon": "mdi-earth",
                                  height: "5",
                                  "search-input": _vm.market_search,
                                  dense: ""
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.countriesDropDownChanged($event)
                                  },
                                  click: function($event) {
                                    _vm.my_market_list_value = null
                                  },
                                  "click:clear": _vm.countriesDropDownClear,
                                  "update:searchInput": function($event) {
                                    _vm.market_search = $event
                                  },
                                  "update:search-input": function($event) {
                                    _vm.market_search = $event
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c("span", {
                                                          staticClass:
                                                            "caption",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.highlight(
                                                                item.text,
                                                                _vm.market_search
                                                              )
                                                            )
                                                          }
                                                        })
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [_c("span", [_vm._v("hover")])]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { sm: "4", md: "2" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "ml-2",
                                      attrs: { cols: "1" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { staticClass: "ma-0 pa-0 mt-1 pt-1" },
                                        [
                                          _vm._v(
                                            "mdi-format-list-bulleted-square "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { md: "9", lg: "10" } },
                                    [
                                      _c("Treeselect", {
                                        ref: "product_multiselect",
                                        staticClass: "ml-2",
                                        attrs: {
                                          options:
                                            _vm.product_tree_select_options,
                                          "value-consists-of":
                                            "BRANCH_PRIORITY",
                                          disableFuzzyMatching: true,
                                          placeholder: "Products Lists"
                                        },
                                        on: {
                                          select: function($event) {
                                            return _vm.load_products($event)
                                          },
                                          change: function($event) {
                                            return _vm.clear_product_selection(
                                              $event
                                            )
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "option-label",
                                              fn: function(ref) {
                                                var node = ref.node
                                                var shouldShowCount =
                                                  ref.shouldShowCount
                                                var count = ref.count
                                                var labelClassName =
                                                  ref.labelClassName
                                                return _c(
                                                  "label",
                                                  { class: labelClassName },
                                                  [
                                                    node.raw.long !== ""
                                                      ? _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {
                                                                            staticClass:
                                                                              "caption"
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                node.label
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "caption"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    node.raw
                                                                      .long
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "caption"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  node.label
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                  ],
                                                  1
                                                )
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.product_tree_select_value,
                                          callback: function($$v) {
                                            _vm.product_tree_select_value = $$v
                                          },
                                          expression:
                                            "product_tree_select_value"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { sm: "4", md: "2" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pl-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "ml-2 pl-0",
                                      attrs: { cols: "1" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { staticClass: "ma-0 pa-0 mt-1 pt-1" },
                                        [
                                          _vm._v(
                                            "mdi-format-list-bulleted-square "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { md: "9", lg: "10" } },
                                    [
                                      _c("Treeselect", {
                                        ref: "market_multiselect",
                                        attrs: {
                                          options:
                                            _vm.market_tree_select_options,
                                          "value-consists-of":
                                            "BRANCH_PRIORITY",
                                          placeholder: "Markets Lists",
                                          disableFuzzyMatching: true
                                        },
                                        on: {
                                          select: function($event) {
                                            return _vm.load_markets($event)
                                          },
                                          change: function($event) {
                                            return _vm.clear_market_selection(
                                              $event
                                            )
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "option-label",
                                              fn: function(ref) {
                                                var node = ref.node
                                                var shouldShowCount =
                                                  ref.shouldShowCount
                                                var count = ref.count
                                                var labelClassName =
                                                  ref.labelClassName
                                                return _c(
                                                  "label",
                                                  { class: labelClassName },
                                                  [
                                                    node.raw.long !== ""
                                                      ? _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {
                                                                            staticClass:
                                                                              "caption"
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                node.label
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "caption"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    node.raw
                                                                      .long
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "caption"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  node.label
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                  ],
                                                  1
                                                )
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.market_tree_select_value,
                                          callback: function($$v) {
                                            _vm.market_tree_select_value = $$v
                                          },
                                          expression: "market_tree_select_value"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { sm: "4", md: "2" } },
                            [
                              _c("v-autocomplete", {
                                staticClass: "pr-1 font-weight-light mt-3",
                                attrs: {
                                  id: "display_magnitude",
                                  disabled: !_vm.get_selectors_state,
                                  items: _vm.display_magnitude,
                                  "background-color": "white",
                                  color: "green",
                                  dense: "",
                                  "hide-details": "true",
                                  "item-color": "green",
                                  label: "Display Magnitude",
                                  outlined: ""
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.change_multipler($event)
                                  },
                                  "click:prepend": function($event) {
                                    return _vm.sync_selectors()
                                  }
                                },
                                model: {
                                  value: _vm.magnitude_start,
                                  callback: function($$v) {
                                    _vm.magnitude_start = $$v
                                  },
                                  expression: "magnitude_start"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { sm: "4", md: "2" } },
                            [_c("currency-changer")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c("span", [
            _vm._v(
              "The filters are disabled in this view. Select the Home button to refine report."
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }