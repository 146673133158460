var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "rounded",
      attrs: { persistent: "", width: "800", "retain-focus": "" },
      model: {
        value: _vm.select_custom_filter_open,
        callback: function($$v) {
          _vm.select_custom_filter_open = $$v
        },
        expression: "select_custom_filter_open"
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "grey lighten-3",
          attrs: { dark: "", outlined: "", rounded: "lg" }
        },
        [
          _c(
            "v-card-title",
            { staticClass: "trade-blue-text text-h5 ml-2" },
            [
              _c(
                "v-flex",
                [
                  _c("span", [
                    _vm._v(" Please select custom filter to apply: ")
                  ]),
                  _c("help-link", {
                    staticClass: "mr-2",
                    staticStyle: { float: "right" },
                    attrs: { help_id: "6" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-subtitle",
            {
              staticClass:
                "ml-1 mt-1 outline mr-8 ml-8 align grey lighten-2 mb-0 pb-2"
            },
            [
              _c("div", { staticClass: "trade-blue-text text-h6 pt-2" }, [
                _vm._v(
                  " Current selection: " +
                    _vm._s(
                      _vm.custom_filers_definition.length > 0
                        ? _vm.custom_filers_definition[
                            _vm.selected_custom_filer
                          ]["name"]
                        : ""
                    ) +
                    " "
                )
              ]),
              _c("div", {
                staticClass: "trade-blue-text subtitle-1",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.custom_filers_definition.length > 0
                      ? _vm.custom_filers_definition[_vm.selected_custom_filer][
                          "hover_description"
                        ]
                      : ""
                  )
                }
              })
            ]
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    [
                      _vm.custom_filers_definition.length > 0
                        ? _c(
                            "v-radio-group",
                            {
                              staticClass: "mt-0",
                              attrs: {
                                value: _vm.select_custom_filter_open,
                                "hide-details": ""
                              },
                              model: {
                                value: _vm.selected_custom_filer,
                                callback: function($$v) {
                                  _vm.selected_custom_filer = $$v
                                },
                                expression: "selected_custom_filer"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "overflow-y-auto pa-2 rounded",
                                  staticStyle: { "max-height": "400px" }
                                },
                                [
                                  _vm._l(_vm.custom_filers_definition, function(
                                    filter,
                                    ix
                                  ) {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          key: filter.id,
                                          attrs: {
                                            left: "",
                                            color: "success",
                                            "max-width": "200px",
                                            disabled:
                                              filter["hover_description"]
                                                .length === 0
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "filterItem trade-blue-text outline"
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            attrs: {
                                                              "no-gutters": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "trade-blue-text"
                                                              },
                                                              [
                                                                _c("v-radio", {
                                                                  key:
                                                                    filter.id +
                                                                    filter.id +
                                                                    1,
                                                                  staticClass:
                                                                    "caption trade-blue-text",
                                                                  attrs: {
                                                                    color:
                                                                      "#1f3c5f"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "label",
                                                                        fn: function() {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "trade-blue-text"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    "[" +
                                                                                      (ix +
                                                                                        1) +
                                                                                      "] : " +
                                                                                      filter.name
                                                                                  ) +
                                                                                    " "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "trade-blue-text font-weight-bold"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    ix ===
                                                                                      _vm
                                                                                        .customer_filters_copy[
                                                                                        "current_value_list_ix"
                                                                                      ]
                                                                                      ? "  (*current selection))"
                                                                                      : ""
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "trade-blue-text font-weight-bold"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    ix ===
                                                                                      _vm
                                                                                        .customer_filters_copy[
                                                                                        "startup_value_list_ix"
                                                                                      ]
                                                                                      ? "  (*default)"
                                                                                      : ""
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        },
                                                                        proxy: true
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.hover_text(
                                                  filter.hover_description
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  })
                                ],
                                2
                              )
                            ]
                          )
                        : [
                            _c("h3", { staticClass: "trade-blue-text ml-8" }, [
                              _vm._v(" No custom filters found. ")
                            ])
                          ]
                    ],
                    2
                  ),
                  false
                    ? _c(
                        "v-col",
                        { attrs: { sm: "6" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "trade-blue-text" },
                                [
                                  _c("div", {
                                    staticClass: "mt-1 ",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.custom_filers_definition.length > 0
                                          ? _vm.custom_filers_definition[
                                              _vm.selected_custom_filer
                                            ]["hover_description"]
                                          : ""
                                      )
                                    }
                                  }),
                                  _c("pre", {
                                    staticClass:
                                      "white trade-blue-text pa-2 rounded caption",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.json_formattter(
                                          _vm.custom_filers_definition.length >
                                            0
                                            ? _vm.custom_filers_definition[
                                                _vm.selected_custom_filer
                                              ]["query"]
                                            : ""
                                        )
                                      )
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-subtitle",
            { staticClass: "ml-1 outline mr-8 ml-8 align grey lighten-2 mb-2" },
            [
              _c("div", { staticClass: "trade-blue-text subtitle-1 pt-2" }, [
                _vm._v(
                  " Note this will override current applied method filters."
                ),
                _c("br"),
                _vm._v(
                  " Current Market and Products selections will be retained. "
                )
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "text-right mr-8 mt-1 mb-1" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "trade-blue mr-2 v-btn--outlined",
                  attrs: { disabled: !_vm.custom_filers_definition.length > 0 },
                  on: {
                    click: function($event) {
                      return _vm.load_custom_filter_cube(false)
                    }
                  }
                },
                [_vm._v("Apply ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "trade-blue mr-2 v-btn--outlined",
                  attrs: { disabled: !_vm.custom_filers_definition.length > 0 },
                  on: {
                    click: function($event) {
                      return _vm.load_custom_filter_cube(true)
                    }
                  }
                },
                [_vm._v("Set as default ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "trade-blue v-btn--outlined",
                  on: { click: _vm.close_window }
                },
                [_vm._v("Close ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }