var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "800", id: "helpdialog" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "text-h5 trade-blue lighten-2" },
            [_vm._t("card_title")],
            2
          ),
          _c("v-card-text", [_vm._t("card_text")], 2),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._t("card_actions"),
              _c(
                "v-btn",
                {
                  staticClass: "trade-blue white--text",
                  attrs: { text: "" },
                  on: { click: _vm.closeMe }
                },
                [_vm._v(" Close ")]
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }