var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        [
          _c("vue-plotly", {
            staticClass: "pa-0 ma-0",
            attrs: {
              id: "abc",
              data: _vm.plotData,
              layout: _vm.layout,
              options: _vm.options
            }
          })
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "align-center" },
        [
          _c("v-img", {
            staticClass: "align-center",
            attrs: {
              "max-height": "100",
              "max-width": "65",
              src: "/reo_pics/error.png"
            }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }