var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ml-12 pa-0 white mt-6" },
    [
      _c("productFilterOptimsed_v2", {
        staticClass: "pt-0 mt-0 pb-0 mb-0",
        attrs: {
          selector_type: "products",
          visible: _vm.$store.state.tradeDSM.productsOpen
        }
      }),
      _c("productFilterOptimsed_v2", {
        staticClass: "pt-0 mt-0 pb-0 mb-0",
        attrs: {
          selector_type: "markets",
          visible: _vm.$store.state.tradeDSM.countriesOpen
        }
      }),
      _c("main-filters", {
        attrs: {
          filters: _vm.$store.getters.dsmFilterSettings.Filters,
          visible: _vm.$store.state.tradeDSM.mainFilterOpen
        }
      }),
      _c("advanced-filters", {
        attrs: { visible: _vm.$store.state.tradeDSM.advancedFilterOpen }
      }),
      _c("trade-left-side-bar"),
      true
        ? _c(
            "v-container",
            { staticClass: "ml-1 pt-1 mt-0", attrs: { fluid: "" } },
            [
              _vm.loading
                ? _c("v-progress-linear", {
                    attrs: { color: "green lighten-3", indeterminate: "" }
                  })
                : _vm._e(),
              _c(
                "v-container",
                { staticClass: "pa-0 pr-1 ", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-0 ma-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "ma-0 pa-0", attrs: { sm: "12" } },
                        [
                          _c("trade-filters-top", {
                            staticClass: "outline_top pt-0 pb-0 pl-1 pr-3"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("trade-results-page", { staticClass: "ma-0 pa-0 ml-1  white" })
            ],
            1
          )
        : _c(
            "v-container",
            { attrs: { "fill-height": "", fluid: "" } },
            [
              _c(
                "v-layout",
                {
                  staticClass: "justify-center align-center",
                  attrs: { row: "" }
                },
                [
                  _c(
                    "v-flex",
                    { staticClass: "align-center justify-center sm-12" },
                    [
                      _c("span", { staticClass: "text-center" }, [
                        _c("h1", [_vm._v(" Loading.... ")])
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }