var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      staticClass: "pl-2 mr-1 sm12 xl5 no-gutters ma-0",
      class: {
        md4: !_vm.$store.state.app.big_map,
        md12: _vm.$store.state.app.big_map
      },
      attrs: { id: "map-and-flags" }
    },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("help-link", {
                staticStyle: { float: "right" },
                attrs: { help_id: "10" }
              }),
              _c(
                "v-icon",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.$store.state.app.big_map,
                      expression: "!$store.state.app.big_map"
                    }
                  ],
                  staticStyle: { float: "right" },
                  attrs: { "data-html2canvas-ignore": "" },
                  on: {
                    click: function($event) {
                      return _vm.take_snapshot(
                        _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters
                          .META.report_type_to_generate === "1P1C"
                          ? "1p1c_map"
                          : "mpmc_map"
                      )
                    }
                  }
                },
                [_vm._v("mdi-camera ")]
              ),
              !_vm.$store.state.app.big_map
                ? _c(
                    "v-icon",
                    {
                      staticStyle: { float: "right" },
                      attrs: { "data-html2canvas-ignore": "" },
                      on: {
                        click: function($event) {
                          _vm.$store.state.app.big_map = !_vm.$store.state.app
                            .big_map
                        }
                      }
                    },
                    [_vm._v("mdi-arrow-expand-all ")]
                  )
                : _c(
                    "v-icon",
                    {
                      staticClass: "mr-5",
                      staticStyle: { float: "right" },
                      on: {
                        click: function($event) {
                          _vm.$store.state.app.big_map = !_vm.$store.state.app
                            .big_map
                        }
                      }
                    },
                    [_vm._v("mdi-arrow-collapse-all ")]
                  ),
              _vm._t("default", null, { id: "Map" })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "keep-alive",
        [
          _c("mpmc-graphs-importer-exporter", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  this.current_report_to_run !== "1P1C" &&
                  !_vm.$store.state.app.big_map,
                expression:
                  "this.current_report_to_run !== '1P1C' && !$store.state.app.big_map"
              }
            ]
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }